import React, {  useRef, useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useDarkMode } from '../../context/darkModeContext'; 


import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns';
import { format } from 'date-fns';
import { addDays, subDays } from 'date-fns';
import es from 'date-fns/locale/es'; // Importa el idioma español

import { isValidDate } from '../../utils/utils';

function ActividadesForm() {
  // eslint-disable-next-line
  const {darkMode, setDarkMode} = useDarkMode();
  const [valorCambiado, setValorCambiado] = useState(false);
  const [valorCambiadoPeso, setValorCambiadoPeso] = useState(false);

  const [sortingColumnSeleccionar, setSortingColumnSeleccionar] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
  const [sortDirectionSeleccionar, setSortDirectionSeleccionar] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

  const [sortingColumnSeleccionados, setSortingColumnSeleccionados] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
  const [sortDirectionSeleccionados, setSortDirectionSeleccionados] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

  const [sortingColumnDetalle, setSortingColumnDetalle] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
  const [sortDirectionDetalle, setSortDirectionDetalle] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'
  
  const inputPredio       = useRef(null);
  const inputPotrero      = useRef(null);
  const inputSubpotrero   = useRef(null);
  const inputResponsable  = useRef(null);
  const inputPrioridad    = useRef(null);
  const inputRaza         = useRef(null);
  const inputTodoeldia    = useRef(null);

  const inputInicioHora     = useRef(null);
  const inputInicioMinuto   = useRef(null);
  const inputTerminoHora    = useRef(null);
  const inputTerminoMinuto  = useRef(null);
  const inputObservacion    = useRef(null);
  const inputLote           = useRef(null);
  
  const inputBuscarAnimal = useRef(null);
  const modalBuscarAnimal = useRef(null);

  const modalAddProducto        = useRef(null);
  const inputBuscarProducto     = useRef(null);
  const inputCantidad           = useRef(null);
  
  
  const bootstrap = window.bootstrap; // se agregó para poder llamar al método hide() de un modal
  
  const { id_actividad } = useParams();
  const [icon, setIcon] = useState('');
  const [textButton, setTextButton] = useState('');

  const navigate = useNavigate();
  const modulo = 'actividades'

  // Obtener la fecha actual
  const fechaActual = new Date();

  // Obtener el día, el mes y el año de la fecha actual
  const dia = fechaActual.getDate().toString().padStart(2, '0');
  const mes = (fechaActual.getMonth() + 1).toString().padStart(2, '0');
  const anio = fechaActual.getFullYear();

  // Crear la cadena con el formato "dd-mm-yyyy"
  const fechaFormateada = `${anio}-${mes}-${dia}`;

  const minSelectableDate = subDays(new Date(), 30);
  const maxSelectableDate = addDays(new Date(), 30);

  const initialDatoState = {
    id_actividad      : null,
    estado            : '',
    id_tipoactividad  : '',
    n_tipoactividad   : '',
    created_date      : '',
    fecha             : fechaFormateada,
    id_especie        : '',
    id_lote           : '',
    id_raza           : '',
    n_especie         : '',
    modalidad         : '',
    id_responsable    : '',
    prioridad         : '',
    fecha_inicio      : fechaFormateada,
    hora_inicio       : '',
    minuto_inicio       : '',
    fecha_termino     : fechaFormateada, 
    hora_termino      : '',
    minuto_termino    : '',
    todo_el_dia       : '',
    observacion       : '',
    id_predio         : '',
    id_potrero        : '',
    id_subpotrero     : ''
  };
  
  const [dato, setDato] = useState(initialDatoState);
  const [datosdetalleseleccionarOriginales, setDatosdetalleseleccionarOriginales] = useState([]);
  const [datosdetalleseleccionar, setDatosdetalleseleccionar] = useState([]);

  const [datosdetalleseleccionadosOriginales, setDatosdetalleseleccionadosOriginales] = useState([]);
  const [datosdetalleseleccionados, setDatosdetalleseleccionados] = useState([]);

  const [datosdetalleproductos, setDatosdetalleproductos]   = useState([]);
  const [datosdetalleproductosOriginales, setDatosdetalleproductosOriginales]  = useState([]);

  const [datoslotes, setDatoslotes]           = useState([]);
  
  const [isLoadingDatosForm, setIsLoadingDatosForm]               = useState(true);
  const [isLoadingDetalleSeleccionar, setIsLoadingDetalleSeleccionar]       = useState(false);
  const [isLoadingDetalleSeleccionados, setIsLoadingDetalleSeleccionados]     = useState(false);
  const [isLoadingBusquedaAnimales, setIsLoadingBusquedaAnimales] = useState(false);
  const [isLoadingDetalleProductos, setIsLoadingDetalleProductos] = useState(false);
  const [isLoadingProductos, setIsLoadingProductos] = useState(false);

  const [productosOriginales, setProductosOriginales]                 = useState([]);
  const [productos, setProductos]                                     = useState([]);
  const [sortingColumnModalProducto, setSortingColumnModalProducto]   = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
  const [sortDirectionModalProducto, setSortDirectionModalProducto]   = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'
  
  const [responsables, setResponsables]   = useState([]);
  const [razas, setRazas]                 = useState([]);
  const [predios, setPredios]             = useState([]);
  const [potreros, setPotreros]           = useState([]);
  const [subpotreros, setSubpotreros]     = useState([]);
  const [listaAnimales, setListaAnimales] = useState([]);

  const getDetalleSeleccionar = () => {
    setIsLoadingDetalleSeleccionar(true);
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalleseleccionar/${id_actividad}/${dato.id_especie}/${dato.id_predio}/${dato.id_potrero}/${dato.id_subpotrero}`,{withCredentials: true})
      .then(response => {
        setDatosdetalleseleccionarOriginales(response.data)
        setDatosdetalleseleccionar(response.data);
        setIsLoadingDetalleSeleccionar(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  const getDetalleSeleccionados = () => {
    setIsLoadingDetalleSeleccionados(true);
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalleseleccionados/${id_actividad}`,{withCredentials: true})
      .then(response => {
        setDatosdetalleseleccionadosOriginales(response.data)
        setDatosdetalleseleccionados(response.data);
        setIsLoadingDetalleSeleccionados(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  const getDetalleProductos = async () => {
    setIsLoadingDetalleProductos(true);
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalleproducto/${id_actividad}`,{withCredentials: true});
      setDatosdetalleproductos(response.data);
      setDatosdetalleproductosOriginales(response.data);
      setIsLoadingDetalleProductos(false);  
    } catch(error) {
      console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    }
  };

  const getProductos = async () => {
    setIsLoadingProductos(true);
    const strBuscar = inputBuscarProducto.current.value;
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/productos_busqueda_aprox_actividad`, 
      {
        withCredentials: true,
        params: {
          strBuscar: strBuscar, 
          id_actividad : id_actividad
        }
      });
      setProductos(response.data);
      setProductosOriginales(response.data);
      setIsLoadingProductos(false);
    } catch(error) {
      console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    }
  };

  const getDato = async (id_actividad) => {
    setIsLoadingDatosForm(true);
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id_actividad}`,{withCredentials: true});
      setDato(response.data);
      setIsLoadingDatosForm(false);
    } catch(error) {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  const getResponsables = () => {
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/responsables`,{withCredentials: true})
      .then(response => {
        setResponsables(response.data);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  const getRazas = async () => {
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/razasxespecie/${dato.id_especie}`,{withCredentials: true})
      .then(response => {
        setRazas(response.data);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  const getPredios = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/predios`,{withCredentials: true});
      setPredios(response.data);
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  useEffect(() => {
    if (predios.length > 0 && inputPredio.current) {
      setDato(prevDato => ({...prevDato, 'id_predio': inputPredio.current.selectedOptions[0].value}));
    }
  }, [predios]);

  useEffect(() => {
    if (dato.id_predio !== '' && dato.id_predio !== null) {
      getPotreros();
    }
    //eslint-disable-next-line
  }, [dato.id_predio]);
  
  const getPotreros = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/potrerosxpredio/${dato.id_predio}`,{withCredentials: true});
      setPotreros(response.data);
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  useEffect(() => {
    if (potreros.length > 0 && inputPotrero.current) {
      setDato(prevDato => ({...prevDato, 'id_potrero': inputPotrero.current.selectedOptions[0].value}));
    }
  }, [potreros]);

  useEffect(() => {
    if (dato.id_potrero !== '' && dato.id_potrero !== null) {
      getSubpotreros();
    }
    //eslint-disable-next-line
  }, [dato.id_potrero]);

  const getSubpotreros = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/subpotrerosxpotrero/${dato.id_potrero}`,{withCredentials: true});
      setSubpotreros(response.data);
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  useEffect(() => {
    if (subpotreros.length > 0 && inputSubpotrero.current) {
      setDato(prevDato => ({...prevDato, 'id_subpotrero': inputSubpotrero.current.selectedOptions[0].value}));
    }
  }, [subpotreros]);

  useEffect(() => {
    if(cargaSeleccionAnimales){
      if (dato.id_predio !== '' && dato.id_predio !== null && dato.id_potrero !== '' && dato.id_potrero !== null) {
        getDetalleSeleccionar();
      }
    }
    //eslint-disable-next-line
  }, [dato.id_potrero, dato.id_subpotrero]);
  //--------------------------------------------------------------------------------

  useEffect(() => {
    if (responsables.length > 0 && inputResponsable.current) {
      // la llamada tiene que ser con 'prevDato =>', si no queda en un loop infinito
      setDato(prevDato => ({
        ...prevDato,
        'id_responsable': inputResponsable.current.selectedOptions[0].value
      }));
    }
  }, [responsables]); //Este se ejecuta cuando se carga completo el arreglo  

  const handleCheckboxChange = (event) => {
    setDato({ ...dato, [ event.target.name]: event.target.checked });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDato({ ...dato, [name]: value });
  };
  
  function handleDateChange(date, name) {
    if(date){      
      setDato({ ...dato, [name]: format(date, 'yyyy-MM-dd') });
    }
  }

  /************ inicio funciones orden array (Datos tabla SELECCION ANIMALES) *************************************** */
  const handleColumnClickSeleccionar = (columnIndex) => {
    if (sortingColumnSeleccionar === columnIndex) {setSortDirectionSeleccionar(sortDirectionSeleccionar === 'asc' ? 'desc' : 'asc');
    } else { setSortingColumnSeleccionar(columnIndex); setSortDirectionSeleccionar('asc');}
  };

  useEffect(() => {    
    const rows = [...datosdetalleseleccionarOriginales]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
    if (sortingColumnSeleccionar !== -1) {  
      rows.sort((a, b) => {
        const valA = a[Object.keys(a)[sortingColumnSeleccionar]]; const valB = b[Object.keys(b)[sortingColumnSeleccionar]];
        if (typeof valA === 'number' && typeof valB === 'number') {
          return valA - valB;
        } else if (isValidDate(valA) && isValidDate(valB)) {
          const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-'));
          return dateA - dateB;
        } else { return valA.localeCompare(valB); }
      });
  
      if (sortDirectionSeleccionar === 'desc') { rows.reverse();}
      setDatosdetalleseleccionar(rows); // Actualizamos el estado de sortedRows con los datos ordenados
    }
  }, [sortingColumnSeleccionar, sortDirectionSeleccionar, datosdetalleseleccionarOriginales]); // Dependencias: sortingColumn, sortDirection, datosOriginales
  /************ fin funciones orden array (datos tabla SELECCION ANIMALES) *************************************** */

  /************ inicio funciones orden array (Datos tabla ANIMALES SELECCIONADOS) *************************************** */
  const handleColumnClickSeleccionados = (columnIndex) => {
    if (sortingColumnSeleccionados === columnIndex) {setSortDirectionSeleccionados(sortDirectionSeleccionados === 'asc' ? 'desc' : 'asc');
    } else { setSortingColumnSeleccionados(columnIndex); setSortDirectionSeleccionados('asc');}
  };

  useEffect(() => {    
    const rows = [...datosdetalleseleccionadosOriginales]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
    if (sortingColumnSeleccionados !== -1) {  
      rows.sort((a, b) => {
        const valA = a[Object.keys(a)[sortingColumnSeleccionados]]; const valB = b[Object.keys(b)[sortingColumnSeleccionados]];
        if (typeof valA === 'number' && typeof valB === 'number') {
          return valA - valB;
        } else if (isValidDate(valA) && isValidDate(valB)) {
          const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-'));
          return dateA - dateB;
        } else { return valA.localeCompare(valB); }
      });
  
      if (sortDirectionSeleccionados === 'desc') { rows.reverse();}
      setDatosdetalleseleccionados(rows); // Actualizamos el estado de sortedRows con los datos ordenados
    }
  }, [sortingColumnSeleccionados, sortDirectionSeleccionados, datosdetalleseleccionadosOriginales]); // Dependencias: sortingColumn, sortDirection, datosOriginales
  /************ fin funciones orden array (datos tabla ANIMALES SELECCIONADOS) *************************************** */

  const AddAnimal = async (e, id_ficha, diio) => {
    e.preventDefault(); // Prevenir el evento submit del formulario
    
    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }

    try {
      var data = {
        id_actividad    : id_actividad,
        id_ficha  : id_ficha,
        diio      : diio, 
        usuario : localStorage.getItem("usuario")
      };
      await axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalleanimal`, data,{withCredentials: true});
      toast.success("Animal agregado correctamente", {
        position: "top-right", autoClose: 2000, hideProgressBar: true,
        closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
      });
      
      setDatosdetalleseleccionar(datosdetalleseleccionar.filter(datosdetalleseleccionar => datosdetalleseleccionar.id_ficha !== id_ficha)); //Quitar del arreglo la fila eliminada
      setDatosdetalleseleccionarOriginales(datosdetalleseleccionarOriginales.filter(datosdetalleseleccionarOriginales => datosdetalleseleccionarOriginales.id_ficha !== id_ficha)); //Quitar del arreglo la fila eliminada
      setDato({ ...dato, 'estado': 'PENDIENTE' });
      getDetalleSeleccionados();
    } catch(error) {
      console.log(error);
      if (error.response.status === 401){
        delete axios.defaults.headers.common["Authorization"]; 
        localStorage.removeItem("token"); 
        localStorage.removeItem("usuario"); 
        navigate('/login');
      } else {
        toast.error(error.response.data, {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      }
    };
  }

  const DelAnimal = async (e, id_actividad_detalle_animal) => {
    e.preventDefault(); // Prevenir el evento submit del formulario
    try{
      const response = await axios.delete(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalleanimal/${id_actividad}/${id_actividad_detalle_animal}`,{withCredentials: true});
      if (response.data) {
        toast.warning(response.data.message, {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });

        setDatosdetalleseleccionados(datosdetalleseleccionados.filter(datosdetalleseleccionados => datosdetalleseleccionados.id_actividad_detalle_animal !== id_actividad_detalle_animal)); //Quitar del arreglo la fila eliminada
        setDatosdetalleseleccionadosOriginales(datosdetalleseleccionadosOriginales.filter(datosdetalleseleccionadosOriginales => datosdetalleseleccionadosOriginales.id_actividad_detalle_animal !== id_actividad_detalle_animal)); //Quitar del arreglo la fila eliminada
        setDato({ ...dato, 'estado': response.data.estado });

        getDetalleSeleccionar();
      } else {
        toast.error('¡No se pudo eliminar el registro!', {
          position: "top-right", autoClose: 2000, hideProgressBar: true, 
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      }
    } catch(error){
      console.log(error);
        if (error.response.status === 401){
          delete axios.defaults.headers.common["Authorization"]; 
          localStorage.removeItem("token"); 
          localStorage.removeItem("usuario"); 
          navigate('/login');
      } else {
        toast.error('¡No se pudo eliminar el registro!', {
          position: "top-right", autoClose: 2000, hid_actividadeProgressBar: true, closeOnClick: true,
          pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      }
    };
  }

  const AddTodos = async (e) => {
    e.preventDefault(); // Prevenir el evento submit del formulario

    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }

    Swal.fire({
      title: 'Agregar todos los animales', text: '¿Está seguro que desea agregar todos los animales?',
      icon: 'question', showCancelButton: true, confirmButtonColor: '#198754', cancelButtonColor: '#666',
      confirmButtonText: 'Agregar todos', cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) 
      {
        // Bloquear pantalla mientras se realiza la operación
        const loadingSwal = Swal.fire({
          title: 'Cargando datos', text: 'Por favor, espere un momento...',
          allowOutsideClick: false,showConfirmButton: false, willOpen: () => {Swal.showLoading();}
        });

        try {
          var data = {
            id_actividad  : id_actividad,
            id_predio     : dato.id_predio,
            id_potrero    : dato.id_potrero,
            subpotrero    : dato.id_subpotrero, 
            usuario : localStorage.getItem("usuario")
          };
          await axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalleall`, data,{withCredentials: true});
          loadingSwal.close();
          toast.success("Animales agregados correctamente", {
            position: "top-right", autoClose: 2000, hideProgressBar: true,
            closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
          getDetalleSeleccionar();
          getDetalleSeleccionados();
          setDato({ ...dato, 'estado': 'PENDIENTE' });
        } catch(error) {
          console.log(error);
          if (error.response.status === 401){
            delete axios.defaults.headers.common["Authorization"]; 
            localStorage.removeItem("token"); 
            localStorage.removeItem("usuario");
            navigate('/login');
          } else {
            loadingSwal.close();
            toast.error(error.response.data, {
              position: "top-right", autoClose: 2000, hideProgressBar: true,
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        };
      }
    });
  }

  const DelTodos = async (e) => {
    e.preventDefault(); // Prevenir el evento submit del formulario

    Swal.fire({
      title: 'Quitar todos los animales', text: '¿Está seguro que desea quitar todos los animales?',
      icon: 'warning', showCancelButton: true, confirmButtonColor: '#198754', cancelButtonColor: '#666',
      confirmButtonText: 'Quitar todos', cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) 
      {
        // Bloquear pantalla mientras se realiza la operación
        const loadingSwal = Swal.fire({
          title: 'Cargando datos', text: 'Por favor, espere un momento...',
          allowOutsideClick: false,showConfirmButton: false, willOpen: () => {Swal.showLoading();}
        });

        try{
          const response = await axios.delete(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalleall/${id_actividad}`,{withCredentials: true});
          if (response.data) {
            loadingSwal.close();
            toast.warning(response.data.message, {
              position: "top-right", autoClose: 2000, hideProgressBar: true,
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
            getDetalleSeleccionar();
            getDetalleSeleccionados();
            setDato({ ...dato, 'estado': response.data.estado });
          } else {
            loadingSwal.close();
            toast.error('¡No se pudieron eliminar los registros!', {
              position: "top-right", autoClose: 2000, hideProgressBar: true, 
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        } catch(error){
          if (error.response.status === 401){
            delete axios.defaults.headers.common["Authorization"]; 
            localStorage.removeItem("token"); 
            localStorage.removeItem("usuario");
            navigate('/login');
          } else {
            toast.error('¡No se pudieron eliminar los registros!', {
              position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        };
      }
    });
  }

  const enviarFormulario = async (e, p_finalizar = '0') => {
    e.preventDefault();

    if(!dato.todo_el_dia){
      dato.fecha = dato.fecha_inicio;
    }
    var data = {
      id_actividad    : dato.id_actividad,
      fecha           : dato.fecha,
      id_responsable  : dato.id_responsable,
      prioridad       : dato.prioridad,
      fecha_inicio    : dato.fecha_inicio,
      hora_inicio     : dato.hora_inicio,
      minuto_inicio   : dato.minuto_inicio,
      fecha_termino   : dato.fecha_termino,
      hora_termino    : dato.hora_termino,
      minuto_termino  : dato.minuto_termino,
      todo_el_dia     : dato.todo_el_dia,
      observacion     : dato.observacion,
      id_predio       : dato.id_predio,
      id_potrero      : inputPotrero.current.value,
      id_subpotrero   : inputSubpotrero.current.value,
      id_raza         : dato.id_raza,
      id_lote         : dato.id_lote,
      finalizar       : p_finalizar, 
      usuario : localStorage.getItem("usuario")
    };

    if(p_finalizar === '1'){
      Swal.fire({
        title: 'Finalizar actividad', text: '¿Está seguro que desea finalizar esta actividad?',
        icon: 'warning', showCancelButton: true, confirmButtonColor: '#198754', cancelButtonColor: '#666',
        confirmButtonText: 'Finalizar', cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) 
        {
          // Bloquear pantalla mientras se realiza la operación
          const loadingSwal = Swal.fire({
            title: 'Cargando datos', text: 'Por favor, espere un momento...',
            allowOutsideClick: false,showConfirmButton: false, willOpen: () => {Swal.showLoading();}
          });

          try {
            if (dato.id_actividad) {
              await axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${dato.id_actividad}`, data,{withCredentials: true});
              loadingSwal.close();
              toast.success('¡Registro actualizado correctamente!', {
                position: "top-right", autoClose: 2000, hideProgressBar: true,
                closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
              });
              if(p_finalizar === '1'){
                setDato({ ...dato, 'estado': 'FINALIZADO' });
              }
            } 
          } catch (error) {
            if (error.response.status === 401){
              delete axios.defaults.headers.common["Authorization"]; 
              localStorage.removeItem("token"); 
              localStorage.removeItem("usuario");
              navigate('/login');
            } else {
              loadingSwal.close();
              toast.error(error.response.data, {
                position: "top-right", autoClose: 2000, hideProgressBar: true,
                closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
              });
            }
          }
        }
      });
    }
    else{
      try {
        if (dato.id_actividad) {
          await axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${dato.id_actividad}`, data,{withCredentials: true});
          toast.success('¡Registro actualizado correctamente!', {
            position: "top-right", autoClose: 2000, hideProgressBar: true,
            closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
          if(p_finalizar === '1'){
            setDato({ ...dato, 'estado': 'FINALIZADO' });
          }
        } 
      } catch (error) {
        if (error.response.status === 401){
          delete axios.defaults.headers.common["Authorization"]; 
          localStorage.removeItem("token"); 
          localStorage.removeItem("usuario");
          navigate('/login');
        } else {
          toast.error(error.response.data, {
            position: "top-right", autoClose: 2000, hideProgressBar: true,
            closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
        }
      }
    }
  };

  /*********************************************************/
  /********** Funciones Modal 'modalBuscarAnimal' *********/
  /*********************************************************/
  
  //Para agregar el addEventListener al modal 'modalBuscarAnimal' cuando se dispare el Show 
  useEffect(() => {
    const modalElement = document.querySelector('#ModalBuscarAnimal');
    modalElement.addEventListener('shown.bs.modal', onModalShowBuscarAnimal);
    return () => {
      modalElement.removeEventListener('shown.bs.modal', onModalShowBuscarAnimal);
    };
  }, []);

  const onModalShowBuscarAnimal = () => {
    inputBuscarAnimal.current.focus();
  }

  const CerrarModalBuscarAnimal = () => {
    const modalElement = modalBuscarAnimal.current;
    if (modalElement) {
      const bootstrapModal = bootstrap.Modal.getInstance(modalElement);
      bootstrapModal.hide();
    } 
  }

  const handleKeyPressBuscarAnimal = (event) => {
    if (event.key === 'Enter') {
      getAnimales();
    }
  };

  const getAnimales = () => {
    const strBuscar = inputBuscarAnimal.current.value;
    if (strBuscar.length >= 3) {
      setIsLoadingBusquedaAnimales(true);
      axios.get(`${process.env.REACT_APP_URL_API_PORT}/fichas_actividades`, 
      {
          params: {
            id_actividad  : id_actividad,
            id_especie    : dato.id_especie,
            strBuscar     : strBuscar,
            estado        : 1
          }
      },{withCredentials: true})
      .then(response => {
        setListaAnimales(response.data);
        setIsLoadingBusquedaAnimales(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){
          delete axios.defaults.headers.common["Authorization"]; 
          localStorage.removeItem("token"); 
          localStorage.removeItem("usuario");
          navigate('/login');
        } 
      });
    }
  };
  /*********************************************************/

  /************ INICIO FUNCIONES ORDEN ARRAY (DETALLE) *************************************** */
  const handleColumnClickDetalle = (columnIndex) => {
    if (sortingColumnDetalle === columnIndex) { setSortDirectionDetalle(sortDirectionDetalle === 'asc' ? 'desc' : 'asc');
    } else { setSortingColumnDetalle(columnIndex); setSortDirectionDetalle('asc');}
  };

  useEffect(() => {    
    const rows = [...datosdetalleproductosOriginales]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
    if (sortingColumnDetalle !== -1) {  
      rows.sort((a, b) => {
        const valA = a[Object.keys(a)[sortingColumnDetalle]]; const valB = b[Object.keys(b)[sortingColumnDetalle]];

        if (typeof valA === 'number' && typeof valB === 'number') { return valA - valB;
        } else if (isValidDate(valA) && isValidDate(valB)) { const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
        } else { return valA.localeCompare(valB); }
      });
  
      if (sortDirectionDetalle === 'desc') { rows.reverse();}
      setDatosdetalleproductos(rows); // Actualizamos el estado de sortedRows con los datos ordenados
    }
  }, [sortingColumnDetalle, sortDirectionDetalle, datosdetalleproductosOriginales]); // Dependencias: sortingColumn, sortDirection, datosOriginales
  /************ FIN FUNCIONES ORDEN ARRAY (DETALLE) *************************************** */
  
  /*********************************************************/
  /********** Funciones Modal 'addProductoModal' *********/
  /*********************************************************/

  //Para agregar el addEventListener al modal 'addProductoModal' cuando se dispare el Show 
  useEffect(() => {
    const modalElement = document.querySelector('#addProductoModal');
    modalElement.addEventListener('shown.bs.modal', onModalShowAddProducto);
    return () => {
      modalElement.removeEventListener('shown.bs.modal', onModalShowAddProducto);
    };
  }, []);

  const onModalShowAddProducto = () => {
    setProductos([]);
    setProductosOriginales([]);
    inputBuscarProducto.current.focus();
    inputCantidad.current.value = '1';
  }

  const handleKeyPressBuscarProducto = (event) => {
    if (event.key === 'Enter') {
      getProductos();
    }
  };
  //--------------------------------------------------------------------------------------

  /************ INICIO FUNCIONES ORDEN ARRAY (MODAL PRODUCTOS) *************************************** */
  const handleColumnClickModalProducto = (columnIndex) => {
    if (sortingColumnModalProducto === columnIndex) { setSortDirectionModalProducto(sortDirectionModalProducto === 'asc' ? 'desc' : 'asc');
    } else { setSortingColumnModalProducto(columnIndex); setSortDirectionModalProducto('asc');}
  };

  useEffect(() => {    
    const rows = [...productosOriginales]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
    if (sortingColumnModalProducto !== -1) {  
      rows.sort((a, b) => {
        const valA = a[Object.keys(a)[sortingColumnModalProducto]]; const valB = b[Object.keys(b)[sortingColumnModalProducto]];

        if (typeof valA === 'number' && typeof valB === 'number') { return valA - valB;
        } else if (isValidDate(valA) && isValidDate(valB)) { const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
        } else { return valA.localeCompare(valB); }
      });
  
      if (sortDirectionModalProducto === 'desc') { rows.reverse(); }
      setProductos(rows); // Actualizamos el estado de sortedRows con los datos ordenados
    }
  }, [sortingColumnModalProducto, sortDirectionModalProducto, productosOriginales]); // Dependencias: sortingColumn, sortDirection, datosOriginales
  /************ FIN FUNCIONES ORDEN ARRAY (MODAL PRODUCTOS) *************************************** */

  const handleKeyPressCantidad = (event) => {
    if (event.key === 'Enter') {
      event.target.blur(); // desenfocar el input si se presiona Enter
    }

    if (!/[0-9\b]|Arrow(Left|Right)|Delete|Backspace/.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleInputChangeCantidad = (event, id_actividad_detalle_producto) => {
    setValorCambiado(true); // marcar la bandera como verdadera --> el onchange siempre se activa si hubo un cambio en el input text
    setDatosdetalleproductos(prevState => {
      return prevState.map(tupla => {
        if (tupla.id_actividad_detalle_producto === id_actividad_detalle_producto) {
          return {...tupla, cantidad: event.target.value};
        }
        return tupla;
      });
    });
  }

  const handleBlurCantidad = (event, id_actividad_detalle_producto, stock, index) => {
    let cant = event.target.value;
    
    if (valorCambiado) {
      if (/^\d+$/.test(cant)){
        if ( parseFloat(cant) > 0 && parseFloat(cant) <= 99999 ) {
          if (parseFloat(cant) <= parseFloat(stock)){
            var data = {
              cantidad : cant, 
              usuario  : localStorage.getItem("usuario")
            };
            
            axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalleproducto/${id_actividad}/${id_actividad_detalle_producto}`, data,{withCredentials: true}).then(response => {
              toast.success("Registro actualizado correctamente", {
                position: "top-right", autoClose: 2000, hideProgressBar: true,
                closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
              });
            })
            .catch(error => {
              if (error.response.status === 401){
                delete axios.defaults.headers.common["Authorization"]; 
                localStorage.removeItem("token"); 
                localStorage.removeItem("usuario");
                navigate('/login');
              } else {
                toast.error(error.response.data, {
                  position: "top-right", autoClose: 2000, hideProgressBar: true,
                  closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
                });
              }
            });
          } else {
            toast.error('Cantidad NO puede ser mayor al Stock disponible', {
              position: "top-right", autoClose: 2000, hideProgressBar: true,
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });

            // Actualizar el estado principal (datosdetalleproductos) con el nuevo valor de cantidad
            const nuevosDatos = [...datosdetalleproductos];
            nuevosDatos[index] = { ...nuevosDatos[index], cantidad: "1" };
            setDatosdetalleproductos(nuevosDatos);
          }
        } else{
          toast.error('Cantidad es un valor entre 1 y 99999', {
            position: "top-right", autoClose: 2000, hideProgressBar: true,
            closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });

          // Actualizar el estado principal (datosdetalleproductos) con el nuevo valor de cantidad
          const nuevosDatos = [...datosdetalleproductos];
          nuevosDatos[index] = { ...nuevosDatos[index], cantidad: "1" };
          setDatosdetalleproductos(nuevosDatos);
        }
      } else {
        toast.error('Cantidad es un valor numérico', {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });

        // Actualizar el estado principal (datosdetalleproductos) con el nuevo valor de cantidad
        const nuevosDatos = [...datosdetalleproductos];
        nuevosDatos[index] = { ...nuevosDatos[index], cantidad: "1" };
        setDatosdetalleproductos(nuevosDatos);
      }
      setValorCambiado(false); // resetear la bandera a falso
    }
  };

  const handleDelete = (id_actividad_detalle_producto) => {
    Swal.fire({
      title: 'Eliminar registro', text: '¿Estás seguro que deseas eliminar este registro?',
      icon: 'warning', showCancelButton: true, confirmButtonColor: '#d33', cancelButtonColor: '#666',
      confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar',
    }).then((result) => {
      // Si se confirma la eliminación, hacer la petición a la API para eliminar el elemento
      if (result.isConfirmed) 
      {
        axios.delete(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalleproducto/${id_actividad}/${id_actividad_detalle_producto}`,{withCredentials: true}).then(response =>
        {
          if (response.data) {
            toast.success(response.data.message, {
              position: "top-right", autoClose: 2000, hid_productoeProgressBar: true,
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
            setDato({ ...dato, 'estado': response.data.estado });
            setProductos([]);
            setDatosdetalleproductos(datosdetalleproductos.filter(datosdetalleproductos => datosdetalleproductos.id_actividad_detalle_producto !== id_actividad_detalle_producto)); //Quitar del arreglo la fila del id_producto eliminado
          } else {
            toast.error('¡No se pudo eliminar el registro!', {
              position: "top-right", autoClose: 2000, hid_productoeProgressBar: true, 
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
      }
    });
  };

  const AddProducto = (id_producto, stock) => {
    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }
    
    let cant = inputCantidad.current.value

    if (/^\d+$/.test(cant)){
      //Number.isInteger(parseFloat(cant)) valida si es entero

      if ( parseFloat(cant) > 0 && parseFloat(cant) <= 99999 ) {
        if(parseFloat(cant) <= parseFloat(stock)){
          var data = {
            id_actividad  : id_actividad,
            id_producto   : id_producto,
            cantidad      : cant, 
            usuario       : localStorage.getItem("usuario")
          };
          
          axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalleproducto`, data,{withCredentials: true}).then(response => {
            toast.success("Registro creado correctamente", {
              position: "top-right", autoClose: 2000, hideProgressBar: true,
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
            
            //------------
            const modalElement = modalAddProducto.current;
            if (modalElement) {
              const bootstrapModal = bootstrap.Modal.getInstance(modalElement);
              bootstrapModal.hide();
            }
            //-----------
            setDato({ ...dato, 'estado': 'PENDIENTE' });
            setProductos([]);
            getDetalleProductos();
          })
          .catch(error => {
            if (error.response.status === 401){
              delete axios.defaults.headers.common["Authorization"]; 
              localStorage.removeItem("token"); 
              localStorage.removeItem("usuario");
              navigate('/login');
            } else {
              toast.error(error.response.data, {
                position: "top-right", autoClose: 2000, hideProgressBar: true,
                closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
              });
            }
          });
        } else {
          toast.error('Cantidad NO puede ser mayor al Stock disponible', {
            position: "top-right", autoClose: 2000, hideProgressBar: true,
            closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
          inputCantidad.current.focus();  
        }
      } else{
        toast.error('Cantidad es un valor entre 1 y 99999', {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
        inputCantidad.current.focus();  
      }
    } else {
      toast.error('Cantidad es un valor numérico', {
        position: "top-right", autoClose: 2000, hideProgressBar: true,
        closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
      });
      inputCantidad.current.focus();
    }
  }

  const horas = Array.from({ length: 23 }, (_, index) => {
    const hour = index + 1;
    return hour < 10 ? '0' + hour.toString() : hour.toString();
  });

  useEffect(() => {
    if (id_actividad) {
      getDato(id_actividad);
      setIcon('fas fa-arrow-left');
      setTextButton('Volver');
      getResponsables();
      getPredios();
      getDetalleProductos();
    }
    //eslint-disable-next-line
  }, [id_actividad]);

  const [tituloFieldset, setTituloFieldset] = useState('Seleccionar animales');
  const [classCantCol, setClassCantCol] = useState('col-12 col-sm-12 col-md-6');
  const [classCantColLabel, setClassCantColLabel] = useState('col-3 col-sm-2 col-md-3');
  const [classCantColData, setClassCantColData] = useState('col-9 col-sm-10 col-md-9');

  const [cargaSeleccionAnimales, setCargaSeleccionAnimales] = useState(true);
  const [heigthFielsetSeleccion, setHeigthFielsetSeleccion] = useState('400px');
  const [cargaRowEspecieRaza, setCargaRowEspecieRaza] = useState(true);
  const [cargaSeleccionProductos, setCargaSeleccionProductos] = useState(true);

  const [mostrarBotFinalizar, setMostrarBotFinalizar] = useState(false);

  useEffect(() => {
    if (dato.id_tipoactividad !== "") {
      if(dato.id_tipoactividad === 1 || dato.id_tipoactividad === 2 || dato.id_tipoactividad === 3){
        setTituloFieldset('Seleccione ubicación');
        setClassCantCol('col-12 col-sm-12 col-md-12');
        setClassCantColLabel('col-2 col-sm-2 col-md-2');
        setClassCantColData('col-10 col-sm-10 col-md-10');
        setCargaSeleccionAnimales(false);
        setHeigthFielsetSeleccion('140px');
        setCargaRowEspecieRaza(false);
      } else {
        if(dato.modalidad === "LOTE"){
          setClassCantCol('col-12 col-sm-12 col-md-12');
          setClassCantColLabel('col-2 col-sm-2 col-md-2');
          setClassCantColData('col-10 col-sm-10 col-md-10');
          setCargaSeleccionAnimales(false);
          setHeigthFielsetSeleccion('200px');
          getRazas();
        } else {
          getDetalleSeleccionados();
        }
        if(dato.id_tipoactividad === 7 || dato.id_tipoactividad === 11){
          //Pesaje o Marca
          setCargaSeleccionProductos(false);
        } 
      }
    }
    //eslint-disable-next-line
  }, [dato.id_tipoactividad]);

  useEffect(() => {
    if(dato.estado !=='FINALIZADO'){
      if(dato.id_tipoactividad === 1 || dato.id_tipoactividad === 2 || dato.id_tipoactividad === 3){
        if(datosdetalleproductos.length > 0){
          setMostrarBotFinalizar(true);
        } else {
          setMostrarBotFinalizar(false);
        }
      } else if(dato.id_tipoactividad === 7){
        if(datosdetalleseleccionados.length > 0){
          setMostrarBotFinalizar(true);
        } else {
          setMostrarBotFinalizar(false);
        }
      } else if(dato.id_tipoactividad === 11){
        if(dato.modalidad === 'INDIVIDUAL'){
          if(datosdetalleseleccionados.length > 0){
            setMostrarBotFinalizar(true);
          } else {
            setMostrarBotFinalizar(false);
          }
        } else {
          setMostrarBotFinalizar(true);
        }
      } else {
        if(dato.modalidad === 'INDIVIDUAL'){
          if(datosdetalleseleccionados.length > 0 && datosdetalleproductos.length > 0){
            setMostrarBotFinalizar(true);
          } else {
            setMostrarBotFinalizar(false);
          }
        } else { //LOTE
          if(datosdetalleproductos.length > 0){
            setMostrarBotFinalizar(true);
          } else {
            setMostrarBotFinalizar(false);
          }
        }
      }      
    }
  }, [datosdetalleseleccionados, datosdetalleproductos, dato.estado, dato.modalidad, dato.id_tipoactividad]);

  //________________________________________________________________________________
  //_________________________________ CARGAR LOTES _________________________________
  //________________________________________________________________________________

  const getLotes = async () => {
    let v_id_potrero = dato.id_potrero;
    if(dato.id_potrero === ''){v_id_potrero = 0}
    let v_id_subpotrero = dato.id_subpotrero;
    if(dato.id_subpotrero === ''){v_id_subpotrero = 0}
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/lotes_porfiltros/${dato.id_predio}/${v_id_potrero}/${v_id_subpotrero}/${dato.id_especie}/${dato.id_raza}`,{withCredentials: true});
      setDatoslotes(response.data)
    } catch(error) {
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}  
    };
  };

  useEffect(() => {
    if (datoslotes.length > 0 && inputLote.current) {
      setDato(prevDato => ({...prevDato, 'id_lote': inputLote.current.selectedOptions[0].value}));
    }
  }, [datoslotes]);

  useEffect(() => {
    if (dato.id_predio !== '' && dato.id_potrero !== '' && dato.id_especie !== '' && dato.id_raza !== '') {
      getLotes();
    }
    //eslint-disable-next-line
  }, [dato.id_lote, dato.id_predio, dato.id_potrero, dato.id_subpotrero, dato.id_especie, dato.id_raza]);
  //________________________________________________________________________________


  const handleKeyPressPeso = (event) => {
    if (event.key === 'Enter') {
      event.target.blur(); // desenfocar el input si se presiona Enter
    }

    if (!/^\d*([,.]\d{0,1})?$|Arrow(Left|Right)|Delete|Backspace/.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleInputChangePeso = (event, id_actividad_detalle_animal) => {
    setValorCambiadoPeso(true); // marcar la bandera como verdadera --> el onchange siempre se activa si hubo un cambio en el input text
    setDatosdetalleseleccionados(prevState => {
      return prevState.map(tupla => {
        if (tupla.id_actividad_detalle_animal === id_actividad_detalle_animal) {
          return {...tupla, peso: event.target.value};
        }
        return tupla;
      });
    });
  }

  const handleBlurPeso = (event, id_actividad_detalle_animal, index) => {
    let peso = event.target.value.replace(',', '.');
    
    if (valorCambiadoPeso) {
      if (/^\d+(.\d+)?$/.test(peso)) {
        if ( parseFloat(peso) > 0 && parseFloat(peso) <= 999.999 ) {
          var data = {
            peso : peso, 
            usuario   : localStorage.getItem("usuario")
          };
            
          axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalleanimal/${id_actividad}/${id_actividad_detalle_animal}`, data,{withCredentials: true}).then(response => {
            toast.success("Registro actualizado correctamente", {
              position: "top-right", autoClose: 2000, hideProgressBar: true,
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });

            const nuevosDatos = [...datosdetalleseleccionados];
            nuevosDatos[index] = { ...nuevosDatos[index], peso: peso.replace('.', ',')};
            setDatosdetalleseleccionados(nuevosDatos);
          })
          .catch(error => {
            if (error.response.status === 401){
              delete axios.defaults.headers.common["Authorization"]; 
              localStorage.removeItem("token"); 
              localStorage.removeItem("usuario");
              navigate('/login');
            } else {
              toast.error(error.response.data, {
                position: "top-right", autoClose: 2000, hideProgressBar: true,
                closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
              });
            }
          });
        } else{
          toast.error('Peso es un valor entre 1 y 999.99', {
            position: "top-right", autoClose: 2000, hideProgressBar: true,
            closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });

          // Actualizar el estado principal (datosdetalleanimales) con el nuevo valor de peso
          const nuevosDatos = [...datosdetalleseleccionados];
          nuevosDatos[index] = { ...nuevosDatos[index], peso: "0" };
          setDatosdetalleseleccionados(nuevosDatos);
        }
      } else {
        toast.error('Peso es un valor numérico', {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });

        // Actualizar el estado principal (datosdetalleanimales) con el nuevo valor de peso
        const nuevosDatos = [...datosdetalleseleccionados];
        nuevosDatos[index] = { ...nuevosDatos[index], peso: "0" };
        setDatosdetalleseleccionados(nuevosDatos);
      }
      setValorCambiadoPeso(false); // resetear la bandera a falso
    }
  };

  return (
    <div className="row justify-content-center w-100">
      <div className="col-12 col-xl-11">
      {isLoadingDatosForm &&  
        <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Cargando datos...</span>
            </div>
        </div>
      }
      {!isLoadingDatosForm &&    
        <form onSubmit={(e) => enviarFormulario(e)}>
          <small>
          <div className="row pt-2 pb-2">
            <div className="col text-end">
              {mostrarBotFinalizar && 
              <button 
                type="button" 
                className="btn btn-primary btn-sm"
                onClick={(e) => enviarFormulario(e, '1')}>
                <i className="fas fa-check-circle"></i> Finalizar
              </button>
              }
              &nbsp;
              {dato.estado !=='FINALIZADO' && 
              <button type="submit" className="btn btn-success btn-sm">
                <i className="fas fa-check"></i> Actualizar
              </button>
              }
              &nbsp;
              <Link to={`/${modulo}`} className="btn btn-secondary btn-sm">
                <i className={icon} title="Volver"></i> {textButton}
              </Link>
            </div>
          </div>
          
          <fieldset className={`border p-2 mb-2 ${darkMode ? 'border-secondary' : ''}`}>
            <div className="row pt-2">
              <label className="col-sm-2">N°&nbsp;Actividad:</label>
              <div className="col-sm-4">
                <input type="text" name="id_actividad" value={dato.id_actividad} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                readOnly/>
              </div>
              <label className="col-sm-2">Estado:</label>
              <div className="col-sm-4">
                <input type="text" name="estado" value={dato.estado} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                readOnly/>
              </div>
            </div>

            <div className="row pt-2">
              <label className="col-sm-2">Tipo:</label>
              <div className="col-sm-4">
                <input type="text" name="n_tipoactividad" value={dato.n_tipoactividad} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                readOnly/>
              </div>
              <label className="col-sm-2">Prioridad:</label>
              <div className="col-sm-4">
                <select 
                  className="form-select form-select-sm" 
                  name="prioridad" 
                  ref={inputPrioridad} 
                  value={dato.prioridad} onChange={handleInputChange} required>
                    <option key={1} value='ALTA'>ALTA</option>
                    <option key={2} value='MEDIA'>MEDIA</option>
                    <option key={3} value='BAJA'>BAJA</option>                    
                </select>
              </div>
            </div>

            <div className="row pt-2">
              <label className="col-sm-2">Modalidad:</label>
              <div className="col-sm-4">
                <input type="text" name="modalidad" value={dato.modalidad} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                readOnly/>
              </div>
              <label className="col-sm-2">Responsable:</label>
              <div className="col-sm-4">
                <select 
                  className="form-select form-select-sm" 
                  name="id_responsable" 
                  ref={inputResponsable} 
                  disabled={dato.estado === 'FINALIZADO'}
                  value={dato.id_responsable} onChange={handleInputChange} required>
                    <option key={0} value=''></option>
                    {responsables.map((opcion) => (
                    <option key={opcion.id_responsable} value={opcion.id_responsable}>{opcion.n_responsable}</option>
                    ))}
                </select>
              </div>
            </div>
            {cargaRowEspecieRaza && 
            <div className="row pt-2">
              <label className="col-sm-2">Especie:</label>
              <div className="col-sm-4">
                <input type="text" name="n_especie" value={dato.n_especie} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`}  
                readOnly/>
              </div>
              {dato.modalidad === "LOTE" && 
              <label className="col-sm-2">Raza:</label>
              }
              {dato.modalidad === "LOTE" && 
              <div className="col-sm-4">
                <select 
                  className="form-select form-select-sm" 
                  name="id_raza" 
                  ref={inputRaza} 
                  value={dato.id_raza} 
                  onChange={handleInputChange} 
                  required>
                  {razas.map((opcion) => (
                    <option key={opcion.id_raza} value={opcion.id_raza}>{opcion.n_raza}</option>
                  ))}
                </select>
              </div>
              }
            </div>
            }

            <div className="row pt-2">
              <div className="col-sm-2">
                <div className="form-check">
                  <input className="form-check-input" onChange={handleCheckboxChange} name="todo_el_dia" ref={inputTodoeldia} 
                    type="checkbox" id="checkTodoeldia" checked={dato.todo_el_dia}/>
                  <label className="form-check-label" htmlFor="checkTodoeldia">
                    Todo el dia
                  </label>
                </div>
              </div>
              {dato.todo_el_dia && 
              <label className="col-sm-2">Fecha:</label>
              }
              {dato.todo_el_dia && 
              <div className="col-sm-2">
                <DatePicker
                  selected={parseISO(dato.fecha, 'ddMMyyyy', new Date())}
                  onChange={(date) => handleDateChange(date, "fecha")}
                  minDate={minSelectableDate}
                  maxDate={maxSelectableDate}
                  dateFormat="dd-MM-yyyy"
                  className="form-control form-control-sm" 
                  disabled={dato.estado === 'FINALIZADO'}
                  locale={es} // Establece el idioma español
                  required 
                />
              </div>
              }
            </div>
            {!dato.todo_el_dia && 
            <div className="row pt-2">
              <label className="col-sm-2">Fec.&nbsp;inicio:</label>
              <div className="col-sm-4">
                <DatePicker
                  selected={parseISO(dato.fecha_inicio, 'ddMMyyyy', new Date())}
                  onChange={(date) => handleDateChange(date, "fecha_inicio")}
                  minDate={minSelectableDate}
                  maxDate={maxSelectableDate}
                  dateFormat="dd-MM-yyyy"
                  className="form-control form-control-sm" 
                  disabled={dato.estado === 'FINALIZADO'}
                  locale={es} // Establece el idioma español
                  required 
                />
              </div>
              <label className="col-sm-2">Hora&nbsp;inicio:</label>
              <div className="col-sm-2">
                <select 
                  className="form-select form-select-sm" 
                  name="hora_inicio" 
                  ref={inputInicioHora} 
                  value={dato.hora_inicio} 
                  onChange={handleInputChange} required>
                    {horas.map((hora, index) => (
                      <option key={index} value={hora}>
                        {hora}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-sm-2">
                <select 
                  className="form-select form-select-sm" 
                  name="minuto_inicio" 
                  ref={inputInicioMinuto} 
                  value={dato.minuto_inicio} 
                  onChange={handleInputChange} required>
                    <option key={1} value={'00'}>00</option>
                    <option key={2} value={'15'}>15</option>
                    <option key={3} value={'30'}>30</option>
                    <option key={4} value={'45'}>45</option>
                </select>
              </div>
            </div>
            }

            {!dato.todo_el_dia && 
            <div className="row pt-2">
              <label className="col-sm-2">Fec.&nbsp;término:</label>
              <div className="col-sm-4">
                <DatePicker
                  selected={parseISO(dato.fecha_termino, 'ddMMyyyy', new Date())}
                  onChange={(date) => handleDateChange(date, "fecha_termino")}
                  minDate={minSelectableDate}
                  maxDate={maxSelectableDate}
                  dateFormat="dd-MM-yyyy"
                  className="form-control form-control-sm" 
                  disabled={dato.estado === 'FINALIZADO'}
                  locale={es} // Establece el idioma español
                  required 
                />
              </div>
              <label className="col-sm-2">Hora&nbsp;término:</label>
              <div className="col-sm-2">
                <select 
                  className="form-select form-select-sm" 
                  name="hora_termino" 
                  ref={inputTerminoHora} 
                  value={dato.hora_termino} 
                  onChange={handleInputChange} required>
                    {horas.map((hora, index) => (
                      <option key={index} value={hora}>
                        {hora}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-sm-2">
                <select 
                  className="form-select form-select-sm" 
                  name="minuto_termino" 
                  ref={inputTerminoMinuto} 
                  value={dato.minuto_termino} 
                  onChange={handleInputChange} required>
                    <option key={1} value={'00'}>00</option>
                    <option key={2} value={'15'}>15</option>
                    <option key={3} value={'30'}>30</option>
                    <option key={4} value={'45'}>45</option>
                </select>
              </div>
            </div>
            }

            <div className="row pt-2">
              <label className="col-sm-2">Observación:</label>
              <div className="col-sm-10">
                <textarea 
                  className="form-control" 
                  style={{
                    resize: 'none',
                    overflow: 'auto'
                  }}
                  name="observacion" 
                  ref={inputObservacion} 
                  onChange={handleInputChange} 
                  value={dato.observacion}
                  ></textarea>
              </div>
            </div>
          </fieldset>
          
          <div className="row">
            {/* INICIO SELECCIONAR ANIMALES */}
            <div className={classCantCol}>
              <fieldset 
                className={`border p-2 mb-2 position-relative ${darkMode ? 'border-secondary' : ''}`} 
                style={{ height: heigthFielsetSeleccion, maxHeight: heigthFielsetSeleccion }}>
                <legend 
                  className={`w-auto position-absolute ${darkMode ? 'bg-dark' : 'bg-light'}`}
                  >{tituloFieldset}</legend>
                <div className="row pt-2">
                  <label className={classCantColLabel}>Predio:</label>
                  <div className={classCantColData}>
                    <select 
                      className="form-select form-select-sm" 
                      name="id_predio" 
                      ref={inputPredio} 
                      disabled={dato.estado === 'FINALIZADO'}
                      value={dato.id_predio} onChange={handleInputChange} required>
                      {predios.map((opcion) => (
                        <option key={opcion.id_predio} value={opcion.id_predio}>{opcion.n_predio}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row pt-2">
                  <label className={classCantColLabel}>Potrero:</label>
                  <div className={classCantColData}>
                    <select 
                      className="form-select form-select-sm" 
                      name="id_potrero" 
                      ref={inputPotrero} 
                      disabled={dato.estado === 'FINALIZADO'}
                      value={dato.id_potrero} onChange={handleInputChange} required>
                      {potreros.map((opcion) => (
                        <option key={opcion.id_potrero} value={opcion.id_potrero}>{opcion.n_potrero}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row pt-2">
                  <label className={classCantColLabel}>Subpotrero:</label>
                  <div className={classCantColData}>
                    <select 
                      className="form-select form-select-sm" 
                      name="id_subpotrero" 
                      ref={inputSubpotrero} 
                      disabled={dato.estado === 'FINALIZADO'}
                      value={dato.id_subpotrero} onChange={handleInputChange}>
                        <option key={0} value={0}></option>
                      {subpotreros.map((opcion) => (
                        <option key={opcion.id_subpotrero} value={opcion.id_subpotrero}>{opcion.n_subpotrero}</option>
                      ))}
                    </select>
                  </div>
                </div>

                {dato.modalidad === "LOTE" &&
                <div className="row pt-2">
                  <label className="col-2"></label>
                  <div className="col-10" style={{fontfamily: 'monospace', fontSize: '0.8rem'}}>
                    &nbsp; Categoría &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    | N° Lote 
                    | Cantidad
                  </div>
                </div>
                }

                {dato.modalidad === "LOTE" &&
                <div className="row pt-2">
                  <label className="col-2">Lotes:</label>
                  <div className="col-10">
                    <select 
                      className="form-select form-select-sm" 
                      name="id_lote" 
                      ref={inputLote} 
                      value={dato.id_lote} 
                      onChange={handleInputChange}
                      required
                      style={{fontfamily: 'monospace', fontSize: '0.8rem'}}>
                      {datoslotes.map((opcion) => {
                        let strLote = opcion.categoria.toString().padEnd(14,'\u00A0') + " | " + opcion.id_lote.toString().padStart(10,'\u00A0') + " | " + opcion.cantidad.toString().padStart(11,'\u00A0')
                        return (
                        <option 
                          key={opcion.id_lote} 
                          data-cantidad={opcion.cantidad}
                          data-categoria={opcion.categoria}
                          value={opcion.id_lote}>{strLote}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                }
                
                {cargaSeleccionAnimales && 
                <div className="row pt-2">
                  {datosdetalleseleccionar.length > 0 && dato.estado !== 'FINALIZADO' && 
                  <div className="col">
                    <button 
                      onClick={(e) => AddTodos(e)}
                      className="btn btn-sm btn-success">
                      Agregar todos <i className="fa fa-arrow-right-to-bracket" title="Agregar todos los animales"></i>
                    </button>
                  </div>
                  }
                </div>
                }
                
                {cargaSeleccionAnimales && isLoadingDetalleSeleccionar &&  
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Cargando datos...</span>
                  </div>
                </div>
                }
                {cargaSeleccionAnimales && !isLoadingDetalleSeleccionar &&  
                <div className="row pt-2">
                  <div className="col">
                    <div className="table-responsive" style={{ maxHeight: 'calc(200px)' }}>
                      <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                        <thead className="sticky-top">
                          <tr>
                            <th onClick={() => handleColumnClickSeleccionar(1)} className={sortingColumnSeleccionar === 1 ? `sorting ${sortDirectionSeleccionar}` : ''}>Diio/Crotal</th>
                            <th onClick={() => handleColumnClickSeleccionar(2)} className={sortingColumnSeleccionar === 2 ? `sorting ${sortDirectionSeleccionar}` : ''}>Categoría</th>
                            {dato.estado !== 'FINALIZADO' && 
                            <th>Acción</th>
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {datosdetalleseleccionar.map(fila => {
                            return (
                            <tr key={fila.id_ficha}>
                              <td>{fila.diio}</td>
                              <td>{fila.categoria}</td>
                              {dato.estado !== 'FINALIZADO' && 
                              <td>
                                <button 
                                  onClick={(e) => AddAnimal(e, fila.id_ficha, fila.diio)}
                                  className="btn btn-sm btn-outline-success">
                                  <i className="fa fa-arrow-right" title="Agregar animal"></i>
                                </button> 
                              </td>
                              }
                            </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                }
              </fieldset>
            </div> 
            {/* FIN SELECCIONAR ANIMALES */}

            {/* INICIO ANIMALES SELECCIONADOS */}
            {cargaSeleccionAnimales && 
            <div className="col-12 col-sm-12 col-md-6">
              <fieldset 
                className={`border p-2 mb-2 position-relative ${darkMode ? 'border-secondary' : ''}`} 
                style={{ height: '400px', maxHeight: '400px' }}>
                <legend 
                  className={`w-auto position-absolute ${darkMode ? 'bg-dark' : 'bg-light'}`} 
                  >Animales seleccionados</legend>
                <div className="row pt-2">
                  <div className="col">
                    {datosdetalleseleccionados.length > 0 && dato.estado !== 'FINALIZADO' && 
                    <button 
                      onClick={(e) => DelTodos(e)}
                      className="btn btn-sm btn-warning">
                      Quitar todos <i className="fa fa-arrow-right-from-bracket" title="Quitar todos los animales"></i>
                    </button>
                    }
                  </div>
                  <div className="col text-end">
                    {dato.estado !== 'FINALIZADO' && 
                    <button 
                      type="button" 
                      className="btn btn-primary btn-sm" 
                      data-bs-toggle="modal" 
                      data-bs-target="#ModalBuscarAnimal">
                      <i className="fa fa-search"></i> Buscar animal
                    </button>
                    }
                  </div>
                </div>

                {isLoadingDetalleSeleccionados &&  
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Cargando datos...</span>
                  </div>
                </div>
                }
                {!isLoadingDetalleSeleccionados &&  
                <div className="row pt-2">
                  <div className="col">
                    <div className="table-responsive" style={{ maxHeight: 'calc(200px)' }}>
                      <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                        <thead className="sticky-top">
                          <tr>
                            <th onClick={() => handleColumnClickSeleccionados(1)} className={sortingColumnSeleccionados === 1 ? `sorting ${sortDirectionSeleccionados}` : ''}>Diio/Crotal</th>
                            <th onClick={() => handleColumnClickSeleccionados(2)} className={sortingColumnSeleccionados === 2 ? `sorting ${sortDirectionSeleccionados}` : ''}>Categoría</th>
                            {dato.id_tipoactividad === 7 && dato.estado !== "FINALIZADO" &&  
                              <th>Peso</th>
                            }
                            {dato.id_tipoactividad === 7 && dato.estado === "FINALIZADO" &&  
                              <th className="text-end">Peso</th>
                            }
                            {dato.estado !== 'FINALIZADO' && 
                            <th>Acción</th>
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {datosdetalleseleccionados.map((fila,index) => {
                            return (
                            <tr key={fila.id_actividad_detalle_animal}>
                              <td>{fila.diio}</td>
                              <td>{fila.categoria}</td>

                              {dato.id_tipoactividad === 7 && dato.estado !== "FINALIZADO" &&  
                              <td className="text-end">
                                <input 
                                  style={{'width':'80px'}} 
                                  value={
                                    fila.peso.toLocaleString('es-CL', {
                                      minimumFractionDigits: 1,
                                      maximumFractionDigits: 1,
                                    })
                                  } 
                                  type="text" 
                                  className="form-control form-control-sm" 
                                  id={"peso" + fila.id_actividad_detalle_animal} 
                                  maxLength="7" 
                                  onChange={(event) => handleInputChangePeso(event, fila.id_actividad_detalle_animal)}
                                  onBlur={(event) => handleBlurPeso(event, fila.id_actividad_detalle_animal, index)}
                                  onKeyDown={handleKeyPressPeso}/>
                              </td>
                              }
                              {dato.id_tipoactividad === 7 && dato.estado === "FINALIZADO" &&  
                              <td className="text-end">{fila.peso}</td>
                              }

                              {dato.estado !== 'FINALIZADO' && 
                              <td>
                                <button 
                                  onClick={(e) => DelAnimal(e, fila.id_actividad_detalle_animal)}
                                  className="btn btn-sm btn-outline-warning">
                                  <i className="fa fa-times" title="Agregar animal"></i>
                                </button>
                              </td>
                              }
                            </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                }
              </fieldset>
            </div>
            }
            {/* FIN ANIMALES SELECCIONADOS */}
          </div>
          
          {cargaSeleccionProductos && 
          <fieldset className={`border p-2 mb-2 position-relative ${darkMode ? 'border-secondary' : ''}`}>
            <legend 
              className={`w-auto position-absolute ${darkMode ? 'bg-dark' : 'bg-light'}`}
              >Detalle productos</legend>
            {!isLoadingDetalleProductos &&  
            <div className="row">
              <div className="col">
                <div className="row">  
                  <div className="col text-end">
                    {dato.estado !== "FINALIZADO" &&  
                    <button 
                      type="button" className="btn btn-primary btn-sm" 
                      data-bs-toggle="modal" data-bs-target="#addProductoModal">
                      <i className="fa fa-plus"></i>&nbsp;Agregar producto
                    </button>
                    }
                  </div>
                </div>
                <div className="table-responsive" style={{ maxHeight: '250px)' }}>
                  <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                    <thead className="sticky-top">
                      <tr>
                        <th onClick={() => handleColumnClickDetalle(1)} className={sortingColumnDetalle === 1 ? `sorting ${sortDirectionDetalle}` : ''}>Id Prod</th>
                        <th onClick={() => handleColumnClickDetalle(2)} className={sortingColumnDetalle === 2 ? `sorting ${sortDirectionDetalle}` : ''}>Nombre</th>
                        <th className='text-end'>Stock</th>
                        {dato.estado !== "FINALIZADO" &&  
                          <th>Cantidad</th>
                        }
                        {dato.estado === "FINALIZADO" &&  
                          <th className="text-end">Cantidad</th>
                        }
                        <th>U. Med. Uso</th>
                        {dato.estado !== "FINALIZADO" &&  
                        <th className="text-center">Acciones</th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {datosdetalleproductos.map((tupla, index) => (
                        <tr key={tupla.id_actividad_detalle_producto}>
                          <td>{tupla.id_producto}</td>
                          <td>{tupla.n_producto}</td>
                          <td className='text-end'>{tupla.stock}</td>
                          {dato.estado !== "FINALIZADO" &&  
                          <td className="text-end">
                            <input 
                              style={{'width':'100px'}} 
                              value={tupla.cantidad} 
                              type="text" 
                              className="form-control form-control-sm" 
                              id={"cantidad" + tupla.id_actividad_detalle_producto} 
                              maxLength="5" 
                              onChange={(event) => handleInputChangeCantidad(event, tupla.id_actividad_detalle_producto)}
                              onBlur={(event) => handleBlurCantidad(event, tupla.id_actividad_detalle_producto, tupla.stock, index)}
                              onKeyDown={handleKeyPressCantidad}/>
                          </td>
                          }
                          {dato.estado === "FINALIZADO" &&  
                          <td className="text-end">{tupla.cantidad}</td>
                          }
                          
                          <td>{tupla.n_unidadmedida}</td>

                          {dato.estado !== "FINALIZADO" &&  
                          <td className="text-center">
                            <button type="button" onClick={() => handleDelete(tupla.id_actividad_detalle_producto)} className="btn btn-sm btn-outline-secondary">
                              <i className="fa fa-trash-alt" title="Eliminar"></i>
                            </button>
                          </td>
                          }
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            }
          </fieldset>
          }
          </small>
          
        </form>
        }

        <div className="modal fade" id="ModalBuscarAnimal" ref={modalBuscarAnimal} 
          tabIndex="-1" aria-labelledby="ModalBuscarAnimalLabel" aria-hidden="true">
          <div 
            className="modal-dialog modal-dialog-scrollable border border-secondary bg-dark"
            style={{ maxHeight: '500px' }}>
            <div className={`modal-content ${darkMode ? 'text-light bg-dark' : ''}`}>
              <div className="modal-header border-bottom border-secondary">
                <h5 className="modal-title" id="ModalBuscarAnimalLabel">Buscar animal</h5>
                <button 
                  type="button" 
                  className={`btn-close ${darkMode ? 'bg-btn-close-modal': ''}`}
                  data-bs-dismiss="modal" aria-label="Close">
                </button>
              </div>
              <div className="modal-body">

                <div className="row">
                  <div className="input-group mb-3">
                    <input 
                      type="text" className="form-control form-control-sm" 
                      placeholder="Buscar por Ficha / Diio / Crotal (Mas de 3 caracteres)" 
                      aria-label="Buscar por Ficha / Diio / Crotal (Mas de 3 caracteres)" 
                      aria-describedby="bot_buscar" 
                      onKeyDown={handleKeyPressBuscarAnimal}
                      ref={inputBuscarAnimal}/>
                    <button 
                      className="btn btn-outline-secondary" type="button" 
                      id="bot_buscar" 
                      onClick={() => getAnimales()}>Buscar</button>
                  </div>
                </div>
                
                {isLoadingBusquedaAnimales &&  
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Cargando listado de animales...</span>
                    </div>
                  </div>
                }
                {!isLoadingBusquedaAnimales &&  
                <table className={`table table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                  <thead>
                    <tr>
                      <th>Id Ficha</th>
                      <th>Diio/Crotal</th>
                      <th>Categoría</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listaAnimales.map(tupla => (
                      <tr key={tupla.id_ficha}>
                        <td>{tupla.id_ficha}</td>
                        <td>{tupla.diio}</td>
                        <td>{tupla.categoria}</td>
                        <td>
                          <button 
                            onClick={(e) => {
                              AddAnimal(e, tupla.id_ficha, tupla.diio);
                              CerrarModalBuscarAnimal();
                            }}
                            className="btn btn-sm btn-outline-success">
                            <i className="fa fa-plus" title="Agregar animal"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                }
              </div>
              <div className="modal-footer border-top border-secondary">
                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>{/*fin modal*/}

        <div className="modal fade" id="addProductoModal" ref={modalAddProducto} 
          tabIndex="-1" aria-labelledby="addProductoModalLabel" aria-hidden="true">
          <div 
            className="modal-dialog modal-lg modal-dialog-scrollable border border-secondary bg-dark"
            style={{ maxHeight: '500px' }}>
            <div className={`modal-content ${darkMode ? 'text-light bg-dark' : ''}`}>
              <div className="modal-header border-bottom border-secondary">
                <h5 className="modal-title" id="addProductoModalLabel">Agregar producto</h5>
                <button 
                  type="button" 
                  className={`btn-close ${darkMode ? 'bg-btn-close-modal': ''}`}
                  data-bs-dismiss="modal" aria-label="Close">
                </button>
              </div>
              <div className="modal-body">

                <div className="row">
                  <div className="input-group mb-3">
                    <input 
                      type="text" className="form-control form-control-sm" 
                      placeholder="Buscar por nombre" 
                      aria-label="Buscar por nombre" 
                      aria-describedby="bot_buscar" 
                      onKeyDown={handleKeyPressBuscarProducto}
                      ref={inputBuscarProducto}/>
                    <button 
                      className="btn btn-outline-secondary" type="button" 
                      id="bot_buscar" 
                      onClick={() => getProductos()}>Buscar</button>
                  </div>
                </div>
                
                {isLoadingProductos &&  
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Cargando listado de productos...</span>
                    </div>
                  </div>
                }
                {!isLoadingProductos &&  
                <div className="table-responsive" style={{ height: '270px', maxHeight: '270px' }}>
                  <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                    <thead className="sticky-top">
                      <tr>
                        <th onClick={() => handleColumnClickModalProducto(1)} className={sortingColumnModalProducto === 1 ? `sorting ${sortDirectionModalProducto}` : ''}>Producto</th>
                        <th onClick={() => handleColumnClickModalProducto(2)} className={sortingColumnModalProducto === 2 ? `sorting ${sortDirectionModalProducto}` : ''}>Tipo</th>
                        <th onClick={() => handleColumnClickModalProducto(3)} className={sortingColumnModalProducto === 3 ? `sorting ${sortDirectionModalProducto}` : ''}>Unidad Med.</th>
                        <th className='text-end'>Stock</th>
                        <th className='text-center'>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productos.map(tupla => (
                        <tr key={tupla.id_producto}>
                          <td>{tupla.n_producto}</td>
                          <td>{tupla.n_tipoproducto}</td>
                          <td>{tupla.n_unidadmedida}</td>
                          <td className='text-end'>{tupla.stock}</td>
                          <td className='text-center'>
                            <button 
                              onClick={() => AddProducto(tupla.id_producto, tupla.stock)}
                              className="btn btn-sm btn-outline-secondary">
                              <i className="fa fa-plus" title="Agregar producto"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                }
              </div>
              <div className="modal-footer border-top border-secondary">
                <div className="row">
                  <div className="col-2">
                  <label htmlFor="cantidad" className="col-form-label">Cantidad:</label>
                  </div>
                  <div className="col-4">
                    <input maxLength="30" 
                    id="cantidad" type="text" className="form-control fomr-control-sm" 
                    onKeyDown={handleKeyPressCantidad} ref={inputCantidad}/>
                  </div>
                  <div className="col-6 text-end">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{/*fin modal*/}
      </div>

    </div>
  );
}

export default ActividadesForm;
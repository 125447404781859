import React, { useRef, useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns';
import { format } from 'date-fns';
import { addDays, subDays } from 'date-fns';
import es from 'date-fns/locale/es'; // Importa el idioma español
import { isValidDate } from '../../utils/utils';

import { CalcularEdad } from '../../utils/utils';

import { useDarkMode } from '../../context/darkModeContext'; 

function FichasForm() {
  // eslint-disable-next-line
  const {darkMode, setDarkMode} = useDarkMode();

  const inputEstado         = useRef(null);
  const inputMotivoinactivo = useRef(null);
  
  const inputDiio           = useRef(null);
  const inputCrotal2        = useRef(null);
  const inputCrotal3        = useRef(null);
  const inputEspecie        = useRef(null);
  const inputRaza           = useRef(null);
  const inputNacpredio      = useRef(null);
  const inputNacpotrero     = useRef(null);
  const inputNacsubpotrero  = useRef(null);
  const inputSexo           = useRef(null);
  const inputCapado         = useRef(null);
  const inputOrigen         = useRef(null);
  const inputOrigenidempresa= useRef(null);
  const inputOrigenidPadre  = useRef(null);

  const inputVentaidempresa = useRef(null);

  const modalReemplazarDiio = useRef(null);
  const inputNuevoDiio      = useRef(null);
  const inputFechaReemplazo = useRef(null);

  const modalObservacion      = useRef(null);
  const inputObservacion      = useRef(null);
  const inputFechaObservacion = useRef(null);

  const modalDiiosDeBaja = useRef(null);

  const bootstrap = window.bootstrap; // se agregó para poder llamar al método hide() de un modal
  
  const { id_ficha } = useParams();
  const [icon, setIcon] = useState('');
  const [textButton, setTextButton] = useState('');

  const [isLoadingDatosForm, setIsLoadingDatosForm] = useState(true);
  const [isLoadingHistorialPesos, setIsLoadingHistorialPesos] = useState(true);
  const [isLoadingMovimientos, setIsLoadingMovimientos] = useState(true);
  const [isLoadingObservaciones, setIsLoadingObservaciones] = useState(true);
  const [isEspeciesLoaded, setEspeciesLoaded] = useState(false);
  const [isLoadingDiiosDeBaja, setIsLoadingDiiosDeBaja] = useState(true);
  
  const modulo = 'fichas'

  const navigate = useNavigate();

  // Obtener la fecha actual
  const fechaActual = new Date();

  // Obtener el día, el mes y el año de la fecha actual
  const dia = fechaActual.getDate().toString().padStart(2, '0');
  const mes = (fechaActual.getMonth() + 1).toString().padStart(2, '0');
  const anio = fechaActual.getFullYear();

  // Crear la cadena con el formato "dd-mm-yyyy"
  const fechaFormateada = `${anio}-${mes}-${dia}`;
  const minSelectableDate = subDays(new Date(), 7); // Fecha actual - 2 día
  const maxSelectableDate = addDays(new Date(), 0); // Fecha actual + 1 día

  const [categoria, setCategoria]                   = useState('');
  const [edad, setEdad]                             = useState('');
  const [ultimopesajepeso, setUltimopesajepeso]     = useState('');
  const [ultimopesajefecha, setUltimopesajefecha]   = useState('');
  const [datosPesaje, setDatospesaje]               = useState([]);
  const [movimientos, setMovimientos]               = useState([]);
  const [observaciones, setObservaciones]           = useState([]);
  const [nombreBotonModalObs, setNombreBotonModalObs] = useState('Ingresar');
  const [accionBotonModalObs, setAccionBotonModalObs] = useState('ingresada');
  

  const [diiosdebaja, setDiiosdebaja]               = useState([]);
  const [diiosdebajaOriginales, setDiiosdebajaOriginales] = useState([]);
  const [sortingColumnModalDiiosBaja, setSortingColumnModalDiiosBaja]   = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
  const [sortDirectionModalDiiosBaja, setSortDirectionModalDiiosBaja]   = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'
  
  const [actualIdPredio, setActualIdPredio]         = useState('');
  const [actualNPredio, setActualNPredio]           = useState('');
  const [actualIdPotrero, setActualIdPotrero]       = useState('');
  const [actualNPotrero, setActualNPotrero]         = useState('');
  const [actualIdSubpotrero, setActualIdSubpotrero] = useState('');
  const [actualNSubpotrero, setActualNSubpotrero]   = useState('');
  
  const [fechareemplazo, setFechareemplazo]         = useState(fechaFormateada);
  const [fechaobservacion, setFechaobservacion]     = useState(fechaFormateada);
  const [actualIdObservacion, setActualIdObservacion] = useState('');

  const initialDatoState = {
    id_ficha              : null,
    created_fecha         : '',
    estado                : '1',
    motivo_inactivo       : 'M',
    venta_id_empresa      : '0',
    venta_fecha           : fechaFormateada, 
    diio                  : '',
    crotal2               : '',
    crotal3               : '',
    id_especie            : '', 
    id_raza               : '',
    sexo                  : 'M',
    capado                : false,
    nac_fecha             : fechaFormateada,
    nac_id_predio         : '',
    nac_id_potrero        : '',
    nac_id_subpotrero     : '',
    actual_id_predio      : '',
    actual_id_potrero     : '',
    actual_id_subpotrero  : '',
    origen                : 'P',
    origen_id_empresa     : '',
    origen_fecha          : fechaFormateada,
    origen_id_padre       : '', 
    muerte_fecha          : fechaFormateada
  };
  const [dato, setDato]                   = useState(initialDatoState);
  const [especies, setEspecies]           = useState([]);
  const [razas, setRazas]                 = useState([]);
  const [predios, setPredios]             = useState([]);
  const [potreros, setPotreros]           = useState([]);
  const [subpotreros, setSubpotreros]     = useState([]);
  const [empresas, setEmpresas]           = useState([]);
  const [empresasVenta, setEmpresasVenta] = useState([]);
  const [padres, setPadres]               = useState([]);
  
  const getDato = async (id_ficha) => {
    setIsLoadingDatosForm(true);
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id_ficha}`,{withCredentials: true});
      setDato(response.data);
      //console.log(response.data.muerte_fecha)
      getCategoriaEspecie(response.data.id_especie, response.data.sexo, response.data.nac_fecha, response.data.capado);
      setIsLoadingDatosForm(false);
    } catch(error) {
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  const getEspecies = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/especies`,{withCredentials: true});
      setEspecies(response.data);
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  const getPredios = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/predios`,{withCredentials: true});
      setPredios(response.data);
    } catch(error){
      console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  const getEmpresas = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/empresas`,{withCredentials: true}); 
      setEmpresas(response.data);
      setEmpresasVenta(response.data)
    } catch(error) {
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  const getCategoriaEspecie = async (v_id_especie, v_sexo, v_nac_fecha, v_capado) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_getcategoriaespecie/${v_id_especie}/${v_sexo}/${v_nac_fecha}/${v_capado}`,{withCredentials: true});
      setCategoria(response.data.nombre);
    } catch (error) {
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    }
  };

  const SetCategoriaEspecie = () => {
    const v_id_especie = inputEspecie.current.value;
    const v_sexo = inputSexo.current.value;
    const v_nac_fecha = dato.nac_fecha;
    let v_capado = false;
    if(inputCapado.current){
      if(v_sexo === "H"){
        inputCapado.current.checked = false
      }
      v_capado = inputCapado.current.checked;
    }
    getCategoriaEspecie(v_id_especie, v_sexo, v_nac_fecha, v_capado);
  };

  const getHistorialPesaje = async () => {
    setIsLoadingHistorialPesos(true);
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_historialpesaje/${id_ficha}`,{withCredentials: true});
      setDatospesaje(response.data);
      setIsLoadingHistorialPesos(false);
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  const getDatosUltimoPesaje = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_datosultimopesaje/${id_ficha}`,{withCredentials: true});
      setUltimopesajepeso(response.data.peso.toLocaleString('es-CL', 
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      ));
      setUltimopesajefecha(response.data.fecha_dd_mm_yyyy);
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  const getMovimientos = async () => {
    setIsLoadingMovimientos(true);
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/movindividual_detallexidficha/${id_ficha}`,{withCredentials: true});
      setMovimientos(response.data);
      setIsLoadingMovimientos(false);
      if(response.data.length > 0){
        getUbicacionActual()
      }
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  const getObservaciones = async () => {
    setIsLoadingObservaciones(true);
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_observaciones/${id_ficha}`,{withCredentials: true});
      setObservaciones(response.data);
      setIsLoadingObservaciones(false);
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  const getUbicacionActual = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_ubicacionactual/${id_ficha}`,{withCredentials: true})
      setActualIdPredio(response.data.actual_id_predio);
      setActualNPredio(response.data.n_predio);
      setActualIdPotrero(response.data.actual_id_potrero);
      setActualNPotrero(response.data.n_potrero);
      setActualIdSubpotrero(response.data.actual_id_subpotrero);
      setActualNSubpotrero(response.data.n_subpotrero);
    } catch(error) {
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  useEffect(() => {
    if(isEspeciesLoaded && dato.id_especie && dato.nac_fecha && dato.sexo && dato.capado !== ''){
      SetCategoriaEspecie();
    }
    // eslint-disable-next-line
  }, [isEspeciesLoaded, dato.id_especie, dato.nac_fecha, dato.sexo, dato.capado]);

  useEffect(() => {
    if(dato.nac_fecha ){
      setEdad(CalcularEdad(dato.nac_fecha));
    }
  }, [dato.nac_fecha]);

  useEffect(() => {
    if (id_ficha) {
      getDato(id_ficha);
      setIcon('fas fa-arrow-left');
      setTextButton('Volver');
      getDatosUltimoPesaje();
      getHistorialPesaje();
      getMovimientos();
      getObservaciones();
      getDiioDeBaja();
    }
    else {
      setIcon('fas fa-ban');
      setTextButton('Cancelar');
      setIsLoadingDatosForm(false);
    }
    getEspecies();
    getPredios();
    getEmpresas();
    //eslint-disable-next-line
  }, [id_ficha]);

  useEffect(() => {
    const fetchData = async () => {
      if (dato.id_especie !== '') {
        try {
          const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/razasxespecie/${dato.id_especie}`,{withCredentials: true});
          setRazas(response.data);
  
          if (response.data.length > 0 && (dato.id_raza === '' || dato.id_raza === 0)) {
            setDato(prevDato => ({
              ...prevDato,
              'id_raza': response.data[0].id_raza
            }));
          }
        } catch (error) {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        }
      }
    };
    fetchData();
    //eslint-disable-next-line
  }, [dato.id_especie]);

  useEffect(() => {
    const fetchData = async () => {
      if (dato.nac_id_predio !== '') {
        try {
          const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/potrerosxpredio/${dato.nac_id_predio}`);
          setPotreros(response.data);
          if (response.data.length > 0 && (dato.nac_id_potrero === '' || dato.nac_id_potrero === 0)) {
            setDato(prevDato => ({
              ...prevDato,
              'nac_id_potrero': response.data[0].id_potrero
            }));
          }
        } catch(error) {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
      }  
    };
    fetchData();
    //eslint-disable-next-line
  }, [dato.nac_id_predio]); 

  useEffect(() => {
    const fetchData = async () => {
      if (dato.nac_id_potrero !== '') {
        try {
          const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/subpotrerosxpotrero/${dato.nac_id_potrero}`);
          setSubpotreros(response.data);
          //No se seleccione el pimer subpotrero de la lista--> subpotrero puede no ser seleccionado
        } catch(error) {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [dato.nac_id_potrero]); 

  useEffect(() => {
    if (especies.length > 0 && inputEspecie.current) {
      // la llamada tiene que ser con 'prevDato =>', si no queda en un loop infinito
      setDato(prevDato => ({
        ...prevDato,
        'id_especie': inputEspecie.current.selectedOptions[0].value
      }));
      setEspeciesLoaded(true);
    }
  }, [especies]); //Este se ejecuta cuando se carga completo el arreglo  

  useEffect(() => {
    if (razas.length > 0 && inputRaza.current && (dato.id_raza === '' || dato.id_raza === 0)) {
      // la llamada tiene que ser con 'prevDato =>', si no queda en un loop infinito
      setDato(prevDato => ({
        ...prevDato,
        'id_raza': inputRaza.current.selectedOptions[0].value
      }));
    } 
  }, [razas, dato.id_raza]); //Este se ejecuta cuando se carga completo el arreglo  

  useEffect(() => {
    if (predios.length > 0 && inputNacpredio.current) {
      // la llamada tiene que ser con 'prevDato =>', si no queda en un loop infinito
      setDato(prevDato => ({
        ...prevDato,
        'nac_id_predio': inputNacpredio.current.selectedOptions[0].value
      }));
      if(movimientos.length === 0){
        setActualIdPredio(inputNacpredio.current.selectedOptions[0].value);
        setActualNPredio(inputNacpredio.current.selectedOptions[0].text);
      }
    }
  }, [predios, movimientos]); //Este se ejecuta cuando se carga completo el arreglo  

  useEffect(() => {
    if (potreros.length > 0 && inputNacpotrero.current) {
      if((dato.nac_id_potrero === '' || dato.nac_id_potrero === 0)){
        // la llamada tiene que ser con 'prevDato =>', si no queda en un loop infinito
        setDato(prevDato => ({
          ...prevDato,
          'nac_id_potrero': inputNacpotrero.current.selectedOptions[0].value
        }));
      }
      if(movimientos.length === 0){
        setActualIdPotrero(inputNacpotrero.current.selectedOptions[0].value);
        setActualNPotrero(inputNacpotrero.current.selectedOptions[0].text);
      }
    }
  }, [potreros, dato.nac_id_potrero, movimientos]); //Este se ejecuta cuando se carga completo el arreglo  

  useEffect(() => {
    if (subpotreros.length > 0 && inputNacsubpotrero.current) {
      //No se seleccione el pimer subpotrero de la lista--> subpotrero puede no ser seleccionado
      if(movimientos.length === 0){
        setActualIdSubpotrero(inputNacsubpotrero.current.selectedOptions[0].value);
        setActualNSubpotrero(inputNacsubpotrero.current.selectedOptions[0].text);
      }
    }
  }, [subpotreros, dato.nac_id_subpotrero, movimientos]); //Este se ejecuta cuando se carga completo el arreglo  

  useEffect(() => {
    if (empresas.length > 0 && inputOrigenidempresa.current) {
      // la llamada tiene que ser con 'prevDato =>', si no queda en un loop infinito
      setDato(prevDato => ({
        ...prevDato,
        'origen_id_empresa': inputOrigenidempresa.current.selectedOptions[0].value
      }));
    }
  }, [empresas]); //Este se ejecuta cuando se carga completo el arreglo  

  useEffect(() => {
    const fetchData = async () => {
      if (dato.origen_id_empresa !== '') {
        try {
          const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/padresxempresa/${dato.origen_id_empresa}`);
          setPadres(response.data);
          if (response.data.length > 0 && (dato.origen_id_padre === '' || dato.origen_id_padre === 0)) {
            setDato(prevDato => ({
              ...prevDato,
              'origen_id_padre': response.data[0].id_padre
            }));
          }
        } catch(error){
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
      }
    };
    fetchData();
    //eslint-disable-next-line
  }, [dato.origen_id_empresa]); 

  useEffect(() => {
    if (padres.length > 0 && inputOrigenidPadre.current && (dato.id_padre === '' || dato.id_padre === 0)) {
      // la llamada tiene que ser con 'prevDato =>', si no queda en un loop infinito
      setDato(prevDato => ({
        ...prevDato,
        'id_padre': inputOrigenidPadre.current.selectedOptions[0].value
      }));
    } 
  }, [padres, dato.id_padre]); //Este se ejecuta cuando se carga completo el arreglo  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDato({ ...dato, [name]: value });
  };

  const handleUbicacionActual = (event) => {
    if(movimientos.length === 0){
      const { name, value } = event.target;
      const selectedText = event.target.options[event.target.selectedIndex].text;
      if(name === 'nac_id_predio'){
        setActualIdPredio(value); setActualNPredio(selectedText)
      } else if(name === 'nac_id_potrero'){
        setActualIdPotrero(value); setActualNPotrero(selectedText)
      } else if(name === 'nac_id_subpotrero'){
        setActualIdSubpotrero(value); setActualNSubpotrero(selectedText)
      }
    }
  };

  const handleCheckboxChange = (event) => {
    setDato({ ...dato, [ event.target.name]: event.target.checked });
  };

  function handleDateChange(date, name) {
    if(date){      
      setDato({ ...dato, [name]: format(date, 'yyyy-MM-dd') });
    }
  }

  function handleDateReemplazoChange(date) {
    if(date){
      setFechareemplazo(format(date, 'yyyy-MM-dd'));
    }
  }
  function handleDateObservacionChange(date) {
    if(date){
      setFechaobservacion(format(date, 'yyyy-MM-dd'));
    }
  }

  const enviarFormulario = async (e) => {
    e.preventDefault();

    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }

    let v_origen_id_empresa = "0";
    let v_origen_id_padre = "0";
    if(dato.origen !== 'P')
    {
      v_origen_id_empresa = inputOrigenidempresa.current.value;
      v_origen_id_padre = inputOrigenidPadre.current.value;
    }
    var data = {
      id_ficha              : dato.id_ficha,
      estado                : dato.estado,
      motivo_inactivo       : dato.motivo_inactivo,
      venta_id_empresa      : dato.venta_id_empresa,
      venta_fecha           : dato.venta_fecha, 
      diio                  : dato.diio,
      crotal2               : dato.crotal2, 
      crotal3               : dato.crotal3,
      id_especie            : inputEspecie.current.value,
      id_raza               : inputRaza.current.value,
      sexo                  : dato.sexo,
      capado                : dato.capado,
      nac_fecha             : dato.nac_fecha,
      nac_id_predio         : inputNacpredio.current.value,
      nac_id_potrero        : inputNacpotrero.current.value,
      nac_id_subpotrero     : inputNacsubpotrero.current.value,
      actual_id_predio      : actualIdPredio,
      actual_id_potrero     : actualIdPotrero,
      actual_id_subpotrero  : actualIdSubpotrero,
      origen                : dato.origen,
      origen_id_empresa     : v_origen_id_empresa,
      origen_fecha          : dato.origen_fecha,
      origen_id_padre       : v_origen_id_padre, 
      usuario               : localStorage.getItem("usuario")
    };
    
    try {
      if (dato.id_ficha) {
        await axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${dato.id_ficha}`, data, {withCredentials: true});
        toast.success('¡Registro actualizado correctamente!', {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      } else {
        const response = await axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`, data, {withCredentials: true});
        toast.success("Registro creado correctamente", {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
  
        navigate(`/${modulo}/${response.data.id_ficha}`);
      }
    } catch (error) {
      if (error.response.status === 401){
        delete axios.defaults.headers.common["Authorization"]; 
        localStorage.removeItem("token"); 
        localStorage.removeItem("usuario");
        navigate('/login');
      } else {
        toast.error(error.response.data, {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      }
    }
  };

  const Imprimir = async () => {
    // Mostrar el mensaje de carga
    const loadingSwal = Swal.fire({
      title: 'Generando documento',
      text: 'Por favor, espere un momento...',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      }
    });
  
    try {
      await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_pdfFicha/${id_ficha}`);
      window.open(`${process.env.REACT_APP_URL_API_PORT}/pdfs/ficha_${id_ficha}.pdf`, "_blank");
      loadingSwal.close();
    } catch (error) {
      if (error.response.status === 401){
        delete axios.defaults.headers.common["Authorization"]; 
        localStorage.removeItem("token"); 
        localStorage.removeItem("usuario");
        navigate('/login');
      } else {
        loadingSwal.close();
        toast.error("Ocurrió un error al generar el pdf", {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      }
    }
  };

  /********** Funciones Modal 'ModalReemplazarDiio' *********/

  //Para agregar el addEventListener al modal 'ModalReemplazarDiio' cuando se dispare el Show 
  useEffect(() => {
    const modalElement = document.querySelector('#ModalReemplazarDiio');
    modalElement.addEventListener('shown.bs.modal', onModalShowReemplazarDiio);
    return () => {
      modalElement.removeEventListener('shown.bs.modal', onModalShowReemplazarDiio);
    };
  }, []);

  const onModalShowReemplazarDiio = () => {
    inputNuevoDiio.current.value='';
    inputNuevoDiio.current.focus();
  }

  const ReemplazarDiio = () => {
    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }

    if(inputNuevoDiio.current.value === ''){
      toast.error('Ingrese el nuevo diio/crotal', {
        position: "top-right", autoClose: 2000, hideProgressBar: true,
        closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
      });
      inputNuevoDiio.current.focus();
    } else {
      Swal.fire({
        title: 'Reemplazar Diio/Crotal', text: '¿Está seguro que desea reemplazar el Diio/Crotal?',
        icon: 'question', showCancelButton: true, confirmButtonColor: '#198754', cancelButtonColor: '#666',
        confirmButtonText: 'Reemplazar', cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed)
        {
          // Bloquear pantalla mientras se realiza la operación
          const loadingSwal = Swal.fire({
            title: 'Cargando datos', text: 'Por favor, espere un momento...',
            allowOutsideClick: false,showConfirmButton: false, willOpen: () => {Swal.showLoading();}
          });
  
          var data = {
            currentdiio : inputDiio.current.value,
            newdiio     : inputNuevoDiio.current.value,
            fecha       : fechareemplazo, 
            usuario : localStorage.getItem("usuario")
          };
  
          try{
            await axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_reemplazardiio/${id_ficha}`, data);
  
            loadingSwal.close();
  
            //***********************************
            //Cerrar modal
            const modalElement = modalReemplazarDiio.current;
            if (modalElement) {
              const bootstrapModal = bootstrap.Modal.getInstance(modalElement);
              bootstrapModal.hide();
            } 
            //***********************************
  
            toast.success("Diio/Crotal reemplazado correctamente", {
              position: "top-right", autoClose: 2000, hideProgressBar: true,
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
            getDiioDeBaja();
            setDato({ ...dato, 'diio': inputNuevoDiio.current.value });
          } catch(error){
            if (error.response.status === 401){
              delete axios.defaults.headers.common["Authorization"]; 
              localStorage.removeItem("token"); 
              localStorage.removeItem("usuario");
              navigate('/login');
            } else {
              loadingSwal.close();
              console.log(error);
              toast.error(error.response.data, {
                position: "top-right", autoClose: 2000, hideProgressBar: true,
                closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
              });
            }
          };
        }
      });
    }
    
  }
  /*********************************************************/

  /********** Funciones Modal 'ModalObservacion' *********/

  //Para agregar el addEventListener al modal 'ModalObservacion' cuando se dispare el Show 
  useEffect(() => {
    const modalElement = document.querySelector('#ModalObservacion');
    modalElement.addEventListener('shown.bs.modal', onModalShowObservacion);
    return () => {
      modalElement.removeEventListener('shown.bs.modal', onModalShowObservacion);
    };
  }, []);

  const onModalShowObservacion = () => {
    inputObservacion.current.focus();
  }

  const Grabar_Observacion = () => {
    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }
    
    if(inputObservacion.current.value === ''){
      toast.error('Ingrese descripción', {
        position: "top-right", autoClose: 2000, hideProgressBar: true,
        closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
      });
      inputObservacion.current.focus();
    } else {
      Swal.fire({
        title: 'Observación', text: '¿Está seguro que desea ' + nombreBotonModalObs.toLowerCase() + ' esta observación?',
        icon: 'question', showCancelButton: true, confirmButtonColor: '#198754', cancelButtonColor: '#666',
        confirmButtonText: nombreBotonModalObs, cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed)
        {
          // Bloquear pantalla mientras se realiza la operación
          const loadingSwal = Swal.fire({
            title: 'Cargando datos', text: 'Por favor, espere un momento...',
            allowOutsideClick: false,showConfirmButton: false, willOpen: () => {Swal.showLoading();}
          });
  
          var data = {
            id_observacion  : actualIdObservacion,
            observacion     : inputObservacion.current.value,
            fecha           : fechaobservacion, 
            usuario         : localStorage.getItem("usuario")
          };
  
          try{
            if(actualIdObservacion !==''){
              await axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_observaciones/${actualIdObservacion}`, data);
            } else {
              await axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_observaciones/${id_ficha}`, data);
            }

            loadingSwal.close();
  
            //***********************************
            //Cerrar modal
            const modalElement = modalObservacion.current;
            if (modalElement) {
              const bootstrapModal = bootstrap.Modal.getInstance(modalElement);
              bootstrapModal.hide();
              inputObservacion.current.value='';
            } 
            //***********************************
  
            toast.success("Observación " + accionBotonModalObs.toLowerCase() + " correctamente", {
              position: "top-right", autoClose: 2000, hideProgressBar: true,
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });

            setNombreBotonModalObs('Ingresar');
            setAccionBotonModalObs('Ingresada');

            getObservaciones();
          } catch(error){
            if (error.response.status === 401){
              delete axios.defaults.headers.common["Authorization"]; 
              localStorage.removeItem("token"); 
              localStorage.removeItem("usuario");
              navigate('/login');
            } else {
              loadingSwal.close();
              console.log(error);
              toast.error(error.response.data, {
                position: "top-right", autoClose: 2000, hideProgressBar: true,
                closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
              });
            }
          };
        }
      });
    }
  }

  const SetObservacion = (event, id_observacion, v_fecha, v_observacion) => {
    event.preventDefault();
    setNombreBotonModalObs('Actualizar');
    setAccionBotonModalObs('Actualizada');
    setActualIdObservacion(id_observacion);
    inputFechaObservacion.current.value = v_fecha;
    inputObservacion.current.value = v_observacion;
    
    const modal = new bootstrap.Modal(modalObservacion.current);
    modal.show();
  }

  const handleDeleteObservacion = (event, id_observacion) => {
    event.preventDefault();
    Swal.fire({
      title: 'Eliminar registro', text: '¿Estás seguro que deseas eliminar este registro?',
      icon: 'warning', showCancelButton: true, confirmButtonColor: '#d33', cancelButtonColor: '#666',
      confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar',
    }).then((result) => {
      // Si se confirma la eliminación, hacer la petición a la API para eliminar el elemento
      if (result.isConfirmed) 
      {
        axios.delete(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_observaciones/${id_observacion}`).then(response =>
        {
          if (response.data) {
            toast.success('¡Registro eliminado correctamente!', {
              position: "top-right", autoClose: 2000, hid_fichaeProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });

            getObservaciones();
          } else {
            toast.error('¡No se pudo eliminar el registro!', {
              position: "top-right", autoClose: 2000, hid_fichaeProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
      }
    });
  };
  /*********************************************************/
  
  /************ INICIO FUNCIONES ORDEN ARRAY (MODAL DIIOSDEBAJA) *************************************** */
  const handleColumnClickModalDiiosBaja = (columnIndex) => {
    if (sortingColumnModalDiiosBaja === columnIndex) { setSortDirectionModalDiiosBaja(sortDirectionModalDiiosBaja === 'asc' ? 'desc' : 'asc');
    } else { setSortingColumnModalDiiosBaja(columnIndex); setSortDirectionModalDiiosBaja('asc');}
  };
  useEffect(() => {    
    const rows = [...diiosdebajaOriginales]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
    if (sortingColumnModalDiiosBaja !== -1) {  
      rows.sort((a, b) => {
        const valA = a[Object.keys(a)[sortingColumnModalDiiosBaja]]; const valB = b[Object.keys(b)[sortingColumnModalDiiosBaja]];

        if (typeof valA === 'number' && typeof valB === 'number') { return valA - valB;
        } else if (isValidDate(valA) && isValidDate(valB)) { const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
        } else { return valA.localeCompare(valB); }
      });
  
      if (sortDirectionModalDiiosBaja === 'desc') { rows.reverse(); }
      setDiiosdebaja(rows); // Actualizamos el estado de sortedRows con los datos ordenados
    }
  }, [sortingColumnModalDiiosBaja, sortDirectionModalDiiosBaja, diiosdebajaOriginales]); // Dependencias: sortingColumn, sortDirection, datosOriginales
  /************ FIN FUNCIONES ORDEN ARRAY (MODAL DIIOSDEBAJA) *************************************** */

  const getDiioDeBaja = async () => {
    setIsLoadingDiiosDeBaja(true);
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_reemplazardiio/${id_ficha}`);
      setDiiosdebaja(response.data);
      setDiiosdebajaOriginales(response.data);
      setIsLoadingDiiosDeBaja(false);
    } catch(error) {
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    }
  };

  return (
    <div className="row justify-content-center w-100">
      <div className="col col-lg-10 col-xl-8">
      {isLoadingDatosForm &&  
        <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Cargando datos...</span>
            </div>
        </div>
      }
      {!isLoadingDatosForm &&    
        <form onSubmit={enviarFormulario}>
          <small>
          <div className="row pt-2 pb-2">
            <div className="col text-end">
              {id_ficha && (
              <button type="button" className="btn btn-info btn-sm" 
                onClick={() => Imprimir()}>
                <i className="fas fa-print"></i> Imprimir
              </button>
              )}
              &nbsp;
              <button type="submit" className="btn btn-success btn-sm">
                <i className="fas fa-check"></i> Guardar
              </button>
              &nbsp;
              <Link to={`/${modulo}`} className="btn btn-secondary btn-sm">
                <i className={icon} title="Cancelar"></i> {textButton}
              </Link>
            </div>
          </div>
          {id_ficha &&
          <fieldset className={`border p-2 mb-2 ${darkMode ? 'border-secondary' : ''}`}>
            <div className="row pb-2">
              <label className="col-sm-2">Id. Ficha:</label>
              <div className="col-sm-4">
                <input type="text" name="id_ficha" value={dato.id_ficha} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                readOnly/>
              </div>
              <label className="col-sm-2">Fecha:</label>
              <div className="col-sm-4">
                <input type="text" name="created_fecha" value={dato.created_fecha} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                readOnly/>
              </div>
            </div>

            <div className="row">
              <label className="col-sm-2">Estado:</label>
              <div className="col-sm-4">
                <select 
                  className="form-select form-select-sm" 
                  name="estado" 
                  ref={inputEstado} 
                  value={dato.estado} 
                  onChange={(event) => { handleInputChange(event); }} 
                  required>
                  <option key={0} value={'0'}>Inactivo</option>
                  <option key={1} value={'1'}>Activo</option>
                </select>
              </div>
              {parseInt(dato.estado) === 0 &&  
              <label className="col-sm-2">Motivo:</label>
              }
              {parseInt(dato.estado) === 0 &&  
              <div className="col-sm-4">
                <select className="form-select form-select-sm" name="motivo_inactivo" ref={inputMotivoinactivo} 
                  value={dato.motivo_inactivo} onChange={(event) => { handleInputChange(event); }} required>
                  <option key={0} value='M'>Muerte</option>
                  <option key={1} value='V'>Venta</option>
                </select>
              </div>
              }
            </div>
            
            {parseInt(dato.estado) === 0 &&  dato.motivo_inactivo === "V" &&  
            <div className="row pb-2 pt-2">
              <label className="col-sm-2">Empresa Vta.:</label>
              <div className="col-sm-4">
                <select className="form-select form-select-sm" name="venta_id_empresa" ref={inputVentaidempresa} 
                  value={dato.venta_id_empresa} onChange={handleInputChange} required>
                  {empresasVenta.map((opcion) => (
                    <option key={opcion.id_empresa} value={opcion.id_empresa}>{opcion.n_empresa}</option>
                  ))}
                </select>
              </div>
              <label className="col-sm-2">Fec. Vta.:</label>
              <div className="col-sm-4">
                <DatePicker
                  selected={parseISO(dato.venta_fecha, 'ddMMyyyy', new Date())}
                  onChange={(date) => handleDateChange(date, "venta_fecha")}
                  dateFormat="dd-MM-yyyy"
                  className="form-control form-control-sm" 
                  required 
                  locale={es} // Establece el idioma español
                />
              </div>
            </div>
            }
            {parseInt(dato.estado) === 0 &&  dato.motivo_inactivo === "M" &&  
            <div className="row pb-2 pt-2 justify-content-end">
              <label className="col-sm-2">Fec. Muerte:</label>
              <div className="col-sm-4">
                <DatePicker
                  selected={parseISO(dato.muerte_fecha, 'ddMMyyyy', new Date())}
                  onChange={(date) => handleDateChange(date, "muerte_fecha")}
                  dateFormat="dd-MM-yyyy"
                  className="form-control form-control-sm" 
                  required 
                  locale={es} // Establece el idioma español
                />
              </div>
            </div>
            }
          </fieldset>
          }

          <fieldset className={`border p-2 mb-2 ${darkMode ? 'border-secondary' : ''}`}>
            <div className="row">
              <label className="col-sm-2">Diio/Crotal:</label>
              <div className="col-sm-4">
                <input 
                  type="text" 
                  name="diio" 
                  value={dato.diio} 
                  ref={inputDiio} 
                  autoFocus 
                  disabled={movimientos.length > 0}
                  onChange={handleInputChange} 
                  className="form-control form-control-sm" 
                  required/>
              </div>
              {id_ficha && 
              <div className="col-sm-6">
                <button 
                  type="button" 
                  className="btn btn-primary btn-sm" 
                  data-bs-toggle="modal" 
                  data-bs-target="#ModalReemplazarDiio">
                  <i className="fas fa-exchange-alt"></i> Reemplazar Diio
                </button>
                &nbsp;
                <button 
                  type="button" 
                  className="btn btn-primary btn-sm"
                  data-bs-toggle="modal" 
                  data-bs-target="#modalDiiosDeBaja">
                  <i className="fas fa-info-circle"></i> Diios de baja
                </button>
              </div>
              }
            </div>

            <div className="row pt-2">
              <label className="col-sm-2">Crotal 2:</label>
              <div className="col-sm-4">
                <input type="text" name="crotal2" value={dato.crotal2} ref={inputCrotal2}  
                onChange={handleInputChange} className="form-control form-control-sm"/>
              </div>
              <label className="col-sm-2">Crotal 3:</label>
              <div className="col-sm-4">
                <input type="text" name="crotal3" value={dato.crotal3} ref={inputCrotal3}  
                onChange={handleInputChange} className="form-control form-control-sm"/>
              </div>
            </div>

            <div className="row pt-2">
              <label className="col-sm-2">Especie:</label>
              <div className="col-sm-4">
                <select className="form-select form-select-sm" name="id_especie" ref={inputEspecie} 
                  value={dato.id_especie} onChange={handleInputChange} required>
                  {especies.map((opcion) => (
                    <option key={opcion.id_especie} value={opcion.id_especie}>{opcion.n_especie}</option>
                  ))}
                </select>
              </div>
              <label className="col-sm-2">Raza:</label>
              <div className="col-sm-4">
                <select className="form-select form-select-sm" name="id_raza" ref={inputRaza} 
                  value={dato.id_raza} onChange={handleInputChange} required>
                  {razas.map((opcion) => (
                    <option key={opcion.id_raza} value={opcion.id_raza}>{opcion.n_raza}</option>
                  ))}
                </select>
              </div>
            </div>
            
            <div className="row pt-2">
              <label className="col-sm-2">Sexo:</label>
              <div className="col-sm-4">
                <select className="form-select form-select-sm" name="sexo" ref={inputSexo} 
                  value={dato.sexo} onChange={(event) => { handleInputChange(event); }} required>
                  <option key={0} value={'M'}>Macho</option>
                  <option key={1} value={'H'}>Hembra</option>
                </select>
              </div>
              <div className="col-sm-6">
                {dato.sexo === "M" &&  
                <div className="form-check">
                  <input className="form-check-input" onChange={handleCheckboxChange} name="capado" ref={inputCapado} 
                    type="checkbox" id="checkCapado" checked={dato.capado}/>
                  <label className="form-check-label" htmlFor="checkCapado">
                    Capado
                  </label>
                </div>
                }
              </div>
            </div>

            <div className="row pt-2">
              <label className="col-sm-2">Fec. Nac.:</label>
              <div className="col-sm-4">
                <DatePicker
                  selected={parseISO(dato.nac_fecha, 'ddMMyyyy', new Date())}
                  onChange={(date) => handleDateChange(date, "nac_fecha")}
                  dateFormat="dd-MM-yyyy"
                  className="form-control form-control-sm" 
                  required 
                  locale={es} // Establece el idioma español
                />
              </div>
              <label className="col-sm-2">Categoría:</label>
              <div className="col-sm-4">
                <input type="text" name="categoria" value={categoria}
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                readOnly/>
              </div>
            </div>

            <div className="row pt-2">
              <label className="col-sm-2">Edad:</label>
              <div className="col-sm-4">
                <input type="text" name="edad" value={edad}
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                readOnly/>
              </div>
            </div>
          </fieldset>
          
          <fieldset className={`border p-2 mb-2 position-relative ${darkMode ? 'border-secondary' : ''}`}>
            <legend 
              className={`w-auto position-absolute ${darkMode ? 'bg-dark' : 'bg-light'}`} 
              >Lugar nacimiento</legend>
            <div className="row">
              <label className="col-sm-2">Predio:</label>
              <div className="col-sm-4">
                <select className="form-select form-select-sm" name="nac_id_predio" ref={inputNacpredio} 
                  value={dato.nac_id_predio} 
                  onChange={(event) => {
                    handleInputChange(event);
                    handleUbicacionActual(event); 
                  }} 
                  disabled={movimientos.length > 0}
                  required>
                  {predios.map((opcion) => (
                    <option key={opcion.id_predio} value={opcion.id_predio}>{opcion.n_predio}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row pt-2">
              <label className="col-sm-2">Potrero:</label>
              <div className="col-sm-4">
                <select className="form-select form-select-sm" name="nac_id_potrero" ref={inputNacpotrero} 
                  value={dato.nac_id_potrero} 
                  onChange={(event) => {
                    handleInputChange(event);
                    handleUbicacionActual(event); 
                  }}
                  disabled={movimientos.length > 0}
                  required>
                  {potreros.map((opcion) => (
                    <option key={opcion.id_potrero} value={opcion.id_potrero}>{opcion.n_potrero}</option>
                  ))}
                </select>
              </div>
              <label className="col-sm-2">Subpotrero:</label>
              <div className="col-sm-4">
                <select className="form-select form-select-sm" name="nac_id_subpotrero" ref={inputNacsubpotrero} 
                  value={dato.nac_id_subpotrero} 
                  onChange={(event) => {
                    handleInputChange(event);
                    handleUbicacionActual(event); 
                  }}
                  disabled={movimientos.length > 0}
                  >
                    <option key={0} value={''}></option>
                  {subpotreros.map((opcion) => (
                    <option key={opcion.id_subpotrero} value={opcion.id_subpotrero}>{opcion.n_subpotrero}</option>
                  ))}
                </select>
              </div>
            </div>
          </fieldset>
          
          <fieldset className={`border p-2 mb-2 position-relative ${darkMode ? 'border-secondary' : ''}`}>
            <legend 
              className={`w-auto position-absolute ${darkMode ? 'bg-dark' : 'bg-light'}`} 
              >Ubicación actual</legend>
            <div className="row">
              <label className="col-sm-2">Predio:</label>
              <div className="col-sm-4">
                <input type="hidden" name="actual_id_predio" value={actualIdPredio} />
                <input type="text" name="actual_n_predio" value={actualNPredio} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                readOnly/>
              </div>
            </div>

            <div className="row pt-2">
              <label className="col-sm-2">Potrero:</label>
              <div className="col-sm-4">
                <input type="hidden" name="actual_id_potrero" value={actualIdPotrero} />
                <input type="text" name="actual_n_potrero" value={actualNPotrero}
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                readOnly/>
              </div>
              <label className="col-sm-2">Subpotrero:</label>
              <div className="col-sm-4">
                <input type="hidden" name="actual_id_subpotrero" value={actualIdSubpotrero} />
                <input type="text" name="actual_n_subpotrero" value={actualNSubpotrero}
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                readOnly/>
              </div>
            </div>
          </fieldset>
          
          <fieldset className={`border p-2 mb-2 ${darkMode ? 'border-secondary' : ''}`}>
            <div className="row">
              <label className="col-sm-2">Origen:</label>
              <div className="col-sm-4">
                <select className="form-select form-select-sm" name="origen" ref={inputOrigen} 
                  value={dato.origen} onChange={(event) => { handleInputChange(event); }} required>
                  <option key={0} value={'I'}>Inseminado</option>
                  <option key={1} value={'C'}>Comprado</option>
                  <option key={2} value={'P'}>Propietario</option>
                </select>
              </div>
              {dato.origen !== 'P' &&  
              <label className="col-sm-2">Fec. Origen:</label>
              }
              {dato.origen !== 'P' &&  
              <div className="col-sm-4">
                <DatePicker
                  selected={parseISO(dato.origen_fecha, 'ddMMyyyy', new Date())}
                  onChange={(date) => handleDateChange(date, "origen_fecha")}
                  dateFormat="dd-MM-yyyy"
                  className="form-control form-control-sm" 
                  required 
                  locale={es} // Establece el idioma español
                />
              </div>
              }
            </div>
            
            {dato.origen !== 'P' &&  
            <div className="row pt-2">
              <label className="col-sm-2">Empresa:</label>
              <div className="col-sm-4">
                <select className="form-select form-select-sm" name="origen_id_empresa" ref={inputOrigenidempresa} 
                  value={dato.origen_id_empresa} onChange={handleInputChange} required>
                  {empresas.map((opcion) => (
                    <option key={opcion.id_empresa} value={opcion.id_empresa}>{opcion.n_empresa}</option>
                  ))}
                </select>
              </div>
              <label className="col-sm-2">Padre:</label>
              <div className="col-sm-4">
                <select className="form-select form-select-sm" name="origen_id_padre" ref={inputOrigenidPadre} 
                  value={dato.origen_id_padre} onChange={handleInputChange} required>
                  {padres.map((opcion) => (
                    <option key={opcion.id_padre} value={opcion.id_padre}>{opcion.n_padre}</option>
                  ))}
                </select>
              </div>
            </div>
            }
          </fieldset>
          
          {id_ficha && 
          <fieldset className={`border p-2 mb-2 ${darkMode ? 'border-secondary' : ''}`}>
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button className="nav-link active" id="nav-pesajes-tab" data-bs-toggle="tab" data-bs-target="#nav-pesajes" type="button" role="tab" aria-controls="nav-pesajes" aria-selected="true">Pesaje</button>
                <button className="nav-link" id="nav-movimientos-tab" data-bs-toggle="tab" data-bs-target="#nav-movimientos" type="button" role="tab" aria-controls="nav-movimientos" aria-selected="false">Movimientos</button>
                <button className="nav-link" id="nav-observaciones-tab" data-bs-toggle="tab" data-bs-target="#nav-observaciones" type="button" role="tab" aria-controls="nav-observaciones" aria-selected="false">Observaciones</button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-pesajes" role="tabpanel" aria-labelledby="nav-pesajes-tab">
                <div className="row pt-2">
                  <label className="col-sm-2">Último Pesaje:</label>
                  <div className="col-sm-4">
                    <input type="text" name="ultimo_pesaje_peso" value={ultimopesajepeso}
                    className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                    readOnly/>
                  </div>
                  <label className="col-sm-2">Fecha:</label>
                  <div className="col-sm-4">
                    <input type="text" name="ultimo_pesaje_fecha" value={ultimopesajefecha}
                    className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                    readOnly/>
                  </div>
                </div>      
                  
                {isLoadingHistorialPesos &&  
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Cargando datos...</span>
                  </div>
                </div>
                }
                {!isLoadingHistorialPesos &&  
                <div className="row">
                  <div className="col-sm-4">
                    <small><label><b>Historial de Pesos</b></label></small>
                    <table className={`table table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                      <thead>
                        <tr>
                          <th>Fecha</th>
                          <th className="text-end">Kgs</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datosPesaje.map(tupla => (
                          <tr key={tupla.id_pesaje}>
                            <td>{tupla.fecha_dd_mm_yyyy}</td>
                            <td className="text-end">
                            {
                              tupla.peso.toLocaleString('es-CL', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                }
              </div>
              <div className="tab-pane fade" id="nav-movimientos" role="tabpanel" aria-labelledby="nav-movimientos-tab">
                {isLoadingMovimientos &&  
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Cargando datos...</span>
                  </div>
                </div>
                }
                {!isLoadingMovimientos &&  
                <div className="row">
                  <div className="col">
                    <small><label><b>Historial de Movimientos</b></label></small>
                    <table className={`table table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                      <thead>
                        <tr>
                          <th>N° Mov.</th>
                          <th>Fecha</th>
                          <th>Origen</th>
                          <th>Destino</th>
                        </tr>
                      </thead>
                      <tbody>
                        {movimientos.map(tupla => {
                          let strOrigen   = tupla.origen_n_predio + "/" + tupla.origen_n_potrero;
                          if(tupla.origen_n_subpotrero !== ''){strOrigen+="/" + tupla.origen_n_subpotrero}
                          let strDestino  = tupla.destino_n_predio + "/" + tupla.destino_n_potrero;
                          if(tupla.destino_n_subpotrero !== ''){strDestino+="/" + tupla.destino_n_subpotrero}

                          return(
                          <tr key={tupla.id_mov}>
                            <td>{tupla.id_mov}</td>
                            <td>{tupla.fecha_mov_dd_mm_yyyy}</td>
                            <td>{strOrigen}</td>
                            <td>{strDestino}</td>
                          </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                }
              </div>
              
              <div className="tab-pane fade" id="nav-observaciones" role="tabpanel" aria-labelledby="nav-observaciones-tab">
                {isLoadingObservaciones &&  
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Cargando datos...</span>
                  </div>
                </div>
                }
                {!isLoadingObservaciones &&  
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="col-8">
                        <small><label><b>Historial de observaciones</b></label></small> 
                      </div>
                      <div className="col-4 text-end">
                        <button 
                          type="button" 
                          className="btn btn-primary btn-sm pt-0 pb-0"
                          data-bs-toggle="modal" 
                          data-bs-target="#ModalObservacion" 
                        >
                          <i className="fas fa-plus-circle"></i> Agregar observación
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                      <table className={`table table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Fecha</th>
                              <th>Observación</th>
                              <th>Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {observaciones.map((tupla, index) => {
                              const cont = index + 1;
                              return (
                              <tr key={tupla.id_observacion}>
                                <td>{cont}</td>
                                <td>{tupla.fecha_dd_mm_yyyy}</td>
                                <td>{tupla.observacion}</td>
                                <td>
                                  <button 
                                    onClick={(event) => SetObservacion(event, tupla.id_observacion, tupla.fecha, tupla.observacion)} 
                                    className="btn btn-sm btn-outline-secondary">
                                    <i className="fa fa-edit" title="Editar"></i>
                                  </button>
                                  &nbsp;
                                  <button 
                                    onClick={(event) => handleDeleteObservacion(event, tupla.id_observacion)} 
                                    className="btn btn-sm btn-outline-secondary">
                                    <i className="fa fa-trash-alt" title="Eliminar"></i>
                                  </button>
                                </td>
                              </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                }
              </div>
            </div>
          </fieldset>
          }
          </small>

        </form>
        }
      </div>
      
      <div className="modal fade" id="ModalReemplazarDiio" ref={modalReemplazarDiio} 
        tabIndex="-1" aria-labelledby="ModalReemplazarDiioLabel" aria-hidden="true">
        <div className="modal-dialog border border-secondary">
          <div className={`modal-content ${darkMode ? 'text-light bg-dark' : ''}`}>
            <div className="modal-header border-bottom border-secondary">
              <h5 className="modal-title" id="ModalReemplazarDiioLabel">Reemplazar Diio/Crotal</h5>
              <button 
                type="button" 
                className={`btn-close ${darkMode ? 'bg-btn-close-modal': ''}`}
                data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              <div className="row pb-2">
                <label className="col-sm-4">Nuevo Diio:</label>
                <div className="col-sm-8">
                  <input type="text" name="nuevo_diio" ref={inputNuevoDiio} 
                  className="form-control form-control-sm"/>
                </div>
              </div>

              <div className="row">
                <label className="col-sm-4">Fecha:</label>
                <div className="col-sm-8">
                  <DatePicker
                    selected={parseISO(fechareemplazo, 'ddMMyyyy', new Date())}
                    dateFormat="dd-MM-yyyy"
                    className="form-control form-control-sm" 
                    onChange={(date) => handleDateReemplazoChange(date)}
                    minDate={minSelectableDate}
                    maxDate={maxSelectableDate}
                    ref={inputFechaReemplazo}
                    locale={es} // Establece el idioma español
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer border-top border-secondary">
              <button type="button" className="btn btn-success btn-sm"
                onClick={() => ReemplazarDiio()}>
                Reemplazar
              </button>

              <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>{/*fin modal*/}
      
      <div className="modal fade" id="modalDiiosDeBaja" ref={modalDiiosDeBaja} 
        tabIndex="-1" aria-labelledby="modalDiiosDeBajaLabel" aria-hidden="true">
        <div className="modal-dialog border border-secondary"
          style={{ maxHeight: '500px' }}>
          <div className={`modal-content ${darkMode ? 'text-light bg-dark' : ''}`}>
            <div className="modal-header border-bottom border-secondary">
              <h5 className="modal-title" id="modalDiiosDeBajaLabel">Diios de Baja</h5>
              <button 
                type="button" 
                className={`btn-close ${darkMode ? 'bg-btn-close-modal': ''}`}
                data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              {isLoadingDiiosDeBaja &&  
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Cargando listado de diios de baja...</span>
                  </div>
                </div>
              }
              {!isLoadingDiiosDeBaja &&  
              <div className="table-responsive" style={{ height: '270px', maxHeight: '270px' }}>
                <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                  <thead className="sticky-top">
                    <tr>
                      <th onClick={() => handleColumnClickModalDiiosBaja(1)} className={sortingColumnModalDiiosBaja === 1 ? `sorting ${sortDirectionModalDiiosBaja}` : ''}>Diios/Crotal</th>
                      <th onClick={() => handleColumnClickModalDiiosBaja(2)} className={sortingColumnModalDiiosBaja === 2 ? `sorting ${sortDirectionModalDiiosBaja}` : ''}>Fecha</th>
                    </tr>
                  </thead>
                  <tbody>
                    {diiosdebaja.map(tupla => (
                      <tr key={tupla.id_reemplazo}>
                        <td>{tupla.diio}</td>
                        <td>{tupla.fecha_dd_mm_yyyy}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              }
            </div>
            <div className="modal-footer border-top border-secondary">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>{/*fin modal*/}

      <div className="modal fade" id="ModalObservacion" ref={modalObservacion} 
        tabIndex="-1" aria-labelledby="ModalObservacionLabel" aria-hidden="true">
        <div className="modal-dialog border border-secondary">
          <div className={`modal-content ${darkMode ? 'text-light bg-dark' : ''}`}>
            <div className="modal-header border-bottom border-secondary">
              <h5 className="modal-title" id="ModalObservacionLabel">Observación</h5>
              <button 
                type="button" 
                className={`btn-close ${darkMode ? 'bg-btn-close-modal': ''}`}
                data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              <div className="row pb-2">
                <label className="col-sm-3">Fecha:</label>
                <div className="col-sm-9">
                  <DatePicker
                    selected={parseISO(fechaobservacion, 'ddMMyyyy', new Date())}
                    dateFormat="dd-MM-yyyy"
                    className="form-control form-control-sm" 
                    onChange={(date) => handleDateObservacionChange(date)}
                    minDate={minSelectableDate}
                    maxDate={maxSelectableDate}
                    ref={inputFechaObservacion}
                    locale={es} // Establece el idioma español
                  />
                </div>
              </div>

              <div className="row">
                <label className="col-sm-3">Observación:</label>
                <div className="col-sm-9">
                  <textarea 
                    name="observacion" ref={inputObservacion} 
                    className="form-control" 
                    rows="3"
                    style={{
                      resize: 'none',
                      overflow: 'auto'
                    }}></textarea>
                </div>
              </div>

              
            </div>
            <div className="modal-footer border-top border-secondary">
              <button type="button" className="btn btn-success btn-sm"
                onClick={() => Grabar_Observacion()}>
                {nombreBotonModalObs}
              </button>

              <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>{/*fin modal*/}
    </div>
  );
}

export default FichasForm;
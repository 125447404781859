import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function UsuariosForm() {
  const { id } = useParams();
  const [icon, setIcon] = useState('');
  const [textButton, setTextButton] = useState('');
  const [showPassInputs, setShowPassInputs] = useState(false);
  const [isLoadingDatos, setIsLoadingDatos] = useState(true);

  const readOnly = id ? true : false;

  const modulo = 'usuarios'

  const navigate = useNavigate();

  const initialDatoState = {
    id: null,
    usuario: '',
    nombre: '',
    email: '',
    id_rol: '',
    pass1: '',
    pass2: '',
  };
  const [dato, setDato] = useState(initialDatoState);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (id) {
      getDato(id);
      setIcon('fas fa-arrow-left');
      setTextButton('Volver');
    }
    else {
      setIcon('fas fa-ban');
      setTextButton('Cancelar');
      setShowPassInputs(true);
      setIsLoadingDatos(false);
    }
    getRoles();
    // eslint-disable-next-line
  }, [id]);
  
  const getDato = (id) => {
    setIsLoadingDatos(true);
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id}`,{withCredentials: true})
      .then(response => {
        setDato(response.data);
        setIsLoadingDatos(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  
  const getRoles = () => {
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/roles`,{withCredentials: true})
      .then(response => {
        setRoles(response.data);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDato({ ...dato, [name]: value });
  };

  const enviarFormulario = (e) => {
    e.preventDefault();

    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }

    if(showPassInputs)
    {
      if(dato.pass1 !== dato.pass2)
      {
        toast.error('¡Las contraseñas ingresadas son distintas!', {
          position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
        return;
      }
    }
    else
    {
      dato.pass1 = "";
    }

    var data = {
        usuario : dato.usuario,
        nombre  : dato.nombre,
        email   : dato.email,
        id_rol  : dato.id_rol,
        pass    : dato.pass1, 
        user    : localStorage.getItem("usuario")
    };

    if (dato.id) {
      axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${dato.id}`, data,{withCredentials: true})
      .then(response => {
        toast.success('¡Registro actualizado correctamente!', {
          position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
        setShowPassInputs(false);
      })
      .catch(error => {
        if (error.response.status === 401){
          delete axios.defaults.headers.common["Authorization"]; 
          localStorage.removeItem("token"); 
          localStorage.removeItem("usuario");
          navigate('/login');
        } else {
          toast.error(error.response.data, {
            position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
        }
      });
    } else {
      //Verificar que las contraseñas sean iguales
      axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`, data,{withCredentials: true})
      .then(response => {
        toast.success("Registro creado correctamente", {
          position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });

        setShowPassInputs(false);
        navigate(`/${modulo}/${response.data.id}`);
        /*
        setUsuario({ ...usuario, 'id': response.data.id });//actualizando sólo el id del arreglo
        setIcon('fas fa-arrow-left');
        setTextButton('Volver');
        inputusuario.current.readOnly = true;
        setShowPassInputs(false);
        */
      })
      .catch(error => {
        if (error.response.status === 401){
          delete axios.defaults.headers.common["Authorization"]; 
          localStorage.removeItem("token"); 
          localStorage.removeItem("usuario");
          navigate('/login');
        } else {
          toast.error(error.response.data, {
            position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
        }
      });
    }
  };

  return (
    <div className="row justify-content-center w-100">
      <div className="col-10 col-xs-8 col-md-8 col-lg-6  col-xl-4">
        {isLoadingDatos &&  
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Cargando datos...</span>
            </div>
          </div>
        }
        {!isLoadingDatos &&  
        <form onSubmit={enviarFormulario}>
          <div className="form-group">
              <label>Usuario:</label>
              <input type="text" name="usuario" readOnly={readOnly} autoFocus 
              value={dato.usuario} onChange={handleInputChange} 
              className="form-control form-control-sm" required/>
          </div>

          <div className="form-group">
              <label>Nombre:</label>
              <input type="text" name="nombre" value={dato.nombre} 
              onChange={handleInputChange} className="form-control form-control-sm" required/>
          </div>

          <div className="form-group">
              <label>Correo:</label>
              <input type="email" name="email" value={dato.email} 
              onChange={handleInputChange} className="form-control form-control-sm" required/>
          </div>

          <div className="form-group">
            <label>Rol:</label>
            <select className="form-select form-select-sm" name="id_rol" value={dato.id_rol} onChange={handleInputChange} required>
              <option value="">Seleccione un rol</option>
              {roles.map((opcion) => (
                <option key={opcion.id_rol} value={opcion.id_rol}>{opcion.n_rol}</option>
              ))}
            </select>
          </div>

          {id && (
            <div className="pt-1">
              <button type="button" onClick={() => setShowPassInputs(!showPassInputs)} 
                className="btn btn-primary btn-sm">
                <i className="fas fa-key"></i> Resetear clave
              </button>
            </div>
          )}
          
          {showPassInputs && (
            <div className="pt-1">
              <div className="form-group pb-1">
                <label>Clave:</label>
                <input type="password" name="pass1" 
                onChange={handleInputChange} className="form-control form-control-sm" required/>
              </div>

              <div className="form-group pb-1">
                <label>Reingrese Clave:</label>
                <input type="password" name="pass2" 
                onChange={handleInputChange} className="form-control form-control-sm" required/>
              </div>
            </div>
          )}
          <hr/>
          <div className="pt-1">
            <button type="submit" className="btn btn-success btn-sm">
              <i className="fas fa-check"></i> Guardar
            </button>
            &nbsp;
            <Link to={`/${modulo}`} className="btn btn-secondary btn-sm">
              <i className={icon} title="Cancelar"></i> {textButton}
            </Link>
          </div>
        </form>
        }
      </div>
    </div>
  );
}

export default UsuariosForm;
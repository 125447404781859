import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function RolesForm() {
  const { id_rol } = useParams();
  const [icon, setIcon] = useState('');
  const [textButton, setTextButton] = useState('');
  const navigate = useNavigate();
  const [isLoadingDatos, setIsLoadingDatos] = useState(true);

  const modulo = 'roles'

  const initialDatoState = {
    id_rol: null,
    n_rol: '',
    acceso_dashboard  : false,
    acceso_mapa      : false,
    acceso_actividades: false,
    acceso_fichas     : false,
    acceso_lotes      : false,
    acceso_movimientos: false,
    acceso_productos  : false,
    acceso_reportes   : false,
    acceso_tablas     : false,
    acceso_config     : false
  };
  const [dato, setDato] = useState(initialDatoState);

  useEffect(() => {
    if (id_rol) {
      getDato(id_rol);
      setIcon('fas fa-arrow-left');
      setTextButton('Volver');
    }
    else {
      setIcon('fas fa-ban');
      setTextButton('Cancelar');
      setIsLoadingDatos(false);
    }
    // eslint-disable-next-line
  }, [id_rol]);
  
  const getDato = (id_rol) => {
    setIsLoadingDatos(true);
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id_rol}`,{withCredentials: true})
      .then(response => {
        setDato(response.data);
        setIsLoadingDatos(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDato({ ...dato, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    setDato({ ...dato, [ event.target.name]: event.target.checked });
  };

  const enviarFormulario = (e) => {
    e.preventDefault();

    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }
    
    var data = {
        n_rol: dato.n_rol,
        acceso_dashboard    : dato.acceso_dashboard, 
        acceso_mapa         : dato.acceso_mapa, 
        acceso_actividades  : dato.acceso_actividades, 
        acceso_fichas       : dato.acceso_fichas, 
        acceso_lotes        : dato.acceso_lotes, 
        acceso_movimientos  : dato.acceso_movimientos, 
        acceso_productos    : dato.acceso_productos, 
        acceso_reportes     : dato.acceso_reportes, 
        acceso_tablas       : dato.acceso_tablas, 
        acceso_config       : dato.acceso_config, 
        usuario             : localStorage.getItem("usuario")
    };

    if (dato.id_rol) {
      axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${dato.id_rol}`, data,{withCredentials: true})
      .then(response => {
        toast.success('¡Registro actualizado correctamente!', {
          position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){
          delete axios.defaults.headers.common["Authorization"]; 
          localStorage.removeItem("token"); 
          localStorage.removeItem("usuario"); 
          navigate('/login');
        } else {
          toast.error(error.response.data, {
            position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
        }
      });
    } else {
      axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`, data,{withCredentials: true})
      .then(response => {
        toast.success("Registro creado correctamente", {
          position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });

        navigate(`/${modulo}/${response.data.id_rol}`);
      })
      .catch(error => {
        if (error.response.status === 401){
          delete axios.defaults.headers.common["Authorization"]; 
          localStorage.removeItem("token"); 
          localStorage.removeItem("usuario"); 
          navigate('/login');
        } else {
          toast.error(error.response.data, {
            position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
        }
      });
    }
  };

  return (
    <div className="row justify-content-center w-100">
      <div className="col-10 col-xs-8 col-md-8 col-lg-6  col-xl-4">
        {isLoadingDatos &&  
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Cargando datos...</span>
            </div>
          </div>
        }
        {!isLoadingDatos &&  
        <form onSubmit={enviarFormulario}>
          
          <div className="form-group">
              <label>Descripción:</label>
              <input type="text" name="n_rol" value={dato.n_rol} autoFocus 
              onChange={handleInputChange} className="form-control form-control-sm" required/>
          </div>
          <hr/>
          <p className="text-start">Permisos</p>

          <div className="form-check">
            <input className="form-check-input" onChange={handleCheckboxChange} name="acceso_dashboard" 
            type="checkbox" id="checkDashboard" checked={dato.acceso_dashboard}/>
            <label className="form-check-label" htmlFor="checkDashboard">
              Dashboard
            </label>
          </div>

          <div className="form-check">
            <input className="form-check-input" onChange={handleCheckboxChange} name="acceso_mapa" 
            type="checkbox" id="checkMapa" checked={dato.acceso_mapa}/>
            <label className="form-check-label" htmlFor="checkMapa">
              Mapa
            </label>
          </div>

          <div className="form-check">
            <input className="form-check-input" onChange={handleCheckboxChange} name="acceso_actividades" 
            type="checkbox" id="checkActividades" checked={dato.acceso_actividades}/>
            <label className="form-check-label" htmlFor="checkActividades">
              Actividades
            </label>
          </div>

          <div className="form-check">
            <input className="form-check-input" onChange={handleCheckboxChange} name="acceso_fichas" 
            type="checkbox" id="checkFichas" checked={dato.acceso_fichas}/>
            <label className="form-check-label" htmlFor="checkFichas">
              Ficha Individual
            </label>
          </div>

          <div className="form-check">
            <input className="form-check-input" onChange={handleCheckboxChange} name="acceso_lotes" 
            type="checkbox" id="checkLotes" checked={dato.acceso_lotes}/>
            <label className="form-check-label" htmlFor="checkLotes">
              Lotes
            </label>
          </div>
          
          <div className="form-check">
            <input className="form-check-input" onChange={handleCheckboxChange} name="acceso_movimientos" 
            type="checkbox" id="checkMovimientos" checked={dato.acceso_movimientos}/>
            <label className="form-check-label" htmlFor="checkMovimientos">
              Movimientos
            </label>
          </div>
          
          <div className="form-check">
            <input className="form-check-input" onChange={handleCheckboxChange} name="acceso_productos" 
            type="checkbox" id="checkProductos" checked={dato.acceso_productos}/>
            <label className="form-check-label" htmlFor="checkProductos">
              Productos
            </label>
          </div>

          <div className="form-check">
            <input className="form-check-input" onChange={handleCheckboxChange} name="acceso_reportes" 
            type="checkbox" id="checkReportes" checked={dato.acceso_reportes}/>
            <label className="form-check-label" htmlFor="checkReportes">
              Reportes
            </label>
          </div>

          <div className="form-check">
            <input className="form-check-input" onChange={handleCheckboxChange} name="acceso_tablas" 
            type="checkbox" id="checkTablas" checked={dato.acceso_tablas}/>
            <label className="form-check-label" htmlFor="checkTablas">
              Tablas
            </label>
          </div>

          <div className="form-check">
            <input className="form-check-input" onChange={handleCheckboxChange} name="acceso_config" 
            type="checkbox" id="checkConfiguracion" checked={dato.acceso_config}/>
            <label className="form-check-label" htmlFor="checkConfiguracion">
              Configuración
            </label>
          </div>

          <hr/>
          <div className="pt-1">
            <button type="submit" className="btn btn-success btn-sm">
              <i className="fas fa-check"></i> Guardar
            </button>
            &nbsp;
            <Link to={`/roles`} className="btn btn-secondary btn-sm">
              <i className={icon} title="Cancelar"></i> {textButton}
            </Link>
          </div>
        </form>
        }
      </div>
    </div>
  );
}

export default RolesForm;
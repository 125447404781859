import React from 'react';
import DashOvino from './DashOvino';
import DashBovino from './DashBovino';
import DashAlpaca from './DashAlpaca';
import DashEquino from './DashEquino';
import DashCanino from './DashCanino';
import DashActividades from './DashActividades';

const Dashboard = () => {
    return (
        <div className="row justify-content-center w-100">
            <div className="col">
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button className="nav-link active" id="nav-ovino-tab" data-bs-toggle="tab" data-bs-target="#nav-ovino" type="button" role="tab" aria-controls="nav-ovino" aria-selected="true">Ovino</button>
                        <button className="nav-link" id="nav-bovino-tab" data-bs-toggle="tab" data-bs-target="#nav-bovino" type="button" role="tab" aria-controls="nav-bovino" aria-selected="false">Bovino</button>
                        <button className="nav-link" id="nav-alpaca-tab" data-bs-toggle="tab" data-bs-target="#nav-alpaca" type="button" role="tab" aria-controls="nav-bovino" aria-selected="false">Alpaca</button>
                        <button className="nav-link" id="nav-alpaca-tab" data-bs-toggle="tab" data-bs-target="#nav-equino" type="button" role="tab" aria-controls="nav-equino" aria-selected="false">Equino</button>
                        <button className="nav-link" id="nav-alpaca-tab" data-bs-toggle="tab" data-bs-target="#nav-canino" type="button" role="tab" aria-controls="nav-canino" aria-selected="false">Canino</button>
                        <button className="nav-link" id="nav-actividades-tab" data-bs-toggle="tab" data-bs-target="#nav-actividades" type="button" role="tab" aria-controls="nav-actividades" aria-selected="false">Actividades</button>
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <DashOvino />
                    <DashBovino />
                    <DashAlpaca />
                    <DashEquino />
                    <DashCanino />
                    <DashActividades />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
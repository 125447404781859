import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = ()=>{
    return(
        <div>
            <h1 className="display-1"><b>404</b></h1>
            <h3 className="text-muted">Página No Encontrada</h3>
            <p>
                <Link to="/" className="text-secondary">Ir al inicio</Link>
            </p>
        </div>
    )
}

export default NotFoundPage;
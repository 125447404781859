import moment from 'moment';

export function validarRUT(rut) {
  const rutRegExp = /^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/; // Expresión regular para validar el formato del RUT
  if (!rutRegExp.test(rut)) {
    return false;
  }
  const rutNumber = rut.replace(/\./g, "").replace(/-/g, ""); // Eliminamos los puntos separadores de miles y el guión del RUT
  const rutDigits = rutNumber.substring(0, rutNumber.length - 1); // Obtenemos los dígitos del RUT
  const dv = rutNumber.charAt(rutNumber.length - 1).toUpperCase(); // Obtenemos el dígito verificador del RUT
  let suma = 0;
  let factor = 2;
  for (let i = rutDigits.length - 1; i >= 0; i--) {
    suma += factor * rutDigits.charAt(i);
    factor = factor === 7 ? 2 : factor + 1;
  }
  const dvCalculado = 11 - (suma % 11);
  const dvEsperado = dvCalculado === 11 ? "0" : dvCalculado === 10 ? "K" : dvCalculado.toString();
  return dv === dvEsperado;
}

export function isValidDate(dateString) {
  // Función para verificar si una cadena representa una fecha válida en formato "dd-mm-yyyy"
  const regexDate = /^\d{2}-\d{2}-\d{4}$/;
  return regexDate.test(dateString);
}  

export function CalcularEdad(v_fecha){
  let strEdad = '', strAnios = '', strMeses = '';
  if(v_fecha){
    const fecha = moment(v_fecha, 'YYYY-MM-DD');
    const hoy = moment();

    const diffYears = hoy.diff(fecha, 'years');
    fecha.add(diffYears, 'years');
    const diffMonths = hoy.diff(fecha, 'months');

    
    if (diffYears > 0) {
        strAnios = diffYears + ' año' + (diffYears === 1 ? '' : 's');
        strEdad = strAnios;
    }
    if (diffMonths > 0) {
        strMeses = diffMonths + ' mes' + (diffMonths === 1 ? '' : 'es');
        strEdad += (strEdad !== '' ? ' y ' : '') + strMeses;
    } 
  }
  return strEdad;
}

export function findLongTextByHref(data, targetHref) {
  const topLevelText = data.map(item => {
    if (item.href === targetHref) {
      return item.longText;
    }
    if (item.children) {
      const childItem = item.children.find(child => child.href === targetHref);
      if (childItem) {
        return childItem.longText;
      }
    }
    return null;
  });

  const foundText = topLevelText.find(longText => longText !== null);
  return foundText || 'Dashboard';
}

export function getNModuloUrl(fullUrl) {
  const urlObject = new URL(fullUrl); // Crear un objeto URL
  const pathname = urlObject.pathname; // Obtener la ruta de la URL
  
  let modulo;
  const parts = pathname.split('/');
  if(parts.length > 0){ modulo = parts[1]; }
  else{ modulo = pathname; }

  return '/' + modulo;
}
import React, { useState, useRef } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

function Login() {
    let navigate = useNavigate();
    const passInputRef = useRef(null);

    const [datos, setDatos] = useState({
        usuario: "",
        pass: ""
    });

    const [msg, setMsg] = useState('');

    const handleInputChange = (e) =>{
        let {name,value} = e.target;
        let newDatos = {...datos, [name]: value};
        setDatos(newDatos);
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            passInputRef.current.focus();
        }
    }

    const handleSubmit = async(e) =>{
        // console.log(process.env.REACT_APP_URL_API_PORT);
        e.preventDefault();
        if(!e.target.checkValidity()){
            console.log("no enviar");
        } else{
            try {
                //Se debe incluir ,{withCredentials: true} para que se puedan crear la session.usuario en el servidor
                let res = await axios.post(`${process.env.REACT_APP_URL_API_PORT}/login`, datos,{withCredentials: true});

                const token = res.data.token;
                localStorage.setItem("token", token);
                localStorage.setItem("usuario", datos.usuario);
                navigate('/');
            } catch (error) {
                if (error.response) {
                    setMsg(error.response.data.msg);
                }
                // console.log(error.response);
                console.log(error);
            }
        }
    };

    document.body.style.backgroundColor = '#212529';

    return (
    <section className="h-100">
        <div className="container-fluid h-100">
            <div className="row justify-content-sm-center h-100">
                <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
                    <hr/>
                    <div className="card shadow-lg bg-dark text-light">
                        <div className="card-body p-5">
                            <h1 className="fs-4 card-title fw-bold mb-4">GesGan</h1>
                            <form onSubmit={handleSubmit} className="needs-validation" noValidate={true} autoComplete="off">
                                <p className="has-text-centered text-danger"><b>{msg}</b></p>
                                <div className="mb-3">
                                    <label className="mb-2" htmlFor="usuario">Usuario</label>
                                    <input id="usuario" type="text" onChange={handleInputChange} onKeyDown={handleKeyDown} value={datos.usuario} 
                                        className="form-control form-control-sm" name="usuario" required autoFocus />
                                    <div className="invalid-feedback">
                                        Usuario inválido
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="mb-2 w-100">
                                        <label htmlFor="pass">Contraseña</label>
                                    </div>
                                    <input id="pass" type="password" onChange={handleInputChange} value={datos.pass} 
                                        className="form-control form-control-sm" name="pass" required ref={passInputRef}/>
                                    <div className="invalid-feedback">
                                        Contraseña es requirida
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <button type="submit" className="btn btn-primary btn-sm ms-auto">
                                        <i className="bi bi-box-arrow-in-right"></i> Ingresar
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="card-footer py-3 border-0">
                            <div className="text-center">
                                Todos los derechos reservados &copy; 2023
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default Login;

import React, { useRef, useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useDarkMode } from '../../context/darkModeContext'; 

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns';
import { format } from 'date-fns';
import es from 'date-fns/locale/es'; // Importa el idioma español
import { isValidDate } from '../../utils/utils';

function GiuasentradaForm() {
  // eslint-disable-next-line
  const {darkMode, setDarkMode} = useDarkMode();
  const { id_guia_entrada } = useParams();
  const [valorCambiado, setValorCambiado] = useState(false);
    
  const navigate = useNavigate();
  const modulo = 'guiasentrada'

  const modalSeleccionarEmpresa = useRef(null);
  const inputBuscarEmpresa      = useRef(null);
  const modalAddProducto        = useRef(null);
  const inputBuscarProducto     = useRef(null);
  const inputCantidad           = useRef(null);

  const [isLoadingDatos, setIsLoadingDatos]         = useState(true);
  const [isLoadingDetalle, setIsLoadingDetalle]     = useState(false);
  const [isLoadingProductos, setIsLoadingProductos] = useState(false);

  const bootstrap = window.bootstrap; // se agregó para poder llamar al método hide() de un modal

  const initialDatoState = {
    id_guia_entrada : null,
    status          : '',
    id_empresa      : '',
    n_empresa       : '',
    rut             : '',
    doc_tipo        : 'F', 
    doc_folio       : '',
    doc_fecha       : ''
  };
  const [dato, setDato]                   = useState(initialDatoState);
  const [datosdetalle, setDatosdetalle]   = useState([]);
  const [datosdetalleOriginales, setDatosdetalleOriginales]   = useState([]);
  const [sortingColumn, setSortingColumn] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
  const [sortDirection, setSortDirection] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

  const [empresas, setEmpresas]                                   = useState([]);
  const [empresasOriginales, setEmpresasOriginales]               = useState([]);
  const [sortingColumnModalEmpresa, setSortingColumnModalEmpresa] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
  const [sortDirectionModalEmpresa, setSortDirectionModalEmpresa] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

  const [productosOriginales, setProductosOriginales]                 = useState([]);
  const [productos, setProductos]                                     = useState([]);
  const [sortingColumnModalProducto, setSortingColumnModalProducto]   = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
  const [sortDirectionModalProducto, setSortDirectionModalProducto]   = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

  const [unidadesmedida, setUnidadesmedida] = useState([]);
  
  const getDetalle = async () => {
    setIsLoadingDetalle(true);
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalle/${id_guia_entrada}`,{withCredentials: true});
      setDatosdetalle(response.data);
      setDatosdetalleOriginales(response.data);
      setIsLoadingDetalle(false);  
    } catch(error) {
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    }
  };

  const getDato = async () => {
    setIsLoadingDatos(true);
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id_guia_entrada}`,{withCredentials: true});
      setDato(response.data);
      setIsLoadingDatos(false);
      getDetalle();
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    }
  };

  const getEmpresas = async () => {
    const strBuscar = inputBuscarEmpresa.current.value;
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/empresas_busqueda_aprox`, 
      {
        withCredentials: true, 
        params: {
          strBuscar: strBuscar
        }
      });
      setEmpresas(response.data);
      setEmpresasOriginales(response.data);
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    }
  };

  const SeleccionarEmpresa = (id_empresa, n_empresa, rut) => {
    setDato({ ...dato, 'id_empresa': id_empresa, 'n_empresa': n_empresa, 'rut': rut });
    const modalElement = modalSeleccionarEmpresa.current;
    if (modalElement) {
      const bootstrapModal = bootstrap.Modal.getInstance(modalElement);
      bootstrapModal.hide();
    }  
  }

  const getProductos = async () => {
    setIsLoadingProductos(true);
    const strBuscar = inputBuscarProducto.current.value;
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/productos_busqueda_aprox`, 
      {
        withCredentials: true, 
        params: {
          strBuscar: strBuscar, 
          id_guia_entrada : id_guia_entrada
        }
      });
      setProductos(response.data);
      setProductosOriginales(response.data);
      setIsLoadingProductos(false);
    } catch(error) {
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    }
  };

  const getUnidadesmedida = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/unidadesmedida`,{withCredentials: true});
      setUnidadesmedida(response.data);
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    }
  };

  useEffect(() => {//Principal
    if (id_guia_entrada) {
      getDato();
      getUnidadesmedida();
    }
    //eslint-disable-next-line
  }, [id_guia_entrada]);
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDato({ ...dato, [name]: value });
  };

  function handleDateChange(date) {
    if(date){
      setDato({ ...dato, 'doc_fecha': format(date, 'yyyy-MM-dd') });
    }
  }

  const enviarFormulario = (e) => {
    e.preventDefault();

    if(dato.id_empresa === "")
    {
      toast.error("Seleccione una empresa", {
        position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
        pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
      });
      return;
    }

    var data = {
      id_guia_entrada : dato.id_guia_entrada,
      id_empresa      : dato.id_empresa,
      doc_tipo        : dato.doc_tipo, 
      doc_folio       : dato.doc_folio, 
      doc_fecha       : dato.doc_fecha, 
      usuario : localStorage.getItem("usuario")
    };

    if (dato.id_guia_entrada) {
      axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${dato.id_guia_entrada}`, data,{withCredentials: true})
      .then(response => {
        toast.success('¡Registro actualizado correctamente!', {
          position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      })
      .catch(error => {
        if (error.response.status === 401){
          delete axios.defaults.headers.common["Authorization"]; 
          localStorage.removeItem("token"); 
          localStorage.removeItem("usuario");
          navigate('/login');
        } else {
          toast.error(error.response.data, {
            position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
        }
      });
    }
  };

  /*********************************************************/
  /********** Funciones Modal 'selectEmpresaModal' *********/
  /*********************************************************/

  //Para agregar el addEventListener al modal 'selectEmpresaModal' cuando se dispare el Show 
  useEffect(() => {
    const modalElement = document.querySelector('#selectEmpresaModal');
    modalElement.addEventListener('shown.bs.modal', onModalShowSelectEmpresa);
    return () => {
      modalElement.removeEventListener('shown.bs.modal', onModalShowSelectEmpresa);
    };
  }, []);
  const onModalShowSelectEmpresa = () => {
    inputBuscarEmpresa.current.focus();
  }
  const handleKeyPressBuscarEmpresa = (event) => {
    if (event.key === 'Enter') {
      getEmpresas();
    }
  };

  /*********************************************************/
  /********** Funciones Modal 'addProductoModal' *********/
  /*********************************************************/

  //Para agregar el addEventListener al modal 'addProductoModal' cuando se dispare el Show 
  useEffect(() => {
    const modalElement = document.querySelector('#addProductoModal');
    modalElement.addEventListener('shown.bs.modal', onModalShowAddProducto);
    return () => {
      modalElement.removeEventListener('shown.bs.modal', onModalShowAddProducto);
    };
  }, []);

  const onModalShowAddProducto = () => {
    inputBuscarProducto.current.focus();
    inputCantidad.current.value = '1';
  }

  const handleKeyPressBuscarProducto = (event) => {
    if (event.key === 'Enter') {
      getProductos();
    }
  };
  //--------------------------------------------------------------------------------------
  
  /************ INICIO FUNCIONES ORDEN ARRAY (DETALLE) *************************************** */
  const handleColumnClick = (columnIndex) => {
    if (sortingColumn === columnIndex) { setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else { setSortingColumn(columnIndex); setSortDirection('asc');}
  };
  useEffect(() => {    
    const rows = [...datosdetalleOriginales]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
    if (sortingColumn !== -1) {  
      rows.sort((a, b) => {
        const valA = a[Object.keys(a)[sortingColumn]]; const valB = b[Object.keys(b)[sortingColumn]];

        if (typeof valA === 'number' && typeof valB === 'number') { return valA - valB;
        } else if (isValidDate(valA) && isValidDate(valB)) { const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
        } else { return valA.localeCompare(valB); }
      });
  
      if (sortDirection === 'desc') { rows.reverse();}
      setDatosdetalle(rows); // Actualizamos el estado de sortedRows con los datos ordenados
    }
  }, [sortingColumn, sortDirection, datosdetalleOriginales]); // Dependencias: sortingColumn, sortDirection, datosOriginales
  /************ FIN FUNCIONES ORDEN ARRAY (DETALLE) *************************************** */

  /************ INICIO FUNCIONES ORDEN ARRAY (MODAL EMPRESAS) *************************************** */
  const handleColumnClickModalEmpresa = (columnIndex) => {
    if (sortingColumnModalEmpresa === columnIndex) { setSortDirectionModalEmpresa(sortDirectionModalEmpresa === 'asc' ? 'desc' : 'asc');
    } else { setSortingColumnModalEmpresa(columnIndex); setSortDirectionModalEmpresa('asc');}
  };
  useEffect(() => {    
    const rows = [...empresasOriginales]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
    if (sortingColumnModalEmpresa !== -1) {  
      rows.sort((a, b) => {
        const valA = a[Object.keys(a)[sortingColumnModalEmpresa]]; const valB = b[Object.keys(b)[sortingColumnModalEmpresa]];

        if (typeof valA === 'number' && typeof valB === 'number') { return valA - valB;
        } else if (isValidDate(valA) && isValidDate(valB)) { const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
        } else { return valA.localeCompare(valB); }
      });
  
      if (sortDirectionModalEmpresa === 'desc') { rows.reverse(); }
      setEmpresas(rows); // Actualizamos el estado de sortedRows con los datos ordenados
    }
  }, [sortingColumnModalEmpresa, sortDirectionModalEmpresa, empresasOriginales]); // Dependencias: sortingColumn, sortDirection, datosOriginales
  /************ FIN FUNCIONES ORDEN ARRAY (MODAL EMPRESAS) *************************************** */

  /************ INICIO FUNCIONES ORDEN ARRAY (MODAL PRODUCTOS) *************************************** */
  const handleColumnClickModalProducto = (columnIndex) => {
    if (sortingColumnModalProducto === columnIndex) { setSortDirectionModalProducto(sortDirectionModalProducto === 'asc' ? 'desc' : 'asc');
    } else { setSortingColumnModalProducto(columnIndex); setSortDirectionModalProducto('asc');}
  };
  useEffect(() => {    
    const rows = [...productosOriginales]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
    if (sortingColumnModalProducto !== -1) {  
      rows.sort((a, b) => {
        const valA = a[Object.keys(a)[sortingColumnModalProducto]]; const valB = b[Object.keys(b)[sortingColumnModalProducto]];

        if (typeof valA === 'number' && typeof valB === 'number') { return valA - valB;
        } else if (isValidDate(valA) && isValidDate(valB)) { const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
        } else { return valA.localeCompare(valB); }
      });
  
      if (sortDirectionModalProducto === 'desc') { rows.reverse(); }
      setProductos(rows); // Actualizamos el estado de sortedRows con los datos ordenados
    }
  }, [sortingColumnModalProducto, sortDirectionModalProducto, productosOriginales]); // Dependencias: sortingColumn, sortDirection, datosOriginales
  /************ FIN FUNCIONES ORDEN ARRAY (MODAL PRODUCTOS) *************************************** */

  const handleInputChangeUniMedCompra = async (event, id_guia_entrada_detalle) => {  
    setDatosdetalle(prevState => {
      return prevState.map(tupla => {
        if (tupla.id_guia_entrada_detalle === id_guia_entrada_detalle) {
          return {...tupla, id_unidadmedida_compra: event.target.value};
        }
        return tupla;
      });
    });

    var data = {
      n_columna : 'id_unidadmedida_compra',
      value     : event.target.value
    };

    try{
      await axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalle/${id_guia_entrada}/${id_guia_entrada_detalle}`, data,{withCredentials: true});
      toast.success("Registro actualizado correctamente", {
        position: "top-right", autoClose: 2000, hideProgressBar: true,
        closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
      });
    } catch(error){
      if (error.response.status === 401){
        delete axios.defaults.headers.common["Authorization"]; 
        localStorage.removeItem("token"); 
        localStorage.removeItem("usuario");
        navigate('/login');
      } else {
        toast.error(error.response.data, {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      }
    }
  };

  //----------------------------------------------
  const handleKeyPressCantidad = (event) => {
    if (event.key === 'Enter') {
      event.target.blur(); // desenfocar el input si se presiona Enter
    }

    if (!/[0-9\b]|Arrow(Left|Right)|Delete|Backspace/.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleInputChangeCantidad = (event, id_guia_entrada_detalle) => {
    setValorCambiado(true); // marcar la bandera como verdadera --> el onchange siempre se activa si hubo un cambio en el input text
    setDatosdetalle(prevState => {
      return prevState.map(tupla => {
        if (tupla.id_guia_entrada_detalle === id_guia_entrada_detalle) {
          return {...tupla, cantidad: event.target.value};
        }
        return tupla;
      });
    });
  }

  const handleBlurCantidad = (event, id_guia_entrada_detalle) => {
    let cant = event.target.value;
    
    if (valorCambiado) {
      if (/^\d+$/.test(cant)){

        if ( parseInt(cant) > 0 && parseFloat(cant) <= 99999 ) {
          var data = {
            n_columna : 'cantidad',
            value     : cant,
            usuario   : localStorage.getItem("usuario")
          };
          
          axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalle/${id_guia_entrada}/${id_guia_entrada_detalle}`, data,{withCredentials: true}).then(response => {
            toast.success("Registro actualizado correctamente", {
              position: "top-right", autoClose: 2000, hideProgressBar: true,
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          })
          .catch(error => {
            if (error.response.status === 401){
              delete axios.defaults.headers.common["Authorization"]; 
              localStorage.removeItem("token"); 
              localStorage.removeItem("usuario");
              navigate('/login');
            } else {
              toast.error(error.response.data, {
                position: "top-right", autoClose: 2000, hideProgressBar: true,
                closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
              });
            }
          });
        } else{
          toast.error('Cantidad es un valor entre 1 y 99999', {
            position: "top-right", autoClose: 2000, hideProgressBar: true,
            closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
        }
      } else {
        toast.error('Cantidad es un valor numérico', {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      }
      setValorCambiado(false); // resetear la bandera a falso
    }
  };

  //----------------------------------------------
  const handleKeyPressPrecio = (event) => {
    if (event.key === 'Enter') {
      event.target.blur(); // desenfocar el input si se presiona Enter
    }

    if (!/[0-9\b]|Arrow(Left|Right)|Delete|Backspace/.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleInputChangePrecio = (event, id_guia_entrada_detalle) => {
    setValorCambiado(true); // marcar la bandera como verdadera --> el onchange siempre se activa si hubo un cambio en el input text
    setDatosdetalle(prevState => {
      return prevState.map(tupla => {
        if (tupla.id_guia_entrada_detalle === id_guia_entrada_detalle) {
          return {...tupla, precio: event.target.value};
        }
        return tupla;
      });
    });
  }

  const handleBlurPrecio = (event, id_guia_entrada_detalle) => {
    let precio = event.target.value;
    
    if (valorCambiado) {
      if (/^\d+$/.test(precio)){

        if ( parseInt(precio) > 0 && parseFloat(precio) <= 99999 ) {
          var data = {
            n_columna : 'precio',
            value     : precio,
            usuario   : localStorage.getItem("usuario")
          };
          
          axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalle/${id_guia_entrada}/${id_guia_entrada_detalle}`, data,{withCredentials: true}).then(response => {
            toast.success("Registro actualizado correctamente", {
              position: "top-right", autoClose: 2000, hideProgressBar: true,
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          })
          .catch(error => {
            if (error.response.status === 401){
              delete axios.defaults.headers.common["Authorization"]; 
              localStorage.removeItem("token"); 
              localStorage.removeItem("usuario");
              navigate('/login');
            } else {
              toast.error(error.response.data, {
                position: "top-right", autoClose: 2000, hideProgressBar: true,
                closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
              });
            }
          });
        } else{
          toast.error('Precio es un valor entre 1 y 9.999.999', {
            position: "top-right", autoClose: 2000, hideProgressBar: true,
            closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
        }
      } else {
        toast.error('Precio es un valor numérico', {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      }
      setValorCambiado(false); // resetear la bandera a falso
    }
  };

  //----------------------------------------------

  const handleDelete = (id_guia_entrada_detalle) => {
    Swal.fire({
      title: 'Eliminar registro', text: '¿Estás seguro que deseas eliminar este registro?',
      icon: 'warning', showCancelButton: true, confirmButtonColor: '#d33', cancelButtonColor: '#666',
      confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar',
    }).then((result) => {
      // Si se confirma la eliminación, hacer la petición a la API para eliminar el elemento
      if (result.isConfirmed) 
      {
        axios.delete(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalle/${id_guia_entrada}/${id_guia_entrada_detalle}`,{withCredentials: true}).then(response =>
        {
          if (response.data) {
            toast.success(response.data.message, {
              position: "top-right", autoClose: 2000, hid_productoeProgressBar: true,
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
            setDato({ ...dato, 'status': response.data.status });
            setProductos([]);
            setDatosdetalle(datosdetalle.filter(datosdetalle => datosdetalle.id_guia_entrada_detalle !== id_guia_entrada_detalle)); //Quitar del arreglo la fila del id_producto eliminado
          } else {
            toast.error('¡No se pudo eliminar el registro!', {
              position: "top-right", autoClose: 2000, hid_productoeProgressBar: true, 
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        });
      }
    });
  };

  const AddProducto = (id_producto) => {
    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }
    
    let cant = inputCantidad.current.value

    if (/^\d+$/.test(cant)){
      //Number.isInteger(parseFloat(cant)) valida si es entero

      if ( parseInt(cant) > 0 && parseFloat(cant) <= 99999 ) {
        var data = {
          id_guia_entrada : id_guia_entrada,
          id_producto     : id_producto,
          cantidad        : cant, 
          usuario : localStorage.getItem("usuario")
        };
        
        axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalle`, data,{withCredentials: true}).then(response => {
          toast.success("Registro creado correctamente", {
            position: "top-right", autoClose: 2000, hideProgressBar: true,
            closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
          
          //------------
          const modalElement = modalAddProducto.current;
          if (modalElement) {
            const bootstrapModal = bootstrap.Modal.getInstance(modalElement);
            bootstrapModal.hide();
          }
          //-----------
          setDato({ ...dato, 'status': 'PENDIENTE' });
          setProductos([]);
          getDetalle();
        })
        .catch(error => {
          if (error.response.status === 401){
            delete axios.defaults.headers.common["Authorization"]; 
            localStorage.removeItem("token"); 
            localStorage.removeItem("usuario");
            navigate('/login');
          } else {
            toast.error(error.response.data, {
              position: "top-right", autoClose: 2000, hideProgressBar: true,
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        });
      } else{
        toast.error('Cantidad es un valor entre 1 y 99999', {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
        inputCantidad.current.focus();  
      }
    } else {
      toast.error('Cantidad es un valor numérico', {
        position: "top-right", autoClose: 2000, hideProgressBar: true,
        closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
      });
      inputCantidad.current.focus();
    }
  }

  const handleClickFinalizar = () =>{
    console.log(dato.id_empresa)
    if(dato.id_empresa === "")
    {
      toast.error("Seleccione una empresa", {
        position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
        pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
      });
      return;
    }

    Swal.fire({
      title: 'Finalizar Guía', text: '¿Estás seguro que deseas finalizar esta guía?',
      icon: 'question', showCancelButton: true, confirmButtonColor: '#198754', cancelButtonColor: '#666',
      confirmButtonText: 'Finalizar', cancelButtonText: 'Cancelar',
    }).then((result) => {
      // Si se confirma la eliminación, hacer la petición a la API para eliminar el elemento
      if (result.isConfirmed) 
      {
        axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_finalizar/${id_guia_entrada}`,{withCredentials: true})
        .then(response =>
        {
            setDato({ ...dato, 'status': 'FINALIZADO' });
            //navigate(url);
            toast.success(response.data, {
              position: "top-right", autoClose: 2000, hideProgressBar: true,
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
        })
        .catch(error => {
          if (error.response.status === 401){
            delete axios.defaults.headers.common["Authorization"]; 
            localStorage.removeItem("token"); 
            localStorage.removeItem("usuario");
            navigate('/login');
          } else {
            toast.error(error.response.data, {
              position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        });
      }
    });
  }

  return (
    <div className="row justify-content-center w-100">
      <div className="col col-lg-10 col-xl-8">
      {isLoadingDatos &&  
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Cargando datos...</span>
          </div>
        </div>
      }
      {!isLoadingDatos &&  
        <form onSubmit={enviarFormulario}>
          <small>
          <div className="row pt-2 pb-2">
            <div className="col text-end">
              {dato.status === "PENDIENTE" &&  
              <button 
                onClick={() => handleClickFinalizar()}
                type="button" className="btn btn-primary btn-sm">
                <i className="fas fa-check-circle"></i> Finalizar
              </button>
              }
              &nbsp; 
              {dato.status !== "FINALIZADO" &&  
              <button type="submit" className="btn btn-success btn-sm">
                <i className="fas fa-check"></i> Actualizar
              </button>
              }
              &nbsp;
              <Link to={`/${modulo}`} className="btn btn-secondary btn-sm">
                <i className="fas fa-arrow-left" title="Volver"></i> Volver
              </Link>
            </div>
          </div>
          <fieldset className={`border p-2 mb-2 ${darkMode ? 'border-secondary' : ''}`}>
            <div className="row pt-2">
              <label className="col-sm-2">N°:</label>
              <div className="col-sm-5">
                <input type="text" name="id_guia_entrada" value={id_guia_entrada} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`}
                readOnly/>
              </div>
              <label className="col-sm-2">Estado:</label>
              <div className="col-sm-3">
                <input type="text" name="status" value={dato.status} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`}
                readOnly/>
              </div>
            </div>

            <div className="row pt-2">
              <label className="col-sm-2">Empresa:</label>
              <div className="col-sm-5">
                <input type="hidden" name="id_empresa" value={dato.id_empresa}/>
                <input type="text" name="n_empresa" value={dato.n_empresa} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`}
                readOnly required/>
              </div>
              <label className="col-sm-2">Rut:</label>
              <div className="col-sm-3">
                <input type="text" name="rut" value={dato.rut} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`}
                readOnly required/>
              </div>
            </div>
            
            {dato.status !== "FINALIZADO" &&  
            <div className="row pt-2">
              <label className="col-sm-2"></label>
              <div className="col-sm-10">
                <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#selectEmpresaModal">
                  <i className="fa fa-check-square"></i>&nbsp;Seleccionar empresa
                </button>
              </div>
            </div>
            }

            <div className="row pt-2">
              <label className="col-sm-2">Tipo Doc:</label>
              <div className="col-sm-5">
                <select 
                  className="form-select form-select-sm" name="doc_tipo" 
                  disabled={dato.status === 'FINALIZADO'}
                  value={dato.doc_tipo} 
                  onChange={handleInputChange} required>
                  <option key={0} value="F">Factura</option>
                  <option key={1} value="B">Boleta</option>
                </select>
              </div>
              <label className="col-sm-2">Folio:</label>
              <div className="col-sm-3">
                <input type="text" name="doc_folio" value={dato.doc_folio} 
                  className="form-control form-control-sm" maxLength="20" 
                  onChange={handleInputChange} 
                  disabled={dato.status === 'FINALIZADO'}
                  required/>
              </div>
            </div>

            <div className="row pt-2">
              <label className="col-sm-2">Fecha Doc:</label>
              <div className="col-sm-5">
                <DatePicker
                  disabled={dato.status === 'FINALIZADO'}
                  selected={parseISO(dato.doc_fecha, 'ddMMyyyy', new Date())}
                  onChange={handleDateChange}
                  dateFormat="dd-MM-yyyy"
                  className="form-control form-control-sm" 
                  required
                  locale={es} // Establece el idioma español
                />
              </div>
            </div>

            {isLoadingDetalle &&  
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Cargando datos...</span>
              </div>
            </div>
            }
          </fieldset>
          <fieldset className={`border p-2 mb-2 position-relative ${darkMode ? 'border-secondary' : ''}`}>
            <legend 
              className={`w-auto position-absolute ${darkMode ? 'bg-dark' : 'bg-light'}`}
              >Detalle guía</legend>
            {!isLoadingDetalle &&  
            <div className="row">
              <div className="col">
                <div className="row">  
                  <div className="col text-end">
                    {dato.status !== "FINALIZADO" &&  
                    <button 
                      type="button" className="btn btn-primary btn-sm" 
                      data-bs-toggle="modal" data-bs-target="#addProductoModal">
                      <i className="fa fa-plus"></i>&nbsp;Agregar producto
                    </button>
                    }
                  </div>
                </div>
                <div className="table-responsive" style={{ maxHeight: '250px)' }}>
                  <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                    <thead className="sticky-top">
                      <tr>
                        <th onClick={() => handleColumnClick(0)} className={sortingColumn === 0 ? `sorting ${sortDirection}` : ''}>Id Prod</th>
                        <th onClick={() => handleColumnClick(1)} className={sortingColumn === 1 ? `sorting ${sortDirection}` : ''}>Nombre</th>
                        <th>U. Med. Compra</th>
                        <th>U. Med. Uso</th>
                        {dato.status !== "FINALIZADO" &&  
                          <th>Cantidad</th>
                        }
                        {dato.status !== "FINALIZADO" &&  
                          <th>Precio</th>
                        }
                        {dato.status === "FINALIZADO" &&  
                          <th className="text-end">Cantidad</th>
                        }
                        {dato.status === "FINALIZADO" &&  
                          <th className="text-end">Precio</th>
                        }
                        {dato.status !== "FINALIZADO" &&  
                        <th className="text-center">Acciones</th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {datosdetalle.map(tupla => (
                        <tr key={tupla.id_guia_entrada_detalle}>
                          <td>{tupla.id_producto}</td>
                          <td>{tupla.n_producto}</td>
                          {dato.status !== "FINALIZADO" &&  
                          <td>
                            <select 
                              className="form-select form-select-sm" 
                              name={"id_unidadmedida_compra_" + tupla.id_guia_entrada_detalle} 
                              id={"id_unidadmedida_compra_" + tupla.id_guia_entrada_detalle} 
                              value={tupla.id_unidadmedida_compra} 
                              onChange={(event) => handleInputChangeUniMedCompra(event, tupla.id_guia_entrada_detalle)}
                              required>
                              {unidadesmedida.map((opcion) => (
                                <option key={opcion.id_unidadmedida} value={opcion.id_unidadmedida}>{opcion.n_unidadmedida}</option>
                              ))}
                            </select>
                          </td>
                          }
                          {dato.status === "FINALIZADO" &&  
                          <td className="text-end">{tupla.n_unidadmedida_compra}</td>
                          }
                          <td>{tupla.n_unidadmedida}</td>

                          {dato.status !== "FINALIZADO" &&  
                          <td className="text-end">
                            <input style={{'width':'100px'}} value={tupla.cantidad} 
                              type="text" className="form-control form-control-sm" 
                              id={"cantidad" + tupla.id_guia_entrada_detalle} 
                              maxLength="5" 
                              onChange={(event) => handleInputChangeCantidad(event, tupla.id_guia_entrada_detalle)}
                              onBlur={(event) => handleBlurCantidad(event, tupla.id_guia_entrada_detalle)}
                              onKeyDown={handleKeyPressCantidad}/>
                          </td>
                          }
                          {dato.status === "FINALIZADO" &&  
                          <td className="text-end">{tupla.cantidad}</td>
                          }

                          {dato.status !== "FINALIZADO" &&  
                          <td className="text-end">
                            <input style={{'width':'100px'}} value={tupla.precio} 
                              type="text" className="form-control form-control-sm" 
                              id={"precio" + tupla.id_guia_entrada_detalle} 
                              maxLength="10" 
                              onChange={(event) => handleInputChangePrecio(event, tupla.id_guia_entrada_detalle)}
                              onBlur={(event) => handleBlurPrecio(event, tupla.id_guia_entrada_detalle)}
                              onKeyDown={handleKeyPressPrecio}/>
                          </td>
                          }
                          {dato.status === "FINALIZADO" &&  
                          <td className="text-end">{tupla.cantidad}</td>
                          }
                          
                          {dato.status !== "FINALIZADO" &&  
                          <td className="text-center">
                            <button type="button" onClick={() => handleDelete(tupla.id_guia_entrada_detalle)} className="btn btn-sm btn-outline-secondary">
                              <i className="fa fa-trash-alt" title="Eliminar"></i>
                            </button>
                          </td>
                          }
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            }
          </fieldset>
          </small>
        </form>
      }
      </div>

      <div className="modal fade" id="selectEmpresaModal" ref={modalSeleccionarEmpresa} 
        tabIndex="-1" aria-labelledby="selectEmpresaModalLabel" aria-hidden="true">
        <div 
          className="modal-dialog modal-dialog-scrollable border border-secondary"
          style={{ maxHeight: '500px' }}>
          <div className={`modal-content ${darkMode ? 'text-light bg-dark' : ''}`}>
            <div className="modal-header border-bottom border-secondary">
              <h5 className="modal-title" id="selectEmpresaModalLabel">Seleccionar empresa</h5>
              <button 
                type="button" 
                className={`btn-close ${darkMode ? 'bg-btn-close-modal': ''}`}
                data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div className="modal-body">

              <div className="row">
                <div className="input-group mb-3">
                  <input 
                    type="text" className="form-control form-control-sm" 
                    placeholder="Buscar por razón social o rut" 
                    aria-label="Buscar por razón social o rut" 
                    aria-describedby="bot_buscar" 
                    onKeyDown={handleKeyPressBuscarEmpresa}
                    ref={inputBuscarEmpresa}/>
                  <button 
                    className="btn btn-outline-secondary" type="button" 
                    id="bot_buscar" 
                    onClick={() => getEmpresas()}>Buscar</button>
                </div>
              </div>
              
              <div className="table-responsive" style={{ height: '270px', maxHeight: '270px' }}>
                <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                  <thead className="sticky-top">
                    <tr>
                      <th onClick={() => handleColumnClickModalEmpresa(1)} className={sortingColumnModalEmpresa === 1 ? `sorting ${sortDirectionModalEmpresa}` : ''}>Empresa</th>
                      <th onClick={() => handleColumnClickModalEmpresa(2)} className={sortingColumnModalEmpresa === 2 ? `sorting ${sortDirectionModalEmpresa}` : ''}>Rut</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    {empresas.map(tupla => (
                      <tr key={tupla.id_empresa}>
                        <td>{tupla.n_empresa}</td>
                        <td>{tupla.rut}</td>
                        <td>
                          <button 
                            onClick={() => SeleccionarEmpresa(tupla.id_empresa, tupla.n_empresa, tupla.rut)}
                            className="btn btn-sm btn-outline-secondary">
                            <i className="fa fa-check-square" title="Seleccionar empresa"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer border-top border-secondary">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div> {/*Fin modal*/}

      <div className="modal fade" id="addProductoModal" ref={modalAddProducto} 
        tabIndex="-1" aria-labelledby="addProductoModalLabel" aria-hidden="true">
        <div 
          className="modal-dialog modal-dialog-scrollable border border-secondary"
          style={{ maxHeight: '500px' }}>
          <div className={`modal-content ${darkMode ? 'text-light bg-dark' : ''}`}>
            <div className="modal-header border-bottom border-secondary">
              <h5 className="modal-title" id="addProductoModalLabel">Agregar producto</h5>
              <button 
                type="button" 
                className={`btn-close ${darkMode ? 'bg-btn-close-modal': ''}`}
                data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div className="modal-body">

              <div className="row">
                <div className="input-group mb-3">
                  <input 
                    type="text" className="form-control form-control-sm" 
                    placeholder="Buscar por nombre" 
                    aria-label="Buscar por nombre" 
                    aria-describedby="bot_buscar" 
                    onKeyDown={handleKeyPressBuscarProducto}
                    ref={inputBuscarProducto}/>
                  <button 
                    className="btn btn-outline-secondary" type="button" 
                    id="bot_buscar" 
                    onClick={() => getProductos()}>Buscar</button>
                </div>
              </div>
              
              {isLoadingProductos &&  
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Cargando listado de productos...</span>
                  </div>
                </div>
              }
              {!isLoadingProductos &&  
              <div className="table-responsive" style={{ height: '270px', maxHeight: '270px' }}>
                <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                  <thead className="sticky-top">
                    <tr>
                      <th onClick={() => handleColumnClickModalProducto(1)} className={sortingColumnModalProducto === 1 ? `sorting ${sortDirectionModalProducto}` : ''}>Producto</th>
                      <th onClick={() => handleColumnClickModalProducto(2)} className={sortingColumnModalProducto === 2 ? `sorting ${sortDirectionModalProducto}` : ''}>Tipo</th>
                      <th onClick={() => handleColumnClickModalProducto(3)} className={sortingColumnModalProducto === 3 ? `sorting ${sortDirectionModalProducto}` : ''}>Unidad Med.</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productos.map(tupla => (
                      <tr key={tupla.id_producto}>
                        <td>{tupla.n_producto}</td>
                        <td>{tupla.n_tipoproducto}</td>
                        <td>{tupla.n_unidadmedida}</td>
                        <td>
                          <button 
                            onClick={() => AddProducto(tupla.id_producto)}
                            className="btn btn-sm btn-outline-secondary">
                            <i className="fa fa-plus" title="Agregar producto"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              }
            </div>
            <div className="modal-footer border-top border-secondary">
              <div className="row">
                <div className="col-2">
                <label htmlFor="cantidad" className="col-form-label">Cantidad:</label>
                </div>
                <div className="col-4">
                  <input maxLength="30" 
                  id="cantidad" type="text" className="form-control fomr-control-sm" 
                  onKeyDown={handleKeyPressCantidad} ref={inputCantidad}/>
                </div>
                <div className="col-6 text-end">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{/*fin modal*/}
    </div>
  );
}

export default GiuasentradaForm;
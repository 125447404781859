import React, {  useState } from 'react';
import MapaPredios from './MapaPredios';
import MapaPotreros from './MapaPotreros';
import MapaSubPotreros from './MapaSubPotreros';

import MapaPrediosLista from './MapaPrediosLista';

import MapaPotrerosListaOvi from './MapaPotrerosListaOvi';
import MapaPotrerosListaBov from './MapaPotrerosListaBov';
import MapaPotrerosListaAlp from './MapaPotrerosListaAlp';
import MapaPotrerosListaEqu from './MapaPotrerosListaEqu';
import MapaPotrerosListaCan from './MapaPotrerosListaCan';

import MapaSubPotrerosListaOvi from './MapaSubPotrerosListaOvi';
import MapaSubPotrerosListaBov from './MapaSubPotrerosListaBov';
import MapaSubPotrerosListaAlp from './MapaSubPotrerosListaAlp';
import MapaSubPotrerosListaEqu from './MapaSubPotrerosListaEqu';
import MapaSubPotrerosListaCan from './MapaSubPotrerosListaCan';

const Mapa = ()=>{
    const [selectedCentroLat, setSelectedCentroLat] = useState(null);
    const [selectedCentroLng, setSelectedCentroLng] = useState(null);
    const [selectedColor, setSelectedColor]         = useState(null);

    const [selectedIdPredio, setSelectedIdPredio]   = useState(null);
    const [selectedNPredio, setSelectedNPredio]     = useState(null);
    
    const [selectedIdPotrero, setSelectedIdPotrero] = useState(null);
    const [selectedNPotrero, setSelectedNPotrero]   = useState(null);

    const handlePredioClick = (id_predio, centro_lat, centro_lng, n_predio, color) => {
        setSelectedIdPredio(id_predio);
        setSelectedNPredio(n_predio);

        setSelectedCentroLat(centro_lat);
        setSelectedCentroLng(centro_lng);
        setSelectedColor(color);
    };

    const handlePotreroClick = (id_potrero, centro_lat, centro_lng, n_predio, n_potrero) => {
        setSelectedNPredio(n_predio);

        setSelectedIdPotrero(id_potrero);
        setSelectedNPotrero(n_potrero);

        setSelectedCentroLat(centro_lat);
        setSelectedCentroLng(centro_lng);
    };
    
    return(
        <div className="row justify-content-center w-100">
            <div className="col-12 col-lg-8 pl-2">
                {selectedIdPredio ? (
                    <React.Fragment>
                        <button 
                            onClick={() => {
                                setSelectedIdPredio(null);
                                setSelectedIdPotrero(null);
                            }}
                            type="button" 
                            className="btn btn-link m-0 pt-0 pb-0"
                        >Predios</button> 
                        {selectedIdPotrero ? (
                            <React.Fragment>
                                <span>/</span>

                                <button 
                                    onClick={() => setSelectedIdPotrero(null)}
                                    type="button" 
                                    className="btn btn-link m-0 pt-0 pb-0"
                                >{selectedNPredio}</button> 

                                <span>/ {selectedNPotrero}</span>

                                <MapaSubPotreros 
                                    onPotreroClick={handlePotreroClick}
                                    id_potrero={selectedIdPotrero} 
                                    centro_lat={selectedCentroLat} 
                                    centro_lng={selectedCentroLng}
                                    n_predio={selectedNPredio}
                                    n_potrero={selectedNPotrero}
                                    color={selectedColor}
                                />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <span>
                                    &nbsp;/&nbsp;{selectedNPredio} 
                                </span>

                                <MapaPotreros 
                                    onPotreroClick={handlePotreroClick}
                                    id_predio={selectedIdPredio} 
                                    centro_lat={selectedCentroLat} 
                                    centro_lng={selectedCentroLng}
                                    n_predio={selectedNPredio}
                                    color={selectedColor}
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ) : (
                    <MapaPredios onPredioClick={handlePredioClick} />
                )}
            </div>
            <div className="col-12 col-lg-4 pl-2">
            {selectedIdPredio ? (
                selectedIdPotrero ? (
                    <>
                        <MapaSubPotrerosListaOvi id_potrero={selectedIdPotrero} />
                        <MapaSubPotrerosListaBov id_potrero={selectedIdPotrero} />
                        <MapaSubPotrerosListaAlp id_potrero={selectedIdPotrero} />
                        <MapaSubPotrerosListaEqu id_potrero={selectedIdPotrero} />
                        <MapaSubPotrerosListaCan id_potrero={selectedIdPotrero} />
                    </>    
                ) : (
                    <>
                        <MapaPotrerosListaOvi id_predio={selectedIdPredio} />
                        <MapaPotrerosListaBov id_predio={selectedIdPredio} />
                        <MapaPotrerosListaAlp id_predio={selectedIdPredio} />
                        <MapaPotrerosListaEqu id_predio={selectedIdPredio} />
                        <MapaPotrerosListaCan id_predio={selectedIdPredio} />
                    </>
                )
            ) : (
                <MapaPrediosLista />
            )
            }
            </div>
        </div>
    )
}

export default Mapa;
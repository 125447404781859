import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import { ToastContainer } from 'react-toastify';
import axios from "axios";

import { titleContext } from "../context/titleContext";
import DarkModeContext from '../context/darkModeContext';

import Items from '../menu/menu.json';

import { getNModuloUrl, findLongTextByHref } from '../utils/utils';

function Template() {
  const [titulo, setTitulo] = useState("");
  const [usuario, setUsuario] = useState("");
  const [isTokenValid, setIsTokenValid] = useState(false);

  const [darkMode, setDarkMode] = useState('');

  const getDarkModeUsuario = async () => {
    const usuario = localStorage.getItem('usuario');
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/usuarios_darkmode/${usuario}`,{withCredentials: true})
    .then(response => {
      setDarkMode(response.data.darkmode);
    })
    .catch(error => {
        console.log(error);
        if (error.response.status === 401){
            delete axios.defaults.headers.common["Authorization"];
            localStorage.removeItem("token"); 
            localStorage.removeItem("usuario"); 
            navigate('/login');
        }
    });
  }

  useEffect( ()=>{
    getDarkModeUsuario();
    // eslint-disable-next-line
  },[])

  const changeTitulo = (newTitulo) => {
    setTitulo(newTitulo);
  };

  const navigate = useNavigate();
  
  const Logout = async () => {
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/logout`,{withCredentials: true})
      .then(response => {
        delete axios.defaults.headers.common["Authorization"];
        localStorage.removeItem("token");
        localStorage.removeItem("usuario");
        navigate('/login');
      })
      .catch(error => {
        console.log(error);
      });
  }

  useEffect(() => {
    const fullUrl = window.location.href;
    const modulo = getNModuloUrl(fullUrl);
    setTitulo(findLongTextByHref(Items, modulo))

    const validateToken = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/validatetoken`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.status === 200) {
          //console.log("ok")
          setUsuario(localStorage.getItem('usuario'))
          setIsTokenValid(true) // Establecer que el token es válido
        } 
      } catch (error) {
        setIsTokenValid(false) // Establecer que el token es inválido
        navigate('/login');
      }
    };

    validateToken();

    const user = localStorage.getItem("usuario")
    if(!user){
      localStorage.removeItem("token");
      localStorage.removeItem("usuario");
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    if(darkMode){
      document.body.style.backgroundColor = '#212529';
    } else {
      document.body.style.backgroundColor = '#fff';
    }
    
  }, [darkMode]);

  if (!isTokenValid) {
    // return <div>Su sesión ha expirado. Por favor, inicie sesión nuevamente.</div>;
    return <div></div>;
  }

  return (
    <DarkModeContext.Provider value={{darkMode, setDarkMode}}>
      <titleContext.Provider value={{ titulo, changeTitulo }}>
        <div className={`main ${darkMode ? 'dark-mode' : 'light-mode'}`}>
            <Sidebar />
            
            <div className="container-fluid">
              <div className={`row ${darkMode ? 'navbar-dark-mode':'navbar-light-mode'}`}>
                <div className="col justify-content-start pt-3" >
                  <span className="navbar-text">
                    <h6>{titulo}</h6>
                  </span>
                </div>
                <div className="col d-flex justify-content-end p-0">
                  <div className="btn-group">
                    <button 
                      type="button" 
                      className={`btn btn-link dropdown-toggle shadow-none text-decoration-none ${darkMode ? 'text-light':'text-dark'}`} 
                      data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                      {usuario}
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end bg-secondary">
                      <li>
                        <button 
                          onClick={Logout} 
                          type="button" 
                          className="dropdown-item btn btn-secondary text-light bg-secondary">
                          Cerrar sesión
                        </button>
                      </li>
                      {/* <li>
                        <button 
                          onClick={toggleDarkMode} 
                          type="button" 
                          className="dropdown-item btn btn-secondary text-light bg-secondary">
                          Modo Obscuro
                        </button>
                      </li> */}
                      {/* <li><a className="dropdown-item" href="/"></a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
              
              {/* Contenido del módulo (<Outlet />) 
              Aquí se cargan los componentes de cada módulo seleccionado en el menú sidebar
              */}
              <div className="row">
                <div className={`col p-0 ${darkMode ? 'outlet-dark-mode': 'outlet-light-mode'}`}>
                  <Outlet />
                </div>
              </div>
            </div>
        </div>
        <ToastContainer />
      </titleContext.Provider>
    </DarkModeContext.Provider>
  );
}

export default Template;
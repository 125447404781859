import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { isValidDate } from '../../utils/utils';
import { useDarkMode } from '../../context/darkModeContext'; 

const DashActividades = () => {
    // eslint-disable-next-line
    const {darkMode, setDarkMode} = useDarkMode();
    const navigate = useNavigate();
    //--------------------------------------------------
    //--------------------- ACTIVIDADES ----------------
    //--------------------------------------------------
    const [proxAct, setProxAct] = useState(null);
    const [proxActOri, setProxActOri] = useState([]);

    const [ultAct, setUltAct] = useState(null);
    const [ultActOri, setUltActOri] = useState([]);

    const [sortingColumnProxAct, setSortingColumnProxAct] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
    const [sortDirectionProxAct, setSortDirectionProxAct] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

    const [sortingColumnUltAct, setSortingColumnUltAct] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
    const [sortDirectionUltAct, setSortDirectionUltAct] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

    const getActividadesProx = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/dashboard_datos_actividades_prox`,{withCredentials: true});
            setProxActOri(response.data);
            setProxAct(response.data);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    const getActividadesUlt = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/dashboard_datos_actividades_ult`,{withCredentials: true});
            setUltActOri(response.data);
            setUltAct(response.data);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    /************ INICIO funciones orden array (PROXIMAS ACTIVIDADES) *************************************** */
    const handleColumnClickProxAct = (columnIndex) => {
        if (sortingColumnProxAct === columnIndex) {
            setSortDirectionProxAct(sortDirectionProxAct === 'asc' ? 'desc' : 'asc');
        } else {
            setSortingColumnProxAct(columnIndex); setSortDirectionProxAct('asc');
        }
    };

    useEffect(() => {    
        const rows = [...proxActOri]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
        if (sortingColumnProxAct !== -1) {  
            rows.sort((a, b) => {
                const valA = a[Object.keys(a)[sortingColumnProxAct]]; const valB = b[Object.keys(b)[sortingColumnProxAct]];

                if (typeof valA === 'number' && typeof valB === 'number') {
                    return valA - valB;
                } else if (isValidDate(valA) && isValidDate(valB)) {
                    const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
                } else { return valA.localeCompare(valB);}
            });

            if (sortDirectionProxAct === 'desc') { rows.reverse();}
            setProxAct(rows); // Actualizamos el estado de sortedRows con los datos ordenados
        }
    }, [sortingColumnProxAct, sortDirectionProxAct, proxActOri]); // Dependencias: sortingColumn, sortDirection, datosOriginales
    /************ FIN funciones orden array (PROXIMAS ACTIVIDADES) *************************************** */

    /************ INICIO funciones orden array (ULTIMAS ACTIVIDADES) *************************************** */
    const handleColumnClickUltAct = (columnIndex) => {
        if (sortingColumnUltAct === columnIndex) {
            setSortDirectionUltAct(sortDirectionUltAct === 'asc' ? 'desc' : 'asc');
        } else {
            setSortingColumnUltAct(columnIndex); setSortDirectionUltAct('asc');
        }
    };

    useEffect(() => {    
        const rows = [...ultActOri]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
        if (sortingColumnUltAct !== -1) {  
            rows.sort((a, b) => {
                const valA = a[Object.keys(a)[sortingColumnUltAct]]; const valB = b[Object.keys(b)[sortingColumnUltAct]];

                if (typeof valA === 'number' && typeof valB === 'number') {
                    return valA - valB;
                } else if (isValidDate(valA) && isValidDate(valB)) {
                    const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
                } else { return valA.localeCompare(valB);}
            });

            if (sortDirectionUltAct === 'desc') { rows.reverse();}
            setUltAct(rows); // Actualizamos el estado de sortedRows con los datos ordenados
        }
    }, [sortingColumnUltAct, sortDirectionUltAct, ultActOri]); // Dependencias: sortingColumn, sortDirection, datosOriginales
    /************ FIN funciones orden array (PROXIMAS ACTIVIDADES) *************************************** */

    //______________________________________________
    //_____________ useEffect PRINCIPAL ____________
    //______________________________________________
    useEffect(() => {
        getActividadesProx();
        getActividadesUlt();
        // eslint-disable-next-line
    }, []);

    const handleRowClick = (v_id_actividad) => {
        window.open('actividades/' + v_id_actividad, '_blank');
    };

    return (
        <div className="tab-pane fade" id="nav-actividades" role="tabpanel" aria-labelledby="nav-actividades-tab">
            <div className="row pt-2">
                <div className="col-12 pl-2">
                    <fieldset className="border p-2 mb-2 border-secondary">
                    {proxAct ? 
                        (
                        <small>
                        <h6>Próximas actividades</h6>
                        <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
                        <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                            <thead>
                            <tr>
                                <th onClick={() => handleColumnClickProxAct(0)} className={sortingColumnProxAct === 0 ? `sorting ${sortDirectionProxAct}` : 'text-center'}>Fecha</th>
                                <th onClick={() => handleColumnClickProxAct(1)} className={sortingColumnProxAct === 1 ? `sorting ${sortDirectionProxAct}` : ''}>Tipo</th>
                                <th>Modalidad</th>
                                <th>Especie</th>
                                <th>Ubicación</th>                                
                            </tr>
                            </thead>
                            <tbody>
                            {proxAct.map(dato => {
                                let strModalidad = dato.modalidad;
                                let strEspecie = dato.n_especie;
                                if(dato.id_tipoactividad === 1 || dato.id_tipoactividad === 2 || dato.id_tipoactividad === 3)
                                {
                                    strModalidad = "";
                                    strEspecie = "";
                                }
                                
                                let strUbicacion   = dato.n_predio + "/" + dato.n_potrero;
                                if(dato.n_subpotrero !== ''){strUbicacion+="/" + dato.n_subpotrero}

                                return (
                                <tr 
                                    style={{ cursor: 'pointer' }}
                                    title="Haga clic para ver el detalle de la actividad"
                                    key={dato.id_actividad}
                                    onClick={() => handleRowClick(dato.id_actividad)}
                                    >
                                    <td className='text-center'>{dato.fecha_DD_MM_YYYY}</td>
                                    <td>{dato.n_tipoactividad}</td>
                                    <td>{strModalidad}</td>
                                    <td>{strEspecie}</td>
                                    <td>{strUbicacion}</td>
                                </tr>
                                );
                            })}
                            </tbody>
                        </table>
                        </div>
                        </small>
                        ) : (
                        <div className="text-center">
                            <br/><br/>
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Cargando datos...</span>
                            </div>
                            <p>Cargando próximas actividades...</p>
                        </div>
                        )
                    }
                    </fieldset>

                    <fieldset className="border p-2 mb-2 border-secondary">
                    {ultAct ? 
                        (
                        <small>
                        <h6>Últimas actividades</h6>
                        <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
                        <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                            <thead>
                            <tr>
                                <th onClick={() => handleColumnClickUltAct(0)} className={sortingColumnUltAct === 0 ? `sorting ${sortDirectionUltAct}` : 'text-center'}>Fecha</th>
                                <th onClick={() => handleColumnClickUltAct(1)} className={sortingColumnUltAct === 1 ? `sorting ${sortDirectionUltAct}` : ''}>Tipo</th>
                                <th>Modalidad</th>
                                <th>Especie</th>
                                <th>Ubicación</th>                                
                            </tr>
                            </thead>
                            <tbody>
                            {ultAct.map(dato => {
                                let strModalidad = dato.modalidad;
                                let strEspecie = dato.n_especie;
                                if(dato.id_tipoactividad === 1 || dato.id_tipoactividad === 2 || dato.id_tipoactividad === 3)
                                {
                                    strModalidad = "";
                                    strEspecie = "";
                                }
                                
                                let strUbicacion;
                                if(dato.n_predio !== '')
                                {
                                    strUbicacion = dato.n_predio + "/" + dato.n_potrero;
                                    if(dato.n_subpotrero !== ''){strUbicacion+="/" + dato.n_subpotrero}
                                }

                                return (
                                <tr 
                                    style={{ cursor: 'pointer' }}
                                    title="Haga clic para ver el detalle de la actividad"
                                    key={dato.id_actividad} 
                                    onClick={() => handleRowClick(dato.id_actividad)}
                                    >
                                    <td className='text-center'>{dato.fecha_DD_MM_YYYY}</td>
                                    <td>{dato.n_tipoactividad}</td>
                                    <td>{strModalidad}</td>
                                    <td>{strEspecie}</td>
                                    <td>{strUbicacion}</td>
                                </tr>
                                );
                            })}
                            </tbody>
                        </table>
                        </div>
                        </small>
                        ) : (
                        <div className="text-center">
                            <br/><br/>
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Cargando datos...</span>
                            </div>
                            <p>Cargando últimas actividades...</p>
                        </div>
                        )
                    }
                    </fieldset>
                </div>
            </div>
        </div>
    );
};

export default DashActividades;
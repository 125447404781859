import React, {useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { titleContext } from "../context/titleContext";
import { useDarkMode } from '../context/darkModeContext'; 


function SidebarItems({item, isOpen,  modulo}) {
    const { changeTitulo } = useContext(titleContext);
    // eslint-disable-next-line
    const {darkMode, setDarkMode} = useDarkMode();

    const handleLink = (title) => {
        changeTitulo(title);
    };
    const [active, setActive] = useState(false);
    
    if(item.children){
        return (
            <div 
                className={`sidebar-item ${active ? (darkMode ? 'sidebar-item-dark-mode active' : 'sidebar-item-light-mode active') : (darkMode ? 'sidebar-item-dark-mode': 'sidebar-item-light-mode')}`}
                >
                <div className="sidebar-title">
                    <div className="sidebar_menu_icon" title={item.text}><i className={item.icon}></i> </div>
                    <div style={{display: isOpen ? "block" : "none"}} className="sidebar_menu_text">{item.text}</div>
                    <div style={{display: isOpen ? "block" : "none"}}><i className="fas fa-chevron-down" onClick={() => setActive(!active)}></i></div>
                </div>
                <div className="sidebar-content">
                    {item.children.map((child, index) => <SidebarItems isOpen={isOpen} key={index} item={child} modulo={modulo}/>)}
                </div>
            </div>
        )
    } else {
        const isItemActive = modulo === item.href; // Compara el modulo con el href del item

        return (
            <NavLink 
                to={item.href} 
                key={item.index} 
                className={`sidebar-item ${isItemActive ? (darkMode ? 'sidebar-item-active-dark-mode' : 'sidebar-item-active-light-mode') : (darkMode ? 'sidebar-item-dark-mode' : 'sidebar-item-light-mode')}`}
                >
                <div 
                    className="sidebar-title"
                    onClick={() => {handleLink(item.text) }}
                    >
                    <div className="sidebar_menu_icon" title={item.text}><i className={item.icon}></i> </div>
                    <div style={{display: isOpen ? "block" : "none"}} className="sidebar_menu_text">{item.text}</div>
                </div>
            </NavLink>
            )
    }
}

export default SidebarItems
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SidebarItems from './SidebarItems';
import Items from '../menu/menu.json';
import axios from 'axios';
import { useDarkMode } from '../context/darkModeContext'; 

import { getNModuloUrl } from '../utils/utils';

function Sidebar(){
    const[isOpen ,setIsOpen] = useState(true); //Sidebar abierto o cerrado
    const toggle = () => setIsOpen (!isOpen); //Cambio de sidebar
    const {darkMode, setDarkMode} = useDarkMode();
    const navigate = useNavigate();

    const[modulo ,setModulo] = useState('');

    const initialPermisosState = {
        acceso_dashboard    : false,
        acceso_mapa         : false,
        acceso_actividades  : false,
        acceso_fichas       : false,
        acceso_lotes        : false,
        acceso_movimientos  : false,
        acceso_productos    : false, 
        acceso_reportes     : false, 
        acceso_tablas       : false, 
        acceso_config       : false
    };
    const [permisos, setPermisos] = useState(initialPermisosState);

    useEffect(() => {
        const fullUrl = window.location.href;
        const modulo = getNModuloUrl(fullUrl);
        setModulo(modulo);

        const id = localStorage.getItem('usuario');
        axios.get(`${process.env.REACT_APP_URL_API_PORT}/permisosrol/${id}`,{withCredentials: true})
        .then(response => {
            setPermisos(response.data);
        })
        .catch(error => {
            console.log(error);
            if (error.response.status === 401){
                delete axios.defaults.headers.common["Authorization"];
                localStorage.removeItem("token"); 
                localStorage.removeItem("usuario"); 
                navigate('/login');
            }
        });
    }, [navigate]);

    const toggleDarkMode = (event) => {
        updateDarkMode(event.target.checked);
    };

    const updateDarkMode = async (v_darkmode) => {
        var data = {
            darkmode : v_darkmode
        };

        const usuario = localStorage.getItem('usuario');
        axios.put(`${process.env.REACT_APP_URL_API_PORT}/usuarios_darkmode/${usuario}`, data, {withCredentials: true})
        .then(response => {
            setDarkMode(v_darkmode);
        })
        .catch(error => {
            console.log(error);
            if (error.response.status === 401){
                delete axios.defaults.headers.common["Authorization"]; 
                localStorage.removeItem("token"); 
                localStorage.removeItem("usuario");
                navigate('/login');
            }
        });
    }

    return(
        <div style={{ position: 'relative', minHeight: '100vh' }}>
            <div style={{width: isOpen ? "160px" : "50px"}} 
                className={`sidebar ${darkMode ? 'sidebar-dark-mode':'sidebar-light-mode'}`}>
                <div className="top_section">
                    <div>
                        <h1 style={{display: isOpen ? "block" : "none"}} className="logo">GesGan {isOpen}</h1>
                    </div>

                    <div 
                        style={{marginLeft: isOpen ? "62px" : "5px"}} 
                        title="Abrir/Cerrar menú" className="bars">
                        <i className="fas fa-bars" onClick={toggle}></i>
                    </div>
                </div>
                {Items.map((item, index) => {
                    
                    if(item.href === "/dashboard" && permisos.acceso_dashboard){
                        return <SidebarItems isOpen={isOpen} key={index} item={item} modulo={modulo}/>
                    } else if(item.href === "/mapa" && permisos.acceso_mapa){
                        return <SidebarItems isOpen={isOpen} key={index} item={item} modulo={modulo}/>
                    } else if(item.href === "/actividades" && permisos.acceso_actividades){
                        return <SidebarItems isOpen={isOpen} key={index} item={item} modulo={modulo}/>
                    } else if(item.href === "/fichas" && permisos.acceso_fichas){
                        return <SidebarItems isOpen={isOpen} key={index} item={item} modulo={modulo}/>
                    } else if(item.href === "/lotes" && permisos.acceso_lotes){
                        return <SidebarItems isOpen={isOpen} key={index} item={item} modulo={modulo}/>
                    } else if(item.href === "/movimientos" && permisos.acceso_movimientos){
                        return <SidebarItems isOpen={isOpen} key={index} item={item} modulo={modulo}/>
                    } else if(item.href === "/productos" && permisos.acceso_productos){
                        return <SidebarItems isOpen={isOpen} key={index} item={item} modulo={modulo}/>
                    } else if(item.href === "/reportes" && permisos.acceso_reportes){
                        return <SidebarItems isOpen={isOpen} key={index} item={item} modulo={modulo}/>
                    } else if(item.href === "/tablas" && permisos.acceso_tablas){
                        return <SidebarItems isOpen={isOpen} key={index} item={item} modulo={modulo}/>
                    } else if(item.href === "/config" && permisos.acceso_config){
                        return <SidebarItems isOpen={isOpen} key={index} item={item} modulo={modulo}/>
                    } else {
                        return null;
                    }
                })}
                
                {/* <div className="form-check form-switch" style={{ position: 'absolute', bottom: 10, left: 45, width: '100%', padding: '0px' }}></div> */}
                <div 
                    className="form-switch" 
                    style={{ paddingLeft: '45px', paddingTop: '20px', width: '100%' }}>
                    <input 
                        onChange={toggleDarkMode}
                        checked={darkMode}
                        className="form-check-input" 
                        type="checkbox" 
                        id="flexSwitchCheckModoDark" 
                    />
                </div>
                <div>
                    <label 
                        className="form-check-label" 
                        htmlFor="flexSwitchCheckModoDark" 
                        style={{ paddingLeft: '5px' }}
                    ><small><small>Obscuro</small></small></label>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
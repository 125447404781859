import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

function PadresForm() {
  const { id_padre } = useParams();
  const [icon, setIcon] = useState('');
  const [textButton, setTextButton] = useState('');
  const navigate = useNavigate();
  const [isLoadingDatos, setIsLoadingDatos] = useState(true);
  const modulo = 'padres'

  const initialDatoState = {
    id_padre: null,
    id_empresa: '',
    n_padre: ''
  };
  const [dato, setDato] = useState(initialDatoState);
  const [empresas, setEmpresas] = useState([]);

  useEffect(() => {
    if (id_padre) {
      getDato(id_padre);
      setIcon('fas fa-arrow-left');
      setTextButton('Volver');
    }
    else {
      setIcon('fas fa-ban');
      setTextButton('Cancelar');
      setIsLoadingDatos(false);
    }
    getEmpresas();
    // eslint-disable-next-line
  }, [id_padre]);
  
  const getDato = (id_padre) => {
    setIsLoadingDatos(true);
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id_padre}`,{withCredentials: true})
      .then(response => {
        setDato(response.data);
        setIsLoadingDatos(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  const getEmpresas = () => {
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/empresas`,{withCredentials: true})
      .then(response => {
        setEmpresas(response.data);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDato({ ...dato, [name]: value });
  };

  const enviarFormulario = (e) => {
    e.preventDefault();

    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }

    var data = {
      id_empresa: dato.id_empresa,
      n_padre: dato.n_padre, 
      usuario : localStorage.getItem("usuario")
    };

    if (dato.id_padre) {
      axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${dato.id_padre}`, data,{withCredentials: true})
      .then(response => {
        toast.success('¡Registro actualizado correctamente!', {
          position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      })
      .catch(error => {
        if (error.response.status === 401){
          delete axios.defaults.headers.common["Authorization"]; 
          localStorage.removeItem("token"); 
          localStorage.removeItem("usuario");
          navigate('/login');
        } else {
          toast.error(error.response.data, {
            position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
        }
      });
    } else {
      axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`, data,{withCredentials: true})
      .then(response => {
        toast.success("Registro creado correctamente", {
          position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });

        navigate(`/${modulo}/${response.data.id_padre}`);
      })
      .catch(error => {
        if (error.response.status === 401){
          delete axios.defaults.headers.common["Authorization"]; 
          localStorage.removeItem("token"); 
          localStorage.removeItem("usuario");
          navigate('/login');
        } else {
          toast.error(error.response.data, {
            position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
        }
      });
    }
  };

  return (
    <div className="row justify-content-center w-100">
      <div className="col-10 col-xs-8 col-md-8 col-lg-6  col-xl-4">
      {isLoadingDatos &&  
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Cargando datos...</span>
          </div>
        </div>
      }
      {!isLoadingDatos &&  
        <form onSubmit={enviarFormulario}>
          <div className="form-group">
            <label>Empresa:</label>
            <select className="form-select form-select-sm" name="id_empresa" 
            value={dato.id_empresa} onChange={handleInputChange} required>
              <option value="">Seleccione una empresa</option>
              {empresas.map((opcion) => (
                <option key={opcion.id_empresa} value={opcion.id_empresa}>{opcion.n_empresa}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
              <label>Padre Insem.:</label>
              <input type="text" name="n_padre" autoFocus  
              value={dato.n_padre} onChange={handleInputChange} 
              className="form-control form-control-sm" required/>
          </div>

          <hr/>
          <div className="pt-1">
            <button type="submit" className="btn btn-success btn-sm">
              <i className="fas fa-check"></i> Guardar
            </button>
            &nbsp;
            <Link to={`/${modulo}`} className="btn btn-secondary btn-sm">
              <i className={icon} title="Cancelar"></i> {textButton}
            </Link>
          </div>
        </form>
      }
      </div>
    </div>
  );
}

export default PadresForm;
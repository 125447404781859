import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useDarkMode } from '../../../context/darkModeContext'; 

function Potreros() {
  // eslint-disable-next-line
  const {darkMode, setDarkMode} = useDarkMode();
  const [datos, setDatos] = useState([]);
  const [isLoadingDatos, setIsLoadingDatos] = useState(false);

  const [predios, setPredios] = useState([]);
  const inputPredio = useRef(null);
  const [selectedPredio, setSelectedPredio] = useState('');
  const navigate = useNavigate();
  const modulo = 'potreros'

  const getPredios = () => {
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/predios`,{withCredentials: true})
      .then(response => {
        setPredios(response.data);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };
  
  const handleDelete = (id_potrero) => {
    Swal.fire({
      title: 'Eliminar registro', text: '¿Estás seguro que deseas eliminar este registro?',
      icon: 'warning', showCancelButton: true, confirmButtonColor: '#d33', cancelButtonColor: '#666',
      confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar',
    }).then((result) => {
      // Si se confirma la eliminación, hacer la petición a la API para eliminar el elemento
      if (result.isConfirmed) 
      {
        axios.delete(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id_potrero}`,{withCredentials: true}).then(response =>
        {
          if (response.data) {
            toast.success('¡Registro eliminado correctamente!', {
              position: "top-right", autoClose: 2000, hid_potreroeProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });

            setDatos(datos.filter(datos => datos.id_potrero !== id_potrero)); //Quitar del arreglo la fila del id_potrero eliminado
          } else {
            toast.error('¡No se pudo eliminar el registro!', {
              position: "top-right", autoClose: 2000, hid_potreroeProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        });
      }
    });
  };

  const getDatos = () => {
    setIsLoadingDatos(true);
    const v_id_predio = inputPredio.current.value;
    sessionStorage.setItem('PotrerosSelectedPredio', v_id_predio);

    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`, 
      {
        withCredentials: true,
        params: {
          id_predio : v_id_predio
        }
      })
      .then(response => {
        //setDatosOriginales(response.data); // Guardar los datos originales
        setDatos(response.data); // Hacer una copia para mantener los datos originales en 'datos' al inicio
        setIsLoadingDatos(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  useEffect( ()=> {
    getPredios();
    const storedPredio = sessionStorage.getItem('PotrerosSelectedPredio');
    setSelectedPredio(storedPredio || '');
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (predios.length > 0 && inputPredio.current) {
      getDatos();
    }
    // eslint-disable-next-line
  }, [predios]); //Este se ejecuta cuando se carga completo el arreglo  

  return (
    <div className="row justify-content-center w-100">
      <div className="col">
        <small>
        <Link to={`/${modulo}/new`} className="btn btn-primary btn-sm">
          <i className="fa fa-plus"></i>&nbsp;Nuevo
        </Link>
        <div className="row pt-2 pb-1">
          <label className="col-sm-1">Predio:</label>
          <div className="col-sm-5">
            <select 
              value={selectedPredio} 
              onChange={(event) => { 
                setSelectedPredio(event.target.value);
                getDatos();
              }}
              className="form-select form-select-sm" 
              name="id_predio" 
              ref={inputPredio}>
              {predios.map((opcion) => (
                <option key={opcion.id_predio} value={opcion.id_predio}>{opcion.n_predio}</option>
              ))}
            </select>
          </div>
        </div>
        </small>
        {isLoadingDatos &&  
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Cargando datos...</span>
            </div>
          </div>
        }
        {!isLoadingDatos &&  
        <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
          <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
            <thead className="sticky-top">
              <tr>
                <th>Potrero</th>
                <th>Nombre corto</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {datos.map(dato => (
                <tr key={dato.id_potrero}>
                  <td>{dato.n_potrero}</td>
                  <td>{dato.n_corto}</td>
                  <td>
                    <Link to={`/${modulo}/${dato.id_potrero}`} className="btn btn-sm btn-outline-secondary">
                      <i className="fa fa-edit" title="Editar"></i>
                    </Link>
                    &nbsp;
                    <button onClick={() => handleDelete(dato.id_potrero)} className="btn btn-sm btn-outline-secondary">
                      <i className="fa fa-trash-alt" title="Eliminar"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        }
      </div>
    </div>
  );
}

export default Potreros;
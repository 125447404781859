import React, {  useRef, useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useDarkMode } from '../../../context/darkModeContext'; 

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns';
import { format } from 'date-fns';
import { addDays, subDays } from 'date-fns';
import es from 'date-fns/locale/es'; // Importa el idioma español

import { isValidDate } from '../../../utils/utils';

function MovindividualForm() {
  // eslint-disable-next-line
  const {darkMode, setDarkMode} = useDarkMode();
  const [sortingColumnOrigen, setSortingColumnOrigen] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
  const [sortDirectionOrigen, setSortDirectionOrigen] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

  const [sortingColumnDestino, setSortingColumnDestino] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
  const [sortDirectionDestino, setSortDirectionDestino] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'
  
  const inputDestinoPredio      = useRef(null);
  const inputDestinoPotrero     = useRef(null);
  const inputDestinoSubpotrero  = useRef(null);
  const inputResponsable        = useRef(null);
  const inputBuscarAnimal       = useRef(null);
  const modalBuscarAnimal       = useRef(null);
  
  const bootstrap = window.bootstrap; // se agregó para poder llamar al método hide() de un modal
  
  const { id_mov } = useParams();
  const [icon, setIcon] = useState('');
  const [textButton, setTextButton] = useState('');

  const navigate = useNavigate();
  const modulo = 'movindividual'

  // Obtener la fecha actual
  const fechaActual = new Date();

  // Obtener el día, el mes y el año de la fecha actual
  const dia = fechaActual.getDate().toString().padStart(2, '0');
  const mes = (fechaActual.getMonth() + 1).toString().padStart(2, '0');
  const anio = fechaActual.getFullYear();

  // Crear la cadena con el formato "dd-mm-yyyy"
  const fechaFormateada = `${anio}-${mes}-${dia}`;

  const minSelectableDate = subDays(new Date(), 7); // Fecha actual - 7 día
  const maxSelectableDate = addDays(new Date(), 0); // Fecha actual + 1 día

  const initialDatoState = {
    id_mov                : null,
    created_date          : '',
    fecha_mov             : fechaFormateada,
    id_responsable        : '',
    estado                : '',
    id_especie            : '',
    n_especie             : '',
    origen_id_predio      : '',
    origen_id_potrero     : '',
    origen_id_subpotrero  : '',
    origen_n_predio       : '',
    origen_n_potrero      : '',
    origen_n_subpotrero   : '',
    destino_id_predio     : '',
    destino_id_potrero    : '',
    destino_id_subpotrero : '',
  };
  
  const [dato, setDato] = useState(initialDatoState);
  const [datosdetalleorigenOriginales, setDatosdetalleorigenOriginales] = useState([]);
  const [datosdetalleorigen, setDatosdetalleorigen] = useState([]);

  const [datosdetalledestinoOriginales, setDatosdetalledestinoOriginales] = useState([]);
  const [datosdetalledestino, setDatosdetalledestino] = useState([]);

  const [isLoadingDatosForm, setIsLoadingDatosForm]               = useState(true);
  const [isLoadingDetalleOrigen, setIsLoadingDetalleOrigen]       = useState(false);
  const [isLoadingDetalleDestino, setIsLoadingDetalleDestino]     = useState(false);
  const [isLoadingBusquedaAnimales, setIsLoadingBusquedaAnimales] = useState(false);
  
  const [responsables, setResponsables]   = useState([]);
  const [predios, setPredios]             = useState([]);
  const [potreros, setPotreros]           = useState([]);
  const [subpotreros, setSubpotreros]     = useState([]);
  const [listaAnimales, setListaAnimales] = useState([]);

  const getDetalleOrigen = (v_id_predio, v_id_potrero, v_id_subpotrero) => {
    setIsLoadingDetalleOrigen(true);
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalleorigen/${id_mov}/${v_id_predio}/${v_id_potrero}/${v_id_subpotrero}`,{withCredentials: true})
      .then(response => {
        setDatosdetalleorigenOriginales(response.data)
        setDatosdetalleorigen(response.data);
        setIsLoadingDetalleOrigen(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  const getDetalleDestino = () => {
    setIsLoadingDetalleDestino(true);
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalledestino/${id_mov}`,{withCredentials: true})
      .then(response => {
        setDatosdetalledestinoOriginales(response.data)
        setDatosdetalledestino(response.data);
        setIsLoadingDetalleDestino(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  const getDato = async (id_mov) => {
    setIsLoadingDatosForm(true);
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id_mov}`,{withCredentials: true});
      setDato(response.data);
      setIsLoadingDatosForm(false);

      getPrediosExceptoIdPredio(response.data.origen_id_predio);
      getDetalleOrigen(response.data.origen_id_predio, response.data.origen_id_potrero, response.data.origen_id_subpotrero);

      getDetalleDestino();
    } catch(error) {
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  const getResponsables = () => {
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/responsables`,{withCredentials: true})
      .then(response => {
        setResponsables(response.data);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  const getPrediosExceptoIdPredio = (v_origen_id_predio) => {
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/predios_exceptoidpredio/${v_origen_id_predio}`,{withCredentials: true})
      .then(response => {
        setPredios(response.data);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  useEffect(() => {
    if (id_mov) {
      getDato(id_mov);
      setIcon('fas fa-arrow-left');
      setTextButton('Volver');
      getResponsables();
    }
    //eslint-disable-next-line
  }, [id_mov]);

  //--------------------------------------------------------------------------------
  useEffect(() => {
    if (dato.destino_id_predio !== '') {
      axios.get(`${process.env.REACT_APP_URL_API_PORT}/potrerosxpredio/${dato.destino_id_predio}`,{withCredentials: true})
        .then(response => {
          setPotreros(response.data);
          if (response.data.length > 0 && (dato.destino_id_potrero === '' || dato.destino_id_potrero === 0)) {
            setDato(prevDato => ({
              ...prevDato,
              'destino_id_potrero': response.data[0].id_potrero
            }));
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        });
    }  
    // eslint-disable-next-line
  }, [dato.destino_id_predio, dato.destino_id_potrero]); 

  useEffect(() => {
    if (predios.length > 0 && inputDestinoPredio.current) {
      // la llamada tiene que ser con 'prevDato =>', si no queda en un loop infinito
      setDato(prevDato => ({
        ...prevDato,
        'destino_id_predio': inputDestinoPredio.current.selectedOptions[0].value
      }));
    }
  }, [predios]); //Este se ejecuta cuando se carga completo el arreglo  

  //--------------------------------------------------------------------------------

  useEffect(() => {
    if (dato.destino_id_potrero !== '') {
      axios.get(`${process.env.REACT_APP_URL_API_PORT}/subpotrerosxpotrero/${dato.destino_id_potrero}`,{withCredentials: true})
        .then(response => {
          setSubpotreros(response.data);
          //No se seleccione el pimer subpotrero de la lista--> subpotrero puede no ser seleccionado
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        });
    }  
    // eslint-disable-next-line
  }, [dato.destino_id_potrero]); 
  //--------------------------------------------------------------------------------

  useEffect(() => {
    if (responsables.length > 0 && inputResponsable.current) {
      // la llamada tiene que ser con 'prevDato =>', si no queda en un loop infinito
      setDato(prevDato => ({
        ...prevDato,
        'id_responsable': inputResponsable.current.selectedOptions[0].value
      }));
    }
  }, [responsables]); //Este se ejecuta cuando se carga completo el arreglo  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDato({ ...dato, [name]: value });
  };
  
  function handleDateChange(date, name) {
    if(date){      
      setDato({ ...dato, [name]: format(date, 'yyyy-MM-dd') });
    }
  }

  /************ inicio funciones orden array (Datos tabla ORIGEN) *************************************** */
  const handleColumnClickOrigen = (columnIndex) => {
    if (sortingColumnOrigen === columnIndex) {setSortDirectionOrigen(sortDirectionOrigen === 'asc' ? 'desc' : 'asc');
    } else { setSortingColumnOrigen(columnIndex); setSortDirectionOrigen('asc');}
  };
  useEffect(() => {    
    const rows = [...datosdetalleorigenOriginales]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
    if (sortingColumnOrigen !== -1) {  
      rows.sort((a, b) => {
        const valA = a[Object.keys(a)[sortingColumnOrigen]]; const valB = b[Object.keys(b)[sortingColumnOrigen]];
        if (typeof valA === 'number' && typeof valB === 'number') {
          return valA - valB;
        } else if (isValidDate(valA) && isValidDate(valB)) {
          const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-'));
          return dateA - dateB;
        } else { return valA.localeCompare(valB); }
      });
  
      if (sortDirectionOrigen === 'desc') { rows.reverse();}
      setDatosdetalleorigen(rows); // Actualizamos el estado de sortedRows con los datos ordenados
    }
  }, [sortingColumnOrigen, sortDirectionOrigen, datosdetalleorigenOriginales]); // Dependencias: sortingColumn, sortDirection, datosOriginales
  /************ fin funciones orden array (datos tabla ORIGEN) *************************************** */

  /************ inicio funciones orden array (Datos tabla DESTINO) *************************************** */
  const handleColumnClickDestino = (columnIndex) => {
    if (sortingColumnDestino === columnIndex) {setSortDirectionDestino(sortDirectionDestino === 'asc' ? 'desc' : 'asc');
    } else { setSortingColumnDestino(columnIndex); setSortDirectionDestino('asc');}
  };
  useEffect(() => {    
    const rows = [...datosdetalledestinoOriginales]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
    if (sortingColumnDestino !== -1) {  
      rows.sort((a, b) => {
        const valA = a[Object.keys(a)[sortingColumnDestino]]; const valB = b[Object.keys(b)[sortingColumnDestino]];
        if (typeof valA === 'number' && typeof valB === 'number') {
          return valA - valB;
        } else if (isValidDate(valA) && isValidDate(valB)) {
          const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-'));
          return dateA - dateB;
        } else { return valA.localeCompare(valB); }
      });
  
      if (sortDirectionDestino === 'desc') { rows.reverse();}
      setDatosdetalledestino(rows); // Actualizamos el estado de sortedRows con los datos ordenados
    }
  }, [sortingColumnDestino, sortDirectionDestino, datosdetalledestinoOriginales]); // Dependencias: sortingColumn, sortDirection, datosOriginales
  /************ fin funciones orden array (datos tabla DESTINO) *************************************** */

  const AddAnimal = async (e, id_ficha, diio) => {
    e.preventDefault(); // Prevenir el evento submit del formulario
    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }

    try {
      var data = {
        id_mov    : id_mov,
        id_ficha  : id_ficha,
        diio      : diio, 
        usuario : localStorage.getItem("usuario")
      };
      await axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalle`, data,{withCredentials: true});
      toast.success("Animal agregado correctamente", {
        position: "top-right", autoClose: 2000, hideProgressBar: true,
        closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
      });
      
      setDatosdetalleorigen(datosdetalleorigen.filter(datosdetalleorigen => datosdetalleorigen.id_ficha !== id_ficha)); //Quitar del arreglo la fila eliminada
      setDatosdetalleorigenOriginales(datosdetalleorigenOriginales.filter(datosdetalleorigenOriginales => datosdetalleorigenOriginales.id_ficha !== id_ficha)); //Quitar del arreglo la fila eliminada
      setDato({ ...dato, 'estado': 'PENDIENTE' });
      getDetalleDestino();
    } catch(error) {
      if (error.response.status === 401){
        delete axios.defaults.headers.common["Authorization"]; 
        localStorage.removeItem("token"); 
        localStorage.removeItem("usuario");
        navigate('/login');
      } else {
        toast.error(error.response.data, {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      }
    };
  }

  const DelAnimal = async (e, id_mov_detalle) => {
    e.preventDefault(); // Prevenir el evento submit del formulario
    try{
      const response = await axios.delete(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalle/${id_mov}/${id_mov_detalle}`,{withCredentials: true});
      if (response.data) {
        toast.warning(response.data.message, {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });

        setDatosdetalledestino(datosdetalledestino.filter(datosdetalledestino => datosdetalledestino.id_mov_detalle !== id_mov_detalle)); //Quitar del arreglo la fila eliminada
        setDatosdetalledestinoOriginales(datosdetalledestinoOriginales.filter(datosdetalledestinoOriginales => datosdetalledestinoOriginales.id_mov_detalle !== id_mov_detalle)); //Quitar del arreglo la fila eliminada
        setDato({ ...dato, 'estado': response.data.estado });

        getDetalleOrigen(dato.origen_id_predio, dato.origen_id_potrero, dato.origen_id_subpotrero);
      } else {
        toast.error('¡No se pudo eliminar el registro!', {
          position: "top-right", autoClose: 2000, hideProgressBar: true, 
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      }
    } catch(error){
      if (error.response.status === 401){
        delete axios.defaults.headers.common["Authorization"]; 
        localStorage.removeItem("token"); 
        localStorage.removeItem("usuario");
        navigate('/login');
      } else {
        toast.error('¡No se pudo eliminar el registro!', {
          position: "top-right", autoClose: 2000, hid_moveProgressBar: true, closeOnClick: true,
          pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      }
    };
  }

  const AddTodos = async (e) => {
    e.preventDefault(); // Prevenir el evento submit del formulario

    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }
    
    Swal.fire({
      title: 'Agregar todos los animales', text: '¿Está seguro que desea agregar todos los animales?',
      icon: 'question', showCancelButton: true, confirmButtonColor: '#198754', cancelButtonColor: '#666',
      confirmButtonText: 'Agregar todos', cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) 
      {
        // Bloquear pantalla mientras se realiza la operación
        const loadingSwal = Swal.fire({
          title: 'Cargando datos', text: 'Por favor, espere un momento...',
          allowOutsideClick: false,showConfirmButton: false, willOpen: () => {Swal.showLoading();}
        });

        try {
          var data = {
            id_mov      : id_mov,
            id_predio   : dato.origen_id_predio,
            id_potrero  : dato.origen_id_potrero,
            subpotrero  : dato.origen_id_subpotrero, 
            usuario : localStorage.getItem("usuario")
          };
          await axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalleall`, data,{withCredentials: true});
          loadingSwal.close();
          toast.success("Animales agregados correctamente", {
            position: "top-right", autoClose: 2000, hideProgressBar: true,
            closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
          getDetalleOrigen(dato.origen_id_predio, dato.origen_id_potrero, dato.origen_id_subpotrero);
          getDetalleDestino();
          setDato({ ...dato, 'estado': 'PENDIENTE' });
        } catch(error) {
          if (error.response.status === 401){
            delete axios.defaults.headers.common["Authorization"]; 
            localStorage.removeItem("token"); 
            localStorage.removeItem("usuario");
            navigate('/login');
          } else {
            loadingSwal.close();
            toast.error(error.response.data, {
              position: "top-right", autoClose: 2000, hideProgressBar: true,
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        };
      }
    });
  }

  const DelTodos = async (e) => {
    e.preventDefault(); // Prevenir el evento submit del formulario

    Swal.fire({
      title: 'Quitar todos los animales', text: '¿Está seguro que desea quitar todos los animales?',
      icon: 'warning', showCancelButton: true, confirmButtonColor: '#198754', cancelButtonColor: '#666',
      confirmButtonText: 'Quitar todos', cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) 
      {
        // Bloquear pantalla mientras se realiza la operación
        const loadingSwal = Swal.fire({
          title: 'Cargando datos', text: 'Por favor, espere un momento...',
          allowOutsideClick: false,showConfirmButton: false, willOpen: () => {Swal.showLoading();}
        });

        try{
          const response = await axios.delete(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_detalleall/${id_mov}`,{withCredentials: true});
          if (response.data) {
            loadingSwal.close();
            toast.warning(response.data.message, {
              position: "top-right", autoClose: 2000, hideProgressBar: true,
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
            getDetalleOrigen(dato.origen_id_predio, dato.origen_id_potrero, dato.origen_id_subpotrero);
            getDetalleDestino();
            setDato({ ...dato, 'estado': response.data.estado });
          } else {
            loadingSwal.close();
            toast.error('¡No se pudieron eliminar los registros!', {
              position: "top-right", autoClose: 2000, hideProgressBar: true, 
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        } catch(error){
          if (error.response.status === 401){
            delete axios.defaults.headers.common["Authorization"]; 
            localStorage.removeItem("token"); 
            localStorage.removeItem("usuario");
            navigate('/login');
          } else {
            toast.error('¡No se pudieron eliminar los registros!', {
              position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        };
      }
    });
  }

  const enviarFormulario = async (e, p_finalizar = '0') => {
    e.preventDefault();

    var data = {
      id_mov                : dato.id_mov,
      fecha_mov             : dato.fecha_mov,
      id_responsable        : dato.id_responsable,
      destino_id_predio     : inputDestinoPredio.current.value,
      destino_id_potrero    : inputDestinoPotrero.current.value,
      destino_id_subpotrero : inputDestinoSubpotrero.current.value,
      finalizar             : p_finalizar, 
      usuario : localStorage.getItem("usuario")
    };

    if(p_finalizar === '1'){
      Swal.fire({
        title: 'Finalizar movimiento', text: '¿Está seguro que desea finalizar el movimiento de animales?',
        icon: 'warning', showCancelButton: true, confirmButtonColor: '#198754', cancelButtonColor: '#666',
        confirmButtonText: 'Finalizar', cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) 
        {
          // Bloquear pantalla mientras se realiza la operación
          const loadingSwal = Swal.fire({
            title: 'Cargando datos', text: 'Por favor, espere un momento...',
            allowOutsideClick: false,showConfirmButton: false, willOpen: () => {Swal.showLoading();}
          });

          try {
            if (dato.id_mov) {
              await axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${dato.id_mov}`, data,{withCredentials: true});
              loadingSwal.close();
              toast.success('¡Registro actualizado correctamente!', {
                position: "top-right", autoClose: 2000, hideProgressBar: true,
                closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
              });
              if(p_finalizar === '1'){
                setDato({ ...dato, 'estado': 'FINALIZADO' });
              }
            } 
          } catch (error) {
            if (error.response.status === 401){
              delete axios.defaults.headers.common["Authorization"]; 
              localStorage.removeItem("token"); 
              localStorage.removeItem("usuario");
              navigate('/login');
            } else {
              loadingSwal.close();
              toast.error(error.response.data, {
                position: "top-right", autoClose: 2000, hideProgressBar: true,
                closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
              });
            }
          }
        }
      });
    }
    else{
      try {
        if (dato.id_mov) {
          await axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${dato.id_mov}`, data,{withCredentials: true});
          toast.success('¡Registro actualizado correctamente!', {
            position: "top-right", autoClose: 2000, hideProgressBar: true,
            closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
          if(p_finalizar === '1'){
            setDato({ ...dato, 'estado': 'FINALIZADO' });
          }
        } 
      } catch (error) {
        if (error.response.status === 401){
          delete axios.defaults.headers.common["Authorization"]; 
          localStorage.removeItem("token"); 
          localStorage.removeItem("usuario");
          navigate('/login');
        } else {
          toast.error(error.response.data, {
            position: "top-right", autoClose: 2000, hideProgressBar: true,
            closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
        }
      }
    }
  };

  /*********************************************************/
  /********** Funciones Modal 'modalBuscarAnimal' *********/
  /*********************************************************/
  
  //Para agregar el addEventListener al modal 'modalBuscarAnimal' cuando se dispare el Show 
  useEffect(() => {
    const modalElement = document.querySelector('#ModalBuscarAnimal');
    modalElement.addEventListener('shown.bs.modal', onModalShowBuscarAnimal);
    return () => {
      modalElement.removeEventListener('shown.bs.modal', onModalShowBuscarAnimal);
    };
  }, []);

  const onModalShowBuscarAnimal = () => {
    inputBuscarAnimal.current.focus();
  }

  const CerrarModalBuscarAnimal = () => {
    const modalElement = modalBuscarAnimal.current;
    if (modalElement) {
      const bootstrapModal = bootstrap.Modal.getInstance(modalElement);
      bootstrapModal.hide();
    } 
  }

  const handleKeyPressBuscarAnimal = (event) => {
    if (event.key === 'Enter') {
      getAnimales();
    }
  };

  const getAnimales = () => {
    const strBuscar = inputBuscarAnimal.current.value;
    if (strBuscar.length >= 3) {
      setIsLoadingBusquedaAnimales(true);
      axios.get(`${process.env.REACT_APP_URL_API_PORT}/fichas_mov`, 
      {
        withCredentials: true,
        params: {
          id_mov        : id_mov,
          id_especie    : dato.id_especie,
          strBuscar     : strBuscar,
          estado        : 1
        }
      })
      .then(response => {
        setListaAnimales(response.data);
        setIsLoadingBusquedaAnimales(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
    }
  };
  /*********************************************************/
  
  return (
    <div className="row justify-content-center w-100">
      <div className="col col-lg-10 col-xl-8">
      {isLoadingDatosForm &&  
        <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Cargando datos...</span>
            </div>
        </div>
      }
      {!isLoadingDatosForm &&    
        <form onSubmit={(e) => enviarFormulario(e)}>
          <small>
          <div className="row pt-2 pb-2">
            <div className="col text-end">
              {datosdetalledestino.length > 0 && dato.estado !=='FINALIZADO' && 
              <button 
                type="button" 
                className="btn btn-primary btn-sm"
                onClick={(e) => enviarFormulario(e, '1')}>
                <i className="fas fa-check-circle"></i> Finalizar
              </button>
              }
              &nbsp;
              {dato.estado !=='FINALIZADO' && 
              <button type="submit" className="btn btn-success btn-sm">
                <i className="fas fa-check"></i> Guardar
              </button>
              }
              &nbsp;
              <Link to={`/${modulo}`} className="btn btn-secondary btn-sm">
                <i className={icon} title="Volver"></i> {textButton}
              </Link>
            </div>
          </div>
          
          <fieldset className={`border p-2 mb-2 ${darkMode ? 'border-secondary' : ''}`}>
            <div className="row pt-2">
              <label className="col-sm-2">N°&nbsp;Mov.:</label>
              <div className="col-sm-4">
                <input type="text" name="id_mov" value={dato.id_mov} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`}
                readOnly/>
              </div>
              <label className="col-sm-2">Fecha:</label>
              <div className="col-sm-4">
                <input type="text" name="created_date" value={dato.created_date} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`}
                readOnly/>
              </div>
            </div>

            <div className="row pt-2">
              <label className="col-sm-2">Responsable:</label>
              <div className="col-sm-4">
                <select 
                  className="form-select form-select-sm" 
                  name="id_responsable" 
                  ref={inputResponsable} 
                  disabled={dato.estado === 'FINALIZADO'}
                  value={dato.id_responsable} onChange={handleInputChange} required>
                  {responsables.map((opcion) => (
                    <option key={opcion.id_responsable} value={opcion.id_responsable}>{opcion.n_responsable}</option>
                  ))}
                </select>
              </div>
              <label className="col-sm-2">Fecha Mov.:</label>
              <div className="col-sm-4">
                <DatePicker
                  selected={parseISO(dato.fecha_mov, 'ddMMyyyy', new Date())}
                  onChange={(date) => handleDateChange(date, "fecha_mov")}
                  minDate={minSelectableDate}
                  maxDate={maxSelectableDate}
                  dateFormat="dd-MM-yyyy"
                  className="form-control form-control-sm" 
                  disabled={dato.estado === 'FINALIZADO'}
                  locale={es} // Establece el idioma español
                  required 
                />
              </div>
            </div>

            <div className="row pb-2 pt-2">
              <label className="col-sm-2">Especie:</label>
              <div className="col-sm-4">
                <input type="text" name="n_especie" value={dato.n_especie} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                readOnly/>
              </div>
              <label className="col-sm-2">Estado:</label>
              <div className="col-sm-4">
                <input type="text" name="estado" value={dato.estado} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                readOnly/>
              </div>
            </div>
          </fieldset>
          
          <div className="row">
            {/* INICIO ORIGEN */}
            <div className="col-12 col-sm-12 col-md-6">
              <fieldset 
                className={`border p-2 mb-2 position-relative ${darkMode ? 'border-secondary' : ''}`} 
                style={{ height: '400px', maxHeight: '400px' }}>
                <legend 
                  className={`w-auto position-absolute ${darkMode ? 'bg-dark' : 'bg-light'}`}
                >Origen</legend>
                <div className="row pt-2">
                  <label className="col-3 col-sm-2 col-md-3">Predio:</label>
                  <div className="col-9 col-sm-10 col-md-9">
                    <input type="text" name="origen_n_predio" value={dato.origen_n_predio} 
                    className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                    readOnly/>
                  </div>
                </div>
                <div className="row pt-2">
                  <label className="col-3 col-sm-2 col-md-3">Potrero:</label>
                  <div className="col-9 col-sm-10 col-md-9">
                    <input type="text" name="origen_n_potrero" value={dato.origen_n_potrero} 
                    className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                    readOnly/>
                  </div>
                </div>
                <div className="row pt-2">
                  <label className="col-3 col-sm-2 col-md-3">Subpotrero:</label>
                  <div className="col-9 col-sm-10 col-md-9">
                    <input type="text" name="origen_n_subpotrero" value={dato.origen_n_subpotrero} 
                    className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                    readOnly/>
                  </div>
                </div>

                <div className="row pt-2">
                  {datosdetalleorigen.length > 0 && dato.estado !== 'FINALIZADO' && 
                  <div className="col">
                    <button 
                      onClick={(e) => AddTodos(e)}
                      className="btn btn-sm btn-success">
                      Agregar todos <i className="fa fa-arrow-right-to-bracket" title="Agregar todos los animales"></i>
                    </button>
                  </div>
                  }
                </div>
                
                {isLoadingDetalleOrigen &&  
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Cargando datos...</span>
                  </div>
                </div>
                }
                {!isLoadingDetalleOrigen &&  
                <div className="row pt-2">
                  <div className="col">
                    <div className="table-responsive" style={{ maxHeight: 'calc(200px)' }}>
                      <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                        <thead className="sticky-top">
                          <tr>
                            <th onClick={() => handleColumnClickOrigen(1)} className={sortingColumnOrigen === 1 ? `sorting ${sortDirectionOrigen}` : ''}>Diio/Crotal</th>
                            <th onClick={() => handleColumnClickOrigen(2)} className={sortingColumnOrigen === 2 ? `sorting ${sortDirectionOrigen}` : ''}>Categoría</th>
                            {dato.estado !== 'FINALIZADO' && 
                            <th>Acción</th>
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {datosdetalleorigen.map(fila => {
                            return (
                            <tr key={fila.id_ficha}>
                              <td>{fila.diio}</td>
                              <td>{fila.categoria}</td>
                              {dato.estado !== 'FINALIZADO' && 
                              <td>
                                <button 
                                  onClick={(e) => AddAnimal(e, fila.id_ficha, fila.diio)}
                                  className="btn btn-sm btn-outline-success">
                                  <i className="fa fa-arrow-right" title="Agregar animal"></i>
                                </button> 
                              </td>
                              }
                            </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                }
              </fieldset>
            </div> 
            {/* FIN ORIGEN */}

            {/* INICIO DESTINO */}
            <div className="col-12 col-sm-12 col-md-6">
              <fieldset 
                className={`border p-2 mb-2 position-relative ${darkMode ? 'border-secondary' : ''}`} 
                style={{ height: '400px', maxHeight: '400px' }}>
                <legend 
                  className={`w-auto position-absolute ${darkMode ? 'bg-dark' : 'bg-light'}`} 
                  >Destino</legend>
                <div className="row pt-2">
                  <label className="col-3 col-sm-2 col-md-3">Predio:</label>
                  <div className="col-9 col-sm-10 col-md-9">
                    <select 
                      className="form-select form-select-sm" 
                      name="destino_id_predio" 
                      ref={inputDestinoPredio} 
                      disabled={dato.estado === 'FINALIZADO'}
                      value={dato.destino_id_predio} onChange={handleInputChange} required>
                      {predios.map((opcion) => (
                        <option key={opcion.id_predio} value={opcion.id_predio}>{opcion.n_predio}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row pt-2">
                  <label className="col-3 col-sm-2 col-md-3">Potrero:</label>
                  <div className="col-9 col-sm-10 col-md-9">
                    <select 
                      className="form-select form-select-sm" 
                      name="destino_id_potrero" 
                      ref={inputDestinoPotrero} 
                      disabled={dato.estado === 'FINALIZADO'}
                      value={dato.destino_id_potrero} onChange={handleInputChange} required>
                      {potreros.map((opcion) => (
                        <option key={opcion.id_potrero} value={opcion.id_potrero}>{opcion.n_potrero}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row pt-2">
                  <label className="col-3 col-sm-2 col-md-3">Subpotrero:</label>
                  <div className="col-9 col-sm-10 col-md-9">
                    <select 
                      className="form-select form-select-sm" 
                      name="destino_id_subpotrero" 
                      ref={inputDestinoSubpotrero} 
                      disabled={dato.estado === 'FINALIZADO'}
                      value={dato.destino_id_subpotrero} onChange={handleInputChange}>
                        <option key={0} value={0}></option>
                      {subpotreros.map((opcion) => (
                        <option key={opcion.id_subpotrero} value={opcion.id_subpotrero}>{opcion.n_subpotrero}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row pt-2">
                  <div className="col">
                    {datosdetalledestino.length > 0 && dato.estado !== 'FINALIZADO' && 
                    <button 
                      onClick={(e) => DelTodos(e)}
                      className="btn btn-sm btn-warning">
                      Quitar todos <i className="fa fa-arrow-right-from-bracket" title="Quitar todos los animales"></i>
                    </button>
                    }
                  </div>
                  <div className="col text-end">
                    {dato.estado !== 'FINALIZADO' && 
                    <button 
                      type="button" 
                      className="btn btn-primary btn-sm" 
                      data-bs-toggle="modal" 
                      data-bs-target="#ModalBuscarAnimal">
                      <i className="fa fa-search"></i> Buscar animal
                    </button>
                    }
                  </div>
                </div>

                {isLoadingDetalleDestino &&  
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Cargando datos...</span>
                  </div>
                </div>
                }
                {!isLoadingDetalleDestino &&  
                <div className="row pt-2">
                  <div className="col">
                    <div className="table-responsive" style={{ maxHeight: 'calc(200px)' }}>
                      <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                        <thead className="sticky-top">
                          <tr>
                            <th onClick={() => handleColumnClickDestino(1)} className={sortingColumnDestino === 1 ? `sorting ${sortDirectionDestino}` : ''}>Diio/Crotal</th>
                            <th onClick={() => handleColumnClickDestino(2)} className={sortingColumnDestino === 2 ? `sorting ${sortDirectionDestino}` : ''}>Categoría</th>
                            {dato.estado !== 'FINALIZADO' && 
                            <th>Acción</th>
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {datosdetalledestino.map(fila => {
                            return (
                            <tr key={fila.id_mov_detalle}>
                              <td>{fila.diio}</td>
                              <td>{fila.categoria}</td>
                              {dato.estado !== 'FINALIZADO' && 
                              <td>
                                <button 
                                  onClick={(e) => DelAnimal(e, fila.id_mov_detalle)}
                                  className="btn btn-sm btn-outline-warning">
                                  <i className="fa fa-times" title="Agregar animal"></i>
                                </button>
                              </td>
                              }
                            </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                }
              </fieldset>
            </div>
            {/* FIN DESTINO */}
          </div>

          
          </small>
          
        </form>
        }

        <div className="modal fade" id="ModalBuscarAnimal" ref={modalBuscarAnimal} 
          tabIndex="-1" aria-labelledby="ModalBuscarAnimalLabel" aria-hidden="true">
          <div 
            className="modal-dialog modal-dialog-scrollable border border-secondary"
            style={{ maxHeight: '500px' }}>
            <div className={`modal-content ${darkMode ? 'text-light bg-dark' : ''}`}>
              <div className="modal-header border-bottom border-secondary">
                <h5 className="modal-title" id="ModalBuscarAnimalLabel">Buscar animal</h5>
                <button 
                  type="button" 
                  className={`btn-close ${darkMode ? 'bg-btn-close-modal': ''}`}
                  data-bs-dismiss="modal" aria-label="Close">
                </button>
              </div>
              <div className="modal-body">

                <div className="row">
                  <div className="input-group mb-3">
                    <input 
                      type="text" className="form-control form-control-sm" 
                      placeholder="Buscar por Ficha / Diio / Crotal (Mas de 3 caracteres)" 
                      aria-label="Buscar por Ficha / Diio / Crotal (Mas de 3 caracteres)" 
                      aria-describedby="bot_buscar" 
                      onKeyDown={handleKeyPressBuscarAnimal}
                      ref={inputBuscarAnimal}/>
                    <button 
                      className="btn btn-outline-secondary" type="button" 
                      id="bot_buscar" 
                      onClick={() => getAnimales()}>Buscar</button>
                  </div>
                </div>
                
                {isLoadingBusquedaAnimales &&  
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Cargando listado de animales...</span>
                    </div>
                  </div>
                }
                {!isLoadingBusquedaAnimales &&  
                <table className={`table table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                  <thead>
                    <tr>
                      <th>Id Ficha</th>
                      <th>Diio/Crotal</th>
                      <th>Categoría</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listaAnimales.map(tupla => (
                      <tr key={tupla.id_ficha}>
                        <td>{tupla.id_ficha}</td>
                        <td>{tupla.diio}</td>
                        <td>{tupla.categoria}</td>
                        <td>
                          <button 
                            onClick={(e) => {
                              AddAnimal(e, tupla.id_ficha, tupla.diio);
                              CerrarModalBuscarAnimal();
                            }}
                            className="btn btn-sm btn-outline-success">
                            <i className="fa fa-plus" title="Agregar animal"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                }
              </div>
              <div className="modal-footer border-top border-secondary">
                <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>{/*fin modal*/}
      </div>

    </div>
  );
}

export default MovindividualForm;
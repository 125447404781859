import React from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Template     from './components/Template';
import Login        from './routes/Login';
import Dashboard    from './routes/dashboard/Dashboard';

import Mapa         from './routes/mapa/Mapa';

import Usuarios     from './routes/config/usuarios/Usuarios';
import UsuariosForm from './routes/config/usuarios/UsuariosForm';

import Roles        from './routes/config/roles/Roles';
import RolesForm    from './routes/config/roles/RolesForm';

import Especies     from './routes/tablas/especies/Especies';
import EspeciesForm from './routes/tablas/especies/EspeciesForm';

import Razas        from './routes/tablas/razas/Razas';
import RazasForm    from './routes/tablas/razas/RazasForm';

import Categorias        from './routes/tablas/categorias/Categorias';
import CategoriasForm    from './routes/tablas/categorias/CategoriasForm';

import Predios      from './routes/tablas/predios/Predios';
import PrediosForm  from './routes/tablas/predios/PrediosForm';

import Potreros     from './routes/tablas/potreros/Potreros';
import PotrerosForm from './routes/tablas/potreros/PotrerosForm';

import Subpotreros        from './routes/tablas/subpotreros/Subpotreros';
import SubpotrerosForm    from './routes/tablas/subpotreros/SubpotrerosForm';

import Marcas             from './routes/tablas/marcas/Marcas';
import MarcasForm         from './routes/tablas/marcas/MarcasForm';

import Metodos            from './routes/tablas/metodos/Metodos';
import MetodosForm        from './routes/tablas/metodos/MetodosForm';

import Tiposproductos     from './routes/tablas/tiposproductos/Tiposproductos';
import TiposproductosForm from './routes/tablas/tiposproductos/TiposproductosForm';

import Tiposactividad     from './routes/tablas/tiposactividad/Tiposactividad';
import TiposactividadForm from './routes/tablas/tiposactividad/TiposactividadForm';

import Padres             from './routes/tablas/padres/Padres';
import PadresForm         from './routes/tablas/padres/PadresForm';

import Empresas           from './routes/tablas/empresas/Empresas';
import EmpresasForm       from './routes/tablas/empresas/EmpresasForm';

import Unidadesmedida     from './routes/tablas/unidadesmedida/Unidadesmedida';
import UnidadesmedidaForm from './routes/tablas/unidadesmedida/UnidadesmedidaForm';

import Responsables       from './routes/tablas/responsables/Responsables';
import ResponsablesForm   from './routes/tablas/responsables/ResponsablesForm';
//------------------------------------------
import Productos          from './routes/productos/Productos';
import ProductosForm      from './routes/productos/ProductosForm';

import Guiasentrada       from './routes/productos/Guiasentrada';
import GuiasentradaForm   from './routes/productos/GuiasentradaForm';
//------------------------------------------
import Fichas             from './routes/fichas/Fichas';
import FichasForm         from './routes/fichas/FichasForm';
//------------------------------------------
import Lotes              from './routes/lotes/Lotes';
import LotesForm          from './routes/lotes/LotesForm';
//------------------------------------------
import Movindividual      from './routes/movimientos/individual/Movindividual';
import MovindividualForm  from './routes/movimientos/individual/MovindividualForm';
//------------------------------------------
import Movlote            from './routes/movimientos/lote/Movlote';
import MovloteForm        from './routes/movimientos/lote/MovloteForm';
//------------------------------------------
import Actividades        from './routes/actividades/Actividades';
import ActividadesForm    from './routes/actividades/ActividadesForm';
//------------------------------------------
import Stock              from './routes/reportes/productos/Stock';
//------------------------------------------
import NotFoundPage       from './routes/NotFoundPage';

import Test       from './routes/test';

let modulo;
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Template />}>
          <Route index element={<Dashboard />} />

          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/mapa" element={<Mapa />} />
          
          
          {/* Routes Tablas base */}
          {modulo = "usuarios"}
          <Route path={`/${modulo}`} exact element={<Usuarios />} />
          <Route path={`/${modulo}/new`} element={<UsuariosForm />} />
          <Route path={`/${modulo}/:id`} element={<UsuariosForm />} />

          {modulo = "roles"}
          <Route path={`/${modulo}`} exact element={<Roles />} />
          <Route path={`/${modulo}/new`} element={<RolesForm />} />
          <Route path={`/${modulo}/:id_rol`} element={<RolesForm />} />

          {modulo = "especies"}
          <Route path={`/${modulo}`} exact element={<Especies />} />
          <Route path={`/${modulo}/new`} element={<EspeciesForm />} />
          <Route path={`/${modulo}/:id_especie`} element={<EspeciesForm />} />

          {modulo = "razas"}
          <Route path={`/${modulo}`} exact element={<Razas />} />
          <Route path={`/${modulo}/new`} element={<RazasForm />} />
          <Route path={`/${modulo}/:id_raza`} element={<RazasForm />} />

          {modulo = "categorias"}
          <Route path={`/${modulo}`} exact element={<Categorias />} />
          <Route path={`/${modulo}/:id_calculo`} element={<CategoriasForm />} />

          {modulo = "predios"}
          <Route path={`/${modulo}`} exact element={<Predios />} />
          <Route path={`/${modulo}/new`} element={<PrediosForm />} />
          <Route path={`/${modulo}/:id_predio`} element={<PrediosForm />} />

          {modulo = "potreros"}
          <Route path={`/${modulo}`} exact element={<Potreros />} />
          <Route path={`/${modulo}/new`} element={<PotrerosForm />} />
          <Route path={`/${modulo}/:id_potrero`} element={<PotrerosForm />} />

          {modulo = "subpotreros"}
          <Route path={`/${modulo}`} exact element={<Subpotreros />} />
          <Route path={`/${modulo}/new`} element={<SubpotrerosForm />} />
          <Route path={`/${modulo}/:id_subpotrero`} element={<SubpotrerosForm />} />

          {modulo = "marcas"}
          <Route path={`/${modulo}`} exact element={<Marcas />} />
          <Route path={`/${modulo}/new`} element={<MarcasForm />} />
          <Route path={`/${modulo}/:id_marca`} element={<MarcasForm />} />

          {modulo = "metodos"}
          <Route path={`/${modulo}`} exact element={<Metodos />} />
          <Route path={`/${modulo}/new`} element={<MetodosForm />} />
          <Route path={`/${modulo}/:id_metodo`} element={<MetodosForm />} />

          {modulo = "tiposproductos"}
          <Route path={`/${modulo}`} exact element={<Tiposproductos />} />
          <Route path={`/${modulo}/new`} element={<TiposproductosForm />} />
          <Route path={`/${modulo}/:id_tipoproducto`} element={<TiposproductosForm />} />

          {modulo = "tiposactividad"}
          <Route path={`/${modulo}`} exact element={<Tiposactividad />} />
          <Route path={`/${modulo}/new`} element={<TiposactividadForm />} />
          <Route path={`/${modulo}/:id_tipoactividad`} element={<TiposactividadForm />} />

          {modulo = "padres"}
          <Route path={`/${modulo}`} exact element={<Padres />} />
          <Route path={`/${modulo}/new`} element={<PadresForm />} />
          <Route path={`/${modulo}/:id_padre`} element={<PadresForm />} />

          {modulo = "empresas"}
          <Route path={`/${modulo}`} exact element={<Empresas />} />
          <Route path={`/${modulo}/new`} element={<EmpresasForm />} />
          <Route path={`/${modulo}/:id_empresa`} element={<EmpresasForm />} />

          {modulo = "unidadesmedida"}
          <Route path={`/${modulo}`} exact element={<Unidadesmedida />} />
          <Route path={`/${modulo}/new`} element={<UnidadesmedidaForm />} />
          <Route path={`/${modulo}/:id_unidadmedida`} element={<UnidadesmedidaForm />} />

          {modulo = "responsables"}
          <Route path={`/${modulo}`} exact element={<Responsables />} />
          <Route path={`/${modulo}/new`} element={<ResponsablesForm />} />
          <Route path={`/${modulo}/:id_responsable`} element={<ResponsablesForm />} />

          {/* Routes Productos */}

          {modulo = "productos"}
          <Route path={`/${modulo}`} exact element={<Productos />} />
          <Route path={`/${modulo}/new`} element={<ProductosForm />} />
          <Route path={`/${modulo}/:id_producto`} element={<ProductosForm />} />

          {modulo = "guiasentrada"}
          <Route path={`/${modulo}`} exact element={<Guiasentrada />} />
          <Route path={`/${modulo}/new`} element={<GuiasentradaForm />} />
          <Route path={`/${modulo}/:id_guia_entrada`} element={<GuiasentradaForm />} />

          {/* Routes Fichas */}
          {modulo = "fichas"}
          <Route path={`/${modulo}`} exact element={<Fichas />} />
          <Route path={`/${modulo}/new`} element={<FichasForm />} />
          <Route path={`/${modulo}/:id_ficha`} element={<FichasForm />} />

          {/* Routes Lotes */}
          {modulo = "lotes"}
          <Route path={`/${modulo}`} exact element={<Lotes />} />
          <Route path={`/${modulo}/new`} element={<LotesForm />} />
          <Route path={`/${modulo}/:id_lote`} element={<LotesForm />} />

          {/* Routes Movimiento Individual */}
          {modulo = "movindividual"}
          <Route path={`/${modulo}`} exact element={<Movindividual />} />
          <Route path={`/${modulo}/new`} element={<MovindividualForm />} />
          <Route path={`/${modulo}/:id_mov`} element={<MovindividualForm />} />

          {/* Routes Movimiento Lote */}
          {modulo = "movlote"}
          <Route path={`/${modulo}`} exact element={<Movlote />} />
          <Route path={`/${modulo}/new`} element={<MovloteForm />} />
          <Route path={`/${modulo}/:id_mov`} element={<MovloteForm />} />

          {/* Routes Actividades */}
          {modulo = "actividades"}
          <Route path={`/${modulo}`} exact element={<Actividades />} />
          <Route path={`/${modulo}/:id_actividad`} element={<ActividadesForm />} />

          {/* Routes Stock productos */}
          {modulo = "stock"}
          <Route path={`/${modulo}`} exact element={<Stock />} />

          <Route path={`/test`} exact element={<Test />} />

        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { isValidDate } from '../../utils/utils';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { useDarkMode } from '../../context/darkModeContext'; 

ChartJS.register(ArcElement, Tooltip, Legend);

const DashEquino = () => {
    // eslint-disable-next-line
    const {darkMode, setDarkMode} = useDarkMode();

    const [colorPorcentaje, setColorPorcentaje] = useState('');
    useEffect( ()=> {
        if(darkMode){
            setColorPorcentaje('#ccc');
        } else {
            setColorPorcentaje('#444');
        }
    },[darkMode]);
    
    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            datalabels: {
                labels: {
                    //title: null, // Desactivar el título dentro del pie
                    value: {
                        color: colorPorcentaje, // Color del texto del porcentaje
                        formatter: (value, context) => {
                            // Formatear el valor dentro del gráfico
                            return value;
                        },
                        font: {
                            size: 10,
                        },
                    },
                    percentage: {
                        formatter: (value, context) => {
                            // Formatear el porcentaje fuera del gráfico
                            const total = context.dataset.data.reduce((acc, num) => acc + num, 0);
                            const percentage = ((value / total) * 100).toFixed(1).replace('.', ',') + '%';
                            return percentage;
                        },
                        font: {
                            size: 10,
                        },
                        color: colorPorcentaje, // Color del texto del porcentaje
                        anchor: 'end',
                        align: 'end',
                        //offset: 0, // Ajusta la distancia entre el texto y el borde del gráfico
                    },
                },
            },
        },
        responsive: true,
        maintainAspectRatio: true, // Si se descomenta este línea el gráfico se ve pequeño y no rellena todo el espacio disponible
        layout: {
            padding: {
                left: 34,
                top: 100
            },
        }
    };
    

    const navigate = useNavigate();
    const bootstrap = window.bootstrap; // se agregó para poder llamar al método hide() de un modal

    const [datosCategorias, setDatosCategorias] = useState([]);

    //--------------------------------------------------
    //--------------------- EQUINO ----------------------
    //--------------------------------------------------
    const [equinoChart, setEquinoChart] = useState(null);
    const [equinoChartCat, setEquinoChartCat] = useState(null);

    const [equinoDatosPredios, setEquinoDatosPredios] = useState([]);
    const [equinoDatosPrediosOri, setEquinoDatosPrediosOri] = useState([]);

    const [equinoDatosPotreros, setEquinoDatosPotreros] = useState([]);
    const [equinoDatosPotrerosOri, setEquinoDatosPotrerosOri] = useState([]);

    const [equinoDatosCat, setEquinoDatosCat] = useState(null);
    const [equinoDatosCatOri, setEquinoDatosCatOri] = useState([]);

    const [sortingColumnPredio, setSortingColumnPredio] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
    const [sortDirectionPredio, setSortDirectionPredio] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

    const [sortingColumnPotrero, setSortingColumnPotrero] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
    const [sortDirectionPotrero, setSortDirectionPotrero] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

    const [sortingColumnCat, setSortingColumnCat] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
    const [sortDirectionCat, setSortDirectionCat] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

    const modalPotreros = useRef(null);
    const [isLoadingPotreros, setIsLoadingPotreros] = useState(false);
    const [n_predio, setN_predio] = useState('');

    const getEquinoChart = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/dashboard_chart_equino`,{withCredentials: true});
            setEquinoChart(response.data);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    const getEquinoChartCat = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/dashboard_chart_equino_categorias`,{withCredentials: true});
            setEquinoChartCat(response.data);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    const getEquinoDatosPredios = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/dashboard_datos_equino_predios`,{withCredentials: true});
            setEquinoDatosPrediosOri(response.data);
            setEquinoDatosPredios(response.data);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    const getEquinoDatosPotreros = async (v_id_predio) => {
        setIsLoadingPotreros(true);
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/dashboard_datos_equino_potreros/${v_id_predio}`,{withCredentials: true});
            setEquinoDatosPotrerosOri(response.data);
            setEquinoDatosPotreros(response.data);
            setIsLoadingPotreros(false);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    const getEquinoDatosCategorias = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/dashboard_datos_equino_categorias`,{withCredentials: true});
            setEquinoDatosCatOri(response.data);
            setEquinoDatosCat(response.data);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    const getDatosCategoriasXEspecie = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/categoriasdatosxespecie/4`,{withCredentials: true});
            setDatosCategorias(response.data);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    function obtenerSiglaPorId(id_calculo) {
        const elementoEncontrado = datosCategorias.find(item => item.id_calculo === id_calculo);
        return elementoEncontrado ? elementoEncontrado.sigla : null;
    }

    /************ INICIO funciones orden array (STOCK PREDIO) *************************************** */
    const handleColumnClickPredio = (columnIndex) => {
        if (sortingColumnPredio === columnIndex) {
            setSortDirectionPredio(sortDirectionPredio === 'asc' ? 'desc' : 'asc');
        } else {
            setSortingColumnPredio(columnIndex); setSortDirectionPredio('asc');
        }
    };

    useEffect(() => {    
        const rows = [...equinoDatosPrediosOri]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
        if (sortingColumnPredio !== -1) {  
            rows.sort((a, b) => {
                const valA = a[Object.keys(a)[sortingColumnPredio]]; const valB = b[Object.keys(b)[sortingColumnPredio]];

                if (typeof valA === 'number' && typeof valB === 'number') {
                    return valA - valB;
                } else if (isValidDate(valA) && isValidDate(valB)) {
                    const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
                } else { return valA.localeCompare(valB);}
            });
    
            if (sortDirectionPredio === 'desc') { rows.reverse();}
            setEquinoDatosPredios(rows); // Actualizamos el estado de sortedRows con los datos ordenados
        }
    }, [sortingColumnPredio, sortDirectionPredio, equinoDatosPrediosOri]); // Dependencias: sortingColumn, sortDirection, datosOriginales
    /************ FIN funciones orden array (STOCK PREDIO) *************************************** */

    /************ INICIO funciones orden array (STOCK POTREROS) *************************************** */
    const handleColumnClickPotrero = (columnIndex) => {
        if (sortingColumnPotrero === columnIndex) {
            setSortDirectionPotrero(sortDirectionPotrero === 'asc' ? 'desc' : 'asc');
        } else {
            setSortingColumnPotrero(columnIndex); setSortDirectionPotrero('asc');
        }
    };

    useEffect(() => {    
        const rows = [...equinoDatosPotrerosOri]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
        if (sortingColumnPotrero !== -1) {  
            rows.sort((a, b) => {
                const valA = a[Object.keys(a)[sortingColumnPotrero]]; const valB = b[Object.keys(b)[sortingColumnPotrero]];

                if (typeof valA === 'number' && typeof valB === 'number') {
                    return valA - valB;
                } else if (isValidDate(valA) && isValidDate(valB)) {
                    const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
                } else { return valA.localeCompare(valB);}
            });

            if (sortDirectionPotrero === 'desc') { rows.reverse();}
            setEquinoDatosPotreros(rows); // Actualizamos el estado de sortedRows con los datos ordenados
        }
    }, [sortingColumnPotrero, sortDirectionPotrero, equinoDatosPotrerosOri]); // Dependencias: sortingColumn, sortDirection, datosOriginales
    /************ FIN funciones orden array (STOCK PREDIO) *************************************** */

    /************ INICIO funciones orden array (STOCK CATEGORIA) *************************************** */
    const handleColumnClickCat = (columnIndex) => {
        if (sortingColumnCat === columnIndex) {
            setSortDirectionCat(sortDirectionCat === 'asc' ? 'desc' : 'asc');
        } else {
            setSortingColumnCat(columnIndex); setSortDirectionCat('asc');
        }
    };

    useEffect(() => {    
        const rows = [...equinoDatosCatOri]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
        if (sortingColumnCat !== -1) {  
            rows.sort((a, b) => {
                const valA = a[Object.keys(a)[sortingColumnCat]]; const valB = b[Object.keys(b)[sortingColumnCat]];

                if (typeof valA === 'number' && typeof valB === 'number') {
                    return valA - valB;
                } else if (isValidDate(valA) && isValidDate(valB)) {
                    const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
                } else { return valA.localeCompare(valB);}
            });

            if (sortDirectionCat === 'desc') { rows.reverse();}
            setEquinoDatosCat(rows); // Actualizamos el estado de sortedRows con los datos ordenados
        }
    }, [sortingColumnCat, sortDirectionCat, equinoDatosCatOri]); // Dependencias: sortingColumn, sortDirection, datosOriginales  
    /************ FIN funciones orden array (STOCK CATEGORIA) *************************************** */

    /********** Funciones Modal 'potrerosModal' *********/
    useEffect(() => {
        const modalElement = document.querySelector('#potrerosModal');
        modalElement.addEventListener('shown.bs.modal', onModalShowPotreros);
        return () => {
        modalElement.removeEventListener('shown.bs.modal', onModalShowPotreros);
        };
    }, []);

    const onModalShowPotreros = () => {
    }

    const SetPotreros = (v_id_predio, v_n_predio) =>{
        setN_predio(v_n_predio);
        getEquinoDatosPotreros(v_id_predio);
        const modal = new bootstrap.Modal(modalPotreros.current);
        modal.show();
    };

    let total_Eq_23 = 0, total_Eq_24 = 0, total_Eq_25 = 0, total_Eq_26 = 0, total_Eq_27 = 0, total_stock = 0;
    let modal_total_Eq_23 = 0, modal_total_Eq_24 = 0, modal_total_Eq_25 = 0, modal_total_Eq_26 = 0, modal_total_Eq_27 = 0, modal_total_stock = 0;

    //______________________________________________
    //_____________ useEffect PRINCIPAL ____________
    //______________________________________________
    useEffect(() => {
        getDatosCategoriasXEspecie();
        
        getEquinoDatosPredios();
        getEquinoChart();

        getEquinoChartCat();
        getEquinoDatosCategorias();
        // eslint-disable-next-line
    }, []);

    return (
        
        <div className="tab-pane fade" id="nav-equino" role="tabpanel" aria-labelledby="nav-equino-tab">
            <div className="row pt-2">
                <div className="col-12 col-lg-6 pl-2">
                    <fieldset className="border p-2 mb-2 border-secondary">
                    {equinoDatosPredios ? 
                        (
                        <small>
                        <h6>Cantidad de Equinos por predio</h6>
                        <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
                        <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                            <thead>
                            <tr>
                                <th onClick={() => handleColumnClickPredio(1)} className={sortingColumnPredio === 1 ? `sorting ${sortDirectionPredio}` : ''}>Predio</th>
                                <th className='text-end'>{obtenerSiglaPorId(23)}</th>
                                <th className='text-end'>{obtenerSiglaPorId(24)}</th>
                                <th className='text-end'>{obtenerSiglaPorId(25)}</th>
                                <th className='text-end'>{obtenerSiglaPorId(26)}</th>
                                <th className='text-end'>{obtenerSiglaPorId(27)}</th>
                                <th className='text-end'>Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {equinoDatosPredios.map(dato => {
                                total_Eq_23+=parseInt(dato.Eq_23);
                                total_Eq_24+=parseInt(dato.Eq_24);
                                total_Eq_25+=parseInt(dato.Eq_25);
                                total_Eq_26+=parseInt(dato.Eq_26);
                                total_Eq_27+=parseInt(dato.Eq_27);
                                total_stock+=parseInt(dato.stock);
                                
                                return (
                                <tr 
                                    onMouseOver={(e) => e.currentTarget.style.cursor = 'pointer'}
                                    onMouseOut={(e) => e.currentTarget.style.cursor = 'default'}
                                    title="Ver Stock por Potreros"
                                    onClick={() => SetPotreros(dato.id_predio, dato.n_predio)}
                                    key={dato.id_predio}>
                                    <td>{dato.n_corto}</td>
                                    <td className='text-end'>{dato.Eq_23 > 0 ? parseInt(dato.Eq_23).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{dato.Eq_24 > 0 ? parseInt(dato.Eq_24).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{dato.Eq_25 > 0 ? parseInt(dato.Eq_25).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{dato.Eq_26 > 0 ? parseInt(dato.Eq_26).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{dato.Eq_27 > 0 ? parseInt(dato.Eq_27).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{dato.stock > 0 ? parseInt(dato.stock).toLocaleString('es-CL') : ''}</td>
                                </tr>
                                );
                            })}
                                <tr>
                                    <td>Totales</td>
                                    <td className='text-end'>{total_Eq_23 > 0 ? parseInt(total_Eq_23).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{total_Eq_24 > 0 ? parseInt(total_Eq_24).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{total_Eq_25 > 0 ? parseInt(total_Eq_25).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{total_Eq_26 > 0 ? parseInt(total_Eq_26).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{total_Eq_27 > 0 ? parseInt(total_Eq_27).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{total_stock > 0 ? parseInt(total_stock).toLocaleString('es-CL') : ''}</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                        </small>
                        ) : (
                        <div className="text-center">
                            <br/><br/>
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Cargando datos...</span>
                            </div>
                            <p>Cargando cantidad de equinos por predio...</p>
                        </div>
                        )
                    }
                    </fieldset>
                </div>
                <div className="col-12 col-lg-6" style={{border: '0px solid white'}}>
                    <fieldset className="border p-2 mb-2 border-secondary">
                        <div className="d-flex justify-content-center align-items-center" style={{ position: 'relative'}}>
                            {equinoChart ? (
                                    <Pie data={equinoChart} options={options} plugins={[ChartDataLabels]}/>
                                ) : (
                                    <div className="text-center">
                                        <br/><br/>
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Cargando gráfico...</span>
                                        </div>
                                        <p>Cargando gráfico...</p>
                                    </div>
                                )
                            }
                            {equinoChart && 
                                <div className="text-center" style={{ position: 'absolute', width: '100%', top: '8%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                                    <h5>Distribución Equino Activo - Predios</h5>
                                    <h6>Cantidad de animales por predio</h6>
                                </div>
                            }
                        </div>
                    </fieldset>
                </div>
            </div>

            <div className="row pt-2">
                <div className="col-12 col-lg-6 pl-2">
                    <fieldset className="border p-2 mb-2 border-secondary">
                    {equinoDatosCat ? 
                        (
                        <small>
                        <h6>Cantidad de Equinos por Categoría</h6>
                        <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
                        <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                            <thead>
                            <tr>
                                <th onClick={() => handleColumnClickCat(1)} className={sortingColumnCat === 1 ? `sorting ${sortDirectionCat}` : ''}>Categoría</th>
                                <th 
                                    onClick={() => handleColumnClickCat(2)} 
                                    className={`${sortingColumnCat === 2 ? `sorting ${sortDirectionCat}` : ''} text-end`}
                                >Stock</th>
                            </tr>
                            </thead>
                            <tbody>
                            {equinoDatosCat.map((tupla, index) => {
                                // const fila = index + 1;
                                return (
                                <tr key={tupla.id_calculo}>
                                    <td>{tupla.categoria} ({tupla.sigla})</td>
                                    <td className="text-end">{parseInt(tupla.stock).toLocaleString('es-CL')}</td>
                                </tr>
                                );
                            })}
                            </tbody>
                        </table>
                        </div>
                        </small>
                        ) : (
                        <div className="text-center">
                            <br/><br/>
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Cargando datos...</span>
                            </div>
                            <p>Cargando cantidad de equinos por categoría...</p>
                        </div>
                        )
                    }
                    </fieldset>
                </div>
                <div className="col-12 col-lg-6" style={{border: '0px solid white'}}>
                    <fieldset className="border p-2 mb-2 border-secondary">
                        <div className="d-flex justify-content-center align-items-center" style={{ position: 'relative'}}>
                            {equinoChartCat ? (
                                    <Pie data={equinoChartCat} options={options} plugins={[ChartDataLabels]}/>
                                ) : (
                                    <div className="text-center">
                                        <br/><br/>
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Cargando gráfico...</span>
                                        </div>
                                        <p>Cargando gráfico...</p>
                                    </div>
                                )
                            }
                            {equinoChartCat && 
                                <div className="text-center" style={{ position: 'absolute', width: '100%', top: '8%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                                    <h5>Distribución Equino Activo - Categorías</h5>
                                    <h6>Cantidad de animales por categoría</h6>
                                </div>
                            }
                        </div>
                    </fieldset>
                </div>
            </div>

            <div className="modal fade" id="potrerosModal" ref={modalPotreros} 
                tabIndex="-1" aria-labelledby="potrerosModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg border border-secondary">
                    <div className={`modal-content ${darkMode ? 'text-light bg-dark' : ''}`}>
                        <div className="modal-header border-bottom border-secondary">
                            <h5 className="modal-title" id="potrerosModalLabel">Stock Categorías por Potreros</h5>
                            <button 
                                type="button" 
                                className={`btn-close ${darkMode ? 'bg-btn-close-modal': ''}`}
                                data-bs-dismiss="modal" aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row pt-2">
                                <label className="col-sm-2">Predio:</label>
                                <div className="col-sm-10">
                                    <input type="text" name="n_predio" value={n_predio} 
                                    className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                                    readOnly/>
                                </div>
                            </div>

                            {isLoadingPotreros &&  
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Cargando datos...</span>
                                    </div>
                                </div>
                            }
                            {!isLoadingPotreros &&  
                            <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
                                <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                                    <thead>
                                    <tr>
                                        <th onClick={() => handleColumnClickPotrero(1)} className={sortingColumnPotrero === 1 ? `sorting ${sortDirectionPotrero}` : ''}>Potrero</th>
                                        <th className='text-end'>{obtenerSiglaPorId(23)}</th>
                                        <th className='text-end'>{obtenerSiglaPorId(24)}</th>
                                        <th className='text-end'>{obtenerSiglaPorId(25)}</th>
                                        <th className='text-end'>{obtenerSiglaPorId(26)}</th>
                                        <th className='text-end'>{obtenerSiglaPorId(27)}</th>
                                        <th className='text-end'>Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {equinoDatosPotreros.map(dato => {
                                        modal_total_Eq_23+=parseInt(dato.Eq_23);
                                        modal_total_Eq_24+=parseInt(dato.Eq_24);
                                        modal_total_Eq_25+=parseInt(dato.Eq_25);
                                        modal_total_Eq_26+=parseInt(dato.Eq_26);
                                        modal_total_Eq_27+=parseInt(dato.Eq_27);
                                        modal_total_stock+=parseInt(dato.stock);
                                        return (
                                        <tr 
                                            key={dato.id_potrero}>
                                            <td>{dato.n_potrero}</td>
                                            <td className='text-end'>{dato.Eq_23 > 0 ? parseInt(dato.Eq_23).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{dato.Eq_24 > 0 ? parseInt(dato.Eq_24).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{dato.Eq_25 > 0 ? parseInt(dato.Eq_25).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{dato.Eq_26 > 0 ? parseInt(dato.Eq_26).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{dato.Eq_27 > 0 ? parseInt(dato.Eq_27).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{dato.stock > 0 ? parseInt(dato.stock).toLocaleString('es-CL') : ''}</td>
                                        </tr>
                                        );
                                    })}
                                        <tr>
                                            <td>Totales</td>
                                            <td className='text-end'>{modal_total_Eq_23 > 0 ? parseInt(modal_total_Eq_24).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{modal_total_Eq_24 > 0 ? parseInt(modal_total_Eq_27).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{modal_total_Eq_25 > 0 ? parseInt(modal_total_Eq_25).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{modal_total_Eq_26 > 0 ? parseInt(modal_total_Eq_26).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{modal_total_Eq_27 > 0 ? parseInt(modal_total_Eq_27).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{modal_total_stock > 0 ? parseInt(modal_total_stock).toLocaleString('es-CL') : ''}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            }
                        </div>
                        <div className="modal-footer border-top border-secondary">
                            <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>{/*fin modal*/}
        </div>
    );
};

export default DashEquino;
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

import { validarRUT } from '../../../utils/utils';

function EmpresasForm() {
  const { id_empresa } = useParams();
  const [icon, setIcon] = useState('');
  const [textButton, setTextButton] = useState('');
  const navigate = useNavigate();
  const [isLoadingDatos, setIsLoadingDatos] = useState(true);
  const modulo = 'empresas'

  const initialDatoState = {
    id_empresa: null,
    n_empresa: '', 
    rut: '', 
    direccion:'',
    telefono:'',
    contacto_nombre:'',
    contacto_email:'',
    contacto_telefono:'',
    proveedor:false,
    comprador:false
  };
  const [dato, setDato] = useState(initialDatoState);

  useEffect(() => {
    if (id_empresa) {
      getDato(id_empresa);
      setIcon('fas fa-arrow-left');
      setTextButton('Volver');
    }
    else {
      setIcon('fas fa-ban');
      setTextButton('Cancelar');
      setIsLoadingDatos(false);
    }
    // eslint-disable-next-line
  }, [id_empresa]);
  
  const getDato = (id_empresa) => {
    setIsLoadingDatos(true);
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id_empresa}`,{withCredentials: true})
      .then(response => {
        setDato(response.data);
        setIsLoadingDatos(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDato({ ...dato, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    setDato({ ...dato, [ event.target.name]: event.target.checked });
  };

  const enviarFormulario = (e) => {
    e.preventDefault();

    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }

    if (!validarRUT(dato.rut)) {
      toast.error('Formato de rut incorrecto', {
        position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
        pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
      });
    }
    else
    {
      setDato({ ...dato, 'rut': dato.rut.replace(/\./g, "") });
      var data = {
          n_empresa: dato.n_empresa, 
          rut: dato.rut.replace(/\./g, ""), 
          direccion: dato.direccion, 
          telefono: dato.telefono, 
          contacto_nombre: dato.contacto_nombre, 
          contacto_email: dato.contacto_email, 
          contacto_telefono: dato.contacto_telefono,
          proveedor : dato.proveedor, 
          comprador : dato.comprador, 
          usuario : localStorage.getItem("usuario")
      };

      if (dato.id_empresa) {
        axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${dato.id_empresa}`, data,{withCredentials: true})
        .then(response => {
          toast.success('¡Registro actualizado correctamente!', {
            position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
        })
        .catch(error => {
          if (error.response.status === 401){
            delete axios.defaults.headers.common["Authorization"]; 
            localStorage.removeItem("token"); 
            localStorage.removeItem("usuario");
            navigate('/login');
          } else {
            toast.error(error.response.data, {
              position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        });
      } else {
        axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`, data,{withCredentials: true})
        .then(response => {
          toast.success("Registro creado correctamente", {
            position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });

          navigate(`/${modulo}/${response.data.id_empresa}`);
        })
        .catch(error => {
          if (error.response.status === 401){
            delete axios.defaults.headers.common["Authorization"]; 
            localStorage.removeItem("token"); 
            localStorage.removeItem("usuario");
            navigate('/login');
          } else {
            toast.error(error.response.data, {
              position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        });
      }
    }
  };

  return (
    <div className="row justify-content-center w-100">
      <div className="col-10 col-xs-8 col-md-8 col-lg-6  col-xl-4">
      {isLoadingDatos &&  
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Cargando datos...</span>
          </div>
        </div>
      }
      {!isLoadingDatos &&  
        <form onSubmit={enviarFormulario}>
          <div className="form-group">
              <label>Razón Social:</label>
              <input type="text" name="n_empresa" autoFocus  
              value={dato.n_empresa} onChange={handleInputChange} 
              className="form-control form-control-sm" required/>
          </div>

          <div className="form-group">
              <label>Rut:</label>
              <input type="text" name="rut" maxlenght="10"
              value={dato.rut} onChange={handleInputChange} 
              className="form-control form-control-sm" required/>
          </div>

          <div className="form-group">
              <label>Dirección:</label>
              <input type="text" name="direccion" 
              value={dato.direccion} onChange={handleInputChange} 
              className="form-control form-control-sm" required/>
          </div>

          <div className="form-group">
              <label>Teléfono:</label>
              <input type="number" name="telefono" 
              value={dato.telefono} onChange={handleInputChange} 
              className="form-control form-control-sm" required/>
          </div>

          <div className="form-group">
              <label>Nombre contacto:</label>
              <input type="text" name="contacto_nombre" 
              value={dato.contacto_nombre} onChange={handleInputChange} 
              className="form-control form-control-sm" required/>
          </div>

          <div className="form-group">
              <label>Email contacto:</label>
              <input type="email" name="contacto_email" 
              value={dato.contacto_email} onChange={handleInputChange} 
              className="form-control form-control-sm" required/>
          </div>

          <div className="form-group">
              <label>Teléfono contacto:</label>
              <input type="number" name="contacto_telefono" 
              value={dato.contacto_telefono} onChange={handleInputChange} 
              className="form-control form-control-sm" required/>
          </div>

          <div className="form-check">
            <input className="form-check-input" onChange={handleCheckboxChange} name="proveedor" 
            type="checkbox" id="checkProveedor" checked={dato.proveedor}/>
            <label className="form-check-label" htmlFor="checkProveedor">
              Proveedor
            </label>
          </div>

          <div className="form-check">
            <input className="form-check-input" onChange={handleCheckboxChange} name="comprador" 
            type="checkbox" id="checkComprador" checked={dato.comprador}/>
            <label className="form-check-label" htmlFor="checkComprador">
              Comprador
            </label>
          </div>

          <hr/>
          <div className="pt-1">
            <button type="submit" className="btn btn-success btn-sm">
              <i className="fas fa-check"></i> Guardar
            </button>
            &nbsp;
            <Link to={`/${modulo}`} className="btn btn-secondary btn-sm">
              <i className={icon} title="Cancelar"></i> {textButton}
            </Link>
          </div>
        </form>
      }
      </div>
    </div>
  );
}

export default EmpresasForm;
import React, {  useRef, useState, useEffect, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { isValidDate } from '../../utils/utils';
import { useDarkMode } from '../../context/darkModeContext'; 

function ProductosForm() {
  // eslint-disable-next-line
  const {darkMode, setDarkMode} = useDarkMode();
  const inputproducto     = useRef(null);
  const inputTipoProducto = useRef(null);
  const inputMarca        = useRef(null);
  const inputMetodo       = useRef(null);
  const inputUnidadmedida = useRef(null);
  const inputEspecie      = useRef(null);
  const inputCantidad     = useRef(null);
  const inputTipoperiodo  = useRef(null);
  const modalAddCategoria = useRef(null);

  const bootstrap = window.bootstrap; // se agregó para poder llamar al método hide() de un modal
  
  const { id_producto } = useParams();
  const [icon, setIcon] = useState('');
  const [textButton, setTextButton] = useState('');

  const [datosCantRecomendadaOriginales, setDatosCantRecomendadaOriginales] = useState([]); // Estado para almacenar los datos originales
  const [datosCantRecomendada, setDatosCantRecomendada]                     = useState([]);
  const [sortingColumn, setSortingColumn]                                   = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
  const [sortDirection, setSortDirection]                                   = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

  const [categoriasOriginales, setCategoriasOriginales] = useState([]);
  const [categorias, setCategorias]                     = useState([]);
  const [sortingColumnModal, setSortingColumnModal]     = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
  const [sortDirectionModal, setSortDirectionModal]     = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

  const [isLoadingCantRecomendada, setIsLoadingCantRecomendada] = useState(false);
  const [isLoadingCatXEspecie, setIsLoadingCatXEspecie]         = useState(false);
  const [isLoadingDatosForm, setIsLoadingDatosForm]             = useState(true);

  // estado para la bandera que indica si el valor ha cambiado de cantidad en la lista de cantidades recomendadas
  const [valorCambiado, setValorCambiado] = useState(false);

  const modulo = 'productos'

  const navigate = useNavigate();

  const initialDatoState = {
    id_producto       : null,
    n_producto        : '',
    id_tipoproducto   : '', 
    id_marca          : '',
    id_unidadmedida   : '',
    id_metodo         : '', 
    observaciones     : '',
    n_componente      : '',
    periodo_resguardo : '',
    tipo_periodo      : ''
  };
  const [dato, setDato]                     = useState(initialDatoState);
  const [especies, setEspecies]             = useState([]);
  const [tiposproductos, setTiposproductos] = useState([]);
  const [marcas, setMarcas]                 = useState([]);
  const [metodos, setMetodos]               = useState([]);
  const [unidadesmedida, setUnidadesmedida] = useState([]);
  const [muestraFormMed, setMuestraFormMed  ]= useState(false);

  const [stock, setStock]= useState('');

  const getDato = async (id_producto) => {
    setIsLoadingDatosForm(true);
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id_producto}`,{withCredentials: true});
      setDato(response.data);
      setIsLoadingDatosForm(false);
    } catch(error){ 
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    }
  };

  const getTiposproductos = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/tiposproductos`,{withCredentials: true});
      setTiposproductos(response.data);
    } catch(error) { 
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    }
  };

  const getMarcas = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/marcas`,{withCredentials: true})
      setMarcas(response.data);
    } catch(error) {
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    }
  };

  const getMetodos = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/metodos`,{withCredentials: true});
      setMetodos(response.data);
    } catch(error){ 
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    }
  };

  const getUnidadesmedida = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/unidadesmedida`,{withCredentials: true});
      setUnidadesmedida(response.data);
    } catch(error){ 
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    }
  };

  const getCategorias_X_Especie = useCallback(async (id_especie) => {
    setIsLoadingCatXEspecie(true);
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/categoriasxespecie/${id_especie}/${id_producto}`,{withCredentials: true});
      setCategoriasOriginales(response.data);
      setCategorias(response.data);
      setIsLoadingCatXEspecie(false);
    } catch(error){ 
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    }
    // eslint-disable-next-line
  },[id_producto]);

  const getCantRecomendada = async () => {
    setIsLoadingCantRecomendada(true);
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_cantrecomendada/${id_producto}/${dato.id_unidadmedida}`,{withCredentials: true});
      setDatosCantRecomendadaOriginales(response.data);
      setDatosCantRecomendada(response.data);
      setIsLoadingCantRecomendada(false);
    } catch(error) { 
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    }
  };

  const getEspecies = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/especies`,{withCredentials: true});
      setEspecies(response.data);
    } catch(error) { 
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    }
  };

  const getStock = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_stock/${id_producto}`,{withCredentials: true});
      setStock(response.data.stock);
    } catch(error) { 
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    }
  };

  useEffect(() => {//Principal
    getTiposproductos();
    getMarcas();
    getMetodos();
    getUnidadesmedida();
    getEspecies();
    if (id_producto) {
      getDato(id_producto);
      setIcon('fas fa-arrow-left');
      setTextButton('Volver');
      getStock();
    }
    else {
      setIcon('fas fa-ban');
      setTextButton('Cancelar');
      setIsLoadingDatosForm(false);
    }
    //eslint-disable-next-line
  }, [id_producto]);

  useEffect(() => {
    if (especies.length > 0 && inputUnidadmedida.current) {
      getCategorias_X_Especie(inputEspecie.current.selectedOptions[0].value);
    }
    //eslint-disable-next-line
  }, [especies]); //Este se ejecuta cuando se carga completo el arreglo

  useEffect(() => {
    if(id_producto){
      if (especies.length > 0 && unidadesmedida.length > 0 && inputUnidadmedida.current && dato.id_unidadmedida !== '') {
        getCantRecomendada(inputEspecie.current.selectedOptions[0].value);
      }
    }
    //eslint-disable-next-line
  }, [unidadesmedida, dato.id_unidadmedida, id_producto]); //Este se ejecuta cuando se carga completo el arreglo

  useEffect(() =>{
    if(dato.id_tipoproducto.toString() === "1" || dato.id_tipoproducto.toString() === "2"){
      setMuestraFormMed(true)
    } else {
      setMuestraFormMed(false)
    }
  }, [dato.id_tipoproducto]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDato({ ...dato, [name]: value });
  };

  const handleEspecieChange = (event) => {
    getCategorias_X_Especie(event.target.value);
  };

  const enviarFormulario = (e) => {
    e.preventDefault();

    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }

    let v_tipo_periodo = '';
    if(inputTipoProducto.current.value === "1"){
      v_tipo_periodo = inputTipoperiodo.current.value;
    }

    var data = {
      id_producto       : dato.id_producto,
      n_producto        : dato.n_producto,
      id_tipoproducto   : inputTipoProducto.current.value, 
      id_marca          : inputMarca.current.value,
      id_unidadmedida   : inputUnidadmedida.current.value,
      id_metodo         : inputMetodo.current.value,
      observaciones     : dato.observaciones,
      n_componente      : dato.n_componente,
      periodo_resguardo : dato.periodo_resguardo,
      tipo_periodo      : v_tipo_periodo, 
      usuario : localStorage.getItem("usuario")
    };

    if (dato.id_producto) {
      axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${dato.id_producto}`, data,{withCredentials: true})
      .then(response => {
        toast.success('¡Registro actualizado correctamente!', {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      })
      .catch(error => {
        if (error.response.status === 401){
          delete axios.defaults.headers.common["Authorization"]; 
          localStorage.removeItem("token"); 
          localStorage.removeItem("usuario");
          navigate('/login');
        } else {
          toast.error(error.response.data, {
            position: "top-right", autoClose: 2000, hideProgressBar: true,
            closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
        }
      });
    } else {
      axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`, data,{withCredentials: true})
      .then(response => {
        toast.success("Registro creado correctamente", {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });

        navigate(`/${modulo}/${response.data.id_producto}`);
      })
      .catch(error => {
        if (error.response.status === 401){
          delete axios.defaults.headers.common["Authorization"]; 
          localStorage.removeItem("token"); 
          localStorage.removeItem("usuario");
          navigate('/login');
        } else {
          toast.error(error.response.data, {
            position: "top-right", autoClose: 2000, hideProgressBar: true,
            closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
        }
      });
    }
  };

  const handleDelete = (id_cant_recomendada) => {
    Swal.fire({
      title: 'Eliminar registro', text: '¿Estás seguro que deseas eliminar este registro?',
      icon: 'warning', showCancelButton: true, confirmButtonColor: '#d33', cancelButtonColor: '#666',
      confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar',
    }).then((result) => {
      // Si se confirma la eliminación, hacer la petición a la API para eliminar el elemento
      if (result.isConfirmed) 
      {
        axios.delete(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_cantrecomendada/${id_cant_recomendada}`,{withCredentials: true}).then(response =>
        {
          if (response.data) {
            toast.success('¡Registro eliminado correctamente!', {
              position: "top-right", autoClose: 2000, hid_productoeProgressBar: true,
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });

            setDatosCantRecomendada(datosCantRecomendada.filter(datosCantRecomendada => datosCantRecomendada.id_cant_recomendada !== id_cant_recomendada)); //Quitar del arreglo la fila del id_producto eliminado
          } else {
            toast.error('¡No se pudo eliminar el registro!', {
              position: "top-right", autoClose: 2000, hid_productoeProgressBar: true, 
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        });
      }
    });
  };

  const AddCategoria = (id_categoria) => {
    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }
    
    let cant = inputCantidad.current.value

    if (/^\d+$/.test(cant)){
      //Number.isInteger(parseFloat(cant)) valida si es entero

      if ( parseInt(cant) > 0 && parseFloat(cant) <= 99999 ) {
        var data = {
          id_producto   : id_producto,
          id_especie    : inputEspecie.current.selectedOptions[0].value,
          id_categoria  : id_categoria,
          cantidad      : cant, 
          usuario : localStorage.getItem("usuario")
        };
        
        axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_cantrecomendada`, data,{withCredentials: true}).then(response => {
          toast.success("Registro creado correctamente", {
            position: "top-right", autoClose: 2000, hideProgressBar: true,
            closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
          
          //------------
          const modalElement = modalAddCategoria.current;
          if (modalElement) {
            const bootstrapModal = bootstrap.Modal.getInstance(modalElement);
            bootstrapModal.hide();
          }
          //-----------
          
          getCantRecomendada();
        })
        .catch(error => {
          if (error.response.status === 401){
            delete axios.defaults.headers.common["Authorization"]; 
            localStorage.removeItem("token"); 
            localStorage.removeItem("usuario");
            navigate('/login');
          } else {
            toast.error(error.response.data, {
              position: "top-right", autoClose: 2000, hideProgressBar: true,
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        });


      } else{
        toast.error('Cantidad es un valor entre 1 y 99999', {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
        inputCantidad.current.focus();  
      }
    } else {
      toast.error('Cantidad es un valor numérico', {
        position: "top-right", autoClose: 2000, hideProgressBar: true,
        closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
      });
      inputCantidad.current.focus();
    }
  }

  //Para agregar el addEventListener al modal cuando se dispare el Show 
  const onModalShow = useCallback(() => {
    getCategorias_X_Especie(inputEspecie.current.selectedOptions[0].value);
  },[getCategorias_X_Especie]);

  useEffect(() => {
    const modalElement = document.querySelector('#addCategoriaModal');
    modalElement.addEventListener('show.bs.modal', onModalShow);
    return () => {
      modalElement.removeEventListener('show.bs.modal', onModalShow);
    };
  }, [onModalShow]);

  const handleKeyPressCantidad = (event) => {
    if (event.key === 'Enter') {
      event.target.blur(); // desenfocar el input si se presiona Enter
    }

    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleInputChangeCantidad = (event, id_cant_recomendada) => {
    setValorCambiado(true); // marcar la bandera como verdadera --> el onchange siempre se activa si hubo un cambio en el input text
    setDatosCantRecomendada(prevState => {
      return prevState.map(tupla => {
        if (tupla.id_cant_recomendada === id_cant_recomendada) {
          return {...tupla, cantidad: event.target.value};
        }
        return tupla;
      });
    });
  }

  const handleBlurCantidad = (event, id_cant_recomendada) => {
    let cant = event.target.value;
    
    if (valorCambiado) {
      if (/^\d+$/.test(cant)){

        if ( parseInt(cant) > 0 && parseFloat(cant) <= 99999 ) {
          var data = {
            cantidad : cant
          };
          
          axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_cantrecomendada/${id_cant_recomendada}`, data,{withCredentials: true}).then(response => {
            toast.success("Registro actualizado correctamente", {
              position: "top-right", autoClose: 2000, hideProgressBar: true,
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          })
          .catch(error => {
            if (error.response.status === 401){
              delete axios.defaults.headers.common["Authorization"]; 
              localStorage.removeItem("token"); 
              localStorage.removeItem("usuario");
              navigate('/login');
            } else {
              toast.error(error.response.data, {
                position: "top-right", autoClose: 2000, hideProgressBar: true,
                closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
              });
            }
          });
        } else{
          toast.error('Cantidad es un valor entre 1 y 99999', {
            position: "top-right", autoClose: 2000, hideProgressBar: true,
            closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
        }
      } else {
        toast.error('Cantidad es un valor numérico', {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      }
      setValorCambiado(false); // resetear la bandera a falso
    }
  };

  /************ INICIO FUNCIONES ORDEN ARRAY (CATEGORIAS) *************************************** */
  const handleColumnClickModal = (columnIndex) => {
    if (sortingColumnModal === columnIndex) { setSortDirectionModal(sortDirectionModal === 'asc' ? 'desc' : 'asc');
    } else { setSortingColumnModal(columnIndex); setSortDirectionModal('asc');}
  };
  useEffect(() => {    
    const rows = [...categoriasOriginales]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
    if (sortingColumnModal !== -1) {  
      rows.sort((a, b) => {
        const valA = a[Object.keys(a)[sortingColumnModal]]; const valB = b[Object.keys(b)[sortingColumnModal]];

        if (typeof valA === 'number' && typeof valB === 'number') { return valA - valB;
        } else if (isValidDate(valA) && isValidDate(valB)) { const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
        } else { return valA.localeCompare(valB); }
      });
  
      if (sortDirectionModal === 'desc') { rows.reverse(); }
      setCategorias(rows); // Actualizamos el estado de sortedRows con los datos ordenados
    }
  }, [sortingColumnModal, sortDirectionModal, categoriasOriginales]); // Dependencias: sortingColumn, sortDirection, datosOriginales
  /************ FIN FUNCIONES ORDEN ARRAY (CATEGORIAS) *************************************** */

  /************ INICIO FUNCIONES ORDEN ARRAY (CANT RECOMENDADA) *************************************** */
  const handleColumnClick = (columnIndex) => {
    if (sortingColumn === columnIndex) { setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else { setSortingColumn(columnIndex); setSortDirection('asc');}
  };
  useEffect(() => {    
    const rows = [...datosCantRecomendadaOriginales]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
    if (sortingColumn !== -1) {  
      rows.sort((a, b) => {
        const valA = a[Object.keys(a)[sortingColumn]]; const valB = b[Object.keys(b)[sortingColumn]];

        if (typeof valA === 'number' && typeof valB === 'number') { return valA - valB;
        } else if (isValidDate(valA) && isValidDate(valB)) { const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
        } else { return valA.localeCompare(valB); }
      });
  
      if (sortDirection === 'desc') { rows.reverse();}
      setDatosCantRecomendada(rows); // Actualizamos el estado de sortedRows con los datos ordenados
    }
  }, [sortingColumn, sortDirection, datosCantRecomendadaOriginales]); // Dependencias: sortingColumn, sortDirection, datosOriginales
  /************ FIN FUNCIONES ORDEN ARRAY (CANT RECOMENDADA) *************************************** */

  return (
    <div className="row justify-content-center w-100">
      <div className="col-12 col-xs-12 col-md-12 col-lg-10 col-xl-8">
      {isLoadingDatosForm &&  
        <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Cargando datos...</span>
            </div>
        </div>
      }
      {!isLoadingDatosForm &&    
        <form onSubmit={enviarFormulario}>
          <small>
          <div className="row pt-2">
            <div className="col text-end">
              <button type="submit" className="btn btn-success btn-sm">
                <i className="fas fa-check"></i> Guardar
              </button>
              &nbsp;
              <Link to={`/${modulo}`} className="btn btn-secondary btn-sm">
                <i className={icon} title="Cancelar"></i> {textButton}
              </Link>
            </div>
          </div>

          <div className="form-group">
              <label>Nombre:</label>
              <input type="text" name="n_producto" value={dato.n_producto} ref={inputproducto} autoFocus 
              onChange={handleInputChange} className="form-control form-control-sm" required/>
          </div>

          <div className="form-group">
            <label>Tipo:</label>
            <select className="form-select form-select-sm" name="id_tipoproducto" ref={inputTipoProducto} 
            value={dato.id_tipoproducto} onChange={(event) => { handleInputChange(event); }} required>
              {tiposproductos.map((opcion) => (
                <option key={opcion.id_tipoproducto} value={opcion.id_tipoproducto}>{opcion.n_tipoproducto}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Marca:</label>
            <select className="form-select form-select-sm" name="id_marca" ref={inputMarca} 
            value={dato.id_marca} onChange={handleInputChange} required>
              {marcas.map((opcion) => (
                <option key={opcion.id_marca} value={opcion.id_marca}>{opcion.n_marca}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Método:</label>
            <select className="form-select form-select-sm" name="id_metodo" ref={inputMetodo} 
            value={dato.id_metodo} onChange={handleInputChange} required>
              {metodos.map((opcion) => (
                <option key={opcion.id_metodo} value={opcion.id_metodo}>{opcion.n_metodo}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Unidad Medida (de uso):</label>
            <select className="form-select form-select-sm" name="id_unidadmedida" ref={inputUnidadmedida} 
            value={dato.id_unidadmedida} onChange={handleInputChange} required>
              {unidadesmedida.map((opcion) => (
                <option key={opcion.id_unidadmedida} value={opcion.id_unidadmedida}>{opcion.n_unidadmedida}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Observaciones:</label>
            <textarea 
              className="form-control" 
              style={{
                resize: 'none',
                overflow: 'auto'
              }}
              placeholder="" 
              name="observaciones" 
              defaultValue={dato.observaciones} 
              onChange={handleInputChange} 
              required></textarea>
          </div>
          {id_producto && 
          <div className="form-group">
            <label>Stock:</label>
            <input type="text" name="stock" value={stock} 
            className={`form-control form-control-sm ${darkMode ? 'bg-dark' : ''}`}
            readOnly/>
          </div>
          } 
          
          {muestraFormMed && (
          <div className="row">
            <div className="form-group">
              <label>Componente:</label>
              <input type="text" name="n_componente" value={dato.n_componente}  
              onChange={handleInputChange} className="form-control form-control-sm" required/>
            </div>
            
            <div className="form-group row pt-1">
              <label className="col-sm-5">Periodo resguardo:</label>
              <div className="col-sm-3">
                <input type="text" name="periodo_resguardo" value={dato.periodo_resguardo}  
                onChange={handleInputChange} className="form-control form-control-sm" required/>
              </div>
              <div className="col-sm-4">
                <select className="form-select form-select-sm" name="tipo_periodo" ref={inputTipoperiodo} 
                  value={dato.tipo_periodo} onChange={handleInputChange} required>
                  <option key="1" value="d">Dias</option>
                  <option key="2" value="m">Meses</option>
                  <option key="3" value="y">Años</option>
                </select>
              </div>
            </div>

            <hr></hr>
            <p><small>Cantidad recomendada por especie y categoría</small></p>
            <div>
              <button type="button" className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#addCategoriaModal">
                <i className="fa fa-plus"></i>&nbsp;Agregar categoría
              </button>
              {isLoadingCantRecomendada &&  
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Cargando cantidad recomendada por categorías...</span>
                  </div>
                </div>
              }
              {!isLoadingCantRecomendada &&  
              <div className="table-responsive" style={{ maxHeight: '250px)' }}>
                <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                  <thead className="sticky-top">
                    <tr>
                      <th onClick={() => handleColumnClick(1)} className={sortingColumn === 1 ? `sorting ${sortDirection}` : ''}>Especie</th>
                      <th onClick={() => handleColumnClick(2)} className={sortingColumn === 2 ? `sorting ${sortDirection}` : ''}>Categoría</th>
                      <th onClick={() => handleColumnClick(3)} className={sortingColumn === 3 ? `sorting ${sortDirection}` : ''}>Cantidad</th>
                      <th>U.Med. Uso</th>
                      <th className="text-center">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {datosCantRecomendada.map(tupla => (
                      <tr key={tupla.id_cant_recomendada}>
                        <td>{tupla.n_especie}</td>
                        <td>{tupla.n_categoria}</td>
                        <td>
                          <input style={{'width':'100px'}} value={tupla.cantidad} 
                            type="text" className="form-control form-control-sm" 
                            id={"cantidad" + tupla.id_cant_recomendada} 
                            maxLength="5" 
                            onChange={(event) => handleInputChangeCantidad(event, tupla.id_cant_recomendada)}
                            onBlur={(event) => handleBlurCantidad(event, tupla.id_cant_recomendada)}
                            onKeyDown={handleKeyPressCantidad}/>
                        </td>
                        <td>{tupla.n_unidadmedida}</td>
                        <td className="text-center">
                          <button type="button" onClick={() => handleDelete(tupla.id_cant_recomendada)} className="btn btn-sm btn-outline-secondary">
                            <i className="fa fa-trash-alt" title="Eliminar"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              }
            </div>
          </div>
          )}
          </small>
        </form>
        }
      </div>

      <div className="modal fade" id="addCategoriaModal" ref={modalAddCategoria} 
        tabIndex="-1" aria-labelledby="addCategoriaModalLabel" aria-hidden="true">
        <div 
          className="modal-dialog modal-dialog-scrollable border border-secondary"
          style={{ maxHeight: '500px' }}>
          <div className={`modal-content ${darkMode ? 'text-light bg-dark' : ''}`}>
            <div className="modal-header border-bottom border-secondary">
              <h5 className="modal-title" id="addCategoriaModalLabel">Agregar categoría</h5>
              <button 
                type="button" 
                className={`btn-close ${darkMode ? 'bg-btn-close-modal': ''}`}
                data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div className="modal-body">

              <div className="row">
                <label className="col-3 col-sm-2 col-form-label">Especie:</label>
                <div className="col-9 col-sm-10">
                  <select className="form-select form-select-sm" name="id_especie" ref={inputEspecie} 
                  onChange={handleEspecieChange}>
                    {especies.map((opcion) => (
                      <option key={opcion.id_especie} value={opcion.id_especie}>{opcion.n_especie}</option>
                    ))}
                  </select>
                </div>
              </div>
              {isLoadingCatXEspecie &&  
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Cargando categorías por especie...</span>
                  </div>
                </div>
              }
              {!isLoadingCatXEspecie &&  
              <div className="table-responsive" style={{ maxHeight: '280px' }}>
                <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                  <thead className="sticky-top">
                    <tr>
                      <th onClick={() => handleColumnClickModal(1)} className={sortingColumnModal === 1 ? `sorting ${sortDirectionModal}` : ''}>Categoría</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categorias.map(tupla => (
                      <tr key={tupla.id_categoria}>
                        <td>{tupla.n_categoria}</td>
                        <td>
                          <button 
                            onClick={() => AddCategoria(tupla.id_categoria)}
                            className="btn btn-sm btn-outline-secondary">
                            <i className="fa fa-plus" title="Agregar categoría"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              }
            </div>
            <div className="modal-footer border-top border-secondary">
              <div className="row">
                <div className="col-2">
                <label htmlFor="cantidad" className="col-form-label">Cantidad:</label>
                </div>
                <div className="col-4">
                  <input maxLength="30" 
                  id="cantidad" type="text" className="form-control fomr-control-sm" 
                  onKeyDown={handleKeyPressCantidad} ref={inputCantidad}/>
                </div>
                <div className="col-6 text-end">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductosForm;
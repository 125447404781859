import React, {  useRef, useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import { useDarkMode } from '../../../context/darkModeContext'; 

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns';
import { format } from 'date-fns';
import { addDays, subDays } from 'date-fns';
import es from 'date-fns/locale/es'; // Importa el idioma español

// import { isValidDate } from '../../../utils/utils';

function MovloteForm() {
  // eslint-disable-next-line
  const {darkMode, setDarkMode} = useDarkMode();
  const navigate = useNavigate();

  const inputResponsable        = useRef(null);
  const inputEspecie            = useRef(null);
  const inputRaza               = useRef(null);

  const inputOrigenPredio       = useRef(null);
  const inputOrigenPotrero      = useRef(null);
  const inputOrigenSubpotrero   = useRef(null);
  const inputOrigenLote         = useRef(null);
  
  const inputDestinoPredio      = useRef(null);
  const inputDestinoPotrero     = useRef(null);
  const inputDestinoSubpotrero  = useRef(null);

  const inputLotecompleto       = useRef(null);
  const inputCantidad           = useRef(null);
  
  const { id_mov } = useParams();
  const [icon, setIcon] = useState('');
  const [textButton, setTextButton] = useState('');

  const modulo = 'movlote'

  
  const fechaActual = new Date(); // Obtener la fecha actual
  // Obtener el día, el mes y el año de la fecha actual
  const dia = fechaActual.getDate().toString().padStart(2, '0');
  const mes = (fechaActual.getMonth() + 1).toString().padStart(2, '0');
  const anio = fechaActual.getFullYear();
  const fechaFormateada = `${anio}-${mes}-${dia}`; // Crear la cadena con el formato "dd-mm-yyyy"

  const minSelectableDate = subDays(new Date(), 7); // Fecha actual - 2 día
  const maxSelectableDate = addDays(new Date(), 0); // Fecha actual + 1 día

  const initialDatoState = {
    id_mov                : null,
    created_date          : '',
    fecha_mov             : fechaFormateada,
    id_responsable        : '',
    id_especie            : '',
    id_raza               : '',
    categoria             : '',
    origen_id_lote        : '',
    origen_cantidad       : '',
    origen_id_predio      : '',
    origen_id_potrero     : '',
    origen_id_subpotrero  : '',
    destino_id_predio     : '',
    destino_id_potrero    : '',
    destino_id_subpotrero : '',
    lote_completo         : false,
    destino_cantidad      : ''
  };
  
  const [dato, setDato]                                   = useState(initialDatoState);
  const [datoslotesorigen, setDatoslotesorigen]           = useState([]);
  const [isLoadingDatosForm, setIsLoadingDatosForm]       = useState(true);

  const [responsables, setResponsables] = useState([]);
  
  const [prediosOrigen, setPrediosOrigen]           = useState([]);
  const [potrerosOrigen, setPotrerosOrigen]         = useState([]);
  const [subpotrerosOrigen, setSubpotrerosOrigen]   = useState([]);

  const [prediosDestino, setPrediosDestino]           = useState([]);
  const [potrerosDestino, setPotrerosDestino]         = useState([]);
  const [subpotrerosDestino, setSubpotrerosDestino]   = useState([]);

  const [especies, setEspecies] = useState([]);
  const [razas, setRazas]       = useState([]);

  useEffect(() => {
    if (id_mov) {
      getDato(id_mov);
      setIcon('fas fa-arrow-left');
      setTextButton('Volver');
    }
    else
    {
      setIcon('fas fa-ban');
      setTextButton('Cancelar');
      setIsLoadingDatosForm(false);
    }
    getResponsables();
    getEspecies();
    getPrediosOrigen();
    getPrediosDestino();
    //eslint-disable-next-line
  }, [id_mov]);
  //________________________________________________________________________________

  const getDato = async (id_mov) => {
    setIsLoadingDatosForm(true);
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id_mov}`,{withCredentials: true});
      setDato(response.data);
      setIsLoadingDatosForm(false);
    } catch(error) {
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  //=================== RESPONSABLES ===================
  const getResponsables = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/responsables`,{withCredentials: true});
      setResponsables(response.data);
    } catch(error) {
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  useEffect(() => {
    if (responsables.length > 0 && inputResponsable.current) {
      setDato(prevDato => ({...prevDato, 'id_responsable': inputResponsable.current.selectedOptions[0].value}));
    }
  }, [responsables]);

  //=================== ESPECIES ===================
  const getEspecies = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/especies`,{withCredentials: true});
      setEspecies(response.data);
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  useEffect(() => {
    if (especies.length > 0 && inputEspecie.current) {
      setDato(prevDato => ({...prevDato, 'id_especie': inputEspecie.current.selectedOptions[0].value}));
    }
  }, [especies]);

  //=================== RAZAS ===================
  const getRazas = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/razasxespecie/${dato.id_especie}`,{withCredentials: true});
      setRazas(response.data);
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  useEffect(() => {
    if (razas.length > 0 && inputRaza.current) {
      setDato(prevDato => ({...prevDato, 'id_raza': inputRaza.current.selectedOptions[0].value}));
    }
  }, [razas]);

  useEffect(() => {
    if (dato.id_especie !== '') {getRazas();}
    //eslint-disable-next-line
  }, [dato.id_especie]);

  //***************************************************************************** */
  //********************************* ORIGEN ************************************ */
  //***************************************************************************** */

  //=================== PREDIOS ORIGEN ===================
  const getPrediosOrigen = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/predios`,{withCredentials: true});
      // Filtrar el objeto con id_predio diferente de 8 (Frigorífico Rio Side)
      const filteredData = response.data.filter(item => item.id_predio !== 8);
      setPrediosOrigen(filteredData);
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  useEffect(() => {
    if (prediosOrigen.length > 0 && inputOrigenPredio.current) {
      setDato(prevDato => ({...prevDato, 'origen_id_predio': inputOrigenPredio.current.selectedOptions[0].value}));
    }
  }, [prediosOrigen]);

  //=================== POTREROS ORIGEN ===================
  const getPotrerosOrigen = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/potrerosxpredio/${dato.origen_id_predio}`,{withCredentials: true});
      setPotrerosOrigen(response.data);
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  useEffect(() => {
    if (potrerosOrigen.length > 0 && inputOrigenPotrero.current) {
      setDato(prevDato => ({...prevDato, 'origen_id_potrero': inputOrigenPotrero.current.selectedOptions[0].value}));
    }
  }, [potrerosOrigen]);

  useEffect(() => {
    if (dato.origen_id_predio !== '') {getPotrerosOrigen();}
    //eslint-disable-next-line
  }, [dato.origen_id_predio]);

  //=================== SUBPOTREROS ORIGEN ===================
  const getSubpotrerosOrigen = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/subpotrerosxpotrero/${dato.origen_id_potrero}`,{withCredentials: true});
      setSubpotrerosOrigen(response.data);  
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  useEffect(() => {
    if (subpotrerosOrigen.length > 0 && inputOrigenSubpotrero.current) {
      setDato(prevDato => ({...prevDato, 'origen_id_subpotrero': inputOrigenSubpotrero.current.selectedOptions[0].value}));
    }
  }, [subpotrerosOrigen]);

  useEffect(() => {
    if (dato.origen_id_potrero !== '') {getSubpotrerosOrigen();}
    //eslint-disable-next-line
  }, [dato.origen_id_potrero]);

  //***************************************************************************** */
  //********************************* DESTINO *********************************** */
  //***************************************************************************** */

  //=================== PREDIOS DESTINO ===================
  const getPrediosDestino = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/predios`,{withCredentials: true});
      setPrediosDestino(response.data);
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  useEffect(() => {
    if (prediosDestino.length > 0 && inputDestinoPredio.current) {
      setDato(prevDato => ({...prevDato, 'destino_id_predio': inputDestinoPredio.current.selectedOptions[0].value}));
    }
  }, [prediosDestino]);

  useEffect(() => {
    if (dato.destino_id_predio !== '') {getPotrerosDestino();}
    //eslint-disable-next-line
  }, [dato.destino_id_predio]);

  //=================== POTREROS DESTINO ===================
  const getPotrerosDestino = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/potrerosxpredio/${dato.destino_id_predio}`,{withCredentials: true});
      setPotrerosDestino(response.data);
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  useEffect(() => {
    if (potrerosDestino.length > 0 && inputDestinoPotrero.current) {
      setDato(prevDato => ({...prevDato, 'destino_id_potrero': inputDestinoPotrero.current.selectedOptions[0].value}));
    }
  }, [potrerosDestino]);

  useEffect(() => {
    if (dato.destino_id_potrero !== '') {getSubpotrerosDestino();}
    //eslint-disable-next-line
  }, [dato.destino_id_potrero]);

  //=================== SUBPOTREROS DESTINO ===================
  const getSubpotrerosDestino = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/subpotrerosxpotrero/${dato.destino_id_potrero}`,{withCredentials: true});
      setSubpotrerosDestino(response.data);
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  useEffect(() => {
    if (subpotrerosDestino.length > 0 && inputDestinoSubpotrero.current) {
      setDato(prevDato => ({...prevDato, 'destino_id_subpotrero': inputDestinoSubpotrero.current.selectedOptions[0].value}));
    }
  }, [subpotrerosDestino]);

  useEffect(() => {
    if (dato.destino_id_potrero !== '') {getSubpotrerosDestino();}
    //eslint-disable-next-line
  }, [dato.destino_id_potrero]);

  //________________________________________________________________________________
  //_________________________________ CARGAR LOTES _________________________________
  //________________________________________________________________________________

  const getLotesOrigen = async () => {
    let v_origen_id_potrero = dato.origen_id_potrero;
    if(dato.origen_id_potrero === ''){v_origen_id_potrero = 0}
    let v_origen_id_subpotrero = dato.origen_id_subpotrero;
    if(dato.origen_id_subpotrero === ''){v_origen_id_subpotrero = 0}
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/lotes_porfiltros/${dato.origen_id_predio}/${v_origen_id_potrero}/${v_origen_id_subpotrero}/${dato.id_especie}/${dato.id_raza}`,{withCredentials: true});
      setDatoslotesorigen(response.data)
    } catch(error) {
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  useEffect(() => {
    if (datoslotesorigen.length > 0 && inputOrigenLote.current) {
      setDato(prevDato => ({...prevDato, 'origen_id_lote': inputOrigenLote.current.selectedOptions[0].value}));
      setDato(prevDato => ({...prevDato, 'destino_id_predio': inputDestinoPredio.current.selectedOptions[0].value}));
    }
  }, [datoslotesorigen]);

  useEffect(() => {
    if(!id_mov){
      if (dato.origen_id_predio !== '' && dato.origen_id_potrero !== '' && dato.id_especie !== '' && dato.id_raza !== '') {
        getLotesOrigen();
      }
    }
    //eslint-disable-next-line
  }, [id_mov, dato.origen_id_lote, dato.origen_id_predio, dato.origen_id_potrero, dato.origen_id_subpotrero, dato.id_especie, dato.id_raza]);

  useEffect(() => {
    if(!id_mov){
      if (dato.origen_id_lote !== '') {
        if(dato.lote_completo === true){
          const selectedCantidad = inputOrigenLote.current.selectedOptions[0].getAttribute("data-cantidad");      
          setDato(prevDato => ({...prevDato, 'destino_cantidad': selectedCantidad}));
        }
        else {setDato(prevDato => ({...prevDato, 'destino_cantidad': ''}));}
      }
    }
  }, [id_mov, dato.origen_id_lote, dato.lote_completo]);
  //________________________________________________________________________________

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDato({ ...dato, [name]: value });
  };

  const handleInputChangeCantidad = (event) => {
    const { name, value } = event.target;
    const selectedCantidad = inputOrigenLote.current.selectedOptions[0].getAttribute("data-cantidad");      
    if (parseInt(value) <= parseInt(selectedCantidad) || value === '') {
      setDato({ ...dato, [name]: value });
    }
  };
  
  function handleDateChange(date, name) {
    if(date){      
      setDato({ ...dato, [name]: format(date, 'yyyy-MM-dd') });
    }
  }

  const handleCheckboxChange = (event) => {
    setDato({ ...dato, [ event.target.name]: event.target.checked });
  };

  const handleKeyPressCantidad = (event) => {
    if (event.key === 'Enter') {
      event.target.blur(); // desenfocar el input si se presiona Enter
    }

    if (!/[0-9]/.test(event.key)) {event.preventDefault();}
  };

  const enviarFormulario = async (e) => {
    e.preventDefault();

    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }
    
    var data = {
      fecha_mov             : dato.fecha_mov,
      id_responsable        : dato.id_responsable,
      
      id_especie            : inputEspecie.current.value,
      id_raza               : inputRaza.current.value,
      categoria             : inputOrigenLote.current.selectedOptions[0].getAttribute("data-categoria"),

      origen_id_predio      : inputOrigenPredio.current.value,
      origen_id_potrero     : inputOrigenPotrero.current.value,
      origen_id_subpotrero  : inputOrigenSubpotrero.current.value,

      origen_id_lote        : inputOrigenLote.current.value,
      origen_cantidad       : inputOrigenLote.current.selectedOptions[0].getAttribute("data-cantidad"),

      destino_id_predio     : inputDestinoPredio.current.value,
      destino_id_potrero    : inputDestinoPotrero.current.value,
      destino_id_subpotrero : inputDestinoSubpotrero.current.value,
      destino_cantidad      : dato.destino_cantidad,
      lote_completo         : dato.lote_completo, 
      usuario : localStorage.getItem("usuario")
    };

    const v_destino_cantidad  = dato.destino_cantidad;
    const v_origen_cantidad   = inputOrigenLote.current.selectedOptions[0].getAttribute("data-cantidad")

    let strMsg = "";
    if(inputDestinoPredio.current.value === "8"){ //Movimiento a Frigorífico
      strMsg = ". Se está movimiendo animales a FRIGORIFICO, se dará de baja la cantidad"
    } else { //Movimiento normal
      if(v_destino_cantidad < v_origen_cantidad){
        strMsg = ". Se está movimiendo una cantidad parcial de animales. Este proceso creará un nuevo lote con esa cantidad"
      }
    }
    
    Swal.fire({
      title: 'Finalizar movimiento', text: '¿Está seguro que desea finalizar el movimiento de animales?' + strMsg,
      icon: 'warning', showCancelButton: true, confirmButtonColor: '#198754', cancelButtonColor: '#666',
      confirmButtonText: 'Finalizar', cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) 
      {
        // Bloquear pantalla mientras se realiza la operación
        const loadingSwal = Swal.fire({
          title: 'Cargando datos', text: 'Por favor, espere un momento...',
          allowOutsideClick: false,showConfirmButton: false, willOpen: () => {Swal.showLoading();}
        });

        try {
          const response = await axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`, data, {withCredentials: true});
          loadingSwal.close();
          toast.success("Registro creado correctamente", {
            position: "top-right", autoClose: 2000, hideProgressBar: true,
            closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });

          navigate(`/${modulo}/${response.data.id_mov}`);
        } catch (error) {
          if (error.response.status === 401){
            delete axios.defaults.headers.common["Authorization"]; 
            localStorage.removeItem("token"); 
            localStorage.removeItem("usuario");
            navigate('/login');
          } else {
            loadingSwal.close();
            toast.error(error.response.data, {
              position: "top-right", autoClose: 2000, hideProgressBar: true,
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        }
      }
    });
  }

  return (
    <div className="row justify-content-center w-100">
      <div className="col col-lg-10 col-xl-8">
      {isLoadingDatosForm &&  
        <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Cargando datos...</span>
            </div>
        </div>
      }
      {!isLoadingDatosForm &&    
        <form onSubmit={(e) => enviarFormulario(e)}>
          <small>
          <div className="row pt-2 pb-2">
            <div className="col text-end">
              {!id_mov && 
              <button type="submit" className="btn btn-success btn-sm">
                <i className="fas fa-check"></i> Finalizar
              </button>
              }
              &nbsp;
              <Link to={`/${modulo}`} className="btn btn-secondary btn-sm">
                <i className={icon} title={textButton}></i> {textButton}
              </Link>
            </div>
          </div>
          
          <fieldset className={`border p-2 mb-2 ${darkMode ? 'border-secondary' : ''}`}>
            {id_mov && 
            <div className="row pt-2">
              <label className="col-sm-2">N°&nbsp;Mov.:</label>
              <div className="col-sm-4">
                <input type="text" name="id_mov" value={dato.id_mov} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`}
                readOnly/>
              </div>
              <label className="col-sm-2">Fecha:</label>
              <div className="col-sm-4">
                <input type="text" name="created_date" value={dato.created_date} 
                className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                readOnly/>
              </div>
            </div>
            }

            <div className="row pt-2">
              <label className="col-sm-2">Responsable:</label>
              <div className="col-sm-4">
                <select 
                  className="form-select form-select-sm" 
                  name="id_responsable" 
                  ref={inputResponsable} 
                  disabled={id_mov}
                  value={dato.id_responsable} onChange={handleInputChange} required>
                  {responsables.map((opcion) => (
                    <option key={opcion.id_responsable} value={opcion.id_responsable}>{opcion.n_responsable}</option>
                  ))}
                </select>
              </div>
              <label className="col-sm-2">Fecha Mov.:</label>
              <div className="col-sm-4">
                <DatePicker
                  selected={parseISO(dato.fecha_mov, 'ddMMyyyy', new Date())}
                  onChange={(date) => handleDateChange(date, "fecha_mov")}
                  minDate={minSelectableDate}
                  maxDate={maxSelectableDate}
                  dateFormat="dd-MM-yyyy"
                  className="form-control form-control-sm" 
                  disabled={id_mov}
                  locale={es} // Establece el idioma español
                  required 
                />
              </div>
            </div>
          </fieldset>

          <fieldset className={`border p-2 mb-2 position-relative ${darkMode ? 'border-secondary' : ''}`}>
            <legend 
              className={`w-auto position-absolute ${darkMode ? 'bg-dark' : 'bg-light'}`}
              >Origen</legend>
            <div className="row">
              <label className="col-sm-2">Especie:</label>
              <div className="col-sm-4">
                <select 
                  className="form-select form-select-sm" 
                  name="id_especie" 
                  ref={inputEspecie} 
                  value={dato.id_especie} 
                  onChange={handleInputChange} 
                  disabled={id_mov}
                  required>
                  {especies.map((opcion) => (
                    <option key={opcion.id_especie} value={opcion.id_especie}>{opcion.n_especie}</option>
                  ))}
                </select>
              </div>
              <label className="col-sm-2">Raza:</label>
              <div className="col-sm-4">
                <select 
                  className="form-select form-select-sm" 
                  name="id_raza" 
                  ref={inputRaza} 
                  value={dato.id_raza} 
                  onChange={handleInputChange} 
                  disabled={id_mov}
                  required>
                  {razas.map((opcion) => (
                    <option key={opcion.id_raza} value={opcion.id_raza}>{opcion.n_raza}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row pt-2">
              <label className="col-sm-2">Predio:</label>
              <div className="col-sm-4">
                <select 
                  className="form-select form-select-sm" 
                  name="origen_id_predio" 
                  ref={inputOrigenPredio} 
                  value={dato.origen_id_predio} 
                  onChange={handleInputChange} 
                  disabled={id_mov}
                  required>
                  {prediosOrigen.map((opcion) => (
                    <option key={opcion.id_predio} value={opcion.id_predio}>{opcion.n_predio}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row pt-2">
              <label className="col-sm-2">Potrero:</label>
              <div className="col-sm-4">
                <select 
                  className="form-select form-select-sm" 
                  name="origen_id_potrero" 
                  ref={inputOrigenPotrero} 
                  value={dato.origen_id_potrero} 
                  onChange={handleInputChange} 
                  disabled={id_mov}
                  required>
                  {potrerosOrigen.map((opcion) => (
                    <option key={opcion.id_potrero} value={opcion.id_potrero}>{opcion.n_potrero}</option>
                  ))}
                </select>
              </div>
              <label className="col-sm-2">Subpotrero:</label>
              <div className="col-sm-4">
                <select 
                  className="form-select form-select-sm" 
                  name="origen_id_subpotrero" 
                  ref={inputOrigenSubpotrero} 
                  value={dato.origen_id_subpotrero} 
                  onChange={handleInputChange} 
                  disabled={id_mov}
                  required>
                    <option key={0} value={0}></option>
                  {subpotrerosOrigen.map((opcion) => (
                    <option key={opcion.id_subpotrero} value={opcion.id_subpotrero}>{opcion.n_subpotrero}</option>
                  ))}
                </select>
              </div>
            </div>

            {id_mov && 
            <div className="row pt-2">
              <label className="col-sm-2">N° Lote:</label>
              <div className="col-sm-4">
                <input maxLength="5" name="origen_id_lote" type="text" 
                  className="form-control form-control-sm" 
                  value={dato.origen_id_lote}
                  readOnly/>
              </div>
              <label className="col-sm-2">Cantidad:</label>
              <div className="col-sm-4">
                <input maxLength="5" name="origen_id_lote" type="text" 
                  className="form-control form-control-sm" 
                  value={dato.origen_cantidad}
                  readOnly/>
              </div>
            </div>
            }

            {id_mov && 
            <div className="row pt-2">
              <label className="col-sm-2">Categoría:</label>
              <div className="col-sm-4">
                <input maxLength="5" name="categoria" type="text" 
                  className="form-control form-control-sm" 
                  value={dato.categoria}
                  readOnly/>
              </div>
            </div>
            }

            {!id_mov &&
            <div className="row pt-2">
              <label className="col-2"></label>
              <div className="col-10" style={{fontfamily: 'monospace', fontSize: '0.8rem'}}>
                &nbsp; Categoría &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                | N° Lote 
                | Cantidad
              </div>
            </div>
            }

            {!id_mov &&
            <div className="row pt-2">
              <label className="col-2">Lotes:</label>
              <div className="col-10">
                <select 
                  className="form-select form-select-sm" 
                  name="origen_id_lote" 
                  ref={inputOrigenLote} 
                  value={dato.origen_id_lote} 
                  onChange={handleInputChange}
                  required
                  style={{fontfamily: 'monospace', fontSize: '0.8rem'}}>
                  {datoslotesorigen.map((opcion) => {
                    let strLote = opcion.categoria.toString().padEnd(14,'\u00A0') + " | " + opcion.id_lote.toString().padStart(10,'\u00A0') + " | " + opcion.cantidad.toString().padStart(11,'\u00A0')
                    return (
                    <option 
                      key={opcion.id_lote} 
                      data-cantidad={opcion.cantidad}
                      data-categoria={opcion.categoria}
                      value={opcion.id_lote}>{strLote}</option>
                    );
                  })}
                </select>
              </div>
            </div>
            }
          </fieldset>
          
          { (datoslotesorigen.length > 0 || id_mov) && 
          <fieldset className={`border p-2 mb-2 position-relative ${darkMode ? 'border-secondary' : ''}`}>
            <legend 
            className={`w-auto position-absolute ${darkMode ? 'bg-dark' : 'bg-light'}`}
            >Destino</legend>
            <div className="row pt-2">
              <label className="col-sm-2">Predio:</label>
              <div className="col-sm-4">
                <select 
                  className="form-select form-select-sm" 
                  name="destino_id_predio" 
                  ref={inputDestinoPredio} 
                  value={dato.destino_id_predio} 
                  onChange={handleInputChange} 
                  disabled={id_mov}
                  required>
                  {prediosDestino.map((opcion) => (
                    <option key={opcion.id_predio} value={opcion.id_predio}>{opcion.n_predio}</option>
                  ))}
                </select>
              </div>
            </div>
            
            <div className="row pt-2">
              <label className="col-sm-2">Potrero:</label>
              <div className="col-sm-4">
                <select 
                  className="form-select form-select-sm" 
                  name="destino_id_potrero" 
                  ref={inputDestinoPotrero} 
                  value={dato.destino_id_potrero} 
                  onChange={handleInputChange} 
                  disabled={id_mov}
                  required>
                  {potrerosDestino.map((opcion) => (
                    <option key={opcion.id_potrero} value={opcion.id_potrero}>{opcion.n_potrero}</option>
                  ))}
                </select>
              </div>
              <label className="col-sm-2">Subpotrero:</label>
              <div className="col-sm-4">
                <select 
                  className="form-select form-select-sm" 
                  name="destino_id_subpotrero" 
                  ref={inputDestinoSubpotrero} 
                  value={dato.destino_id_subpotrero} 
                  onChange={handleInputChange} 
                  disabled={id_mov}
                  required>
                    <option key={0} value={0}></option>
                  {subpotrerosDestino.map((opcion) => (
                    <option key={opcion.id_subpotrero} value={opcion.id_subpotrero}>{opcion.n_subpotrero}</option>
                  ))}
                </select>
              </div>
            </div>
            
            <div className="row pt-2">
              <label className="col-2"></label>
              <div className="col-4">
                <input 
                  checked={dato.lote_completo}
                  onChange={handleCheckboxChange}
                  className="form-check-input" 
                  name="lote_completo" 
                  ref={inputLotecompleto} 
                  type="checkbox" 
                  disabled={id_mov}
                  id="check_lote_completo"/>
                <label className="form-check-label" htmlFor="check_lote_completo">
                  &nbsp;Lote completo
                </label>
              </div>
              <label className="col-sm-2">Cantidad:</label>
              <div className="col-sm-4">
                <input maxLength="5" name="destino_cantidad" type="text" 
                  className="form-control form-control-sm" 
                  value={dato.destino_cantidad} 
                  onKeyDown={handleKeyPressCantidad} 
                  onChange={handleInputChangeCantidad}
                  ref={inputCantidad} 
                  readOnly={dato.lote_completo}
                  disabled={id_mov}
                  required/>
              </div>
            </div>
          </fieldset>
          }
          
          </small>
          
        </form>
        }
      </div>
    </div>
  );
}

export default MovloteForm;
import React, { useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import { isValidDate } from '../../utils/utils';

import { useDarkMode } from '../../context/darkModeContext'; 

function Fichas() {
  // eslint-disable-next-line
  const {darkMode, setDarkMode} = useDarkMode();
  const [datosOriginales, setDatosOriginales] = useState([]); // Estado para almacenar los datos originales
  const [datos, setDatos] = useState([]); // Estado para almacenar los datos ordenados
  const [isLoadingDatos, setIsLoadingDatos] = useState(false);

  const [sortingColumn, setSortingColumn] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
  const [sortDirection, setSortDirection] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

  const [especies, setEspecies] = useState([]);

  const inputEspecie = useRef(null);
  const inputBuscar = useRef(null);
  const inputEstado = useRef(null);

  const [selectedEspecie, setSelectedEspecie] = useState('');
  const [selectedEstado, setSelectedEstado] = useState(false);
  const [selectedBusquedaAprox, setSelectedBusquedaAprox] = useState('');
  
  const navigate = useNavigate();
  const modulo = 'fichas'

  useEffect( ()=> {
    getEspecies();
    // inputEstado.current.checked = true;

    const storedEspecie = sessionStorage.getItem('FichasSelectedEspecie');
    const storedBusquedaAprox = sessionStorage.getItem('FichasSelectedBusquedaAprox');
    
    if(sessionStorage.getItem('FichasSelectedEstado') === null){
      sessionStorage.setItem('FichasSelectedEstado', true);
    }
    const storedEstado = sessionStorage.getItem('FichasSelectedEstado');

    setSelectedEspecie(storedEspecie || '');
    setSelectedEstado(storedEstado === 'true');
    setSelectedBusquedaAprox(storedBusquedaAprox || '');
    // eslint-disable-next-line
  },[]);

  
  /************ INICIO FUNCIONES ORDEN ARRAY (DATOS TABLA) *************************************** */

  const handleColumnClick = (columnIndex) => {
    // Si la columna actualmente ordenada es la misma que se hizo clic, cambia la dirección de ordenamiento
    // Si la columna actualmente ordenada es diferente, establece la columna y la dirección de ordenamiento predeterminada a 'asc'
    if (sortingColumn === columnIndex) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortingColumn(columnIndex);
      setSortDirection('asc');
    }
  };

  useEffect(() => {    
    const rows = [...datosOriginales]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
    if (sortingColumn !== -1) {  
      rows.sort((a, b) => {
        const valA = a[Object.keys(a)[sortingColumn]];
        const valB = b[Object.keys(b)[sortingColumn]];

        if (typeof valA === 'number' && typeof valB === 'number') {
          return valA - valB;
        } else if (isValidDate(valA) && isValidDate(valB)) {
          const dateA = new Date(valA.split('-').reverse().join('-'));
          const dateB = new Date(valB.split('-').reverse().join('-'));
          return dateA - dateB;
        } else {
          return valA.localeCompare(valB);
        }
      });
  
      if (sortDirection === 'desc') {
        rows.reverse();
      }
      setDatos(rows); // Actualizamos el estado de sortedRows con los datos ordenados
    }
  }, [sortingColumn, sortDirection, datosOriginales]); // Dependencias: sortingColumn, sortDirection, datosOriginales
  
  /************ FIN FUNCIONES ORDEN ARRAY (DATOS TABLA) *************************************** */

  const handleDelete = (id_ficha) => {
    Swal.fire({
      title: 'Eliminar registro', text: '¿Estás seguro que deseas eliminar este registro?',
      icon: 'warning', showCancelButton: true, confirmButtonColor: '#d33', cancelButtonColor: '#666',
      confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar',
    }).then((result) => {
      // Si se confirma la eliminación, hacer la petición a la API para eliminar el elemento
      if (result.isConfirmed) 
      {
        axios.delete(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id_ficha}`,{withCredentials: true}).then(response =>
        {
          if (response.data) {
            toast.success('¡Registro eliminado correctamente!', {
              position: "top-right", autoClose: 2000, hid_fichaeProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });

            setDatos(datos.filter(datos => datos.id_ficha !== id_ficha)); //Quitar del arreglo la fila del id_ficha eliminado
          } else {
            toast.error('¡No se pudo eliminar el registro!', {
              position: "top-right", autoClose: 2000, hid_fichaeProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        })        
      }
    });
  };

  const getEspecies = () => {
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/especies`,{withCredentials: true})
      .then(response => {
        setEspecies(response.data);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  const getDatos = () => {
    setIsLoadingDatos(true);
    const v_id_especie = inputEspecie.current.value;
    const v_estado = inputEstado.current.checked;
    const strBuscar = inputBuscar.current.value;

    sessionStorage.setItem('FichasSelectedEspecie', v_id_especie);
    sessionStorage.setItem('FichasSelectedBusquedaAprox', strBuscar);
    sessionStorage.setItem('FichasSelectedEstado', v_estado);

    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`,
      {
        withCredentials: true,
        params: {
          id_especie : v_id_especie,
          strBuscar: strBuscar,
          estado : v_estado
        }
      })
      .then(response => {
        setDatosOriginales(response.data); // Guardar los datos originales
        setDatos(response.data); // Hacer una copia para mantener los datos originales en 'datos' al inicio
        setIsLoadingDatos(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };
  
  const handleKeyPressBuscar = (event) => {
    if (event.key === 'Enter') {
      getDatos();
    }
  };
  
  return (
    <div className="row justify-content-center w-100">
      <div className="col">
        <small>
        <Link to={`/${modulo}/new`} className="btn btn-primary btn-sm">
          <i className="fa fa-plus"></i>&nbsp;Nuevo
        </Link>
        <div className="row pt-2 pb-1">
          <label className="col-sm-1">Especie:</label>
          <div className="col-sm-3">
            <select 
              value={selectedEspecie} 
              onChange={(event) => { 
                setSelectedEspecie(event.target.value);
                setDatosOriginales([]);
                setDatos([]);
              }}
              className="form-select form-select-sm" 
              name="id_especie" 
              ref={inputEspecie}>
              {especies.map((opcion) => (
                <option key={opcion.id_especie} value={opcion.id_especie}>{opcion.n_especie}</option>
              ))}
            </select>
          </div>
          <div className="col-sm-4">
            <input 
              value={selectedBusquedaAprox} onChange={(event) => { setSelectedBusquedaAprox(event.target.value)}}
              type="text" className="form-control form-control-sm" 
              placeholder="Buscar por Ficha / Diio / Crotal" 
              aria-label="Buscar por Ficha / Diio / Crotal" 
              aria-describedby="bot_buscar" 
              onKeyDown={handleKeyPressBuscar}
              autoFocus 
              ref={inputBuscar}/>
          </div>
          <div className="col-sm-2">
            <div className="form-check">
              <input checked={selectedEstado} onChange={(event) => { setSelectedEstado(event.target.checked)}}
                className="form-check-input" name="estado" ref={inputEstado} 
                type="checkbox" id="estado"/>
              <label className="form-check-label" htmlFor="estado">
                Activos
              </label>
            </div>
          </div>
          <div className="col-sm-2">
            <button 
              className="btn btn-outline-secondary btn-sm" type="button" 
              id="bot_buscar" onClick={() => getDatos()}>
                <i className="fa fa-search"></i>&nbsp;Buscar</button>
          </div>
        </div>
        </small>
        {isLoadingDatos &&  
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Cargando datos...</span>
            </div>
          </div>
        }
        {!isLoadingDatos &&  
        <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
          <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
            <thead className="sticky-top">
              <tr>
                <th onClick={() => handleColumnClick(1)} className={sortingColumn === 1 ? `sorting ${sortDirection}` : ''}>Id. Ficha</th>
                <th onClick={() => handleColumnClick(2)} className={sortingColumn === 2 ? `sorting ${sortDirection}` : ''}>Raza</th>
                <th onClick={() => handleColumnClick(3)} className={sortingColumn === 3 ? `sorting ${sortDirection}` : ''}>Diio/Crotal</th>
                <th onClick={() => handleColumnClick(4)} className={sortingColumn === 4 ? `sorting ${sortDirection}` : ''}>Sexo</th>
                <th onClick={() => handleColumnClick(5)} className={sortingColumn === 5 ? `sorting ${sortDirection}` : ''}>Categoría</th>
                <th onClick={() => handleColumnClick(6)} className={sortingColumn === 6 ? `sorting ${sortDirection}` : ''}>Ubicacíon</th>
                <th onClick={() => handleColumnClick(7)} className={sortingColumn === 7 ? `sorting ${sortDirection}` : ''}>Activo</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {datos.map(dato => {
                let strIconActivo = ''
                if(dato.estado === 1){
                  strIconActivo = <i className="fa fa-check"></i>
                }
                let strUbicacion   = dato.n_predio + "/" + dato.n_potrero;
                if(dato.n_subpotrero !== ''){strUbicacion+="/" + dato.n_subpotrero}

                return (
                <tr key={dato.id_ficha}>
                  <td>{dato.id_ficha}</td>
                  <td>{dato.n_raza}</td>
                  <td>{dato.diio}</td>
                  <td>{dato.sexo}</td>
                  <td>{dato.categoria}</td>
                  <td>{strUbicacion}</td>
                  <td>{strIconActivo}</td>
                  <td>
                    <Link to={`/${modulo}/${dato.id_ficha}`} className="btn btn-sm btn-outline-secondary">
                      <i className="fa fa-edit" title="Editar"></i>
                    </Link>
                    &nbsp;
                    <button onClick={() => handleDelete(dato.id_ficha)} className="btn btn-sm btn-outline-secondary">
                      <i className="fa fa-trash-alt" title="Eliminar"></i>
                    </button>
                  </td>
                </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        }
      </div>
    </div>
  );
}

export default Fichas;
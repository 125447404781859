import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useDarkMode } from '../../context/darkModeContext'; 

import { isValidDate } from '../../utils/utils';

function Guiasentrada() {
  // eslint-disable-next-line
  const {darkMode, setDarkMode} = useDarkMode();
  const [datosOriginales, setDatosOriginales] = useState([]); // Estado para almacenar los datos originales
  const [datos, setDatos] = useState([]); // Estado para almacenar los datos ordenados
  const [sortingColumn, setSortingColumn] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
  const [sortDirection, setSortDirection] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

  const inputAnio = useRef(null);
  const inputMes = useRef(null);

  const [isLoadingDatos, setIsLoadingDatos] = useState(false);
  const navigate = useNavigate();
  const modulo = 'guiasentrada'

  const getDatos = () => {
    setIsLoadingDatos(true);
    const v_anio = inputAnio.current.value;
    const v_mes = inputMes.current.value;
    
    sessionStorage.setItem('GuiasSelectedAnio', v_anio);
    sessionStorage.setItem('GuiasSelectedMes', v_mes);
    
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`, 
      {
        withCredentials: true,
        params: {
          anio : selectedAnio,
          mes : selectedMes
        }
      })
      .then(response => {
        setDatosOriginales(response.data); // Guardar los datos originales
        setDatos(response.data); // Hacer una copia para mantener los datos originales en 'datos' al inicio
        setIsLoadingDatos(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  const handleDelete = (id_guia_entrada) => {
    Swal.fire({
      title: 'Eliminar registro', text: '¿Estás seguro que deseas eliminar este registro?',
      icon: 'warning', showCancelButton: true, confirmButtonColor: '#d33', cancelButtonColor: '#666',
      confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar',
    }).then((result) => {
      // Si se confirma la eliminación, hacer la petición a la API para eliminar el elemento
      if (result.isConfirmed) 
      {
        axios.delete(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id_guia_entrada}`,{withCredentials: true}).then(response =>
        {
          if (response.data) {
            toast.success('¡Registro eliminado correctamente!', {
              position: "top-right", autoClose: 2000, hid_guia_entradaeProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          } else {
            toast.error('¡No se pudo eliminar el registro!', {
              position: "top-right", autoClose: 2000, hid_guia_entradaeProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        });
      }
    });
  };

  const handleNew = () => {
    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }
    
    Swal.fire({
      title: 'Nuevo registro', text: '¿Estás seguro que deseas crear una nueva guía?',
      icon: 'question', showCancelButton: true, confirmButtonColor: '#198754', cancelButtonColor: '#666',
      confirmButtonText: 'Crear', cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) 
      {
        var data = {
          usuario : localStorage.getItem("usuario")
        };
        axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`, data,{withCredentials: true}).then(response =>
        {
          if (response.data) {
            toast.success('¡Registro creado correctamente!', {
              position: "top-right", autoClose: 2000, hid_guia_entradaeProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
            navigate(`/${modulo}/${response.data.id_guia_entrada}`);
          } else {
            toast.error('¡No se pudo crear el registro!', {
              position: "top-right", autoClose: 2000, hid_guia_entradaeProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        });
      }
    });
  }

  /************ INICIO FUNCIONES ORDEN ARRAY (DATOS TABLA) *************************************** */

  const handleColumnClick = (columnIndex) => {
    // Si la columna actualmente ordenada es la misma que se hizo clic, cambia la dirección de ordenamiento
    // Si la columna actualmente ordenada es diferente, establece la columna y la dirección de ordenamiento predeterminada a 'asc'
    if (sortingColumn === columnIndex) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortingColumn(columnIndex);
      setSortDirection('asc');
    }
  };

  useEffect(() => {    
    const rows = [...datosOriginales]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
    if (sortingColumn !== -1) {  
      rows.sort((a, b) => {
        const valA = a[Object.keys(a)[sortingColumn]];
        const valB = b[Object.keys(b)[sortingColumn]];

        if (typeof valA === 'number' && typeof valB === 'number') {
          return valA - valB;
        } else if (isValidDate(valA) && isValidDate(valB)) {
          const dateA = new Date(valA.split('-').reverse().join('-'));
          const dateB = new Date(valB.split('-').reverse().join('-'));
          return dateA - dateB;
        } else {
          return valA.localeCompare(valB);
        }
      });
  
      if (sortDirection === 'desc') {
        rows.reverse();
      }
      setDatos(rows); // Actualizamos el estado de sortedRows con los datos ordenados
    }
  }, [sortingColumn, sortDirection, datosOriginales]); // Dependencias: sortingColumn, sortDirection, datosOriginales
  
  /************ FIN FUNCIONES ORDEN ARRAY (DATOS TABLA) *************************************** */

  const [anios, setAnios] = useState([]);
  const [selectedAnio, setSelectedAnio] = useState('');

  const [meses, setMeses] = useState([]);
  const [selectedMes, setSelectedMes] = useState('');

  useEffect(() => {
    const anioInicial = 2022;
    const anioActual = new Date().getFullYear();

    const fechaActual = new Date();
    const numeroMes = fechaActual.getMonth() + 1; // Los meses comienzan desde 0

    // Asegurarse de tener un cero adelante si el número del mes es menor que 10
    const mesConCero = numeroMes < 10 ? `0${numeroMes}` : `${numeroMes}`;
    
    const storedAnio = sessionStorage.getItem('GuiasSelectedAnio');
    setSelectedAnio(storedAnio || anioActual);

    const storedMes = sessionStorage.getItem('GuiasSelectedMes');
    setSelectedMes(storedMes || mesConCero);

    const aniosArray = Array.from({ length: anioActual - anioInicial + 1 }, (_, index) => {
      const anio = anioActual - index;
      return anio.toString();
    });

    setAnios(aniosArray);

    const nombresMeses = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    const mesesArray = Array.from({ length: 12 }, (_, index) => {
      const mesNumero = index + 1;
      const mes = mesNumero < 10 ? `0${mesNumero}` : `${mesNumero}`;
      const descripcionMes = nombresMeses[index];
      return { mes, descripcionMes };
    });

    setMeses(mesesArray);
  }, []);

  useEffect(() => {
    if(selectedAnio !== '' && selectedMes !== ''){
      getDatos();
    }
    // eslint-disable-next-line
  }, [selectedAnio, selectedMes]);

  return (
    <div className="row justify-content-center w-100">
      <div className="col">
        <small>
        <button type="button" onClick={() => handleNew()} className="btn btn-primary btn-sm">
          <i className="fa fa-plus"></i>&nbsp;Nueva guía
        </button>
        <div className="row pt-2 pb-1">
          <label className="col-sm-1">Año:</label>
          <div className="col-sm-3">
            <select 
              className="form-select form-select-sm" 
              name="anio" 
              ref={inputAnio}
              value={selectedAnio} 
              onChange={(event) => { 
                setSelectedAnio(event.target.value);
              }}>
              {anios.map((anio) => (
                <option key={anio} value={anio}>{anio}</option>
              ))}
            </select>
          </div>
          <label className="col-sm-1">Mes:</label>
          <div className="col-sm-3">
            <select 
              className="form-select form-select-sm" 
              name="mes" 
              ref={inputMes}
              value={selectedMes} 
              onChange={(event) => { 
                setSelectedMes(event.target.value);
              }}>
              {meses.map(({ mes, descripcionMes }) => (
                <option key={mes} value={mes}>{descripcionMes }</option>
              ))}
            </select>
          </div>
        </div>
        </small>
        {isLoadingDatos &&  
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Cargando datos...</span>
            </div>
          </div>
        }
        {!isLoadingDatos &&  
        <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
          <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
            <thead className="sticky-top">
              <tr>
                <th onClick={() => handleColumnClick(1)} className={sortingColumn === 1 ? `sorting ${sortDirection}` : ''}>N°</th>
                <th onClick={() => handleColumnClick(2)} className={sortingColumn === 2 ? `sorting ${sortDirection}` : ''}>Fecha</th>
                <th onClick={() => handleColumnClick(3)} className={sortingColumn === 3 ? `sorting ${sortDirection}` : ''}>Empresa</th>
                {/* <th>Rut</th> */}
                <th onClick={() => handleColumnClick(4)} className={sortingColumn === 4 ? `sorting ${sortDirection}` : ''}>Tipo Doc.</th>
                <th onClick={() => handleColumnClick(5)} className={sortingColumn === 5 ? `sorting ${sortDirection}` : ''}>Folio Doc.</th>
                <th onClick={() => handleColumnClick(6)} className={sortingColumn === 6 ? `sorting ${sortDirection}` : ''}>Fecha Doc.</th>
                <th onClick={() => handleColumnClick(7)} className={sortingColumn === 7 ? `sorting ${sortDirection}` : ''}>Estado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {datos.map(dato => (
                <tr key={dato.id_guia_entrada}>
                  <td>{dato.id_guia_entrada}</td>
                  <td>{dato.mod_date}</td>
                  <td>{dato.n_empresa}</td>
                  {/* <td>{dato.rut}</td> */}
                  <td>{dato.doc_tipo}</td>
                  <td>{dato.doc_folio}</td>
                  <td>{dato.doc_fecha}</td>
                  <td>{dato.status}</td>
                  <td>
                    <Link to={`/${modulo}/${dato.id_guia_entrada}`} className="btn btn-sm btn-outline-secondary">
                      <i className="fa fa-edit" title="Editar"></i>
                    </Link>
                    &nbsp;
                    <button onClick={() => handleDelete(dato.id_guia_entrada)} className="btn btn-sm btn-outline-secondary">
                      <i className="fa fa-trash-alt" title="Eliminar"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        }
      </div>
    </div>
  );
}

export default Guiasentrada;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { isValidDate } from '../../utils/utils';

import { useDarkMode } from '../../context/darkModeContext'; 


const MapaPrediosLista = () => {
    // eslint-disable-next-line
    const {darkMode, setDarkMode} = useDarkMode();
    const navigate = useNavigate();

    const [isLoadingDatos, setIsLoadingDatos] = useState(false);
    
    const [sortingColumn, setSortingColumn] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
    const [sortDirection, setSortDirection] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

    const [datosOri, setDatosOri] = useState([]);
    const [datos, setDatos] = useState([]);

    /************ INICIO FUNCIONES ORDEN ARRAY (DATOS TABLA) *************************************** */
    const handleColumnClick = (columnIndex) => {
        if (sortingColumn === columnIndex) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortingColumn(columnIndex); setSortDirection('asc');
        }
    };

    useEffect(() => {    
        const rows = [...datosOri]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
        if (sortingColumn !== -1) {  
            rows.sort((a, b) => {
                const valA = a[Object.keys(a)[sortingColumn]]; const valB = b[Object.keys(b)[sortingColumn]];

                if (typeof valA === 'number' && typeof valB === 'number') {
                    return valA - valB;
                } else if (isValidDate(valA) && isValidDate(valB)) {
                    const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
                } else { return valA.localeCompare(valB);}
            });
    
            if (sortDirection === 'desc') { rows.reverse();}
            setDatos(rows); // Actualizamos el estado de sortedRows con los datos ordenados
        }
    }, [sortingColumn, sortDirection, datosOri]); // Dependencias: sortingColumn, sortDirection, datosOriginales

    /************ FIN FUNCIONES ORDEN ARRAY (DATOS TABLA) *************************************** */

    const getDatos = async () => {
        setIsLoadingDatos(true);
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/mapa_datospredios`,{withCredentials: true});
            setDatosOri(response.data);
            setDatos(response.data);

            setIsLoadingDatos(false);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    useEffect(() => {
        getDatos();
        // eslint-disable-next-line
    }, []);

    return(
        <div>
            {isLoadingDatos &&  
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                <span className="visually-hidden">Cargando datos...</span>
                </div>
            </div>
            }
            {!isLoadingDatos &&  
            <small>
            <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
            <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                <thead>
                <tr>
                    <th onClick={() => handleColumnClick(1)} 
                        className={sortingColumn === 1 ? `sorting ${sortDirection}` : ''}>Predio</th>
                    <th className='text-end'>Ovino</th>
                    <th className='text-end'>Bovino</th>
                    <th className='text-end'>Alpaca</th>
                    <th className='text-end'>Equino</th>
                    <th className='text-end'>Canino</th>
                </tr>
                </thead>
                <tbody>
                {datos.map(dato =>
                    dato.stocks[0].total_animales !== 0 && (
                        <tr key={dato.id_predio}>
                            <td>{dato.n_predio}</td>
                            <td className="text-end">{dato.stocks[0].ovino !== 0 ? dato.stocks[0].ovino : ''}</td>
                            <td className="text-end">{dato.stocks[0].bovino !== 0 ? dato.stocks[0].bovino : ''}</td>
                            <td className="text-end">{dato.stocks[0].alpaca !== 0 ? dato.stocks[0].alpaca : ''}</td>
                            <td className="text-end">{dato.stocks[0].equino !== 0 ? dato.stocks[0].equino : ''}</td>
                            <td className="text-end">{dato.stocks[0].canino !== 0 ? dato.stocks[0].canino : ''}</td>
                            
                        </tr>
                    )
                )}
                </tbody>
            </table>
            </div>
            </small>
            }
        </div>
    )
};

export default MapaPrediosLista;
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDarkMode } from '../../../context/darkModeContext'; 

function Categorias() {
  // eslint-disable-next-line
  const {darkMode, setDarkMode} = useDarkMode();
  const [datos, setDatos] = useState([]);
  const [isLoadingDatos, setIsLoadingDatos] = useState(false);

  const [especies, setEspecies] = useState([]);
  const inputEspecie = useRef(null);
  const [selectedEspecie, setSelectedEspecie] = useState('');
  const navigate = useNavigate();
  const modulo = 'categorias'

  const getEspecies = () => {
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/especies`,{withCredentials: true})
      .then(response => {
        setEspecies(response.data);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };
  
  const getDatos = () => {
    setIsLoadingDatos(true);
    const v_id_especie = inputEspecie.current.value;
    sessionStorage.setItem('CategoriasSelectedEspecie', v_id_especie);

    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`, 
      {
        withCredentials: true,
        params: {
          id_especie : v_id_especie
        }
      })
      .then(response => {
        //setDatosOriginales(response.data); // Guardar los datos originales
        setDatos(response.data); // Hacer una copia para mantener los datos originales en 'datos' al inicio
        setIsLoadingDatos(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  useEffect( ()=> {
    getEspecies();
    const storedEspecie = sessionStorage.getItem('CategoriasSelectedEspecie');
    setSelectedEspecie(storedEspecie || '');
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (especies.length > 0 && inputEspecie.current) {
      getDatos();
    }
    // eslint-disable-next-line
  }, [especies]); //Este se ejecuta cuando se carga completo el arreglo  

  return (
    <div className="row justify-content-center w-100">
      <div className="col">
        <small>
        {/* <Link to={`/${modulo}/new`} className="btn btn-primary btn-sm">
          <i className="fa fa-plus"></i>&nbsp;Nuevo
        </Link> */}
        <div className="row pt-2 pb-1">
          <label className="col-sm-1">Especie:</label>
          <div className="col-sm-5">
            <select 
              value={selectedEspecie} 
              onChange={(event) => { 
                setSelectedEspecie(event.target.value);
                getDatos();
              }}
              className="form-select form-select-sm" 
              name="id_especie" 
              ref={inputEspecie}>
              {especies.map((opcion) => (
                <option key={opcion.id_especie} value={opcion.id_especie}>{opcion.n_especie}</option>
              ))}
            </select>
          </div>
        </div>
        </small>
        {isLoadingDatos &&  
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Cargando datos...</span>
            </div>
          </div>
        }
        {!isLoadingDatos &&  
        <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
          <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
            <thead className="sticky-top">
              <tr>
                <th className='text-center'>Sexo</th>
                <th className='text-end'>Días Min</th>
                <th className='text-end'>Días Max</th>
                <th className='text-center'>Capado</th>
                <th>Nombre</th>
                <th>Sigla</th>
                <th className='text-center'>Color</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {datos.map(dato => (
                <tr key={dato.id_calculo}>
                  <td className='text-center'>{dato.sexo}</td>
                  <td className='text-end'>{dato.dias_min}</td>
                  <td className='text-end'>{dato.dias_max}</td>
                  <td className='text-center'>{dato.capado}</td>
                  <td>{dato.nombre}</td>
                  <td>{dato.sigla}</td>
                  <td className='text-center'>{dato.color}</td>
                  <td>
                    <Link to={`/${modulo}/${dato.id_calculo}`} className="btn btn-sm btn-outline-secondary">
                      <i className="fa fa-edit" title="Editar"></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        }
      </div>
    </div>
  );
}

export default Categorias;
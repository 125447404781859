import React, {  useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { GoogleMap, LoadScript, Polygon, InfoWindow } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "600px",
  position: "relative"
};

const center = {
  lat: -52.806514,
  lng: -69.279367,
};

const titleContainerStyle = {
  position: "absolute",
  top: "40px", // Ajusta la posición vertical del contenedor del título
  left: "50%", // Coloca el contenedor del título en el centro horizontalmente
  transform: "translateX(-50%)", // Centra el contenedor del título en el punto especificado
  zIndex: 1, // Asegura que el título esté en la parte superior del mapa
};

const titleStyle = {
  backgroundColor: "rgba(255, 255, 255, 0.7)", // Fondo semi-transparente para hacer el texto legible
  padding: "10px", // Espaciado interno del título
  borderRadius: "5px", // Esquinas redondeadas para el fondo
};

const MapaPredios = ({ onPredioClick }) => {
  const navigate = useNavigate();
  const [coordenadasPredios, setCoordenadasPredios] = useState([]);

  const getCoordenadasPredios = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/coordenadas_predios`,{withCredentials: true});
      // Transformar el formato del campo 'coordenadas' en un arreglo de objetos
      const newData = response.data.map(item => ({
        ...item,
        coordenadas: JSON.parse(item.coordenadas),
      }));
      setCoordenadasPredios(newData);
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  useEffect(() => {
    getCoordenadasPredios();
    // eslint-disable-next-line
  }, []);

  return (
    <LoadScript googleMapsApiKey="AIzaSyBpGnfol6dU0f-KfDDX-gjaok41FDeZa1s">
      <div style={titleContainerStyle}>
        <h6 className="p-1 m-1 d-none" style={titleStyle}>Predios Ganadera Rio Side</h6>
      </div>
      <div style={containerStyle}>
        <GoogleMap 
          mapContainerStyle={containerStyle} 
          center={center} 
          zoom={10} 
          mapTypeId={"satellite"} 
        >
          {coordenadasPredios.map(item => (
            <React.Fragment key={item.id_predio}>
              <Polygon 
                paths={item.coordenadas} 
                options={{
                  fillColor: item.color, 
                  fillOpacity: 0.4,
                  strokeColor: item.color,
                  strokeOpacity: 0.9,
                  strokeWeight: 2,
                }}
                onClick={() => onPredioClick(item.id_predio, item.centro_lat, item.centro_lng, item.n_predio, item.color)}
              />
              <InfoWindow 
                position={{ lat: parseFloat(item.centro_lat), lng: parseFloat(item.centro_lng) }}>
                <div 
                  onClick={() => onPredioClick(item.id_predio, item.centro_lat, item.centro_lng, item.n_predio, item.color)}
                  style={{color: '#333', cursor: 'pointer'}}>
                    <label style={{color: '#000'}}>
                      <strong>{item.n_corto}</strong>
                    </label>
                    <small>
                      {item.stock_ovino > 0 && 
                        <div>Ovi: {item.stock_ovino}</div>
                      }
                      {item.stock_bovino > 0 && 
                        <div>Bov: {item.stock_bovino}</div>
                      }
                      {item.stock_alpaca > 0 && 
                        <div>Alp: {item.stock_alpaca}</div>
                      }
                      {item.stock_equino > 0 && 
                        <div>Equ: {item.stock_equino}</div>
                      }
                      {item.stock_canino > 0 && 
                        <div>Can: {item.stock_canino}</div>
                      }
                    </small>
                    
                </div>
              </InfoWindow>
            </React.Fragment>
          ))}
        </GoogleMap>
      </div>
    </LoadScript>
  );
};

export default MapaPredios;
import React, { useRef, useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useDarkMode } from '../../../context/darkModeContext'; 

function PotrerosForm() {
  // eslint-disable-next-line
  const {darkMode, setDarkMode} = useDarkMode();
  const { id_potrero } = useParams();
  const [icon, setIcon] = useState('');
  const [textButton, setTextButton] = useState('');
  const [isLoadingDatos, setIsLoadingDatos] = useState(true);
  const [isLoadingCoordenadas, setIsLoadingCoordenadas] = useState(true);

  const [coordenadas, setCoordenadas] = useState([]);
  const [nombreBotonModalCoord, setNombreBotonModalCoord] = useState('Ingresar');
  const [accionBotonModalCoord, setAccionBotonModalCoord] = useState('ingresada');
  const [actualIdCoordenada, setActualIdCoordenada] = useState('');

  const modalCoordenadas  = useRef(null);
  const modalInputLat     = useRef(null);
  const modalInputLng     = useRef(null);
  const modalInputOrden   = useRef(null);
  
  const [actualOrden, setActualOrden] = useState('');
  const [ordenAnterior, setOrdenAnterior] = useState('');

  const [totalFilasOrden, setTotalFilasOrden] = useState(0);
  
  const bootstrap = window.bootstrap; // se agregó para poder llamar al método hide() de un modal

  const modulo = 'potreros'

  const navigate = useNavigate();

  const initialDatoState = {
    id_potrero: null,
    n_potrero: '',
    id_predio: '',
    centro_lat: '',
    centro_lng: '',
    color: '',
  };
  const [dato, setDato] = useState(initialDatoState);
  const [predios, setPredios] = useState([]);

  useEffect(() => {
    if (id_potrero) {
      getDato(id_potrero);
      setIcon('fas fa-arrow-left');
      setTextButton('Volver');
      getCoordenadas();
    }
    else {
      setIcon('fas fa-ban');
      setTextButton('Cancelar');
      setIsLoadingDatos(false);
    }
    getPredios();
    //eslint-disable-next-line
  }, [id_potrero]);
  
  const getDato = (id_potrero) => {
    setIsLoadingDatos(true);
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id_potrero}`,{withCredentials: true})
      .then(response => {
        setDato(response.data);
        setIsLoadingDatos(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  
  const getPredios = () => {
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/predios`,{withCredentials: true})
      .then(response => {
        setPredios(response.data);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  const getCoordenadas = async () => {
    setIsLoadingCoordenadas(true);
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_coordenadas/${id_potrero}`,{withCredentials: true});
      setCoordenadas(response.data);
      setIsLoadingCoordenadas(false);
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDato({ ...dato, [name]: value });
  };

  const enviarFormulario = (e) => {
    e.preventDefault();

    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }

    var data = {
      id_predio: dato.id_predio,
      n_potrero: dato.n_potrero,
      n_corto: dato.n_corto,
      centro_lat: dato.centro_lat,
      centro_lng: dato.centro_lng, 
      usuario : localStorage.getItem("usuario")
    };

    if (dato.id_potrero) {
      axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${dato.id_potrero}`, data,{withCredentials: true})
      .then(response => {
        toast.success('¡Registro actualizado correctamente!', {
          position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      })
      .catch(error => {
        if (error.response.status === 401){
          delete axios.defaults.headers.common["Authorization"]; 
          localStorage.removeItem("token"); 
          localStorage.removeItem("usuario");
          navigate('/login');
        } else {
          toast.error(error.response.data, {
            position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
        }
      });
    } else {
      axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`, data,{withCredentials: true})
      .then(response => {
        toast.success("Registro creado correctamente", {
          position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });

        navigate(`/${modulo}/${response.data.id_potrero}`);
      })
      .catch(error => {
        if (error.response.status === 401){
          delete axios.defaults.headers.common["Authorization"]; 
          localStorage.removeItem("token"); 
          localStorage.removeItem("usuario");
          navigate('/login');
        } else {
          toast.error(error.response.data, {
            position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
        }
      });
    }
  };

  /********** Funciones Modal 'ModalCoordenadas' *********/

  //Para agregar el addEventListener al modal 'ModalCoordenadas' cuando se dispare el Show 
  useEffect(() => {
    const modalElement = document.querySelector('#ModalCoordenadas');
    
    // Agregar un event listener para el evento cuando el modal se abre
    modalElement.addEventListener('shown.bs.modal', onModalShowCoordenadas);

    // Agregar un event listener para el evento cuando el modal se cierra
    modalElement.addEventListener('hidden.bs.modal', onModalHideCoordenadas);

    return () => {
      modalElement.removeEventListener('shown.bs.modal', onModalShowCoordenadas);
      modalElement.removeEventListener('hidden.bs.modal', onModalHideCoordenadas);
    };
  }, []);

  const onModalShowCoordenadas = () => {
    modalInputLat.current.focus();
  }

  const onModalHideCoordenadas = () => {
    modalInputLat.current.value = "";
    modalInputLng.current.value = "";
    setActualIdCoordenada('');
    setNombreBotonModalCoord('Ingresar');
    setAccionBotonModalCoord('Ingresada');
  }

  const Grabar_Coordenada = () => {
    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }
    
    if(modalInputLat.current.value === ''){
      toast.error('Ingrese latitud', {
        position: "top-right", autoClose: 2000, hideProgressBar: true,
        closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
      });
      modalInputLat.current.focus();
    } else if(modalInputLat.current.value === ''){
        toast.error('Ingrese longitud', {
          position: "top-right", autoClose: 2000, hideProgressBar: true,
          closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
        modalInputLat.current.focus();
    } else {
      Swal.fire({
        title: 'Coordenadas', text: '¿Está seguro que desea ' + nombreBotonModalCoord.toLowerCase() + ' esta coordenada?',
        icon: 'question', showCancelButton: true, confirmButtonColor: '#198754', cancelButtonColor: '#666',
        confirmButtonText: nombreBotonModalCoord, cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed)
        {
          // Bloquear pantalla mientras se realiza la operación
          const loadingSwal = Swal.fire({
            title: 'Cargando datos', text: 'Por favor, espere un momento...',
            allowOutsideClick: false,showConfirmButton: false, willOpen: () => {Swal.showLoading();}
          });
  
          var data = {
            id_potrero      : id_potrero,
            id_predio       : dato.id_predio,
            lat             : modalInputLat.current.value,
            lng             : modalInputLng.current.value,
            orden_anterior  : ordenAnterior,
            orden_nuevo     : actualOrden, 
            total_coordenadas : coordenadas.length, 
            usuario : localStorage.getItem("usuario")
          };
          
          try{
            if(actualIdCoordenada !==''){
              await axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_coordenadas/${actualIdCoordenada}`, data,{withCredentials: true});
            } else {
              await axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_coordenadas/${dato.id_predio}/${id_potrero}`, data,{withCredentials: true});
            }

            loadingSwal.close();
  
            //***********************************
            //Cerrar modal
            const modalElement = modalCoordenadas.current;
            if (modalElement) {
              const bootstrapModal = bootstrap.Modal.getInstance(modalElement);
              bootstrapModal.hide();
            } 
            //***********************************
  
            toast.success("Coordenada " + accionBotonModalCoord.toLowerCase() + " correctamente", {
              position: "top-right", autoClose: 2000, hideProgressBar: true,
              closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });

            setNombreBotonModalCoord('Ingresar');
            setAccionBotonModalCoord('Ingresada');

            getCoordenadas();
          } catch(error){
            if (error.response.status === 401){
              delete axios.defaults.headers.common["Authorization"]; 
              localStorage.removeItem("token"); 
              localStorage.removeItem("usuario");
              navigate('/login');
            } else {
              loadingSwal.close();
              console.log(error);
              toast.error(error.response.data, {
                position: "top-right", autoClose: 2000, hideProgressBar: true,
                closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
              });
            }
          };
        }
      });
    }
  }

  const SetCoordenada = (event, id_coordenada, latitud, longitud, orden, total_filas_coordenadas) => {
    event.preventDefault();
    setNombreBotonModalCoord('Actualizar');
    setAccionBotonModalCoord('Actualizada');
    setActualIdCoordenada(id_coordenada);
    modalInputLat.current.value = latitud;
    modalInputLng.current.value = longitud;
    
    setActualOrden(orden);
    setOrdenAnterior(orden);
    setTotalFilasOrden(total_filas_coordenadas);

    const modal = new bootstrap.Modal(modalCoordenadas.current);
    modal.show();
  }

  const opcionesFilasOrden = Array.from({ length: totalFilasOrden }, (_, index) => index + 1).map((numero) => (
    <option key={numero} value={numero}>
      {numero}
    </option>
  ));

  const handleOrdenChange = (event) => {
    setActualOrden(event.target.value);
  };

  const handleDeleteCoordenada = (event, id_coordenada) => {
    event.preventDefault();
    Swal.fire({
      title: 'Eliminar registro', text: '¿Estás seguro que deseas eliminar este registro?',
      icon: 'warning', showCancelButton: true, confirmButtonColor: '#d33', cancelButtonColor: '#666',
      confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar',
    }).then((result) => {
      // Si se confirma la eliminación, hacer la petición a la API para eliminar el elemento
      if (result.isConfirmed) 
      {
        axios.delete(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_coordenadas/${id_coordenada}`,{withCredentials: true}).then(response =>
        {
          if (response.data) {
            toast.success('¡Registro eliminado correctamente!', {
              position: "top-right", autoClose: 2000, hid_fichaeProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });

            getCoordenadas();
          } else {
            toast.error('¡No se pudo eliminar el registro!', {
              position: "top-right", autoClose: 2000, hid_fichaeProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        });
      }
    });
  };

  const handleKeyPressCoordenada = (event) => {
    // Permitir números, punto decimal y signo negativo
    if (!/[0-9.-]/.test(event.key)) {
      event.preventDefault();
    }
  
    // Permitir solo un punto decimal
    if (event.key === '.' && event.target.value.includes('.')) {
      event.preventDefault();
    }
  
    // Permitir solo un signo negativo al principio
    if (event.key === '-' && event.target.selectionStart !== 0) {
      event.preventDefault();
    }
  };
  /*********************************************************/

  return (
    <div className="row justify-content-center w-100">
      <div className="col-12 col-sm-11 col-md-7 col-lg-6 col-xl-5">
        {isLoadingDatos &&  
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Cargando datos...</span>
            </div>
          </div>
        }
        {!isLoadingDatos &&  
        <form onSubmit={enviarFormulario}>
          <div className="form-group">
            <label>Predio:</label>
            <select 
              className="form-select form-select-sm" 
              name="id_predio" 
              value={dato.id_predio} 
              onChange={handleInputChange} 
              disabled={coordenadas.length > 0}
              required
            >
              <option value="">Seleccione un predio</option>
              {predios.map((opcion) => (
                <option key={opcion.id_predio} value={opcion.id_predio}>{opcion.n_predio}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
              <label>Potrero:</label>
              <input type="text" name="n_potrero" value={dato.n_potrero} autoFocus 
              onChange={handleInputChange} className="form-control form-control-sm" required/>
          </div>

          <div className="form-group">
              <label>Nombre corto:</label>
              <input type="text" name="n_corto" 
              value={dato.n_corto} onChange={handleInputChange} 
              className="form-control form-control-sm" required/>
          </div>
          <small><label>Coordenadas centro mapa</label></small>
          <div className="form-group">
              <label>Latitud:</label>
              <input 
                type="text" 
                name="centro_lat" 
                onKeyDown={handleKeyPressCoordenada} 
                maxLength="20" 
                value={dato.centro_lat} 
                onChange={handleInputChange} 
                className="form-control form-control-sm" 
                required/>
          </div>

          <div className="form-group">
              <label>Longitud:</label>
              <input 
                type="text" 
                name="centro_lng" 
                onKeyDown={handleKeyPressCoordenada} 
                maxLength="20" 
                value={dato.centro_lng} 
                onChange={handleInputChange} 
                className="form-control form-control-sm" 
                required/>
          </div>

          {id_potrero && isLoadingCoordenadas &&  
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Cargando datos...</span>
              </div>
            </div>
            }
          {id_potrero && !isLoadingCoordenadas &&  
          <div className="row pt-1">
            <div className="col">
              <div className="row">
                <div className="col-6">
                  <small><label><b>Coordenadas polígono</b></label></small> 
                </div>
                <div className="col-6 text-end">
                  <button 
                    type="button" 
                    className="btn btn-primary btn-sm pt-0 pb-0"
                    data-bs-toggle="modal" 
                    data-bs-target="#ModalCoordenadas"  
                  >
                    <i className="fas fa-plus-circle"></i> Agregar coordenada
                  </button>
                </div>
              </div>
              <div className="row pt-1">
                <div className="col">
                  <div className="table-responsive" style={{ maxHeight: 'calc(200px)' }}>
                    <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                        <thead className="sticky-top">
                        <tr>
                          <th>Orden</th>
                          <th>Latitud</th>
                          <th>Longitud</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {coordenadas.map((tupla, index) => {
                          // const cont = index + 1;
                          return (
                          <tr key={tupla.id_coordenada}>
                            <td>{tupla.orden}</td>
                            <td>{tupla.lat}</td>
                            <td>{tupla.lng}</td>
                            <td>
                              <button title="Editar"
                                onClick={(event) => SetCoordenada(event, tupla.id_coordenada, tupla.lat, tupla.lng, tupla.orden, coordenadas.length)} 
                                className="btn btn-sm btn-outline-secondary pt-0 pb-0 ps-1 pe-1">
                                <i className="fa fa-edit fa-xs"></i>
                              </button>
                              &nbsp;
                              <button title="Eliminar"
                                onClick={(event) => handleDeleteCoordenada(event, tupla.id_coordenada)} 
                                className="btn btn-sm btn-outline-secondary pt-0 pb-0 ps-1 pe-1">
                                <i className="fa fa-trash-alt fa-xs"></i>
                              </button>
                            </td>
                          </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }

          <hr/>
          <div className="pt-1">
            <button type="submit" className="btn btn-success btn-sm">
              <i className="fas fa-check"></i> Guardar
            </button>
            &nbsp;
            <Link to={`/${modulo}`} className="btn btn-secondary btn-sm">
              <i className={icon} title="Cancelar"></i> {textButton}
            </Link>
          </div>
        </form>
        }
      </div>

      <div className="modal fade" id="ModalCoordenadas" ref={modalCoordenadas} 
        tabIndex="-1" aria-labelledby="ModalCoordenadasLabel" aria-hidden="true">
        <div className="modal-dialog border border-secondary">
          <div className={`modal-content ${darkMode ? 'text-light bg-dark' : ''}`}>
            <div className="modal-header border-bottom border-secondary">
              <h5 className="modal-title" id="ModalCoordenadasLabel">Coordenadas</h5>
              <button 
                type="button" 
                className={`btn-close ${darkMode ? 'bg-btn-close-modal': ''}`}
                data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              <div className="row pb-2">
                <label className="col-sm-3">Latitud:</label>
                <div className="col-sm-9">
                  <input 
                    maxLength="20" 
                    type="text" 
                    name="modal_lat" 
                    className="form-control form-control-sm" 
                    onKeyDown={handleKeyPressCoordenada} 
                    ref={modalInputLat} 
                  />
                </div>
              </div>

              <div className="row pb-2">
                <label className="col-sm-3">Longitud:</label>
                <div className="col-sm-9">
                  <input 
                    maxLength="20" 
                    type="text" 
                    name="modal_lng" 
                    className="form-control form-control-sm" 
                    onKeyDown={handleKeyPressCoordenada} 
                    ref={modalInputLng} 
                  />
                </div>
              </div>
              {actualIdCoordenada && 
              <div className="row">
                <label className="col-sm-3">Orden:</label>
                <div className="col-sm-9">
                  <select 
                    onChange={handleOrdenChange}
                    value={actualOrden} 
                    className="form-select form-select-sm" 
                    name="modal_orden" 
                    ref={modalInputOrden}
                  >
                    {opcionesFilasOrden}
                  </select>
                </div>
              </div>
              }
            </div>
            <div className="modal-footer border-top border-secondary">
              <button type="button" className="btn btn-success btn-sm"
                onClick={() => Grabar_Coordenada()}>
                {nombreBotonModalCoord}
              </button>

              <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>{/*fin modal*/}
    </div>
  );
}

export default PotrerosForm;
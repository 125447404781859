import React, { useState, useEffect, useRef} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useDarkMode } from '../../../context/darkModeContext'; 

import { isValidDate } from '../../../utils/utils';

function Movindividual() {
  // eslint-disable-next-line
  const {darkMode, setDarkMode} = useDarkMode();
  const navigate = useNavigate();

  const [datosOriginales, setDatosOriginales] = useState([]); 
  const [datos, setDatos] = useState([]); 
  const [isLoadingDatos, setIsLoadingDatos] = useState(false);

  const [sortingColumn, setSortingColumn] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
  const [sortDirection, setSortDirection] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

  const inputEspecie = useRef(null);
  const inputAnio = useRef(null);
  const inputMes = useRef(null);

  const [selectedEspecie, setSelectedEspecie] = useState('');
  const [especies, setEspecies] = useState([]);

  const modulo = 'movlote'

  const [anios, setAnios] = useState([]);
  const [selectedAnio, setSelectedAnio] = useState('');

  const [meses, setMeses] = useState([]);
  const [selectedMes, setSelectedMes] = useState('');

  useEffect( ()=> {
    getEspecies();

    const storedEspecie = sessionStorage.getItem('MovLoteSelectedEspecie');
    setSelectedEspecie(storedEspecie || '');

    const anioInicial = 2022;
    const anioActual = new Date().getFullYear();

    const fechaActual = new Date();
    const numeroMes = fechaActual.getMonth() + 1; // Los meses comienzan desde 0

    // Asegurarse de tener un cero adelante si el número del mes es menor que 10
    const mesConCero = numeroMes < 10 ? `0${numeroMes}` : `${numeroMes}`;
    
    const storedAnio = sessionStorage.getItem('MovLoteSelectedAnio');
    setSelectedAnio(storedAnio || '');

    const storedMes = sessionStorage.getItem('MovLoteSelectedMes');
    setSelectedMes(storedMes || mesConCero);

    const aniosArray = Array.from({ length: anioActual - anioInicial + 1 }, (_, index) => {
      const anio = anioActual - index;
      return anio.toString();
    });

    setAnios(aniosArray);

    const nombresMeses = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    const mesesArray = Array.from({ length: 12 }, (_, index) => {
      const mesNumero = index + 1;
      const mes = mesNumero < 10 ? `0${mesNumero}` : `${mesNumero}`;
      const descripcionMes = nombresMeses[index];
      return { mes, descripcionMes };
    });

    setMeses(mesesArray);
    // eslint-disable-next-line
  },[]);

  
  /************ INICIO FUNCIONES ORDEN ARRAY (DATOS TABLA) *************************************** */
  const handleColumnClick = (columnIndex) => {
    // Si la columna actualmente ordenada es la misma que se hizo clic, cambia la dirección de ordenamiento
    // Si la columna actualmente ordenada es diferente, establece la columna y la dirección de ordenamiento predeterminada a 'asc'
    if (sortingColumn === columnIndex) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortingColumn(columnIndex);
      setSortDirection('asc');
    }
  };
  useEffect(() => {    
    const rows = [...datosOriginales]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
    if (sortingColumn !== -1) {  
      rows.sort((a, b) => {
        const valA = a[Object.keys(a)[sortingColumn]];
        const valB = b[Object.keys(b)[sortingColumn]];

        if (typeof valA === 'number' && typeof valB === 'number') {
          return valA - valB;
        } else if (isValidDate(valA) && isValidDate(valB)) {
          const dateA = new Date(valA.split('-').reverse().join('-'));
          const dateB = new Date(valB.split('-').reverse().join('-'));
          return dateA - dateB;
        } else {
          return valA.localeCompare(valB);
        }
      });
  
      if (sortDirection === 'desc') {
        rows.reverse();
      }
      setDatos(rows); 
    }
  }, [sortingColumn, sortDirection, datosOriginales]); // Dependencias: sortingColumn, sortDirection, datosOriginales
  /************ FIN FUNCIONES ORDEN ARRAY (DATOS TABLA) *************************************** */

  const handleDelete = (id_mov) => {
    Swal.fire({
      title: 'Eliminar registro', text: '¿Estás seguro que deseas eliminar este registro?',
      icon: 'warning', showCancelButton: true, confirmButtonColor: '#d33', cancelButtonColor: '#666',
      confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar',
    }).then((result) => {
      // Si se confirma la eliminación, hacer la petición a la API para eliminar el elemento
      if (result.isConfirmed) 
      {
        axios.delete(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id_mov}`,{withCredentials: true}).then(response =>
        {
          if (response.data) {
            toast.success('¡Registro eliminado correctamente!', {
              position: "top-right", autoClose: 2000, hid_moveProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });

            setDatos(datos.filter(datos => datos.id_mov !== id_mov)); //Quitar del arreglo la fila del id_mov eliminado
          } else {
            toast.error('¡No se pudo eliminar el registro!', {
              position: "top-right", autoClose: 2000, hid_moveProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
      }
    });
  };

  const getEspecies = () => {
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/especies`,{withCredentials: true})
      .then(response => {
        setEspecies(response.data);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };
  
  const getDatos = () => {
    setIsLoadingDatos(true);
    const v_id_especie = inputEspecie.current.value;
    const v_anio = inputAnio.current.value;
    const v_mes = inputMes.current.value;
    
    sessionStorage.setItem('MovLoteSelectedEspecie', v_id_especie);
    sessionStorage.setItem('MovLoteSelectedAnio', v_anio);
    sessionStorage.setItem('MovLoteSelectedMes', v_mes);

    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`, 
      {
        withCredentials: true, 
        params: {
          id_especie : v_id_especie,
          anio : v_anio,
          mes: v_mes
        }
      })
      .then(response => {
        setDatosOriginales(response.data); // Guardar los datos originales
        setDatos(response.data); // Hacer una copia para mantener los datos originales en 'datos' al inicio
        setIsLoadingDatos(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };
  
  return (
    <div className="row justify-content-center w-100">
      <div className="col">
        <small>
        <Link to={`/${modulo}/new`} className="btn btn-primary btn-sm">
          <i className="fa fa-plus"></i>&nbsp;Nuevo movimiento
        </Link>
        <div className="row pt-2 pb-1">
          <label className="col-sm-1">Especie:</label>
          <div className="col-sm-2">
            <select value={selectedEspecie} 
              onChange={(event) => { 
                setSelectedEspecie(event.target.value)
                setDatosOriginales([]);
                setDatos([]);
              }}
              className="form-select form-select-sm" 
              name="id_especie" ref={inputEspecie}>
              {especies.map((opcion) => (
                <option key={opcion.id_especie} value={opcion.id_especie}>{opcion.n_especie}</option>
              ))}
            </select>
          </div>
          <label className="col-sm-1">Año:</label>
          <div className="col-sm-2">
            <select 
              className="form-select form-select-sm" 
              name="anio" 
              ref={inputAnio}
              value={selectedAnio} 
              onChange={(event) => { 
                setSelectedAnio(event.target.value);
                setDatosOriginales([]);
                setDatos([]);
              }}>
              {anios.map((anio) => (
                <option key={anio} value={anio}>{anio}</option>
              ))}
            </select>
          </div>
          <label className="col-sm-1">Mes:</label>
          <div className="col-sm-3">
            <select 
              className="form-select form-select-sm" 
              name="mes" 
              ref={inputMes}
              value={selectedMes} 
              onChange={(event) => { 
                setSelectedMes(event.target.value);
                setDatosOriginales([]);
                setDatos([]);
              }}>
              {meses.map(({ mes, descripcionMes }) => (
                <option key={mes} value={mes}>{descripcionMes }</option>
              ))}
            </select>
          </div>
          <div className="col-sm-2">
            <button 
              className="btn btn-outline-secondary btn-sm" type="button" 
              id="bot_buscar" onClick={() => getDatos()}>
                <i className="fa fa-search"></i>&nbsp;Buscar</button>
          </div>
        </div>
        </small>
        {isLoadingDatos &&  
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Cargando datos...</span>
            </div>
          </div>
        }
        {!isLoadingDatos &&  
        <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
          <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
            <thead className="sticky-top">
              <tr>
                <th onClick={() => handleColumnClick(1)} className={sortingColumn === 1 ? `sorting ${sortDirection}` : ''}>N° Mov</th>
                <th onClick={() => handleColumnClick(2)} className={sortingColumn === 2 ? `sorting ${sortDirection}` : ''}>Fec. Mov</th>
                <th onClick={() => handleColumnClick(3)} className={sortingColumn === 3 ? `sorting ${sortDirection}` : ''}>Especie</th>
                <th onClick={() => handleColumnClick(4)} className={sortingColumn === 4 ? `sorting ${sortDirection}` : ''}>Raza</th>
                <th onClick={() => handleColumnClick(5)} className={sortingColumn === 5 ? `sorting ${sortDirection}` : ''}>Categoría</th>
                <th onClick={() => handleColumnClick(6)} className={sortingColumn === 6 ? `sorting ${sortDirection}` : ''}>N° Lote</th>
                <th>Origen</th>
                <th>Destino</th>
                <th>Cantidad</th>
                <th>Responsable</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {datos.map(dato => {
                let strOrigen   = dato.ori_n_predio + "/" + dato.ori_n_potrero;
                if(dato.ori_n_subpotrero !== ''){strOrigen+="/" + dato.ori_n_subpotrero}
                let strDestino  = dato.dest_n_predio + "/" + dato.dest_n_potrero;
                if(dato.dest_n_subpotrero !== ''){strDestino+="/" + dato.dest_n_subpotrero}
                return (
                <tr key={dato.id_mov} >
                  <td>{dato.id_mov}</td>
                  <td>{dato.fecha_mov}</td>
                  <td>{dato.n_especie}</td>
                  <td>{dato.n_raza}</td>
                  <td>{dato.categoria}</td>
                  <td>{dato.origen_id_lote}</td>
                  <td>{strOrigen}</td>
                  <td>{strDestino}</td>
                  <td className='text-end'>
                  {
                    dato.cantidad.toLocaleString('es-CL', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  </td>
                  <td>{dato.n_responsable}</td>
                  <td>
                    <Link to={`/${modulo}/${dato.id_mov}`} className="btn btn-sm btn-outline-secondary">
                      <i className="fa fa-edit" title="Editar"></i>
                    </Link>
                    &nbsp;
                    <button onClick={() => handleDelete(dato.id_mov)} className="btn btn-sm btn-outline-secondary">
                      <i className="fa fa-trash-alt" title="Eliminar"></i>
                    </button>
                  </td>
                </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        }
      </div>
    </div>
  );
}

export default Movindividual;
import React, {  useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { GoogleMap, LoadScript, Polygon, InfoWindow } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "600px",
  position: "relative"
};

// const titleContainerStyle = {
//   position: "absolute",
//   top: "70px", // Ajusta la posición vertical del contenedor del título
//   left: "50%", // Coloca el contenedor del título en el centro horizontalmente
//   transform: "translateX(-50%)", // Centra el contenedor del título en el punto especificado
//   zIndex: 1, // Asegura que el título esté en la parte superior del mapa
// };

// const titleStyle = {
//   backgroundColor: "rgba(255, 255, 255, 0.7)", // Fondo semi-transparente para hacer el texto legible
//   padding: "10px", // Espaciado interno del título
//   borderRadius: "5px", // Esquinas redondeadas para el fondo
// };

const MapaPotreros = ({ onPotreroClick, id_predio, centro_lat, centro_lng, n_predio, color }) => {
  const navigate = useNavigate();
  const [coordenadasPotreros, setCoordenadasPotreros] = useState([]);

  //const [especiesYCategorias, setEspeciesYCategorias] = useState([]);

  const center = {
    lat: parseFloat(centro_lat),
    lng: parseFloat(centro_lng),
  };

  const getCoordenadasPotreros = async () => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/coordenadas_potreros/${id_predio}`,{withCredentials: true});
      // Transformar el formato del campo 'coordenadas' en un arreglo de objetos
      const newData = response.data.map(item => ({
        ...item,
        coordenadas: JSON.parse(item.coordenadas),
      }));
      setCoordenadasPotreros(newData);
    } catch(error){
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    };
  };

  // const getDatosCategorias = async () => {
  //   try{
  //     const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/mapa_datoscategorias`,{withCredentials: true});
  //     setEspeciesYCategorias(response.data);
  //   } catch(error){
  //     console.log(error);
  //     if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
  //   };
  // };

  // async function getStockCategoria_IdPotrero_IdCalculo (v_id_potrero, v_id_calculo){
  //   let stock = 0;
  //   try{
  //     const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/coordenadas_potreros_stock_potrero_categoria/${v_id_potrero}/${v_id_calculo}`,{withCredentials: true});
  //     stock = response.data.stock;
  //   } catch(error){
  //     console.log(error);
  //     if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
  //   };
  //   return stock;
  // };

  useEffect(() => {
    getCoordenadasPotreros();
    //getDatosCategorias();
    // eslint-disable-next-line
  }, [id_predio]);

  return (
    <LoadScript googleMapsApiKey="AIzaSyBpGnfol6dU0f-KfDDX-gjaok41FDeZa1s">
      {/* <div style={titleContainerStyle}>
        <small><span className="p-1 m-1" style={titleStyle}>{n_predio}</span></small>
      </div> */}
      <div style={containerStyle}>
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={12} mapTypeId={"satellite"}>
          {coordenadasPotreros.map(item => (
            <React.Fragment key={item.id_potrero}>
              <Polygon 
                paths={item.coordenadas} 
                options={{
                  fillColor: color, 
                  fillOpacity: 0.4,
                  strokeColor: color,
                  strokeOpacity: 0.9,
                  strokeWeight: 2,
                }}
                onClick={() => onPotreroClick(item.id_potrero, item.centro_lat, item.centro_lng, n_predio, item.n_potrero, item.color)}
              />
              <InfoWindow position={{ lat: parseFloat(item.centro_lat), lng: parseFloat(item.centro_lng) }}>
                <div key={item.id_potrero}
                    onClick={() => onPotreroClick(item.id_potrero, item.centro_lat, item.centro_lng, n_predio, item.n_potrero, item.color)}
                    style={{color: '#333', cursor: 'pointer'}}>
                    <label style={{color: '#000'}}>
                      <strong>{item.n_potrero}</strong>
                    </label>
                    {item.stocksCat && 
                      JSON.parse(item.stocksCat).map((especie)  => (
                        <table key={especie.id_especie} className="table table-fixed table-hover table-sm small">
                        <thead>
                          <tr key={especie.id_especie}>
                            <th key={especie.id_especie} colSpan={2} className="text-center">{especie.n_especie}</th>
                          </tr>
                        </thead>
                        <tbody>                            
                        {especie.detalles &&
                          especie.detalles.map((detalle, index) => (
                            <tr key={index}>
                              <td>{detalle.sigla}</td>
                              <td className="text-end">
                              {
                                detalle.stock.toLocaleString('es-CL', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}
                              </td>
                            </tr>
                          ))
                        }
                        </tbody>
                      </table>
                      ))
                    }
                </div>
              </InfoWindow>
            </React.Fragment>
          ))}
        </GoogleMap>
      </div>
    </LoadScript>
  );
};

export default MapaPotreros;
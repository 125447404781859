import React, { useState, useEffect, useRef} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useDarkMode } from '../../context/darkModeContext'; 

import { isValidDate } from '../../utils/utils';

function Productos() {
  // eslint-disable-next-line
  const {darkMode, setDarkMode} = useDarkMode();
  const [datosOriginales, setDatosOriginales] = useState([]); // Estado para almacenar los datos originales
  const [datos, setDatos] = useState([]); // Estado para almacenar los datos ordenados
  const [isLoadingDatos, setIsLoadingDatos] = useState(false);
  const [tiposproductos, setTiposproductos] = useState([]);

  const [sortingColumn, setSortingColumn] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
  const [sortDirection, setSortDirection] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'
  
  const inputTipoProducto = useRef(null);
  const inputBuscar = useRef(null);

  const [selectedTipoProducto, setSelectedTipoProducto] = useState('');
  const [selectedBusquedaAprox, setSelectedBusquedaAprox] = useState('');

  const navigate = useNavigate();
  const modulo = 'productos'

  useEffect( ()=> {
    getTiposproductos();
    // inputEstado.current.checked = true;

    const storedTipoProducto = sessionStorage.getItem('ProductosSelectedTipoProducto');
    const storedBusquedaAprox = sessionStorage.getItem('ProductosSelectedBusquedaAprox');
    
    setSelectedTipoProducto(storedTipoProducto || '')    
    setSelectedBusquedaAprox(storedBusquedaAprox || '');
    // eslint-disable-next-line
  },[]);

  const getTiposproductos = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/tiposproductos`,{withCredentials: true});
      setTiposproductos(response.data);
    } catch(error) { 
      console.log(error);
      if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
    }
  };

  const getDatos = () => {
    setIsLoadingDatos(true);
    const v_id_tipoproducto = inputTipoProducto.current.value;
    const strBuscar = inputBuscar.current.value;

    sessionStorage.setItem('ProductosSelectedTipoProducto', v_id_tipoproducto);
    sessionStorage.setItem('ProductosSelectedBusquedaAprox', strBuscar);

    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`, 
      {
        withCredentials: true, 
        params: {
          id_tipoproducto : v_id_tipoproducto,
          strBuscar: strBuscar
        }
      })
      .then(response => {
        setDatosOriginales(response.data); // Guardar los datos originales
        setDatos(response.data); // Hacer una copia para mantener los datos originales en 'datos' al inicio
        setIsLoadingDatos(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  const handleKeyPressBuscar = (event) => {
    if (event.key === 'Enter') {
      getDatos();
    }
  };

  /************ INICIO FUNCIONES ORDEN ARRAY (DATOS TABLA) *************************************** */
  const handleColumnClick = (columnIndex) => {
    if (sortingColumn === columnIndex) { setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else { setSortingColumn(columnIndex); setSortDirection('asc');}
  };

  useEffect(() => {    
    const rows = [...datosOriginales]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
    if (sortingColumn !== -1) {  
      rows.sort((a, b) => {
        const valA = a[Object.keys(a)[sortingColumn]]; const valB = b[Object.keys(b)[sortingColumn]];

        if (typeof valA === 'number' && typeof valB === 'number') { return valA - valB;
        } else if (isValidDate(valA) && isValidDate(valB)) { const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
        } else { return valA.localeCompare(valB); }
      });
  
      if (sortDirection === 'desc') { rows.reverse();}
      setDatos(rows); // Actualizamos el estado de sortedRows con los datos ordenados
    }
  }, [sortingColumn, sortDirection, datosOriginales]); // Dependencias: sortingColumn, sortDirection, datosOriginales
  /************ FIN FUNCIONES ORDEN ARRAY (DATOS TABLA) *************************************** */

  const handleDelete = (id_producto) => {
    Swal.fire({
      title: 'Eliminar registro', text: '¿Estás seguro que deseas eliminar este registro?',
      icon: 'warning', showCancelButton: true, confirmButtonColor: '#d33', cancelButtonColor: '#666',
      confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar',
    }).then((result) => {
      // Si se confirma la eliminación, hacer la petición a la API para eliminar el elemento
      if (result.isConfirmed) 
      {
        axios.delete(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id_producto}`,{withCredentials: true}).then(response =>
        {
          if (response.data) {
            toast.success('¡Registro eliminado correctamente!', {
              position: "top-right", autoClose: 2000, hid_productoeProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });

            setDatos(datos.filter(datos => datos.id_producto !== id_producto)); //Quitar del arreglo la fila del id_producto eliminado
          } else {
            toast.error('¡No se pudo eliminar el registro!', {
              position: "top-right", autoClose: 2000, hid_productoeProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        });
      }
    });
  };
  
  return (
    <div className="row justify-content-center w-100">
      <div className="col">
        <small>
        <Link to={`/${modulo}/new`} className="btn btn-primary btn-sm">
          <i className="fa fa-plus"></i>&nbsp;Nuevo
        </Link>
        <div className="row pt-2 pb-1">
          <label className="col-sm-1">Tipo:</label>
          <div className="col-sm-3">
            <select 
              className="form-select form-select-sm" 
              name="id_tipoproducto" 
              ref={inputTipoProducto} 
              value={selectedTipoProducto} 
              onChange={(event) => { 
                setSelectedTipoProducto(event.target.value);
                setDatosOriginales([]);
                setDatos([]);
              }}>
              {tiposproductos.map((opcion) => (
                <option key={opcion.id_tipoproducto} value={opcion.id_tipoproducto}>{opcion.n_tipoproducto}</option>
              ))}
            </select>
          </div>
          <div className="col-sm-5">
            <input 
              value={selectedBusquedaAprox} onChange={(event) => { setSelectedBusquedaAprox(event.target.value)}}
              type="text" className="form-control form-control-sm" 
              placeholder="Buscar por Id / Nombre" 
              aria-label="Buscar por Id / Nombre" 
              aria-describedby="bot_buscar" 
              onKeyDown={handleKeyPressBuscar}
              autoFocus 
              ref={inputBuscar}/>
          </div>
          <div className="col-sm-2">
            <button 
              className="btn btn-outline-secondary btn-sm" type="button" 
              id="bot_buscar" onClick={() => getDatos()}>
                <i className="fa fa-search"></i>&nbsp;Buscar</button>
          </div>
        </div>
        </small>

        {isLoadingDatos &&  
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Cargando datos...</span>
            </div>
          </div>
        }
        {!isLoadingDatos &&  
        <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
          <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
            <thead className="sticky-top">
              <tr>
                <th onClick={() => handleColumnClick(1)} className={sortingColumn === 1 ? `sorting ${sortDirection}` : ''}>Id</th>
                <th onClick={() => handleColumnClick(2)} className={sortingColumn === 2 ? `sorting ${sortDirection}` : ''}>Nombre</th>
                <th onClick={() => handleColumnClick(3)} className={sortingColumn === 3 ? `sorting ${sortDirection}` : ''}>Tipo</th>
                <th onClick={() => handleColumnClick(4)} className={sortingColumn === 4 ? `sorting ${sortDirection}` : ''}>Marca</th>
                <th onClick={() => handleColumnClick(5)} className={sortingColumn === 5 ? `sorting ${sortDirection}` : ''}>Método Aplic.</th>
                <th onClick={() => handleColumnClick(6)} className={sortingColumn === 6 ? `sorting ${sortDirection}` : 'text-end'}>Stock</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {datos.map(dato => (
                <tr key={dato.id_producto}>
                  <td>{dato.id_producto}</td>
                  <td>{dato.n_producto}</td>
                  <td>{dato.n_tipoproducto}</td>
                  <td>{dato.n_marca}</td>
                  <td>{dato.n_metodo}</td>
                  <td className='text-end'>
                  {
                    dato.stock.toLocaleString('es-CL', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  </td>
                  {/* <td>{dato.number.toLocaleString('es-CL')}</td> */}
                  <td>
                    <Link to={`/${modulo}/${dato.id_producto}`} className="btn btn-sm btn-outline-secondary">
                      <i className="fa fa-edit" title="Editar"></i>
                    </Link>
                    &nbsp;
                    <button onClick={() => handleDelete(dato.id_producto)} className="btn btn-sm btn-outline-secondary">
                      <i className="fa fa-trash-alt" title="Eliminar"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        }
      </div>
    </div>
  );
}

export default Productos;
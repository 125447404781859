import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

function CategoriasForm() {
  const { id_calculo } = useParams();
  const [icon, setIcon] = useState('');
  const [textButton, setTextButton] = useState('');
  const navigate = useNavigate();
  const [isLoadingDatos, setIsLoadingDatos] = useState(true);
  const modulo = 'categorias'

  const initialDatoState = {
    id_calculo: null,
    id_especie: '', 
    sexo: '', 
    dias_min: '', 
    dias_max:'',
    capado:false,
    nombre:'',
    color:'',
    sigla:''
  };
  const [dato, setDato] = useState(initialDatoState);
  const [especies, setEspecies] = useState([]);

  const getEspecies = () => {
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/especies`,{withCredentials: true})
      .then(response => {
        setEspecies(response.data);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  useEffect(() => {
    if (id_calculo) {
      getEspecies();
      getDato(id_calculo);
      setIcon('fas fa-arrow-left');
      setTextButton('Volver');
    }
    // eslint-disable-next-line
  }, [id_calculo]);
  
  const getDato = (id_calculo) => {
    setIsLoadingDatos(true);
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id_calculo}`,{withCredentials: true})
      .then(response => {
        setDato(response.data);
        setIsLoadingDatos(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDato({ ...dato, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    setDato({ ...dato, [ event.target.name]: event.target.checked });
  };

  const enviarFormulario = (e) => {
    e.preventDefault();

    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }

    var data = {
        id_especie: dato.id_especie, 
        sexo: dato.sexo, 
        dias_min: dato.dias_min, 
        dias_max: dato.dias_max, 
        capado: dato.capado, 
        nombre: dato.nombre, 
        color: dato.color,
        sigla : dato.sigla, 
        usuario : localStorage.getItem("usuario")
    };

    if (dato.id_calculo) {
      axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${dato.id_calculo}`, data,{withCredentials: true})
      .then(response => {
        toast.success('¡Registro actualizado correctamente!', {
          position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      })
      .catch(error => {
        if (error.response.status === 401){
          delete axios.defaults.headers.common["Authorization"]; 
          localStorage.removeItem("token"); 
          localStorage.removeItem("usuario");
          navigate('/login');
        } else {
          toast.error(error.response.data, {
            position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
        }
      });
    } 
  };

  return (
    <div className="row justify-content-center w-100">
      <div className="col-10 col-xs-8 col-md-8 col-lg-6  col-xl-4">
      {isLoadingDatos &&  
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Cargando datos...</span>
          </div>
        </div>
      }
      {!isLoadingDatos &&  
        <form onSubmit={enviarFormulario}>
          <div className="form-group">
            <label>Especie:</label>
            <select 
              className="form-select form-select-sm" 
              name="id_especie" 
              value={dato.id_especie} 
              onChange={handleInputChange} 
              disabled 
              required
            >
              {especies.map((opcion) => (
                <option key={opcion.id_especie} value={opcion.id_especie}>{opcion.n_especie}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Sexo:</label>
            <select 
              className="form-select form-select-sm" 
              name="sexo" 
              value={dato.sexo} 
              onChange={handleInputChange} 
              disabled 
              required
            >
              <option key={0} value={'H'}>H</option>
              <option key={1} value={'M'}>M</option>
            </select>
          </div>

          <div className="form-group">
              <label>Días Min.:</label>
              <input type="text" name="dias_min" 
              value={dato.dias_min} onChange={handleInputChange} disabled 
              className="form-control form-control-sm" required/>
          </div>

          <div className="form-group">
              <label>Días Max.:</label>
              <input type="text" name="dias_max" 
              value={dato.dias_max} onChange={handleInputChange} disabled 
              className="form-control form-control-sm" required/>
          </div>
          
          <div className="form-check">
            <input className="form-check-input" onChange={handleCheckboxChange} name="capado" 
            type="checkbox" id="checkCapado" checked={dato.capado} disabled />
            <label className="form-check-label" htmlFor="checkCapado">
              Capado
            </label>
          </div>

          <div className="form-group">
              <label>Nombre:</label>
              <input type="text" name="nombre" 
              value={dato.nombre} onChange={handleInputChange} 
              className="form-control form-control-sm" required/>
          </div>

          <div className="form-group">
              <label>Sigla:</label>
              <input type="text" name="sigla" 
              value={dato.sigla} onChange={handleInputChange} 
              className="form-control form-control-sm" required/>
          </div>

          <div className="form-group">
              <label>Color:</label>
              <input type="text" name="color" 
              value={dato.color} onChange={handleInputChange} 
              className="form-control form-control-sm" required/>
          </div>

          <hr/>
          <div className="pt-1">
            <button type="submit" className="btn btn-success btn-sm">
              <i className="fas fa-check"></i> Guardar
            </button>
            &nbsp;
            <Link to={`/${modulo}`} className="btn btn-secondary btn-sm">
              <i className={icon} title="Cancelar"></i> {textButton}
            </Link>
          </div>
        </form>
      }
      </div>
    </div>
  );
}

export default CategoriasForm;
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

function EspeciesForm() {
  const { id_especie } = useParams();
  const [icon, setIcon] = useState('');
  const [textButton, setTextButton] = useState('');
  const navigate = useNavigate();
  const [isLoadingDatos, setIsLoadingDatos] = useState(true);
  const modulo = 'especies'

  const initialDatoState = {
    id_especie: null,
    n_especie: ''
  };
  const [dato, setDato] = useState(initialDatoState);

  useEffect(() => {
    if (id_especie) {
      getDato(id_especie);
      setIcon('fas fa-arrow-left');
      setTextButton('Volver');
    }
    else {
      setIcon('fas fa-ban');
      setTextButton('Cancelar');
      setIsLoadingDatos(false);
    }
    // eslint-disable-next-line
  }, [id_especie]);
  
  const getDato = (id_especie) => {
    setIsLoadingDatos(true);
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id_especie}`,{withCredentials: true})
      .then(response => {
        setDato(response.data);
        setIsLoadingDatos(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDato({ ...dato, [name]: value });
  };

  const enviarFormulario = (e) => {
    e.preventDefault();

    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }
    
    var data = {
        n_especie : dato.n_especie, 
        usuario   : localStorage.getItem("usuario")
    };

    if (dato.id_especie) {
      axios.put(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${dato.id_especie}`, data,{withCredentials: true})
      .then(response => {
        toast.success('¡Registro actualizado correctamente!', {
          position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });
      })
      .catch(error => {
        if (error.response.status === 401){
          delete axios.defaults.headers.common["Authorization"]; 
          localStorage.removeItem("token"); 
          localStorage.removeItem("usuario");
          navigate('/login');
        } else {
          toast.error(error.response.data, {
            position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
        }
      });
    } else {
      axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`, data,{withCredentials: true})
      .then(response => {
        toast.success("Registro creado correctamente", {
          position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
        });

        navigate(`/${modulo}/${response.data.id_especie}`);
      })
      .catch(error => {
        if (error.response.status === 401){
          delete axios.defaults.headers.common["Authorization"]; 
          localStorage.removeItem("token"); 
          localStorage.removeItem("usuario");
          navigate('/login');
        } else {
          toast.error(error.response.data, {
            position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
          });
        }
      });
    }
  };

  return (
    <div className="row justify-content-center w-100">
      <div className="col-10 col-xs-8 col-md-8 col-lg-6  col-xl-4">
      {isLoadingDatos &&  
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Cargando datos...</span>
          </div>
        </div>
      }
      {!isLoadingDatos &&  
        <form onSubmit={enviarFormulario}>
          <div className="form-group">
              <label>Especie:</label>
              <input type="text" name="n_especie" autoFocus  
              value={dato.n_especie} onChange={handleInputChange} 
              className="form-control form-control-sm" required/>
          </div>

          <hr/>
          <div className="pt-1">
            <button type="submit" className="btn btn-success btn-sm">
              <i className="fas fa-check"></i> Guardar
            </button>
            &nbsp;
            <Link to={`/${modulo}`} className="btn btn-secondary btn-sm">
              <i className={icon} title="Cancelar"></i> {textButton}
            </Link>
          </div>
        </form>
      }
      </div>
    </div>
  );
}

export default EspeciesForm;
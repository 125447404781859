import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { isValidDate } from '../../utils/utils';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { useDarkMode } from '../../context/darkModeContext'; 

ChartJS.register(ArcElement, Tooltip, Legend);

const DashOvino = () => {
    // eslint-disable-next-line
    const {darkMode, setDarkMode} = useDarkMode();

    const [colorPorcentaje, setColorPorcentaje] = useState('');
    useEffect( ()=> {
        if(darkMode){
            setColorPorcentaje('#ccc');
        } else {
            setColorPorcentaje('#444');
        }
    },[darkMode]);
    
    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            datalabels: {
                labels: {
                    //title: null, // Desactivar el título dentro del pie
                    value: {
                        color: colorPorcentaje, // Color del texto del porcentaje
                        formatter: (value, context) => {
                            // Formatear el valor dentro del gráfico
                            return value;
                        },
                        font: {
                            size: 10,
                        },
                    },
                    percentage: {
                        formatter: (value, context) => {
                            // Formatear el porcentaje fuera del gráfico
                            const total = context.dataset.data.reduce((acc, num) => acc + num, 0);
                            const percentage = ((value / total) * 100).toFixed(1).replace('.', ',') + '%';
                            return percentage;
                        },
                        font: {
                            size: 10,
                        },
                        color: colorPorcentaje, // Color del texto del porcentaje
                        anchor: 'end',
                        align: 'end',
                        //offset: 0, // Ajusta la distancia entre el texto y el borde del gráfico
                    },
                },
            },
        },
        responsive: true,
        maintainAspectRatio: true, // Si se descomenta este línea el gráfico se ve pequeño y no rellena todo el espacio disponible
        layout: {
            padding: {
                left: 34,
                top: 100
            },
        }
    };
    

    const navigate = useNavigate();
    const bootstrap = window.bootstrap; // se agregó para poder llamar al método hide() de un modal

    const [datosCategorias, setDatosCategorias] = useState([]);

    //--------------------------------------------------
    //--------------------- OVINO ----------------------
    //--------------------------------------------------
    const [ovinoChart, setOvinoChart] = useState(null);
    const [ovinoChartCat, setOvinoChartCat] = useState(null);

    const [ovinoDatosPredios, setOvinoDatosPredios] = useState([]);
    const [ovinoDatosPrediosOri, setOvinoDatosPrediosOri] = useState([]);

    const [ovinoDatosPotreros, setOvinoDatosPotreros] = useState([]);
    const [ovinoDatosPotrerosOri, setOvinoDatosPotrerosOri] = useState([]);

    const [ovinoDatosCat, setOvinoDatosCat] = useState(null);
    const [ovinoDatosCatOri, setOvinoDatosCatOri] = useState([]);

    const [sortingColumnPredioOvi, setSortingColumnPredioOvi] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
    const [sortDirectionPredioOvi, setSortDirectionPredioOvi] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

    const [sortingColumnPotreroOvi, setSortingColumnPotreroOvi] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
    const [sortDirectionPotreroOvi, setSortDirectionPotreroOvi] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

    const [sortingColumnCatOvi, setSortingColumnCatOvi] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
    const [sortDirectionCatOvi, setSortDirectionCatOvi] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

    const modalPotrerosOvi = useRef(null);
    const [isLoadingPotrerosOvi, setIsLoadingPotrerosOvi] = useState(false);
    const [n_predioOvi, setN_predioOvi] = useState('');

    const getOvinoChart = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/dashboard_chart_ovino`,{withCredentials: true});
            setOvinoChart(response.data);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    const getOvinoChartCat = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/dashboard_chart_ovino_categorias`,{withCredentials: true});
            setOvinoChartCat(response.data);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    const getOvinoDatosPredios = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/dashboard_datos_ovino_predios`,{withCredentials: true});
            setOvinoDatosPrediosOri(response.data);
            setOvinoDatosPredios(response.data);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    const getOvinoDatosPotreros = async (v_id_predio) => {
        setIsLoadingPotrerosOvi(true);
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/dashboard_datos_ovino_potreros/${v_id_predio}`,{withCredentials: true});
            setOvinoDatosPotrerosOri(response.data);
            setOvinoDatosPotreros(response.data);
            setIsLoadingPotrerosOvi(false);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    const getOvinoDatosCategorias = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/dashboard_datos_ovino_categorias`,{withCredentials: true});
            setOvinoDatosCatOri(response.data);
            setOvinoDatosCat(response.data);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    const getDatosCategoriasXEspecie = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/categoriasdatosxespecie/2`,{withCredentials: true});
            setDatosCategorias(response.data);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    function obtenerSiglaPorId(id_calculo) {
        const elementoEncontrado = datosCategorias.find(item => item.id_calculo === id_calculo);
        return elementoEncontrado ? elementoEncontrado.sigla : null;
    }

    /************ INICIO funciones orden array (STOCK PREDIO) *************************************** */
    const handleColumnClickPredioOvi = (columnIndex) => {
        if (sortingColumnPredioOvi === columnIndex) {
            setSortDirectionPredioOvi(sortDirectionPredioOvi === 'asc' ? 'desc' : 'asc');
        } else {
            setSortingColumnPredioOvi(columnIndex); setSortDirectionPredioOvi('asc');
        }
    };

    useEffect(() => {    
        const rows = [...ovinoDatosPrediosOri]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
        if (sortingColumnPredioOvi !== -1) {  
            rows.sort((a, b) => {
                const valA = a[Object.keys(a)[sortingColumnPredioOvi]]; const valB = b[Object.keys(b)[sortingColumnPredioOvi]];

                if (typeof valA === 'number' && typeof valB === 'number') {
                    return valA - valB;
                } else if (isValidDate(valA) && isValidDate(valB)) {
                    const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
                } else { return valA.localeCompare(valB);}
            });
    
            if (sortDirectionPredioOvi === 'desc') { rows.reverse();}
            setOvinoDatosPredios(rows); // Actualizamos el estado de sortedRows con los datos ordenados
        }
    }, [sortingColumnPredioOvi, sortDirectionPredioOvi, ovinoDatosPrediosOri]); // Dependencias: sortingColumn, sortDirection, datosOriginales
    /************ FIN funciones orden array (STOCK PREDIO) *************************************** */

    /************ INICIO funciones orden array (STOCK POTREROS) *************************************** */
    const handleColumnClickPotreroOvi = (columnIndex) => {
        if (sortingColumnPotreroOvi === columnIndex) {
            setSortDirectionPotreroOvi(sortDirectionPotreroOvi === 'asc' ? 'desc' : 'asc');
        } else {
            setSortingColumnPotreroOvi(columnIndex); setSortDirectionPotreroOvi('asc');
        }
    };

    useEffect(() => {    
        const rows = [...ovinoDatosPotrerosOri]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
        if (sortingColumnPotreroOvi !== -1) {  
            rows.sort((a, b) => {
                const valA = a[Object.keys(a)[sortingColumnPotreroOvi]]; const valB = b[Object.keys(b)[sortingColumnPotreroOvi]];

                if (typeof valA === 'number' && typeof valB === 'number') {
                    return valA - valB;
                } else if (isValidDate(valA) && isValidDate(valB)) {
                    const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
                } else { return valA.localeCompare(valB);}
            });

            if (sortDirectionPotreroOvi === 'desc') { rows.reverse();}
            setOvinoDatosPotreros(rows); // Actualizamos el estado de sortedRows con los datos ordenados
        }
    }, [sortingColumnPotreroOvi, sortDirectionPotreroOvi, ovinoDatosPotrerosOri]); // Dependencias: sortingColumn, sortDirection, datosOriginales
    /************ FIN funciones orden array (STOCK PREDIO) *************************************** */

    /************ INICIO funciones orden array (STOCK CATEGORIA) *************************************** */
    const handleColumnClickCatOvi = (columnIndex) => {
        if (sortingColumnCatOvi === columnIndex) {
            setSortDirectionCatOvi(sortDirectionCatOvi === 'asc' ? 'desc' : 'asc');
        } else {
            setSortingColumnCatOvi(columnIndex); setSortDirectionCatOvi('asc');
        }
    };

    useEffect(() => {    
        const rows = [...ovinoDatosCatOri]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
        if (sortingColumnCatOvi !== -1) {  
            rows.sort((a, b) => {
                const valA = a[Object.keys(a)[sortingColumnCatOvi]]; const valB = b[Object.keys(b)[sortingColumnCatOvi]];

                if (typeof valA === 'number' && typeof valB === 'number') {
                    return valA - valB;
                } else if (isValidDate(valA) && isValidDate(valB)) {
                    const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
                } else { return valA.localeCompare(valB);}
            });

            if (sortDirectionCatOvi === 'desc') { rows.reverse();}
            setOvinoDatosCat(rows); // Actualizamos el estado de sortedRows con los datos ordenados
        }
    }, [sortingColumnCatOvi, sortDirectionCatOvi, ovinoDatosCatOri]); // Dependencias: sortingColumn, sortDirection, datosOriginales  
    /************ FIN funciones orden array (STOCK CATEGORIA) *************************************** */

    /********** Funciones Modal 'potrerosModal' *********/
    useEffect(() => {
        const modalElement = document.querySelector('#potrerosModal');
        modalElement.addEventListener('shown.bs.modal', onModalShowPotrerosOvi);
        return () => {
        modalElement.removeEventListener('shown.bs.modal', onModalShowPotrerosOvi);
        };
    }, []);

    const onModalShowPotrerosOvi = () => {
    }

    const SetPotrerosOvi = (v_id_predio, v_n_predioOvi) =>{
        setN_predioOvi(v_n_predioOvi);
        getOvinoDatosPotreros(v_id_predio);
        const modal = new bootstrap.Modal(modalPotrerosOvi.current);
        modal.show();
    };

    let total_Ov_1 = 0, total_Ov_2 = 0, total_Ov_3 = 0, total_Ov_4 = 0, total_Ov_5 = 0, total_Ov_6 = 0, total_Ov_7 = 0, total_Ov_8 = 0, total_Ov_9 = 0, total_Ov_10 = 0, total_stock = 0;
    let modal_total_Ov_1 = 0, modal_total_Ov_2 = 0, modal_total_Ov_3 = 0, modal_total_Ov_4 = 0, modal_total_Ov_5 = 0, modal_total_Ov_6 = 0, modal_total_Ov_7 = 0, modal_total_Ov_8 = 0, modal_total_Ov_9 = 0, modal_total_Ov_10 = 0, modal_total_stock = 0;

    //______________________________________________
    //_____________ useEffect PRINCIPAL ____________
    //______________________________________________
    useEffect(() => {
        getDatosCategoriasXEspecie();

        getOvinoDatosPredios();
        getOvinoChart();

        getOvinoChartCat();
        getOvinoDatosCategorias();
        // eslint-disable-next-line
    }, []);

    return (
        
        <div className="tab-pane fade show active" id="nav-ovino" role="tabpanel" aria-labelledby="nav-ovino-tab">
            <div className="row pt-2">
                <div className="col-12 col-lg-6 pl-2">
                    <fieldset className="border p-2 mb-2 border-secondary">
                    {ovinoDatosPredios ? 
                        (
                        <small>
                        <h6>Cantidad de Ovinos por predio</h6>
                        <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
                        <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                            <thead>
                            <tr>
                                <th onClick={() => handleColumnClickPredioOvi(1)} className={sortingColumnPredioOvi === 1 ? `sorting ${sortDirectionPredioOvi}` : ''}>Predio</th>
                                {ovinoDatosPredios.reduce((stock, item) => stock + item.Ov_1, 0) > 0 &&
                                <th className='text-end'>{obtenerSiglaPorId(1)}</th>
                                }
                                {ovinoDatosPredios.reduce((stock, item) => stock + item.Ov_2, 0) > 0 &&
                                <th className='text-end'>{obtenerSiglaPorId(2)}</th>
                                }
                                {ovinoDatosPredios.reduce((stock, item) => stock + item.Ov_3, 0) > 0 &&
                                <th className='text-end'>{obtenerSiglaPorId(3)}</th>
                                }
                                {ovinoDatosPredios.reduce((stock, item) => stock + item.Ov_4, 0) > 0 &&
                                <th className='text-end'>{obtenerSiglaPorId(4)}</th>
                                }
                                {ovinoDatosPredios.reduce((stock, item) => stock + item.Ov_5, 0) > 0 &&
                                <th className='text-end'>{obtenerSiglaPorId(5)}</th>
                                }
                                {ovinoDatosPredios.reduce((stock, item) => stock + item.Ov_6, 0) > 0 &&
                                <th className='text-end'>{obtenerSiglaPorId(6)}</th>
                                }
                                {ovinoDatosPredios.reduce((stock, item) => stock + item.Ov_7, 0) > 0 &&
                                <th className='text-end'>{obtenerSiglaPorId(7)}</th>
                                }
                                {ovinoDatosPredios.reduce((stock, item) => stock + item.Ov_8, 0) > 0 &&
                                <th className='text-end'>{obtenerSiglaPorId(8)}</th>
                                }
                                {ovinoDatosPredios.reduce((stock, item) => stock + item.Ov_9, 0) > 0 &&
                                <th className='text-end'>{obtenerSiglaPorId(9)}</th>
                                }
                                {ovinoDatosPredios.reduce((stock, item) => stock + item.Ov_10, 0) > 0 &&
                                <th className='text-end'>{obtenerSiglaPorId(10)}</th>
                                }
                                <th className='text-end'>Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {ovinoDatosPredios.map(dato => {
                                total_Ov_1+=parseInt(dato.Ov_1);
                                total_Ov_2+=parseInt(dato.Ov_2);
                                total_Ov_3+=parseInt(dato.Ov_3);
                                total_Ov_4+=parseInt(dato.Ov_4);
                                total_Ov_5+=parseInt(dato.Ov_5);
                                total_Ov_6+=parseInt(dato.Ov_6);
                                total_Ov_7+=parseInt(dato.Ov_7);
                                total_Ov_8+=parseInt(dato.Ov_8);
                                total_Ov_9+=parseInt(dato.Ov_9);
                                total_Ov_10+=parseInt(dato.Ov_10);
                                total_stock+=parseInt(dato.stock);
                                
                                return (
                                <tr 
                                    onMouseOver={(e) => e.currentTarget.style.cursor = 'pointer'}
                                    onMouseOut={(e) => e.currentTarget.style.cursor = 'default'}
                                    title="Ver Stock por Potreros"
                                    onClick={() => SetPotrerosOvi(dato.id_predio, dato.n_predio)}
                                    key={dato.id_predio}>
                                    <td>{dato.n_corto}</td>
                                    {dato.Ov_1 > 0 ?
                                    <td className='text-end'>{parseInt(dato.Ov_1).toLocaleString('es-CL')}</td>
                                    :null}
                                    {dato.Ov_2 > 0 ?
                                    <td className='text-end'>{parseInt(dato.Ov_2).toLocaleString('es-CL')}</td>
                                    :null}
                                    {dato.Ov_3 > 0 ?
                                    <td className='text-end'>{parseInt(dato.Ov_3).toLocaleString('es-CL')}</td>
                                    :null}
                                    {dato.Ov_4 > 0 ?
                                    <td className='text-end'>{parseInt(dato.Ov_4).toLocaleString('es-CL')}</td>
                                    :null}
                                    {dato.Ov_5 > 0 ?
                                    <td className='text-end'>{parseInt(dato.Ov_5).toLocaleString('es-CL')}</td>
                                    :null}
                                    {dato.Ov_6 > 0 ?
                                    <td className='text-end'>{parseInt(dato.Ov_6).toLocaleString('es-CL')}</td>
                                    :null}
                                    {dato.Ov_7 > 0 ?
                                    <td className='text-end'>{parseInt(dato.Ov_7).toLocaleString('es-CL')}</td>
                                    :null}
                                    {dato.Ov_8 > 0 ?
                                    <td className='text-end'>{parseInt(dato.Ov_8).toLocaleString('es-CL')}</td>
                                    :null}
                                    {dato.Ov_9 > 0 ?
                                    <td className='text-end'>{parseInt(dato.Ov_9).toLocaleString('es-CL')}</td>
                                    :null}
                                    {dato.Ov_10 > 0 ?
                                    <td className='text-end'>{parseInt(dato.Ov_10).toLocaleString('es-CL')}</td>
                                    :null}
                                    <td className='text-end'>{dato.stock > 0 ? parseInt(dato.stock).toLocaleString('es-CL') : ''}</td>
                                </tr>
                                );
                            })}
                                <tr>
                                    <td>Totales</td>
                                    {total_Ov_1 > 0 ? 
                                    <td className='text-end'>{parseInt(total_Ov_1).toLocaleString('es-CL')}</td>
                                    :null}
                                    {total_Ov_2 > 0 ? 
                                    <td className='text-end'>{parseInt(total_Ov_2).toLocaleString('es-CL')}</td>
                                    :null}
                                    {total_Ov_3 > 0 ? 
                                    <td className='text-end'>{parseInt(total_Ov_3).toLocaleString('es-CL')}</td>
                                    :null}
                                    {total_Ov_4 > 0 ? 
                                    <td className='text-end'>{parseInt(total_Ov_4).toLocaleString('es-CL')}</td>
                                    :null}
                                    {total_Ov_5 > 0 ? 
                                    <td className='text-end'>{parseInt(total_Ov_5).toLocaleString('es-CL')}</td>
                                    :null}
                                    {total_Ov_6 > 0 ? 
                                    <td className='text-end'>{parseInt(total_Ov_6).toLocaleString('es-CL')}</td>
                                    :null}
                                    {total_Ov_7 > 0 ? 
                                    <td className='text-end'>{parseInt(total_Ov_7).toLocaleString('es-CL')}</td>
                                    :null}
                                    {total_Ov_8 > 0 ? 
                                    <td className='text-end'>{parseInt(total_Ov_8).toLocaleString('es-CL')}</td>
                                    :null}
                                    {total_Ov_9 > 0 ? 
                                    <td className='text-end'>{parseInt(total_Ov_9).toLocaleString('es-CL')}</td>
                                    :null}
                                    {total_Ov_10 > 0 ? 
                                    <td className='text-end'>{parseInt(total_Ov_10).toLocaleString('es-CL')}</td>
                                    :null}
                                    <td className='text-end'>{total_stock > 0 ? parseInt(total_stock).toLocaleString('es-CL') : ''}</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                        </small>
                        ) : (
                        <div className="text-center">
                            <br/><br/>
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Cargando datos...</span>
                            </div>
                            <p>Cargando cantidad de ovinos por predio...</p>
                        </div>
                        )
                    }
                    </fieldset>
                </div>
                <div className="col-12 col-lg-6" style={{border: '0px solid white'}}>
                    <fieldset className="border p-2 mb-2 border-secondary">
                        <div className="d-flex justify-content-center align-items-center" style={{ position: 'relative'}}>
                            {ovinoChart ? (
                                    <Pie data={ovinoChart} options={options} plugins={[ChartDataLabels]}/>
                                ) : (
                                    <div className="text-center">
                                        <br/><br/>
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Cargando gráfico...</span>
                                        </div>
                                        <p>Cargando gráfico...</p>
                                    </div>
                                )
                            }
                            {ovinoChart && 
                                <div className="text-center" style={{ position: 'absolute', width: '100%', top: '8%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                                    <h5>Distribución Ovino Activo - Predios</h5>
                                    <h6>Cantidad de animales por predio</h6>
                                </div>
                            }
                        </div>
                    </fieldset>
                </div>
            </div>

            <div className="row pt-2">
                <div className="col-12 col-lg-6 pl-2">
                    <fieldset className="border p-2 mb-2 border-secondary">
                    {ovinoDatosCat ? 
                        (
                        <small>
                        <h6>Cantidad de Ovinos por Categoría</h6>
                        <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
                        <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                            <thead>
                            <tr>
                                <th onClick={() => handleColumnClickCatOvi(1)} className={sortingColumnCatOvi === 1 ? `sorting ${sortDirectionCatOvi}` : ''}>Categoría</th>
                                <th 
                                    onClick={() => handleColumnClickCatOvi(2)} 
                                    className={`${sortingColumnCatOvi === 2 ? `sorting ${sortDirectionCatOvi}` : ''} text-end`}
                                >Stock</th>
                            </tr>
                            </thead>
                            <tbody>
                            {ovinoDatosCat.map((tupla, index) => {
                                // const fila = index + 1;
                                return (
                                <tr key={tupla.id_calculo}>
                                    <td>{tupla.categoria} ({tupla.sigla})</td>
                                    <td className="text-end">{parseInt(tupla.stock).toLocaleString('es-CL')}</td>
                                </tr>
                                );
                            })}
                            </tbody>
                        </table>
                        </div>
                        </small>
                        ) : (
                        <div className="text-center">
                            <br/><br/>
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Cargando datos...</span>
                            </div>
                            <p>Cargando cantidad de ovinos por categoría...</p>
                        </div>
                        )
                    }
                    </fieldset>
                </div>
                <div className="col-12 col-lg-6" style={{border: '0px solid white'}}>
                    <fieldset className="border p-2 mb-2 border-secondary">
                        <div className="d-flex justify-content-center align-items-center" style={{ position: 'relative'}}>
                            {ovinoChartCat ? (
                                    <Pie data={ovinoChartCat} options={options} plugins={[ChartDataLabels]}/>
                                ) : (
                                    <div className="text-center">
                                        <br/><br/>
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Cargando gráfico...</span>
                                        </div>
                                        <p>Cargando gráfico...</p>
                                    </div>
                                )
                            }
                            {ovinoChartCat && 
                                <div className="text-center" style={{ position: 'absolute', width: '100%', top: '8%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                                    <h5>Distribución Ovino Activo - Categorías</h5>
                                    <h6>Cantidad de animales por categoría</h6>
                                </div>
                            }
                        </div>
                    </fieldset>
                </div>
            </div>

            <div className="modal fade" id="potrerosModal" ref={modalPotrerosOvi} 
                tabIndex="-1" aria-labelledby="potrerosModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg border border-secondary">
                    <div className={`modal-content ${darkMode ? 'text-light bg-dark' : ''}`}>
                        <div className="modal-header border-bottom border-secondary">
                            <h5 className="modal-title" id="potrerosModalLabel">Stock Categorías por Potreros</h5>
                            <button 
                                type="button" 
                                className={`btn-close ${darkMode ? 'bg-btn-close-modal': ''}`}
                                data-bs-dismiss="modal" aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row pt-2">
                                <label className="col-sm-2">Predio:</label>
                                <div className="col-sm-10">
                                    <input type="text" name="n_predioOvi" value={n_predioOvi} 
                                    className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                                    readOnly/>
                                </div>
                            </div>

                            {isLoadingPotrerosOvi &&  
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Cargando datos...</span>
                                    </div>
                                </div>
                            }
                            {!isLoadingPotrerosOvi &&  
                            <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
                                <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                                    <thead>
                                    <tr>
                                        <th onClick={() => handleColumnClickPotreroOvi(1)} className={sortingColumnPotreroOvi === 1 ? `sorting ${sortDirectionPotreroOvi}` : ''}>Potrero</th>
                                        {ovinoDatosPotreros.reduce((stock, item) => stock + item.Ov_1, 0) > 0 &&
                                        <td className='text-end'>{obtenerSiglaPorId(1)}</td>
                                        }
                                        {ovinoDatosPotreros.reduce((stock, item) => stock + item.Ov_2, 0) > 0 &&
                                        <td className='text-end'>{obtenerSiglaPorId(2)}</td>
                                        }
                                        {ovinoDatosPotreros.reduce((stock, item) => stock + item.Ov_3, 0) > 0 &&
                                        <td className='text-end'>{obtenerSiglaPorId(3)}</td>
                                        }
                                        {ovinoDatosPotreros.reduce((stock, item) => stock + item.Ov_4, 0) > 0 &&
                                        <td className='text-end'>{obtenerSiglaPorId(4)}</td>
                                        }
                                        {ovinoDatosPotreros.reduce((stock, item) => stock + item.Ov_5, 0) > 0 &&
                                        <td className='text-end'>{obtenerSiglaPorId(5)}</td>
                                        }
                                        {ovinoDatosPotreros.reduce((stock, item) => stock + item.Ov_6, 0) > 0 &&
                                        <td className='text-end'>{obtenerSiglaPorId(6)}</td>
                                        }
                                        {ovinoDatosPotreros.reduce((stock, item) => stock + item.Ov_7, 0) > 0 &&
                                        <td className='text-end'>{obtenerSiglaPorId(7)}</td>
                                        }
                                        {ovinoDatosPotreros.reduce((stock, item) => stock + item.Ov_8, 0) > 0 &&
                                        <td className='text-end'>{obtenerSiglaPorId(8)}</td>
                                        }
                                        {ovinoDatosPotreros.reduce((stock, item) => stock + item.Ov_9, 0) > 0 &&
                                        <td className='text-end'>{obtenerSiglaPorId(9)}</td>
                                        }
                                        {ovinoDatosPotreros.reduce((stock, item) => stock + item.Ov_10, 0) > 0 &&
                                        <td className='text-end'>{obtenerSiglaPorId(10)}</td>
                                        }
                                        <th className='text-end'>Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {ovinoDatosPotreros.map(dato => {
                                        modal_total_Ov_1+=parseInt(dato.Ov_1);
                                        modal_total_Ov_2+=parseInt(dato.Ov_2);
                                        modal_total_Ov_3+=parseInt(dato.Ov_3);
                                        modal_total_Ov_4+=parseInt(dato.Ov_4);
                                        modal_total_Ov_5+=parseInt(dato.Ov_5);
                                        modal_total_Ov_6+=parseInt(dato.Ov_6);
                                        modal_total_Ov_7+=parseInt(dato.Ov_7);
                                        modal_total_Ov_8+=parseInt(dato.Ov_8);
                                        modal_total_Ov_9+=parseInt(dato.Ov_9);
                                        modal_total_Ov_10+=parseInt(dato.Ov_10);
                                        modal_total_stock+=parseInt(dato.stock);
                                        return (
                                        <tr 
                                            key={dato.id_potrero}>
                                            <td>{dato.n_potrero}</td>
                                            {dato.Ov_1 > 0 ? 
                                            <td className='text-end'>{parseInt(dato.Ov_1).toLocaleString('es-CL')}</td>
                                            :null}
                                            {dato.Ov_2 > 0 ? 
                                            <td className='text-end'>{parseInt(dato.Ov_2).toLocaleString('es-CL')}</td>
                                            :null}
                                            {dato.Ov_3 > 0 ? 
                                            <td className='text-end'>{parseInt(dato.Ov_3).toLocaleString('es-CL')}</td>
                                            :null}
                                            {dato.Ov_4 > 0 ? 
                                            <td className='text-end'>{parseInt(dato.Ov_4).toLocaleString('es-CL')}</td>
                                            :null}
                                            {dato.Ov_5 > 0 ? 
                                            <td className='text-end'>{parseInt(dato.Ov_5).toLocaleString('es-CL')}</td>
                                            :null}
                                            {dato.Ov_6 > 0 ? 
                                            <td className='text-end'>{parseInt(dato.Ov_6).toLocaleString('es-CL')}</td>
                                            :null}
                                            {dato.Ov_7 > 0 ? 
                                            <td className='text-end'>{parseInt(dato.Ov_7).toLocaleString('es-CL')}</td>
                                            :null}
                                            {dato.Ov_8 > 0 ? 
                                            <td className='text-end'>{parseInt(dato.Ov_8).toLocaleString('es-CL')}</td>
                                            :null}
                                            {dato.Ov_9 > 0 ? 
                                            <td className='text-end'>{parseInt(dato.Ov_9).toLocaleString('es-CL')}</td>
                                            :null}
                                            {dato.Ov_10 > 0 ? 
                                            <td className='text-end'>{parseInt(dato.Ov_10).toLocaleString('es-CL')}</td>
                                            :null}
                                            <td className='text-end'>{dato.stock > 0 ? parseInt(dato.stock).toLocaleString('es-CL') : ''}</td>
                                        </tr>
                                        );
                                    })}
                                        <tr>
                                            <td>Totales</td>
                                            {modal_total_Ov_1 > 0 ? 
                                            <td className='text-end'>{parseInt(modal_total_Ov_1).toLocaleString('es-CL')}</td>
                                            :null}
                                            {modal_total_Ov_2 > 0 ? 
                                            <td className='text-end'>{parseInt(modal_total_Ov_2).toLocaleString('es-CL')}</td>
                                            :null}
                                            {modal_total_Ov_3 > 0 ? 
                                            <td className='text-end'>{parseInt(modal_total_Ov_3).toLocaleString('es-CL')}</td>
                                            :null}
                                            {modal_total_Ov_4 > 0 ? 
                                            <td className='text-end'>{parseInt(modal_total_Ov_4).toLocaleString('es-CL')}</td>
                                            :null}
                                            {modal_total_Ov_5 > 0 ? 
                                            <td className='text-end'>{parseInt(modal_total_Ov_5).toLocaleString('es-CL')}</td>
                                            :null}
                                            {modal_total_Ov_6 > 0 ? 
                                            <td className='text-end'>{parseInt(modal_total_Ov_6).toLocaleString('es-CL')}</td>
                                            :null}
                                            {modal_total_Ov_7 > 0 ? 
                                            <td className='text-end'>{parseInt(modal_total_Ov_7).toLocaleString('es-CL')}</td>
                                            :null}
                                            {modal_total_Ov_8 > 0 ? 
                                            <td className='text-end'>{parseInt(modal_total_Ov_8).toLocaleString('es-CL')}</td>
                                            :null}
                                            {modal_total_Ov_9 > 0 ? 
                                            <td className='text-end'>{parseInt(modal_total_Ov_9).toLocaleString('es-CL')}</td>
                                            :null}
                                            {modal_total_Ov_10 > 0 ? 
                                            <td className='text-end'>{parseInt(modal_total_Ov_10).toLocaleString('es-CL')}</td>
                                            :null}
                                            <td className='text-end'>{modal_total_stock > 0 ? parseInt(modal_total_stock).toLocaleString('es-CL') : ''}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            }
                        </div>
                        <div className="modal-footer border-top border-secondary">
                            <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>{/*fin modal*/}
        </div>
    );
};

export default DashOvino;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { isValidDate } from '../../utils/utils';

import { useDarkMode } from '../../context/darkModeContext'; 


const MapaPotrerosLista = ({id_predio}) => {
    // eslint-disable-next-line
    const {darkMode, setDarkMode} = useDarkMode();
    const navigate = useNavigate();

    const [isLoadingDatos, setIsLoadingDatos] = useState(false);
    
    const [sortingColumn, setSortingColumn] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
    const [sortDirection, setSortDirection] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

    const [datosOri, setDatosOri] = useState([]);
    const [datos, setDatos] = useState([]);

    const [tieneStock, setTieneStock] = useState(false);

    /************ INICIO FUNCIONES ORDEN ARRAY (DATOS TABLA) *************************************** */
    const handleColumnClick = (columnIndex) => {
        if (sortingColumn === columnIndex) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortingColumn(columnIndex); setSortDirection('asc');
        }
    };

    useEffect(() => {    
        const rows = [...datosOri]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
        if (sortingColumn !== -1) {  
            rows.sort((a, b) => {
                const valA = a[Object.keys(a)[sortingColumn]]; const valB = b[Object.keys(b)[sortingColumn]];

                if (typeof valA === 'number' && typeof valB === 'number') {
                    return valA - valB;
                } else if (isValidDate(valA) && isValidDate(valB)) {
                    const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
                } else { return valA.localeCompare(valB);}
            });
    
            if (sortDirection === 'desc') { rows.reverse();}
            setDatos(rows); // Actualizamos el estado de sortedRows con los datos ordenados
        }
    }, [sortingColumn, sortDirection, datosOri]); // Dependencias: sortingColumn, sortDirection, datosOriginales

    /************ FIN FUNCIONES ORDEN ARRAY (DATOS TABLA) *************************************** */

    const getDatos = async () => {
        setIsLoadingDatos(true);
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/mapa_datospotreros_canino/${id_predio}`,{withCredentials: true});
            setDatosOri(response.data);
            setDatos(response.data);

            setIsLoadingDatos(false);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    useEffect(() => {
        getDatos();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(datos){
            const columnasExcluidas = ['n_potrero', 'id_potrero'];
            const tieneStock = datos.some((dato) => {
                for (const key in dato) {
                    if (!columnasExcluidas.includes(key) && dato[key] > 0) {
                        return true;
                    }
                }
                return false;
            });
            setTieneStock(tieneStock);
        }
    }, [datos]);

    let total_H = 0, total_M = 0, total_stock = 0, total_fila = 0;

    return tieneStock ? (
        <div>
            {isLoadingDatos &&  
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                <span className="visually-hidden">Cargando datos...</span>
                </div>
            </div>
            }
            {!isLoadingDatos &&  
            <small>
            <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
            {datos && 
                <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                    <thead>
                    <tr>
                        <th onClick={() => handleColumnClick(1)} className={sortingColumn === 1 ? `sorting ${sortDirection}` : ''}>Potreros CANINOS</th>
                        {datos.reduce((stock, item) => stock + item.H, 0) > 0 &&
                        <th className='text-end'>H</th>
                        }
                        {datos.reduce((stock, item) => stock + item.M, 0) > 0 &&
                        <th className='text-end'>M</th>
                        }
                        <th className='text-end'>Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {datos.map(dato => {
                        total_H+=parseInt(dato.H);
                        total_M+=parseInt(dato.M);
                        
                        total_fila = parseInt(dato.H) + parseInt(dato.M);
                        total_stock+=parseInt(total_fila);
                        
                        if(total_fila > 0){
                            return ( 
                            <tr 
                                key={dato.id_potrero}>
                                <td>{dato.n_potrero}</td>
                                {datos.reduce((stock, item) => stock + item.H, 0) > 0 &&
                                <td className='text-end'>{dato.H > 0 ? parseInt(dato.H).toLocaleString('es-CL') : ''}</td>
                                }
                                {datos.reduce((stock, item) => stock + item.M, 0) > 0 &&
                                <td className='text-end'>{dato.M > 0 ? parseInt(dato.M).toLocaleString('es-CL') : ''}</td>
                                }
                                <td className='text-end'>{total_fila > 0 ? parseInt(total_fila).toLocaleString('es-CL') : ''}</td>
                            </tr>
                            )
                        }
                        return null;
                    })}
                        <tr>
                            <td>Totales</td>
                            {total_H > 0 && 
                            <td className='text-end'>{parseInt(total_H).toLocaleString('es-CL')}</td>
                            }
                            {total_M > 0 && 
                            <td className='text-end'>{parseInt(total_M).toLocaleString('es-CL')}</td>
                            }
                            <td className='text-end'>{total_stock > 0 ? parseInt(total_stock).toLocaleString('es-CL') : ''}</td>
                        </tr>
                    </tbody>
                </table>
                }
            </div>
            </small>
            }
        </div>
    ) : null;
};

export default MapaPotrerosLista;
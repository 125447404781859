import React, { useState, useRef, useEffect  } from 'react';
import { useTable, useSortBy, usePagination, useRowSelect } from 'react-table';
//import axios from 'axios';

const MyTableComponent = () => {
  // Datos de ejemplo
  const [data, setData] = useState([
    { id: 1, name: 'John Doe', age: 25, valor: '11.200,3' },
    { id: 2, name: 'Jane Smith', age: 30, valor: '13,2' },
    { id: 3, name: 'Jane Smith', age: 30, valor: '1,2' },
    { id: 4, name: 'Jane Smith', age: 30, valor: '1,2' },
    { id: 5, name: 'Jane Smith', age: 30, valor: '1,2' },
    { id: 6, name: 'Jane Smith', age: 30, valor: '14,2' },
    { id: 7, name: 'Jane Smith', age: 30, valor: '15,2' },
    { id: 8, name: 'Jane Smith', age: 30, valor: '43,2' },
    { id: 9, name: 'Jane Smith', age: 30, valor: '16,2' },
    { id: 10, name: 'Jane Smith', age: 30, valor: '17,2' },
    { id: 11, name: 'Jane Smith', age: 30, valor: '14,2' },
    { id: 12, name: 'Jane Smith', age: 30, valor: '14,2' },
    { id: 13, name: 'Jane Smith', age: 30, valor: '13,2' },
    { id: 14, name: 'Jane Smith', age: 30, valor: '12,2' },
    { id: 15, name: 'Jane Smith', age: 30, valor: '11,2' },
    { id: 16, name: 'Jane Smith', age: 30, valor: '10,2' },
    { id: 17, name: 'Jane Smith', age: 30, valor: '19,2' },
    { id: 18, name: 'Jane Smith', age: 30, valor: '18,2' },
    { id: 19, name: 'Jane Smith', age: 30, valor: '17,2' },
    { id: 20, name: 'Jane Smith', age: 30, valor: '16,2' },
    { id: 21, name: 'Jane Smith', age: 30, valor: '15,2' },
    { id: 22, name: 'Jane Smith', age: 30, valor: '145,2' },
    { id: 23, name: 'Jane Smith', age: 30, valor: '133,2' },
    { id: 24, name: 'Jane Smith', age: 30, valor: '133,2' },
    { id: 25, name: 'Jane Smith', age: 30, valor: '132,2' },
    { id: 26, name: 'Jane Smith', age: 30, valor: '134,2' },
    { id: 27, name: 'Jane Smith', age: 30, valor: '135,2' },
    { id: 28, name: 'Jane Smith', age: 30, valor: '136,2' },
    { id: 29, name: 'Jane Smith', age: 30, valor: '137,2' },
    { id: 30, name: 'Jane Smith', age: 30, valor: '138,2' },
    { id: 31, name: 'Jane Smith', age: 30, valor: '139,2' },
    { id: 32, name: 'Jane Smith', age: 30, valor: '130,2' },
    { id: 33, name: 'Jane Smith', age: 30, valor: '130,2' },
    { id: 34, name: 'Jane Smith', age: 30, valor: '13,2' },
    { id: 35, name: 'Jane Smith', age: 30, valor: '103,2' },
    { id: 36, name: 'Jane Smith', age: 30, valor: '1113,2' },
    
    // Agrega aquí más filas si es necesario
  ]);

  // Configuración de las columnas
  const columns = React.useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Nombre', accessor: 'name' },
      {
        Header: 'Edad',
        accessor: 'age',
        Cell: ({ row, value }) => {
          const [editing, setEditing] = useState(false);
          const [inputValue, setInputValue] = useState(value);
          const inputRef = useRef(null);

          useEffect(() => {
            if (editing) {
              inputRef.current.style.width = `${inputRef.current.parentNode.offsetWidth}px`;
            }
          }, [editing]);

          const handleInputChange = e => {
            setInputValue(e.target.value);
          };

          const handleBlur = async () => {
            setEditing(false);

            const updatedData = data.map(item => {
                if (item.id === row.original.id) {
                  return { ...item, age: inputValue };
                }
                return item;
              });
              setData(updatedData);

            await saveData(row.original.id, 'age', inputValue);
          };

          const handleKeyDown = async e => {
            if (e.key === 'Enter') {
              setEditing(false);
              
              const updatedData = data.map(item => {
                if (item.id === row.original.id) {
                  return { ...item, age: inputValue };
                }
                return item;
              });
              setData(updatedData);

              await saveData(row.original.id, 'age', inputValue);
            }
          };

          return editing ? (
            <input
              ref={inputRef}
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              className="form-control form-control-sm"
              autoFocus 
              style={{ width: '100%', maxWidth: '100px' }} // Ajusta el ancho máximo según sea necesario
            />
          ) : (
            <div onClick={() => setEditing(true)}>{value}</div>
          );
        },
      },
      { 
        Header: 'Valor', 
        accessor: 'valor', 
        sortType: (rowA, rowB, columnId) => {
            const getValue = row => {
              const value = row.values[columnId];
              if (typeof value === 'string') {
                // Eliminar puntos de separación de miles
                const noThousandsSeparator = value.replace(/\./g, '');
                // Reemplazar la coma decimal por un punto decimal
                const decimalSeparator = noThousandsSeparator.replace(/,/g, '.');
                // Convertir a número
                return parseFloat(decimalSeparator);
              }
              return value;
            };
          
            const aValue = getValue(rowA);
            const bValue = getValue(rowB);
          
            if (aValue < bValue) {
              return -1;
            }
            if (aValue > bValue) {
              return 1;
            }
            return 0;
          },
      }
      // Agrega aquí más columnas si es necesario
    ],
    [data]
  );

  // Crea la instancia de la tabla
  const { 
    getTableProps,
    getTableBodyProps,
    headerGroups,
    //page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    //state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    rows,
    state: { pageIndex, pageSize },
   } = useTable(
    {
      columns,
      data,
      //initialState: { selectAll: false },
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const saveData = async (id, field, value) => {
    console.log(value)
    /*
    try {
      await axios.put(`/api/your-endpoint/${id}`, { [field]: value });
      // Aquí puedes manejar la respuesta exitosa de la API
    } catch (error) {
      // Aquí puedes manejar los errores de la API
      console.error(error);
    }
    */
  };


  return (
    <>
    <table className="table text-light" {...getTableProps()}>
      <thead className="thead-dark">
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
    <div>
    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
      {"<<"}
    </button>
    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
      {"<"}
    </button>
    <button onClick={() => nextPage()} disabled={!canNextPage}>
      {">"}
    </button>
    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
      {">>"}
    </button>
    <span>
      Página{" "}
      <strong>
        {pageIndex + 1} de {pageOptions.length}
      </strong>{" "}
    </span>
    <span>
      | Ir a la página:{" "}
      <input
        type="number"
        defaultValue={pageIndex + 1}
        onChange={(e) => {
          const page = e.target.value ? Number(e.target.value) - 1 : 0;
          gotoPage(page);
        }}
        style={{ width: "50px" }}
      />
    </span>
    <select
      value={pageSize}
      onChange={(e) => {
        setPageSize(Number(e.target.value));
      }}
    >
      {[10, 20, 30, 40, 50].map((pageSize) => (
        <option key={pageSize} value={pageSize}>
          Mostrar {pageSize}
        </option>
      ))}
    </select>
  </div>
  </>
  );
};

export default MyTableComponent

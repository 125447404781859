import React, { useState, useEffect, useRef} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useDarkMode } from '../../../context/darkModeContext'; 

import { isValidDate } from '../../../utils/utils';

function Movindividual() {
  // eslint-disable-next-line
  const {darkMode, setDarkMode} = useDarkMode();
  const [datosOriginales, setDatosOriginales] = useState([]); // Estado para almacenar los datos originales
  const [datos, setDatos] = useState([]); // Estado para almacenar los datos ordenados
  const [isLoadingDatos, setIsLoadingDatos] = useState(false);

  const [sortingColumn, setSortingColumn] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
  const [sortDirection, setSortDirection] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

  const [especies, setEspecies] = useState([]);

  
  const [modalIdEspecie, setModalIdEspecie] = useState('');
  const [modalIdPredio, setModalIdPredio] = useState('');
  const [modalIdPotrero, setModalIdPotrero] = useState('');
  const [modalIdSubpotrero, setModalIdSubpotrero] = useState('');
  
  const [especiesModal, setEspeciesModal] = useState([]);
  const [prediosModal, setPrediosModal] = useState([]);
  const [potrerosModal, setPotrerosModal] = useState([]);
  const [subpotrerosModal, setSubpotrerosModal] = useState([]);

  const inputEspecie = useRef(null);
  const inputEstado = useRef(null);
  const inputAnio = useRef(null);
  const inputMes = useRef(null);

  const modalInputEspecie = useRef(null);
  const modalInputPredio = useRef(null);
  const modalInputPotrero = useRef(null);
  const modalInputSubpotrero = useRef(null);

  const [selectedEspecie, setSelectedEspecie] = useState('');
  const [selectedEstado, setSelectedEstado] = useState(false);

  const modalNewMov = useRef(null);

  const bootstrap = window.bootstrap; // se agregó para poder llamar al método hide() de un modal
  const navigate = useNavigate();

  const [anios, setAnios] = useState([]);
  const [selectedAnio, setSelectedAnio] = useState('');

  const [meses, setMeses] = useState([]);
  const [selectedMes, setSelectedMes] = useState('');

  const modulo = 'movindividual'

  useEffect( ()=> {
    getEspecies();
    getPredios();

    const storedEspecie = sessionStorage.getItem('MovIndSelectedEspecie');
    const storedEstado = sessionStorage.getItem('MovIndSelectedEstado');

    setSelectedEspecie(storedEspecie || '');
    setSelectedEstado(storedEstado || '');

    const anioInicial = 2022;
    const anioActual = new Date().getFullYear();

    const fechaActual = new Date();
    const numeroMes = fechaActual.getMonth() + 1; // Los meses comienzan desde 0

    // Asegurarse de tener un cero adelante si el número del mes es menor que 10
    const mesConCero = numeroMes < 10 ? `0${numeroMes}` : `${numeroMes}`;
    
    const storedAnio = sessionStorage.getItem('MovIndSelectedAnio');
    setSelectedAnio(storedAnio || '');

    const storedMes = sessionStorage.getItem('MovIndSelectedMes');
    setSelectedMes(storedMes || mesConCero);

    const aniosArray = Array.from({ length: anioActual - anioInicial + 1 }, (_, index) => {
      const anio = anioActual - index;
      return anio.toString();
    });

    setAnios(aniosArray);

    const nombresMeses = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    const mesesArray = Array.from({ length: 12 }, (_, index) => {
      const mesNumero = index + 1;
      const mes = mesNumero < 10 ? `0${mesNumero}` : `${mesNumero}`;
      const descripcionMes = nombresMeses[index];
      return { mes, descripcionMes };
    });

    setMeses(mesesArray);
    // eslint-disable-next-line
  },[]);

  
  /************ INICIO FUNCIONES ORDEN ARRAY (DATOS TABLA) *************************************** */

  const handleColumnClick = (columnIndex) => {
    // Si la columna actualmente ordenada es la misma que se hizo clic, cambia la dirección de ordenamiento
    // Si la columna actualmente ordenada es diferente, establece la columna y la dirección de ordenamiento predeterminada a 'asc'
    if (sortingColumn === columnIndex) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortingColumn(columnIndex);
      setSortDirection('asc');
    }
  };

  useEffect(() => {    
    const rows = [...datosOriginales]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
    if (sortingColumn !== -1) {  
      rows.sort((a, b) => {
        const valA = a[Object.keys(a)[sortingColumn]];
        const valB = b[Object.keys(b)[sortingColumn]];

        if (typeof valA === 'number' && typeof valB === 'number') {
          return valA - valB;
        } else if (isValidDate(valA) && isValidDate(valB)) {
          const dateA = new Date(valA.split('-').reverse().join('-'));
          const dateB = new Date(valB.split('-').reverse().join('-'));
          return dateA - dateB;
        } else {
          return valA.localeCompare(valB);
        }
      });
  
      if (sortDirection === 'desc') {
        rows.reverse();
      }
      setDatos(rows); // Actualizamos el estado de sortedRows con los datos ordenados
    }
  }, [sortingColumn, sortDirection, datosOriginales]); // Dependencias: sortingColumn, sortDirection, datosOriginales
  
  /************ FIN FUNCIONES ORDEN ARRAY (DATOS TABLA) *************************************** */

  const handleDelete = (id_mov) => {
    Swal.fire({
      title: 'Eliminar registro', text: '¿Estás seguro que deseas eliminar este registro?',
      icon: 'warning', showCancelButton: true, confirmButtonColor: '#d33', cancelButtonColor: '#666',
      confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar',
    }).then((result) => {
      // Si se confirma la eliminación, hacer la petición a la API para eliminar el elemento
      if (result.isConfirmed) 
      {
        axios.delete(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id_mov}`,{withCredentials: true}).then(response =>
        {
          if (response.data) {
            toast.success('¡Registro eliminado correctamente!', {
              position: "top-right", autoClose: 2000, hid_moveProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });

            setDatos(datos.filter(datos => datos.id_mov !== id_mov)); //Quitar del arreglo la fila del id_mov eliminado
          } else {
            toast.error('¡No se pudo eliminar el registro!', {
              position: "top-right", autoClose: 2000, hid_moveProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
      }
    });
  };

  const getEspecies = () => {
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/especies`,{withCredentials: true})
      .then(response => {
        setEspecies(response.data);
        setEspeciesModal(response.data);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };
  
  //---------------------------------------------------------------------
  //---------------------------------------------------------------------
  const getPredios = () => {
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/predios`,{withCredentials: true})
      .then(response => {
        setPrediosModal(response.data);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };
  
  useEffect(() => {
    if (modalIdPredio !== '') {
      axios.get(`${process.env.REACT_APP_URL_API_PORT}/potrerosxpredio/${modalIdPredio}`,{withCredentials: true})
        .then(response => {
          setPotrerosModal(response.data);
          if (response.data.length > 0) {
            setModalIdPotrero(response.data[0].id_potrero);
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        });
    }  
    // eslint-disable-next-line
  }, [modalIdPredio]); 

  useEffect(() => {
    if (prediosModal.length > 0 && modalInputPredio.current) {
      setModalIdPredio(modalInputPredio.current.selectedOptions[0].value);
    }
  }, [prediosModal]); //Este se ejecuta cuando se carga completo el arreglo  

  //---------------------------------------------------------------------
  //---------------------------------------------------------------------

  useEffect(() => {
    if (modalIdPotrero !== '') {
      axios.get(`${process.env.REACT_APP_URL_API_PORT}/subpotrerosxpotrero/${modalIdPotrero}`,{withCredentials: true})
        .then(response => {
          setSubpotrerosModal(response.data);
          if (response.data.length > 0) {
            setModalIdSubpotrero(response.data[0].id_subpotrero);
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        });
    }  
    // eslint-disable-next-line
  }, [modalIdPotrero]); 

  useEffect(() => {
    if (potrerosModal.length > 0 && modalInputPotrero.current) {
      setModalIdPotrero(modalInputPotrero.current.selectedOptions[0].value);
    }
  }, [potrerosModal]); //Este se ejecuta cuando se carga completo el arreglo  

  //---------------------------------------------------------------------
  //---------------------------------------------------------------------

  const getDatos = () => {
    setIsLoadingDatos(true);
    const v_id_especie = inputEspecie.current.value;
    const v_estado = inputEstado.current.value;
    const v_anio = inputAnio.current.value;
    const v_mes = inputMes.current.value;
    
    sessionStorage.setItem('MovIndSelectedEspecie', v_id_especie);
    sessionStorage.setItem('MovIndSelectedEstado', v_estado);
    sessionStorage.setItem('MovIndSelectedAnio', v_anio);
    sessionStorage.setItem('MovIndSelectedMes', v_mes);

    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`, 
      {
        withCredentials: true,
        params: {
          id_especie  : v_id_especie,
          estado      : v_estado, 
          anio        : v_anio,
          mes         : v_mes
        }
      })
      .then(response => {
        setDatosOriginales(response.data); // Guardar los datos originales
        setDatos(response.data); // Hacer una copia para mantener los datos originales en 'datos' al inicio
        setIsLoadingDatos(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };
  
  /********** Funciones Modal 'AddNewMovModal' *********/

  //Para agregar el addEventListener al modal 'selectEmpresaModal' cuando se dispare el Show 
  useEffect(() => {
    const modalElement = document.querySelector('#addNewMovModal');
    modalElement.addEventListener('shown.bs.modal', onModalShowAddNewMov);
    return () => {
      modalElement.removeEventListener('shown.bs.modal', onModalShowAddNewMov);
    };
  }, []);

  const onModalShowAddNewMov = () => {
    //inputBuscarEmpresa.current.focus();
  }

  const CrearNewMov = () => {
    if(!localStorage.getItem("usuario")){
      localStorage.removeItem("token"); navigate('/login'); return;
    }
    
    Swal.fire({
      title: 'Nuevo Movimiento', text: '¿Está seguro que desea crear un nuevo movimiento?',
      icon: 'question', showCancelButton: true, confirmButtonColor: '#198754', cancelButtonColor: '#666',
      confirmButtonText: 'Crear', cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) 
      {
        var data = {
          id_especie    : modalIdEspecie,
          id_predio     : modalIdPredio,
          id_potrero    : modalIdPotrero,
          id_subpotrero : modalIdSubpotrero,
          usuario : localStorage.getItem("usuario")
        };

        axios.post(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`,data,{withCredentials: true}).then(response =>
        {
          //***********************************
          //Cerrar modal
          const modalElement = modalNewMov.current;
          if (modalElement) {
            const bootstrapModal = bootstrap.Modal.getInstance(modalElement);
            bootstrapModal.hide();
          } 
          //***********************************

          if (response.data) {
            toast.success('¡Registro creado correctamente!', {
              position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
            navigate(`/${modulo}/${response.data.id_mov}`);
          } else {
            toast.error('¡No se pudo crear el registro!', {
              position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        });
      }
    });
  }
  /*********************************************************/

  return (
    <div className="row justify-content-center w-100">
      <div className="col">
        <small>
        <button type="button" className="btn btn-primary btn-sm" 
          data-bs-toggle="modal" data-bs-target="#addNewMovModal">
          <i className="fa fa-plus"></i>&nbsp;Nuevo movimiento
        </button>
        <div className="row pt-2 pb-1">
          <label className="col-sm-1">Especie:</label>
          <div className="col-sm-2">
            <select value={selectedEspecie} onChange={(event) => { 
              setSelectedEspecie(event.target.value)
              setDatosOriginales([]);
              setDatos([]);
            }}
              className="form-select form-select-sm" name="id_especie" ref={inputEspecie}>
              {especies.map((opcion) => (
                <option key={opcion.id_especie} value={opcion.id_especie}>{opcion.n_especie}</option>
              ))}
            </select>
          </div>
          <label className="col-sm-1">Año:</label>
          <div className="col-sm-1">
            <select 
              className="form-select form-select-sm" 
              name="anio" 
              ref={inputAnio}
              value={selectedAnio} 
              onChange={(event) => { 
                setSelectedAnio(event.target.value);
                setDatosOriginales([]);
                setDatos([]);
              }}>
              {anios.map((anio) => (
                <option key={anio} value={anio}>{anio}</option>
              ))}
            </select>
          </div>
          <label className="col-sm-1">Mes:</label>
          <div className="col-sm-2">
            <select 
              className="form-select form-select-sm" 
              name="mes" 
              ref={inputMes}
              value={selectedMes} 
              onChange={(event) => { 
                setSelectedMes(event.target.value);
                setDatosOriginales([]);
                setDatos([]);
              }}>
              {meses.map(({ mes, descripcionMes }) => (
                <option key={mes} value={mes}>{descripcionMes }</option>
              ))}
            </select>
          </div>
          <label className="col-sm-1">Estado:</label>
          <div className="col-sm-2">
            <select value={selectedEstado} onChange={(event) => { 
              setSelectedEstado(event.target.value)
              setDatosOriginales([]);
              setDatos([]);
            }}
              className="form-select form-select-sm" name="estado" ref={inputEstado}>
                <option key={0} value={''}></option>
                <option key={1} value={'CREADO'}>CREADO</option>
                <option key={2} value={'PENDIENTE'}>PENDIENTE</option>
                <option key={3} value={'FINALIZADO'}>FINALIZADO</option>
            </select>
          </div>
          <div className="col-sm-1">
            <button 
              className="btn btn-outline-secondary btn-sm" type="button" 
              id="bot_buscar" onClick={() => getDatos()}>
                <i className="fa fa-search"></i>&nbsp;Buscar</button>
          </div>
        </div>
        </small>
        {isLoadingDatos &&  
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Cargando datos...</span>
            </div>
          </div>
        }
        {!isLoadingDatos &&  
        <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
          <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
            <thead className="sticky-top">
              <tr>
                <th onClick={() => handleColumnClick(1)} className={sortingColumn === 1 ? `sorting ${sortDirection}` : ''}>N° Mov</th>
                <th onClick={() => handleColumnClick(2)} className={sortingColumn === 2 ? `sorting ${sortDirection}` : ''}>Fec. Mov</th>
                <th onClick={() => handleColumnClick(3)} className={sortingColumn === 3 ? `sorting ${sortDirection}` : ''}>Especie</th>
                <th>Categoría</th>
                <th onClick={() => handleColumnClick(4)} className={sortingColumn === 4 ? `sorting ${sortDirection}` : ''}>Origen</th>
                <th onClick={() => handleColumnClick(5)} className={sortingColumn === 5 ? `sorting ${sortDirection}` : ''}>Destino</th>
                <th onClick={() => handleColumnClick(6)} className={sortingColumn === 6 ? `sorting ${sortDirection}` : 'text-end'}>Cantidad</th>
                <th onClick={() => handleColumnClick(7)} className={sortingColumn === 7 ? `sorting ${sortDirection}` : ''}>Responsable</th>
                <th onClick={() => handleColumnClick(8)} className={sortingColumn === 8 ? `sorting ${sortDirection}` : ''}>Estado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {datos.map(dato => {
                let strDestino = "";
                let strOrigen   = dato.ori_n_predio + "/" + dato.ori_n_potrero;
                if(dato.ori_n_subpotrero !== ''){strOrigen+="/" + dato.ori_n_subpotrero}
                if(dato.dest_n_predio && dato.dest_n_potrero){
                  strDestino = dato.dest_n_predio + "/" + dato.dest_n_potrero;
                  if(dato.dest_n_subpotrero !== ''){strDestino+="/" + dato.dest_n_subpotrero}
                }
                
                return (
                <tr key={dato.id_mov} >
                  <td>{dato.id_mov}</td>
                  <td>{dato.fecha_mov}</td>
                  <td>{dato.n_especie}</td>
                  <td>
                  {dato.categorias && (
                    dato.categorias.map((item, index) => (
                    <div key={index}>
                      <small><small>
                        {item.categoria} ({item.cantidad})
                        </small></small>
                    </div>
                    ))
                  )}
                  </td>
                  <td>{strOrigen}</td>
                  <td>{strDestino}</td>
                  <td className='text-end'>
                  {
                    dato.cantidad.toLocaleString('es-CL', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  </td>
                  <td>{dato.n_responsable}</td>
                  <td>{dato.estado}</td>
                  <td>
                    <Link to={`/${modulo}/${dato.id_mov}`} className="btn btn-sm btn-outline-secondary">
                      <i className="fa fa-edit" title="Editar"></i>
                    </Link>
                    &nbsp;
                    <button onClick={() => handleDelete(dato.id_mov)} className="btn btn-sm btn-outline-secondary">
                      <i className="fa fa-trash-alt" title="Eliminar"></i>
                    </button>
                  </td>
                </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        }
      </div>

      <div className="modal fade" id="addNewMovModal" ref={modalNewMov} 
        tabIndex="-1" aria-labelledby="addNewMovModalLabel" aria-hidden="true">
        <div className="modal-dialog border border-secondary">
          <div className={`modal-content ${darkMode ? 'text-light bg-dark' : ''}`}>
            <div className="modal-header border-bottom border-secondary">
              <h5 className="modal-title" id="addNewMovModalLabel">Nuevo Movimiento</h5>
              <button 
                type="button" 
                className={`btn-close ${darkMode ? 'bg-btn-close-modal': ''}`}
                data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              <p>Seleccione origen</p>
              <div className="row pb-2">
                <label className="col-sm-4">Especie:</label>
                <div className="col-sm-8">
                  <select value={modalIdEspecie} 
                    onChange={(event) => {setModalIdEspecie(event.target.value)}}
                    className="form-select form-select-sm" name="modal_id_especie" ref={modalInputEspecie}>
                    {especiesModal.map((opcion) => (
                      <option key={opcion.id_especie} value={opcion.id_especie}>{opcion.n_especie}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row pb-2">
                <label className="col-sm-4">Predio:</label>
                <div className="col-sm-8">
                  <select value={modalIdPredio} 
                    onChange={(event) => {setModalIdPredio(event.target.value)}}
                    className="form-select form-select-sm" name="modal_id_predio" ref={modalInputPredio}>
                    {prediosModal.map((opcion) => (
                      <option key={opcion.id_predio} value={opcion.id_predio}>{opcion.n_predio}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row pb-2">
                <label className="col-sm-4">Potrero:</label>
                <div className="col-sm-8">
                  <select value={modalIdPotrero} 
                    onChange={(event) => {setModalIdPotrero(event.target.value)}}
                    className="form-select form-select-sm" name="modal_id_potrero" ref={modalInputPotrero}>
                    {potrerosModal.map((opcion) => (
                      <option key={opcion.id_potrero} value={opcion.id_potrero}>{opcion.n_potrero}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row pb-2">
                <label className="col-sm-4">Subpotrero:</label>
                <div className="col-sm-8">
                  <select value={modalIdSubpotrero} 
                    onChange={(event) => {setModalIdSubpotrero(event.target.value)}}
                    className="form-select form-select-sm" name="modal_id_subpotrero" ref={modalInputSubpotrero}>
                      <option key={0} value={''}></option>
                    {subpotrerosModal.map((opcion) => (
                      <option key={opcion.id_subpotrero} value={opcion.id_subpotrero}>{opcion.n_subpotrero}</option>
                    ))}
                  </select>
                </div>
              </div>

            </div>
            <div className="modal-footer border-top border-secondary">
              <button type="button" className="btn btn-success btn-sm"
                onClick={() => CrearNewMov()}>
                Aceptar
              </button>
              <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>{/*fin modal*/}
    </div>
  );
}

export default Movindividual;
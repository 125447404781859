import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useDarkMode } from '../../../context/darkModeContext'; 


function Predios() {
  // eslint-disable-next-line
  const {darkMode, setDarkMode} = useDarkMode();
  const [datos, setDatos] = useState([]);
  const [isLoadingDatos, setIsLoadingDatos] = useState(true);
  const navigate = useNavigate();
  const modulo = 'predios'

  useEffect(() => {
    setIsLoadingDatos(true);
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`,{withCredentials: true})
      .then(response => {
        setDatos(response.data);
        setIsLoadingDatos(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
    // eslint-disable-next-line
  }, []);

  const handleDelete = (id_predio) => {
    Swal.fire({
      title: 'Eliminar registro', text: '¿Estás seguro que deseas eliminar este registro?',
      icon: 'warning', showCancelButton: true, confirmButtonColor: '#d33', cancelButtonColor: '#666',
      confirmButtonText: 'Eliminar', cancelButtonText: 'Cancelar',
    }).then((result) => {
      // Si se confirma la eliminación, hacer la petición a la API para eliminar el elemento
      if (result.isConfirmed) 
      {
        axios.delete(`${process.env.REACT_APP_URL_API_PORT}/${modulo}/${id_predio}`,{withCredentials: true}).then(response =>
        {
          if (response.data) {
            toast.success('¡Registro eliminado correctamente!', {
              position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });

            setDatos(datos.filter(dato => dato.id_predio !== id_predio)); //Quitar del arreglo la fila del id eliminado
          } else {
            toast.error('¡No se pudo eliminar el registro!', {
              position: "top-right", autoClose: 2000, hideProgressBar: true, closeOnClick: true,
              pauseOnHover: true, draggable: true, progress: undefined, theme: "colored",
            });
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        });
      }
    });
  };

  return (
    <div>
      <Link to={`/${modulo}/new`} className="btn btn-primary btn-sm">
        <i className="fa fa-plus"></i>&nbsp;Nuevo
      </Link>
      {isLoadingDatos &&  
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Cargando datos...</span>
          </div>
        </div>
      }
      {!isLoadingDatos &&  
      <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
        <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
          <thead className="sticky-top">
            <tr>
              <th>Predio</th>
              <th>Nombre corto</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {datos.map(dato => (
              <tr key={dato.id_predio}>
                <td>{dato.n_predio}</td>
                <td>{dato.n_corto}</td>
                <td>
                  <Link to={`/${modulo}/${dato.id_predio}`} className="btn btn-sm btn-outline-secondary">
                    <i className="fa fa-edit" title="Editar"></i>
                  </Link>
                  &nbsp;
                  <button onClick={() => handleDelete(dato.id_predio)} className="btn btn-sm btn-outline-secondary">
                    <i className="fa fa-trash-alt" title="Eliminar"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      }
    </div>
  );
}

export default Predios;
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { isValidDate } from '../../utils/utils';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie} from 'react-chartjs-2';
//import 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { useDarkMode } from '../../context/darkModeContext'; 

ChartJS.register(ArcElement, Tooltip, Legend);

const DashBovino = () => {
    // eslint-disable-next-line
    const {darkMode, setDarkMode} = useDarkMode();

    const [colorPorcentaje, setColorPorcentaje] = useState('');
    useEffect( ()=> {
        if(darkMode){
            setColorPorcentaje('#ccc');
        } else {
            setColorPorcentaje('#444');
        }
    },[darkMode]);

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            datalabels: {
                labels: {
                    //title: null, // Desactivar el título dentro del pie
                    value: {
                        color: colorPorcentaje, // Color del texto del porcentaje
                        formatter: (value, context) => {
                            // Formatear el valor dentro del gráfico
                            return value;
                        },
                        font: {
                            size: 10,
                        },
                    },
                    percentage: {
                        formatter: (value, context) => {
                            // Formatear el porcentaje fuera del gráfico
                            const total = context.dataset.data.reduce((acc, num) => acc + num, 0);
                            const percentage = ((value / total) * 100).toFixed(1).replace('.', ',') + '%';
                            return percentage;
                        },
                        font: {
                            size: 10,
                        },
                        color: colorPorcentaje, // Color del texto del porcentaje
                        anchor: 'end',
                        align: 'end',
                        //offset: 0, // Ajusta la distancia entre el texto y el borde del gráfico
                    },
                },
            },
        },
        responsive: true,
        maintainAspectRatio: true, // Si se descomenta este línea el gráfico se ve pequeño y no rellena todo el espacio disponible
        layout: {
            padding: {
                left: 34,
                top: 100
            },
        }
    };
    

    const navigate = useNavigate();

    const [datosCategorias, setDatosCategorias] = useState([]);
    const bootstrap = window.bootstrap; // se agregó para poder llamar al método hide() de un modal

    //--------------------------------------------------
    //--------------------- BOVINO ----------------------
    //--------------------------------------------------
    const [bovinoChart, setBovinoChart] = useState(null);
    const [bovinoChartCat, setBovinoChartCat] = useState(null);

    const [bovinoDatosPredios, setBovinoDatosPredios] = useState([]);
    const [bovinoDatosPrediosOri, setBovinoDatosPrediosOri] = useState([]);

    const [bovinoDatosPotreros, setBovinoDatosPotreros] = useState([]);
    const [bovinoDatosPotrerosOri, setBovinoDatosPotrerosOri] = useState([]);

    const [bovinoDatosCat, setBovinoDatosCat] = useState(null);
    const [bovinoDatosCatOri, setBovinoDatosCatOri] = useState([]);

    const [sortingColumnPredioBovi, setSortingColumnPredioBovi] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
    const [sortDirectionPredioBovi, setSortDirectionPredioBovi] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

    const [sortingColumnPotreroBovi, setSortingColumnPotreroBovi] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
    const [sortDirectionPotreroBovi, setSortDirectionPotreroBovi] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

    const [sortingColumnCatBovi, setSortingColumnCatBovi] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
    const [sortDirectionCatBovi, setSortDirectionCatBovi] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'

    const modalPotrerosBovi = useRef(null);
    const [isLoadingPotrerosBovi, setIsLoadingPotrerosBovi] = useState(false);
    const [n_predioBovi, setN_predioBovi] = useState('');

    const getBovinoChart = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/dashboard_chart_bovino`,{withCredentials: true});
            setBovinoChart(response.data);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    const getBovinoChartCat = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/dashboard_chart_bovino_categorias`,{withCredentials: true});
            setBovinoChartCat(response.data);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    const getBovinoDatosPredios = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/dashboard_datos_bovino_predios`,{withCredentials: true});
            setBovinoDatosPrediosOri(response.data);
            setBovinoDatosPredios(response.data);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    const getBovinoDatosPotreros = async (v_id_predio) => {
        setIsLoadingPotrerosBovi(true);
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/dashboard_datos_bovino_potreros/${v_id_predio}`,{withCredentials: true});
            setBovinoDatosPotrerosOri(response.data);
            setBovinoDatosPotreros(response.data);
            setIsLoadingPotrerosBovi(false);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    const getBovinoDatosCategorias = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/dashboard_datos_bovino_categorias`,{withCredentials: true});
            setBovinoDatosCatOri(response.data);
            setBovinoDatosCat(response.data);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    const getDatosCategoriasXEspecie = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_URL_API_PORT}/categoriasdatosxespecie/1`,{withCredentials: true});
            setDatosCategorias(response.data);
        } catch(error){
            console.log(error);
            if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
        };
    };

    function obtenerSiglaPorId(id_calculo) {
        const elementoEncontrado = datosCategorias.find(item => item.id_calculo === id_calculo);
        return elementoEncontrado ? elementoEncontrado.sigla : null;
    }

    /************ INICIO funciones orden array (STOCK PREDIO) *************************************** */
    const handleColumnClickPredioBovi = (columnIndex) => {
        if (sortingColumnPredioBovi === columnIndex) {
            setSortDirectionPredioBovi(sortDirectionPredioBovi === 'asc' ? 'desc' : 'asc');
        } else {
            setSortingColumnPredioBovi(columnIndex); setSortDirectionPredioBovi('asc');
        }
    };

    useEffect(() => {    
        const rows = [...bovinoDatosPrediosOri]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
        if (sortingColumnPredioBovi !== -1) {  
            rows.sort((a, b) => {
                const valA = a[Object.keys(a)[sortingColumnPredioBovi]]; const valB = b[Object.keys(b)[sortingColumnPredioBovi]];

                if (typeof valA === 'number' && typeof valB === 'number') {
                    return valA - valB;
                } else if (isValidDate(valA) && isValidDate(valB)) {
                    const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
                } else { return valA.localeCompare(valB);}
            });
    
            if (sortDirectionPredioBovi === 'desc') { rows.reverse();}
            setBovinoDatosPredios(rows); // Actualizamos el estado de sortedRows con los datos ordenados
        }
    }, [sortingColumnPredioBovi, sortDirectionPredioBovi, bovinoDatosPrediosOri]); // Dependencias: sortingColumn, sortDirection, datosOriginales
    /************ FIN funciones orden array (STOCK PREDIO) *************************************** */

    /************ INICIO funciones orden array (STOCK POTREROS) *************************************** */
    const handleColumnClickPotreroBovi = (columnIndex) => {
        if (sortingColumnPotreroBovi === columnIndex) {
            setSortDirectionPotreroBovi(sortDirectionPotreroBovi === 'asc' ? 'desc' : 'asc');
        } else {
            setSortingColumnPotreroBovi(columnIndex); setSortDirectionPotreroBovi('asc');
        }
    };

    useEffect(() => {    
        const rows = [...bovinoDatosPotrerosOri]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
        if (sortingColumnPotreroBovi !== -1) {  
            rows.sort((a, b) => {
                const valA = a[Object.keys(a)[sortingColumnPotreroBovi]]; const valB = b[Object.keys(b)[sortingColumnPotreroBovi]];

                if (typeof valA === 'number' && typeof valB === 'number') {
                    return valA - valB;
                } else if (isValidDate(valA) && isValidDate(valB)) {
                    const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
                } else { return valA.localeCompare(valB);}
            });

            if (sortDirectionPotreroBovi === 'desc') { rows.reverse();}
            setBovinoDatosPotreros(rows); // Actualizamos el estado de sortedRows con los datos ordenados
        }
    }, [sortingColumnPotreroBovi, sortDirectionPotreroBovi, bovinoDatosPotrerosOri]); // Dependencias: sortingColumn, sortDirection, datosOriginales
    /************ FIN funciones orden array (STOCK PREDIO) *************************************** */

    /************ INICIO funciones orden array (STOCK CATEGORIA) *************************************** */
    const handleColumnClickCatBovi = (columnIndex) => {
        if (sortingColumnCatBovi === columnIndex) {
            setSortDirectionCatBovi(sortDirectionCatBovi === 'asc' ? 'desc' : 'asc');
        } else {
            setSortingColumnCatBovi(columnIndex); setSortDirectionCatBovi('asc');
        }
    };

    useEffect(() => {    
        const rows = [...bovinoDatosCatOri]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
        if (sortingColumnCatBovi !== -1) {  
            rows.sort((a, b) => {
                const valA = a[Object.keys(a)[sortingColumnCatBovi]]; const valB = b[Object.keys(b)[sortingColumnCatBovi]];

                if (typeof valA === 'number' && typeof valB === 'number') {
                    return valA - valB;
                } else if (isValidDate(valA) && isValidDate(valB)) {
                    const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
                } else { return valA.localeCompare(valB);}
            });

            if (sortDirectionCatBovi === 'desc') { rows.reverse();}
            setBovinoDatosCat(rows); // Actualizamos el estado de sortedRows con los datos ordenados
        }
    }, [sortingColumnCatBovi, sortDirectionCatBovi, bovinoDatosCatOri]); // Dependencias: sortingColumn, sortDirection, datosOriginales  
    /************ FIN funciones orden array (STOCK CATEGORIA) *************************************** */

    /********** Funciones Modal 'potrerosModal' *********/
    useEffect(() => {
        const modalElement = document.querySelector('#potrerosModal');
        modalElement.addEventListener('shown.bs.modal', onModalShowPotrerosBovi);
        return () => {
        modalElement.removeEventListener('shown.bs.modal', onModalShowPotrerosBovi);
        };
    }, []);

    const onModalShowPotrerosBovi = () => {
    }

    const SetPotrerosBovi = (v_id_predio, v_n_predioBovi) =>{
        setN_predioBovi(v_n_predioBovi);
        getBovinoDatosPotreros(v_id_predio);
        const modal = new bootstrap.Modal(modalPotrerosBovi.current);
        modal.show();
    };

    let total_Bov_11 = 0, total_Bov_12 = 0, total_Bov_13 = 0, total_Bov_14 = 0, total_Bov_15 = 0, total_Bov_16 = 0, total_Bov_17 = 0, total_Bov_18 = 0, total_stock = 0;
    let modal_total_Bov_11 = 0, modal_total_Bov_12 = 0, modal_total_Bov_13 = 0, modal_total_Bov_14 = 0, modal_total_Bov_15 = 0, modal_total_Bov_16 = 0, modal_total_Bov_17 = 0, modal_total_Bov_18 = 0, modal_total_stock = 0;

    //______________________________________________
    //_____________ useEffect PRINCIPAL ____________
    //______________________________________________
    useEffect(() => {
        getDatosCategoriasXEspecie();
        getBovinoDatosPredios();
        getBovinoChart();

        getBovinoChartCat();
        getBovinoDatosCategorias();
        // eslint-disable-next-line
    }, []);
    
    return (
        
        <div className="tab-pane fade" id="nav-bovino" role="tabpanel" aria-labelledby="nav-bovino-tab">
            <div className="row pt-2">
                <div className="col-12 col-lg-6 pl-2">
                    <fieldset className="border p-2 mb-2 border-secondary">
                    {bovinoDatosPredios ? 
                        (
                        <small>
                        <h6>Cantidad de Bovinos por predio</h6>
                        <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
                        <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                            <thead>
                            <tr>
                                <th onClick={() => handleColumnClickPredioBovi(1)} className={sortingColumnPredioBovi === 1 ? `sorting ${sortDirectionPredioBovi}` : ''}>Predio</th>
                                <th className='text-end'>{obtenerSiglaPorId(11)}</th>
                                <th className='text-end'>{obtenerSiglaPorId(12)}</th>
                                <th className='text-end'>{obtenerSiglaPorId(13)}</th>
                                <th className='text-end'>{obtenerSiglaPorId(14)}</th>
                                <th className='text-end'>{obtenerSiglaPorId(15)}</th>
                                <th className='text-end'>{obtenerSiglaPorId(16)}</th>
                                <th className='text-end'>{obtenerSiglaPorId(17)}</th>
                                <th className='text-end'>{obtenerSiglaPorId(18)}</th>
                                <th className='text-end'>Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {bovinoDatosPredios.map(dato => {
                                total_Bov_11+=parseInt(dato.Bov_11);
                                total_Bov_12+=parseInt(dato.Bov_12);
                                total_Bov_13+=parseInt(dato.Bov_13);
                                total_Bov_14+=parseInt(dato.Bov_14);
                                total_Bov_15+=parseInt(dato.Bov_15);
                                total_Bov_16+=parseInt(dato.Bov_16);
                                total_Bov_17+=parseInt(dato.Bov_17);
                                total_Bov_18+=parseInt(dato.Bov_18);
                                total_stock+=parseInt(dato.stock);
                                
                                return (
                                <tr 
                                    onMouseOver={(e) => e.currentTarget.style.cursor = 'pointer'}
                                    onMouseOut={(e) => e.currentTarget.style.cursor = 'default'}
                                    title="Ver Stock por Potreros"
                                    onClick={() => SetPotrerosBovi(dato.id_predio, dato.n_predio)}
                                    key={dato.id_predio}>
                                    <td>{dato.n_corto}</td>
                                    <td className='text-end'>{dato.Bov_11 > 0 ? parseInt(dato.Bov_11).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{dato.Bov_12 > 0 ? parseInt(dato.Bov_12).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{dato.Bov_13 > 0 ? parseInt(dato.Bov_13).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{dato.Bov_14 > 0 ? parseInt(dato.Bov_14).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{dato.Bov_15 > 0 ? parseInt(dato.Bov_15).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{dato.Bov_16 > 0 ? parseInt(dato.Bov_16).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{dato.Bov_17 > 0 ? parseInt(dato.Bov_17).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{dato.Bov_18 > 0 ? parseInt(dato.Bov_18).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{dato.stock > 0 ? parseInt(dato.stock).toLocaleString('es-CL') : ''}</td>
                                </tr>
                                );
                            })}
                                <tr>
                                    <td>Totales</td>
                                    <td className='text-end'>{total_Bov_11 > 0 ? parseInt(total_Bov_11).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{total_Bov_12 > 0 ? parseInt(total_Bov_12).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{total_Bov_13 > 0 ? parseInt(total_Bov_13).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{total_Bov_14 > 0 ? parseInt(total_Bov_14).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{total_Bov_15 > 0 ? parseInt(total_Bov_15).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{total_Bov_16 > 0 ? parseInt(total_Bov_16).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{total_Bov_17 > 0 ? parseInt(total_Bov_17).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{total_Bov_18 > 0 ? parseInt(total_Bov_18).toLocaleString('es-CL') : ''}</td>
                                    <td className='text-end'>{total_stock > 0 ? parseInt(total_stock).toLocaleString('es-CL') : ''}</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                        </small>
                        ) : (
                        <div className="text-center">
                            <br/><br/>
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Cargando datos...</span>
                            </div>
                            <p>Cargando cantidad de bovinos por predio...</p>
                        </div>
                        )
                    }
                    </fieldset>
                </div>
                <div className="col-12 col-lg-6" style={{border: '0px solid white'}}>
                    <fieldset className="border p-2 mb-2 border-secondary">
                        <div className="d-flex justify-content-center align-items-center" style={{ position: 'relative'}}>
                            {bovinoChart ? (
                                    <Pie data={bovinoChart} options={options} plugins={[ChartDataLabels]}/>
                                ) : (
                                    <div className="text-center">
                                        <br/><br/>
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Cargando gráfico...</span>
                                        </div>
                                        <p>Cargando gráfico...</p>
                                    </div>
                                )
                            }
                            {bovinoChart && 
                                <div className="text-center" style={{ position: 'absolute', width: '100%', top: '8%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                                    <h5>Distribución Bovino Activo - Predios</h5>
                                    <h6>Cantidad de animales por predio</h6>
                                </div>
                            }
                        </div>
                    </fieldset>
                </div>
            </div>

            <div className="row pt-2">
                <div className="col-12 col-lg-6 pl-2">
                    <fieldset className="border p-2 mb-2 border-secondary">
                    {bovinoDatosCat ? 
                        (
                        <small>
                        <h6>Cantidad de Bovinos por Categoría</h6>
                        <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
                        <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                            <thead>
                            <tr>
                                <th onClick={() => handleColumnClickCatBovi(1)} className={sortingColumnCatBovi === 1 ? `sorting ${sortDirectionCatBovi}` : ''}>Categoría</th>
                                <th 
                                    onClick={() => handleColumnClickCatBovi(2)} 
                                    className={`${sortingColumnCatBovi === 2 ? `sorting ${sortDirectionCatBovi}` : ''} text-end`}
                                >Stock</th>
                            </tr>
                            </thead>
                            <tbody>
                            {bovinoDatosCat.map((tupla, index) => {
                                // const fila = index + 1;
                                return (
                                <tr key={tupla.id_calculo}>
                                    <td>{tupla.categoria} ({tupla.sigla})</td>
                                    <td className="text-end">{parseInt(tupla.stock).toLocaleString('es-CL')}</td>
                                </tr>
                                );
                            })}
                            </tbody>
                        </table>
                        </div>
                        </small>
                        ) : (
                        <div className="text-center">
                            <br/><br/>
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Cargando datos...</span>
                            </div>
                            <p>Cargando cantidad de bovinos por categoría...</p>
                        </div>
                        )
                    }
                    </fieldset>
                </div>
                <div className="col-12 col-lg-6" style={{border: '0px solid white'}}>
                    <fieldset className="border p-2 mb-2 border-secondary">
                        <div className="d-flex justify-content-center align-items-center" style={{ position: 'relative'}}>
                            {bovinoChartCat ? (
                                    <Pie data={bovinoChartCat} options={options} plugins={[ChartDataLabels]}/>
                                ) : (
                                    <div className="text-center">
                                        <br/><br/>
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Cargando gráfico...</span>
                                        </div>
                                        <p>Cargando gráfico...</p>
                                    </div>
                                )
                            }
                            {bovinoChartCat && 
                                <div className="text-center" style={{ position: 'absolute', width: '100%', top: '8%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                                    <h5>Distribución Bovino Activo - Categorías</h5>
                                    <h6>Cantidad de animales por categoría</h6>
                                </div>
                            }
                        </div>
                    </fieldset>
                </div>
            </div>

            <div className="modal fade" id="potrerosModal" ref={modalPotrerosBovi} 
                tabIndex="-1" aria-labelledby="potrerosModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg border border-secondary">
                    <div className={`modal-content ${darkMode ? 'text-light bg-dark' : ''}`}>
                        <div className="modal-header border-bottom border-secondary">
                            <h5 className="modal-title" id="potrerosModalLabel">Stock Categorías por Potreros</h5>
                            <button 
                                type="button" 
                                className={`btn-close ${darkMode ? 'bg-btn-close-modal': ''}`}
                                data-bs-dismiss="modal" aria-label="Close">
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row pt-2">
                                <label className="col-sm-2">Predio:</label>
                                <div className="col-sm-10">
                                    <input type="text" name="n_predioBovi" value={n_predioBovi} 
                                    className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`} 
                                    readOnly/>
                                </div>
                            </div>

                            {isLoadingPotrerosBovi &&  
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Cargando datos...</span>
                                    </div>
                                </div>
                            }
                            {!isLoadingPotrerosBovi &&  
                            <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
                                <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                                    <thead>
                                    <tr>
                                        <th onClick={() => handleColumnClickPotreroBovi(1)} className={sortingColumnPotreroBovi === 1 ? `sorting ${sortDirectionPotreroBovi}` : ''}>Potrero</th>
                                        <th className='text-end'>{obtenerSiglaPorId(11)}</th>
                                        <th className='text-end'>{obtenerSiglaPorId(12)}</th>
                                        <th className='text-end'>{obtenerSiglaPorId(13)}</th>
                                        <th className='text-end'>{obtenerSiglaPorId(14)}</th>
                                        <th className='text-end'>{obtenerSiglaPorId(15)}</th>
                                        <th className='text-end'>{obtenerSiglaPorId(16)}</th>
                                        <th className='text-end'>{obtenerSiglaPorId(17)}</th>
                                        <th className='text-end'>{obtenerSiglaPorId(18)}</th>
                                        <th className='text-end'>Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {bovinoDatosPotreros.map(dato => {
                                        modal_total_Bov_11+=parseInt(dato.Bov_11);
                                        modal_total_Bov_12+=parseInt(dato.Bov_12);
                                        modal_total_Bov_13+=parseInt(dato.Bov_13);
                                        modal_total_Bov_14+=parseInt(dato.Bov_14);
                                        modal_total_Bov_15+=parseInt(dato.Bov_15);
                                        modal_total_Bov_16+=parseInt(dato.Bov_16);
                                        modal_total_Bov_17+=parseInt(dato.Bov_17);
                                        modal_total_Bov_18+=parseInt(dato.Bov_18);
                                        modal_total_stock+=parseInt(dato.stock);
                                        return (
                                        <tr 
                                            key={dato.id_potrero}>
                                            <td>{dato.n_potrero}</td>
                                            <td className='text-end'>{dato.Bov_11 > 0 ? parseInt(dato.Bov_11).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{dato.Bov_12 > 0 ? parseInt(dato.Bov_12).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{dato.Bov_13 > 0 ? parseInt(dato.Bov_13).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{dato.Bov_14 > 0 ? parseInt(dato.Bov_14).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{dato.Bov_15 > 0 ? parseInt(dato.Bov_15).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{dato.Bov_16 > 0 ? parseInt(dato.Bov_16).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{dato.Bov_17 > 0 ? parseInt(dato.Bov_17).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{dato.Bov_18 > 0 ? parseInt(dato.Bov_18).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{dato.stock > 0 ? parseInt(dato.stock).toLocaleString('es-CL') : ''}</td>
                                        </tr>
                                        );
                                    })}
                                        <tr>
                                            <td>Totales</td>
                                            <td className='text-end'>{modal_total_Bov_11 > 0 ? parseInt(modal_total_Bov_11).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{modal_total_Bov_12 > 0 ? parseInt(modal_total_Bov_12).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{modal_total_Bov_13 > 0 ? parseInt(modal_total_Bov_13).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{modal_total_Bov_14 > 0 ? parseInt(modal_total_Bov_14).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{modal_total_Bov_15 > 0 ? parseInt(modal_total_Bov_15).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{modal_total_Bov_16 > 0 ? parseInt(modal_total_Bov_16).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{modal_total_Bov_17 > 0 ? parseInt(modal_total_Bov_17).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{modal_total_Bov_18 > 0 ? parseInt(modal_total_Bov_18).toLocaleString('es-CL') : ''}</td>
                                            <td className='text-end'>{modal_total_stock > 0 ? parseInt(modal_total_stock).toLocaleString('es-CL') : ''}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            }
                        </div>
                        <div className="modal-footer border-top border-secondary">
                            <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>{/*fin modal*/}
        </div>
    );
};

export default DashBovino;
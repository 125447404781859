import React, { useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { isValidDate } from '../../../utils/utils';
import { useDarkMode } from '../../../context/darkModeContext'; 

function Stock() {
  // eslint-disable-next-line
  const {darkMode, setDarkMode} = useDarkMode();
  const [datosOriginales, setDatosOriginales] = useState([]); // Estado para almacenar los datos originales
  const [datos, setDatos] = useState([]); // Estado para almacenar los datos ordenados
  const [isLoadingDatos, setIsLoadingDatos] = useState(false);

  const [movimientos, setMovimientos] = useState([]);
  const [isLoadingMovimientos, setIsLoadingMovimientos] = useState(false);
  
  
  const [sortingColumn, setSortingColumn] = useState(-1); // Columna actualmente ordenada (-1 = ninguna, 0 = primera columna, 1 = segunda columna, etc.)
  const [sortDirection, setSortDirection] = useState('asc'); // Dirección de ordenamiento: 'asc' o 'desc'
  
  const inputBuscar = useRef(null);
  const [selectedBusquedaAprox, setSelectedBusquedaAprox] = useState('');

  const [id_producto, setId_producto] = useState('');
  const [n_producto, setN_producto] = useState('');
  const [stock, setStock] = useState('');

  const modalMov = useRef(null);
  
  const navigate = useNavigate();
  const modulo = 'stock'

  useEffect( ()=> {
    const storedBusquedaAprox = sessionStorage.getItem('StockSelectedBusquedaAprox');
    
    setSelectedBusquedaAprox(storedBusquedaAprox || '');
  },[]);

  const getDatos = () => {
    setIsLoadingDatos(true);
    const strBuscar = inputBuscar.current.value;
    sessionStorage.setItem('StockSelectedBusquedaAprox', strBuscar);

    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}`, 
      {
        withCredentials: true,
        params: {
          strBuscar: strBuscar
        }
      })
      .then(response => {
        setDatosOriginales(response.data); // Guardar los datos originales
        setDatos(response.data); // Hacer una copia para mantener los datos originales en 'datos' al inicio
        setIsLoadingDatos(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  const getMovimientosProducto = (v_id_producto) => {
    setIsLoadingMovimientos(true);
    axios.get(`${process.env.REACT_APP_URL_API_PORT}/${modulo}_movprod`, 
      {
        withCredentials: true,
        params: {
          id_producto: v_id_producto
        }
      })
      .then(response => {
        setMovimientos(response.data);
        setIsLoadingMovimientos(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401){delete axios.defaults.headers.common["Authorization"]; localStorage.removeItem("token"); localStorage.removeItem("usuario"); navigate('/login');}
      });
  };

  const handleKeyPressBuscar = (event) => {
    if (event.key === 'Enter') {
      getDatos();
    }
  };

  /************ INICIO FUNCIONES ORDEN ARRAY (DATOS TABLA) *************************************** */
  const handleColumnClick = (columnIndex) => {
    if (sortingColumn === columnIndex) { setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else { setSortingColumn(columnIndex); setSortDirection('asc');}
  };

  useEffect(() => {    
    const rows = [...datosOriginales]; // Hacer una copia de los datos originales para ordenarlos sin afectar los datos originales
    if (sortingColumn !== -1) {  
      rows.sort((a, b) => {
        const valA = a[Object.keys(a)[sortingColumn]]; const valB = b[Object.keys(b)[sortingColumn]];

        if (typeof valA === 'number' && typeof valB === 'number') { return valA - valB;
        } else if (isValidDate(valA) && isValidDate(valB)) { const dateA = new Date(valA.split('-').reverse().join('-')); const dateB = new Date(valB.split('-').reverse().join('-')); return dateA - dateB;
        } else { return valA.localeCompare(valB); }
      });
  
      if (sortDirection === 'desc') { rows.reverse();}
      setDatos(rows); // Actualizamos el estado de sortedRows con los datos ordenados
    }
  }, [sortingColumn, sortDirection, datosOriginales]); // Dependencias: sortingColumn, sortDirection, datosOriginales
  /************ FIN FUNCIONES ORDEN ARRAY (DATOS TABLA) *************************************** */

  /********** Funciones Modal 'movModal' *********/
  useEffect(() => {
    const modalElement = document.querySelector('#movModal');
    modalElement.addEventListener('shown.bs.modal', onModalShowMov);
    return () => {
      modalElement.removeEventListener('shown.bs.modal', onModalShowMov);
    };
  }, []);

  const onModalShowMov = () => {
  }

  const SetMovimientos = (v_id_producto, v_n_producto, v_stock) =>{
    setId_producto(v_id_producto);
    setN_producto(v_n_producto);
    const stock_formateado = v_stock.toLocaleString('es-CL', {minimumFractionDigits: 0,maximumFractionDigits: 0})
    setStock(stock_formateado)
    getMovimientosProducto(v_id_producto);
  };

  return (
    <div className="row justify-content-center w-100">
      <div className="col">
        <small>
        <div className="row pt-2 pb-1">
          <div className="col-sm-5">
            <input 
              value={selectedBusquedaAprox} onChange={(event) => { setSelectedBusquedaAprox(event.target.value)}}
              type="text" className="form-control form-control-sm" 
              placeholder="Buscar por Id / Nombre" 
              aria-label="Buscar por Id / Nombre" 
              aria-describedby="bot_buscar" 
              onKeyDown={handleKeyPressBuscar}
              autoFocus 
              ref={inputBuscar}/>
          </div>
          <div className="col-sm-2">
            <button 
              className="btn btn-outline-secondary btn-sm" type="button" 
              id="bot_buscar" onClick={() => getDatos()}>
                <i className="fa fa-search"></i>&nbsp;Buscar</button>
          </div>
        </div>
        </small>

        {isLoadingDatos &&  
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Cargando datos...</span>
            </div>
          </div>
        }
        {!isLoadingDatos &&  
        <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
          <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
            <thead className="sticky-top">
              <tr>
                <th onClick={() => handleColumnClick(1)} className={sortingColumn === 1 ? `sorting ${sortDirection}` : ''}>Id</th>
                <th onClick={() => handleColumnClick(2)} className={sortingColumn === 2 ? `sorting ${sortDirection}` : ''}>Nombre</th>
                <th onClick={() => handleColumnClick(3)} className={sortingColumn === 3 ? `sorting ${sortDirection}` : ''}>Tipo</th>
                <th onClick={() => handleColumnClick(4)} className={sortingColumn === 4 ? `sorting ${sortDirection}` : ''}>Marca</th>
                <th onClick={() => handleColumnClick(5)} className={sortingColumn === 5 ? `sorting ${sortDirection}` : 'text-end'}>Stock</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {datos.map(dato => (
                <tr key={dato.id_producto}>
                  <td>{dato.id_producto}</td>
                  <td>{dato.n_producto}</td>
                  <td>{dato.n_tipoproducto}</td>
                  <td>{dato.n_marca}</td>
                  <td className='text-end'>
                  {
                    dato.stock.toLocaleString('es-CL', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  </td>
                  <td>
                    <button 
                      onClick={() => SetMovimientos(dato.id_producto, dato.n_producto, dato.stock)}
                      data-bs-toggle="modal" data-bs-target="#movModal" 
                      className="btn btn-sm btn-outline-secondary">
                      Movimientos
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        }
      </div>

      <div className="modal fade" id="movModal" ref={modalMov} 
        tabIndex="-1" aria-labelledby="movModalLabel" aria-hidden="true">
        <div 
          className="modal-dialog modal-lg border border-secondary">
          <div className={`modal-content ${darkMode ? 'text-light bg-dark' : ''}`}>
            <div className="modal-header border-bottom border-secondary">
              <h5 className="modal-title" id="movModalLabel">Movimientos producto</h5>
              <button 
                type="button" 
                className={`btn-close ${darkMode ? 'bg-btn-close-modal': ''}`}
                data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div className="modal-body">
              <div className="row pt-2">
                <label className="col-sm-2">Id:</label>
                <div className="col-sm-4">
                  <input type="text" name="id_producto" value={id_producto} 
                  className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`}
                  readOnly/>
                </div>
              </div>

              <div className="row pt-2">
                <label className="col-sm-2">Nombre:</label>
                <div className="col-sm-10">
                  <input type="text" name="n_producto" value={n_producto} 
                  className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`}
                  readOnly/>
                </div>
              </div>

              <div className="row pt-2">
                <label className="col-sm-2">Stock:</label>
                <div className="col-sm-10">
                  <input type="text" name="stock" value={stock} 
                  className={`form-control form-control-sm ${darkMode ? 'bg-dark text-light' : ''}`}
                  readOnly/>
                </div>
              </div>

              {isLoadingMovimientos &&  
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Cargando datos...</span>
                  </div>
                </div>
              }
              {!isLoadingMovimientos &&  
              <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 150px)' }}>
                <table className={`table table-fixed table-hover table-sm small ${darkMode ? 'table-dark' : 'table-light'}`}>
                  <thead className="sticky-top">
                    <tr>
                      <th className='text-center'>Tipo Mov.</th>
                      <th className='text-center'>N° Guía/Actividad</th>
                      <th className='text-center'>Fecha</th>
                      <th className='text-end'>Cantidad</th>
                      <th className='text-center'>Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {movimientos.map(dato => (
                      <tr key={dato.id}>
                        <td className='text-center'>{dato.TipoMov}</td>
                        <td className='text-center'>{dato.id}</td>
                        <td className='text-center'>{dato.fecha_DD_MM_YYYY}</td>
                        <td className='text-end'>
                        {
                          dato.cantidad.toLocaleString('es-CL', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                        </td>
                        <td className='text-center'>{dato.estado}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              }
            </div>
            <div className="modal-footer border-top border-secondary">
              <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal">
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>{/*fin modal*/}
    </div>
  );
}

export default Stock;